<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { deleteWithJwt } from "../../lib/requests";
  import { dialogTypes, serverlessRoutes } from "../../lib/constants";
  import { trainerWorkouts } from "../../stores/trainerStores";
  import { InfiniteScroll } from "ui";
  import { dialogData } from "../../stores/dialogDataStore";
  import { trainerWorkoutsCount } from "../../stores/trainerWorkoutsCount";
  import { selectedWorkoutIds } from "../../stores/idSelectorStore";
  import { showAlert, workoutsStore } from "stores";
  import { Capacitor } from "@capacitor/core";
  import { isLoadingMore } from "../../stores/isLoadingMoreStore";
  import WorkoutExpansionPanel from "../../components/UI/WorkoutExpansionPanel.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import SelectMany from "../../components/Training/SelectMany.svelte";
  import WorkoutsToolbarComponent from "../../components/Training/WorkoutsToolbarComponent.svelte";

  let isSelectMode = false;

  const onSelectMany = (event: CustomEvent<string>): void => {
    const selectValue = event.detail;

    if (selectValue === "DELETE") {
      $dialogData.data = {
        title: "više vežbi",
        async executeFunction(): Promise<void> {
          try {
            await deleteWithJwt(serverlessRoutes.WORKOUT, {
              ids: $selectedWorkoutIds,
            });
            $trainerWorkouts = $trainerWorkouts.filter(
              ({ id }) => !$selectedWorkoutIds.includes(id)
            );
            $trainerWorkoutsCount -= $selectedWorkoutIds.length;
            $selectedWorkoutIds = [];

            $showAlert.color = "black";
            $showAlert.message = `Obrisano više vežbi.`;
          } catch (error) {
            console.error(error);
            $showAlert.color = "red-400";
            $showAlert.message = `Greška pri brisanju.`;
          }
        },
      };
      $dialogData.type = dialogTypes.CONFIRM_DELETE;
    } else if (selectValue === "SAVE") {
      // $dialogData.data = {};
      // $dialogData.type = dialogTypes.SELECT_MUSCLE_GROUP;
      // open create training plan dialog
    }
  };

  const onEditMode = (): void => {
    $selectedWorkoutIds = [];
    isSelectMode = !isSelectMode;
  };

  onDestroy(() => {
    $isLoadingMore = false;
  });

  onMount(() => {
    workoutsStore.loadCache();
    workoutsStore.fetchData();
  });
</script>

<svelte:head>
  {#if Capacitor.isNativePlatform()}
    <style>
      body {
        overflow: hidden;
      }
    </style>
  {/if}
</svelte:head>

<TopMenu entity="TRAINING" />

<div class="p-4 flex flex-col gap-4">
  <WorkoutsToolbarComponent isAddVisible />

  {#each $workoutsStore.items as workout}
    <WorkoutExpansionPanel {workout} {isSelectMode} />
  {/each}

  <InfiniteScroll
    hasMore={$workoutsStore.hasMore}
    isFetchingMore={$workoutsStore.isFetchingMore}
    on:loadMore={workoutsStore.fetchMoreData}
  />

  {#if isSelectMode}
    <SelectMany
      on:selectMany={onSelectMany}
      count={$selectedWorkoutIds.length}
      items={[
        { name: "Sačuvaj u novi program", value: "SAVE" },
        { name: "Obriši", value: "DELETE" },
      ]}
    />
  {/if}

  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
