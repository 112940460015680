<script lang="ts">
  import { onMount } from "svelte";

  import {
    formStoreCreate,
    patchWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate
  } from "lib";

  import { alertStore, dialogData, habitsStore, user } from "stores";
  import { ButtonComponent, InputField } from "ui";

  export let data: any;

  let id = 0;

  const form = formStoreCreate({
    name: ["", "NAME"],
    steps: ["", "STEPS"]
  });

  const dirty = {name: false, steps: false};

  const editDefaultHabit = async (): Promise<void> => {
    const {data: rData, error} = await patchWithJwt(`${serverlessRoutes.HABITS}/v2`, {
      partialHabitMap: {
        [id]: {
          name: $form.field.name.value,
          steps: parseInt($form.field.steps.value),
        },
      },
    });

    if (error && !rData) {
      alertStore.show(translate("ERROR_EDITING_HABIT"), "error");
      return console.error(error);
    }

    habitsStore.replace(rData.habits);

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const createDefaultHabit = async (): Promise<void> => {
    const {data: rData, error} = await postWithJwt(serverlessRoutes.HABITS, {
      habit: {
        name: $form.field.name.value,
        steps: parseInt($form.field.steps.value),
        isDefault: 1
      },
      clientId: $user.id
    });

    if (error && !rData) {
      alertStore.show(translate("ERROR_CREATING_HABIT"), "error");
      return console.error(error);
    }

    habitsStore.add([rData]);

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    $form.isLoading = true;

    if (data.habitToUpdate) {
      data.executeFunction({
        id,
        name: $form.field.name.value,
        steps: $form.field.steps.value,
      });
      $dialogData = { data: {}, type: "" };
    } else if (data.isDefaultHabit) {
      if (data.defaultHabit) {
        await editDefaultHabit();
      } else {
        await createDefaultHabit();
      }
    } else if (data.executeFunction) {
      data.executeFunction({ name: $form.field.name.value, steps: $form.field.steps.value });
      $dialogData = { data: {}, type: "" };
    }

    $form.isLoading = false;
  };

  onMount((): void => {
    if (data.habitToUpdate) {
      form.setValue("name", data.habitToUpdate.title);
      form.setValue("steps", data.habitToUpdate.steps);

      id = data.habitToUpdate.id;
    } else if (data.isDefaultHabit && data.defaultHabit) {
      form.setValue("name", data.defaultHabit.name);
      form.setValue("steps", data.defaultHabit.steps);

      id = data.defaultHabit.id;
    }

    form.validate("name");
    form.validate("steps");
  });
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {#if data.habitToUpdate}
      {translate("EDIT_HABIT")}
    {:else}
      {translate("CREATE_NEW_HABIT")}
    {/if}
  </div>

  <InputField
    label={translate("TITLE")}
    error={dirty.name ? $form.field.name.error : ""}
    bind:value={$form.field.name.value}
    on:input={() => {
      if (!dirty.name) {dirty.name = true;}
      form.validate("name");
    }}
  />

  <InputField
    type="number"
    label={translate("STEPS")}
    error={dirty.steps ? $form.field.steps.error : ""}
    bind:value={$form.field.steps.value}
    on:input={() => {
      if (!dirty.steps) {dirty.steps = true;}
      form.validate("steps");
    }}
  />

  <div class="flex justify-center">
    <ButtonComponent
      isLoading={$form.isLoading}
      disabled={$form.isDisabled}
      on:click={onSubmit}
    >
      {translate("SUBMIT")}
    </ButtonComponent>
  </div>
</div>
