<script lang="ts">
  import {onMount} from "svelte";
  import {patchWithJwt, postWithJwt, serverlessRoutes, translate} from "lib";

  import {
    alertStore,
    currentClient,
    dialogData,
    paymentsStore,
    trainerClients,
  } from "stores";

  import {ButtonComponent, InputField} from "ui";
  import Macros from "../../../components/Diet/Macros.svelte";
  import RadioField from "../../../components/UI/RadioField.svelte";
  import DualSliderInput from "../../../components/UI/DualSliderInput.svelte";

  let data: any;

  let form = {
    carbs: { value: 8.3, error: "" },
    protein: { value: 8.5, error: "" },
    fats: { value: 3.7, error: "" },
    calories: { value: 100, error: "" },
  };

  let percentages = {carbs: 33, protein: 34, fats: 33};

  let disabled = false;
  let isLoading = false;
  let radioGroup: "calories" | "grams" = "calories";

  const onInput = (field: keyof typeof form): void => {
    // if (field === "carbs") {
      // percentages.carbs = parseFloat( ((form.carbs.value * 4) / form.calories.value * 100).toFixed(1) );
    // }

    // if (field === "protein") {
      // percentages.protein = parseFloat( ((form.protein.value * 4) / form.calories.value * 100).toFixed(1) );
    // }

    // if (field === "fats") {
      // percentages.fats = parseFloat( ((form.fats.value * 9) / form.calories.value * 100).toFixed(1) );
    // }

    // percentages = percentages;
    // if (field === "calories") { return; }



    if (field === "calories") {
      // if (!form.carbs.value) { form.carbs.value = 33; }
      // if (!form.protein.value) { form.protein.value = 34; }
      // if (!form.fats.value) { form.fats.value = 33; }
    } else {
      const carbs = form.carbs.value || 0;
      const protein = form.protein.value || 0;
      const fats = form.fats.value || 0;

      form.calories.value = parseFloat((carbs * 4 + protein * 4 + fats * 9).toFixed(1));

      if (carbs === 0 && protein === 0 && fats === 0) {
        disabled = true;
      } else {
        disabled = false;
      }
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    const response = await postWithJwt(serverlessRoutes.MACRO, {
      partialMacro: {
        carbs: form.carbs.value || 0,
        protein: form.protein.value || 0,
        fats: form.fats.value || 0,
        calories: form.calories.value || 0,
        from: new Date().toISOString().slice(0, 10),
        to: null,
        userId: data.macros.userId || $currentClient.id,
      }
    });

    if (response.error) {
      isLoading = false;
      return console.error(response.error);
    }

    data.executeFunction(response);

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onDualSliderValues = (event: CustomEvent<any>): void => {
    console.log({event});
    form.carbs.value = event.detail.grams.carbs;
    form.protein.value = event.detail.grams.protein;
    form.fats.value = event.detail.grams.fats;
  };

  onMount((): void => {
    form.calories.value = data.macros?.calories || 0;

    if (data.macros?.carbs) {
      form.carbs.value = data.macros.carbs;
      percentages.carbs = parseFloat( ((form.carbs.value * 4) / form.calories.value * 100).toFixed(1) );
    }

    if (data.macros?.protein) {
      form.protein.value = data.macros.protein;
      percentages.protein = parseFloat( ((form.protein.value * 4) / form.calories.value * 100).toFixed(1) );
    }

    if (data.macros?.fats) {
      form.fats.value = data.macros.fats;
      percentages.fats = parseFloat( ((form.fats.value * 9) / form.calories.value * 100).toFixed(1) );
    }

    form = form;
    percentages = percentages;

    onInput("carbs");
    onInput("protein");
    onInput("fats");
    onInput("calories");
  });

  export { data };
</script>

<style>
  .fade {
    opacity: 0.2;
    pointer-events: none;
  }
</style>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">{translate("DAILY_MACRO_GOALS")}</div>

  <RadioField bind:group={radioGroup} value="calories">
    {translate("BY_CALORIES")}
  </RadioField>

  <div class:fade={radioGroup === "grams"}>
    <InputField
      type="number"
      label={translate("CALORIES")}
      error={form.calories.error}
      bind:value={form.calories.value}
      on:input={() => onInput("calories")}
    />
    {#if form.calories.value > 0}
      <DualSliderInput
        bind:percentages
        calories={form.calories.value}
        on:values={onDualSliderValues}
      />
    {:else}
      Unesite kalorije
    {/if}
  </div>

  <div></div>

  <RadioField bind:group={radioGroup} value="grams">
    {translate("BY_GRAMS_OF_MACRONUTRIENTS")}
  </RadioField>

  <div class:fade={radioGroup === "calories"}>
    <div class="flex gap-4">

      <InputField
        type="number"
        label={translate("CARBS")}
        disabled={radioGroup !== "grams"}
        error={form.carbs.error}
        bind:value={form.carbs.value}
        on:input={() => onInput("carbs")}
      />

      <InputField
        type="number"
        label={translate("PROTEIN")}
        disabled={radioGroup !== "grams"}
        error={form.protein.error}
        bind:value={form.protein.value}
        on:input={() => onInput("protein")}
      />

      <InputField
        type="number"
        label={translate("FATS")}
        disabled={radioGroup !== "grams"}
        error={form.fats.error}
        bind:value={form.fats.value}
        on:input={() => onInput("fats")}
      />

    </div>

    {#key form}
      <Macros
        carbs={form.carbs.value}
        protein={form.protein.value}
        fats={form.fats.value}
        calories={form.calories.value}
        isBarVisible
        isCaloriesVisible
      />
    {/key}
  </div>

  <div class="flex justify-center">
    <ButtonComponent {isLoading} {disabled} on:click={onSubmit}>
      {translate("EDIT")}
    </ButtonComponent>
  </div>

</div>
