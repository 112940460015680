import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

const isNative = Capacitor.isNativePlatform();

const get = async <T = any>(key: string): Promise<T | null> => {
  let rawValue: string | null;

  if (isNative) {
    rawValue = (await Preferences.get({ key })).value;
  } else {
    rawValue = localStorage.getItem(key);
  }

  if (rawValue === null) return null;

  // For keys that aren't supposed to be JSON parsed
  if (key === "authJwt") {
    return rawValue as T;
  }

  try {
    return JSON.parse(rawValue) as T;
  } catch (e) {
    // Fallback: if rawValue isn't valid JSON, return it as is.
    return rawValue as unknown as T;
  }
};

const set = async <T = any>(key: string, rawValue: T): Promise<void> => {
  const value = key !== "authJwt" ? JSON.stringify(rawValue) : rawValue;

  if (isNative) {
    await Preferences.set({ key, value });
  } else {
    localStorage.setItem(key, value);
  }
};

const remove = async (key: string): Promise<void> => {
  if (isNative) {
    await Preferences.remove({ key });
  } else {
    localStorage.removeItem(key);
  }
};

const clearAll = async (): Promise<void> => {
  if (isNative) {
    await Preferences.clear();
  } else {
    localStorage.clear();
  }
};

const storage = { get, set, remove, clearAll };

export { storage };
