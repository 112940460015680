<script lang="ts">
  import WorkoutExpansionPanel from "../../components/UI/WorkoutExpansionPanel.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import { getWithJwt, postWithJwt } from "../../lib/requests";
  import { dialogTypes, serverlessRoutes } from "../../lib/constants";
  import { onMount } from "svelte";
  import { user } from "../../stores/userStore";
  import Spinner from "../../components/UI/Spinner.svelte";
  import { dialogData } from "../../stores/dialogDataStore";
  import { translate } from "../../lib/translate";
  import { showAlert } from "../../stores/showAlertStore";
  import { Capacitor } from "@capacitor/core";
  import { isLoadingMore } from "../../stores/isLoadingMoreStore";
  import { ButtonComponent, InfiniteScroll } from "ui";
  import { journalWorkoutsStore } from "stores";
  import { clientWorkouts } from "../../stores/clientStores";

  let workouts: any = [];
  let workoutCount = 0;
  let page = 0;
  let isLoading = false;

  $: hasMore = page * 15 <= workoutCount;

  const fetchData = async () => {
    if (!isLoading) {
      isLoading = true;
      const url = `${serverlessRoutes.WORKOUT}/journal/${$user.id}?take=15&skip=0`;
      try {
        const data = await getWithJwt(url);
        $journalWorkoutsStore = data.data.workouts;
        workouts = data.data.workouts;
        workoutCount = data.data.count;
        isLoading = false;
      } catch (err) {}
    }
  };

  const onLoadMore = async (): Promise<void> => {
    $isLoadingMore = true;
    page += 1;
    const url = `${serverlessRoutes.WORKOUT}/journal/${$user.id}?take=15&skip=${page * 15}`;

    try {
      const response = await getWithJwt(url);
      workouts = [...workouts, ...response.data.workouts];
    } catch (error) {
      $isLoadingMore = false;
      console.error(error);
    }

    $isLoadingMore = false;
  };

  const submit = async (data) => {
    const url = serverlessRoutes.WORKOUT;
    const partialWorkout = {
      name: data.name,
      description: data.description,
      entityType: 0,
      programId: $clientWorkouts[0].programId,
      protocoll: null,
      showMacros: true,
      finishedAt: data.finishedAt
        ? new Date(data.finishedAt).toISOString()
        : new Date().toISOString(),
    };
    try {
      const res = await postWithJwt(url, { partialWorkout });
      workouts = [res.data.workout, ...workouts];
      $dialogData = {};
      $showAlert.message = `${translate("SUCCESSFULLY_CREATED")} ${translate(
        "WORKOUT"
      ).toLowerCase()}.`;
    } catch (err) {}
  };

  const openCreateEditWorkoutDialog = () => {
    $dialogData.data = {
      executeFunction: submit,
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_FINISHED_WORKOUT;
  };

  onMount(() => {
    fetchData();
  });
</script>

<TopMenu entity="TRAINING" />
<div class="p-4 flex flex-col gap-4 bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100">
  {#if isLoading}
    <Spinner />
  {:else}
    <div class="flex justify-between">
      <ButtonComponent on:click={openCreateEditWorkoutDialog}
        >{translate("ADD_WORKOUT")}</ButtonComponent
      >
      {#if workouts && workouts.length > 0}
        <!-- <ButtonComponent
          on:click={() =>
            exportToPdf($user.id, "program_journal", "TrainingJournal.pdf")}
          >{translate("EXPORT_TO_PDF")}</ButtonComponent
        > -->
      {/if}
    </div>
    <!-- <div style="margin-bottom: -8px;" /> -->
    <div class="flex flex-col gap-4">
      {#each workouts as workout}
        <div>
          <!-- <span class="finished-at mt-2 mgl-12">
            {parseDate(workout.finishedAt)}
          </span> -->
          <WorkoutExpansionPanel showComments={true} isFinished {workout} />
        </div>
      {/each}
    </div>
    <InfiniteScroll {hasMore} isFetchingMore={isLoading} on:loadMore={onLoadMore} />
  {/if}
  {#if Capacitor.isNativePlatform()}
    <div class="mb-12" />
  {/if}
  {#if Capacitor.getPlatform() === "ios"}
    <div class="pb-16" />
  {/if}
</div>

<style>
  /* .finished-at {
    align-self: flex-start;
    font-size: small;
  } */
</style>
