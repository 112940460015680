<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import GalleryEntry from "../../components/Progress/GalleryEntry.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import InfiniteScroll from "../../components/UI/InfiniteScroll.svelte";
  import Spinner from "../../components/UI/Spinner.svelte";
  import { dialogTypes, entities, serverlessRoutes } from "../../lib/constants";
  import { getWithJwt } from "../../lib/requests";
  import { dialogData } from "../../stores/dialogDataStore";
  import { refetchGallery } from "../../stores/refetchStore";
  import { user } from "../../stores/userStore";
  import { clientGalleryEntries } from "../../stores/clientStores";
  import { translate } from "lib";
  import { Capacitor } from "@capacitor/core";
  import { currentClient } from "stores";
  import { isClient } from "../../lib/roles";
  import { ButtonComponent } from "ui";

  let count: number = 0;
  let isLoading: boolean = false;
  // let skip: number = 0;
  let mutex = false;
  const take: number = 4;

  const fetchData = async () => {
    if (!isLoading && $clientGalleryEntries.length < count) {
      if ($clientGalleryEntries.length === 0) isLoading = true;
      const clientId = $currentClient?.id || $user.id;
      if (!clientId) return;
      const url = `${serverlessRoutes.GALLERY}/list?take=${take}&skip=${$clientGalleryEntries.length}&clientId=${clientId}`;
      try {
        const result = await getWithJwt(url);
        $clientGalleryEntries = [...$clientGalleryEntries, ...result.data.data];
        $clientGalleryEntries = [
          ...$clientGalleryEntries,
          ...result.data.data.filter(
            (newEntry) => !$clientGalleryEntries.some((entry) => entry.id === newEntry.id)
          ),
        ];
        count = result.data.count;
        isLoading = false;
        // skip += take;
      } catch (err) {}
    }
  };

  const forceFetchData = async () => {
    if (mutex === true) return;
    mutex = true;
    isLoading = true;
    // skip = 0;
    const clientId = $currentClient?.id || $user?.id;
    if (!clientId) return;
    const url = `${serverlessRoutes.GALLERY}/list?take=${take}&skip=${$clientGalleryEntries.length}&clientId=${clientId}`;
    try {
      const result = await getWithJwt(url);
      $clientGalleryEntries = [...result.data.data];
      count = result.data.count;
      isLoading = false;
      // skip += 4;
    } catch (err) {}
  };

  // const unsubscribeRefetch = refetchGallery.subscribe(async (shouldRefetch: boolean) => {
  //   console.log(shouldRefetch);
  //   if (shouldRefetch) {
  //     await forceFetchData();
  //     refetchGallery.set(false);
  //   }
  // });

  onDestroy(() => {
    // unsubscribeRefetch();
    if (!isClient($user)) $clientGalleryEntries = [];
  });

  onMount(() => {
    $clientGalleryEntries = [];
    // skip = $clientGalleryEntries.length;
    forceFetchData();
  });
</script>

{#if isClient($user)}
  <TopMenu entity={entities.PROGRESS} />
{/if}
<div class="flex flex-col gap-4">
  {#if isLoading}
    <Spinner />
  {:else}
    <div class="flex justify-between m-4">
      <ButtonComponent
        on:click={() => {
          $dialogData.type = dialogTypes.CREATE_EDIT_GALLERY_ENTRY;
          $dialogData.data = {};
        }}
      >
        {translate("ADD_PHOTOS")}
      </ButtonComponent>
      <ButtonComponent
        on:click={() => {
          $dialogData.type = dialogTypes.COMPARE_PHOTOS;
          $dialogData.data = {};
        }}>{translate("COMPARE_PHOTOS")}</ButtonComponent
      >
    </div>
    <h3 class="text-center">Ukupan broj unosa u galeriji: {count}</h3>
    <br />
    <div>
      {#each $clientGalleryEntries as entry}
        <GalleryEntry {entry} />
      {/each}
    </div>
    <InfiniteScroll
      hasMore={$clientGalleryEntries.length < count}
      on:loadMore={() => {
        fetchData();
      }}
    />
  {/if}
  {#if Capacitor.isNativePlatform()}
    <div class="mb-12" />
  {/if}
</div>
