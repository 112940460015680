<script lang="ts">
  import Svg from "../UI/Svg.svelte";
  import dateString from "../../lib/dateString";
    import { parseDate } from "lib";
    import { DatePicker } from "date-picker-svelte";
    import { createEventDispatcher, onDestroy, onMount } from "svelte";

  export let incrementDate;
  export let selectedDateDecremented;
  export let skip: number = 1;

  const dispatch = createEventDispatcher();

  let isCalendarVisible = false;

  let min = "1950-01-01";
  let max = "2030-01-01";

  const onCalendarSelect = (): void => {
    dispatch("select", selectedDateDecremented);
  };

  // export let dates = [
  //   "2025-02-17 13:27:06.793000",
  //   "2025-02-15 03:00:00.572166",
  //   "2025-02-14 03:00:00.387565"
  // ];
  // const parsedDates = dates.map((date) => date.slice(0, 10));
  export let parsedDates: Array<string> = [];

  const repaintDates = (curMonth: string, curYear: string) => {
    Array.from(document.getElementsByClassName("cell")).forEach((item) => {
      // if (!item) { return; }

      const curMonthInt = parseInt(curMonth);
      const curDateInt = parseInt(item.textContent || "0");
      const curDate = item.textContent.length > 1 ? curDateInt.toString() : `0${curDateInt}`;
      const dateString = `${curYear}-${curMonth.length > 1 ? curMonthInt + 1 : `0${curMonthInt + 1}`}-${curDate}`;

      if (
        !item.classList.contains("other-month") &&
        parsedDates.includes(dateString)
      ) {
        item.classList.add("date-highlighter");
      } else {
        item.classList.remove("date-highlighter");
      }
    });
  };

  let unsubMonthSelect: () => void;
  let unsubYearSelect: () => void;
  let unsubPrevButton: () => void;
  let unsubNextButton: () => void;

  const unsubDatePicker = (): void => {
    unsubMonthSelect();
    unsubYearSelect();
    unsubPrevButton();
    unsubNextButton();
  };

  let curMonth = "0";
  let curYear = "0";

  const highlightDates = () => {
    // mora timeout jer kad se pozove ova funkcija elementi jos uvek nisu renderovani u dom
    setTimeout(() => {
      const monthSelectElement = document.querySelector<HTMLSelectElement>(".dropdown.month select");
      const yearSelectElement = document.querySelector<HTMLSelectElement>(".dropdown.year select");
      const prevButtonElement = document.querySelector<HTMLButtonElement>("button[aria-label=\"Previous month\"]");
      const nextButtonElement = document.querySelector<HTMLButtonElement>("button[aria-label=\"Next month\"]");

      if (monthSelectElement) {
        curMonth = monthSelectElement.value;

        const onChange = (event: Event): void => {
          curMonth = (event.target as HTMLSelectElement).value;
          repaintDates((event.target as HTMLSelectElement).value, curYear);
        }
        monthSelectElement.addEventListener("change", onChange);
        unsubMonthSelect = () => monthSelectElement.removeEventListener("change", onChange);
      }
      if (yearSelectElement) {
        curYear = yearSelectElement.value;

        const onChange = (event: Event): void => {
          curYear = (event.target as HTMLSelectElement).value;
          repaintDates(curMonth, (event.target as HTMLSelectElement).value);
        };
        yearSelectElement.addEventListener("change", onChange);
        unsubYearSelect = () => yearSelectElement.removeEventListener("change", onChange);
      }
      const onClick = () => {
        curMonth = monthSelectElement?.value || "0";
        curYear = yearSelectElement?.value || "0";
        repaintDates(curMonth, curYear);
      }
      if (prevButtonElement) {
        prevButtonElement.addEventListener("click", onClick);
        unsubPrevButton = () => prevButtonElement.removeEventListener("click", onClick)
      }
      if (nextButtonElement) {
        nextButtonElement.addEventListener("click", onClick);
        unsubNextButton = () => nextButtonElement.removeEventListener("click", onClick)
      }

      repaintDates(curMonth, curYear);
    }, 100);
  };

  const toggleCalendar = () => {
    isCalendarVisible = !isCalendarVisible;
    if (isCalendarVisible) {
      highlightDates();
    } else {
      unsubDatePicker();
    }
  };

  // onMount(highlightDates);
  // onDestroy(unsubDatePicker);
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="relative flex-row center-center mt-3">
  <div on:click={() => incrementDate(-1 * skip)}>
    <Svg name="left-caret" size={16} myClass="mg-8" />
  </div>

  <div class="h-8 flex items-center" on:click={() => {toggleCalendar()}}>
    <h3 class="date">
      {parseDate( dateString(selectedDateDecremented) )}
    </h3>
    <Svg name="calendar" size={16} myClass="ml-2"/>
  </div>

  <div on:click={() => incrementDate(skip)}>
    <Svg name="right-caret" size={16} myClass="mg-8" />
  </div>

  {#if isCalendarVisible}
    <div class="absolute z-50 top-10 left-[50%] translate-x-[-50%]">
      <DatePicker
        bind:value={selectedDateDecremented}
        min={new Date(min)}
        max={new Date()}
        on:select={() => {isCalendarVisible = false; onCalendarSelect();}}
      />
    </div>
  {/if}

</div>
