<script lang="ts">
  import { onMount } from "svelte";
  import {
    deleteWithJwt,
    dialogTypes,
    getWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import { dialogData, showAlert, user } from "stores";
  import { ButtonComponent, Divider, ExpansionPanel, More, Svg } from "ui";
  import { uploadImageCloudflare } from "../../../../lib/cloudflare/uploadImageCloudflare.js";

  let templateMessages: any[] = [];
  let filter: string | null = null;
  let messageToAdd: string | null = null;
  let file: File;
  let fileInput: HTMLInputElement;
  let isImageEdit = false;
  let templateMessageId: number | null = null;

  const fetchTemplateMessages = async () => {
    try {
      const res = await getWithJwt(
        `${serverlessRoutes.TEMPLATE_MESSAGE}/list${filter ? `?filterBy=${filter}` : ``}`
      );
      templateMessages = res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTemplateMessage = async (id: any) => {
    try {
      await deleteWithJwt(`${serverlessRoutes.TEMPLATE_MESSAGE}/${id}`);

      templateMessages = templateMessages.filter((templateMessage) => templateMessage.id !== id);
      $showAlert.message = `${translate("TEMPLATE_MESSAGE_DELETED_SUCCESSFULLY")}`;
    } catch (error) {
      console.error(error);
    }
  };

  const onAddMessage = async () => {
    $dialogData.data = {
      executeFunction(response) {
        templateMessages = [response.data, ...templateMessages];
      },
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_TEMPLATE_MESSAGE;
  };

  const uploadImage = async () => {
    try {
      const uploadRes = await uploadImageCloudflare(file, true);
      if (!uploadRes.imageUrl) return;
      const text = uploadRes.imageUrl;
      const userId = $user.id;

      await postWithJwt(serverlessRoutes.TEMPLATE_MESSAGE, { text, userId });
      fetchTemplateMessages();
    } catch (error) {
      console.error(error);
    }
  };

  const onFileSelected = async (e: any) => {
    file = e.target.files[0];
    await uploadImage();
  };

  const onAddImage = () => {
    fileInput.click();
  };

  onMount(fetchTemplateMessages);
</script>

<ExpansionPanel title={translate("TEMPLATE_MESSAGES")}>
  <div class="flex flex-col gap-4">
    {#each templateMessages as templateMessage}
      <div>
        <div class="flex gap-4 items-center">
          <div class="grow">
            {#if templateMessage.text?.includes("imagedelivery")}
              <img alt="Template Message" class="w-20 h-20" src={templateMessage.text} />
            {:else}
              <div class="text-xxs text-justify">{templateMessage.text}</div>
            {/if}
          </div>
          <More
            menuItems={[
              {
                title: "EDIT",
                icon: "edit",
                executeFunction() {
                  if (templateMessage.text.includes("imagedelivery")) {
                  } else {
                    $dialogData.data = {
                      templateMessage,
                      executeFunction(response) {
                        const msg = templateMessages.find(({ id }) => id === response.data.id);
                        const index = templateMessages.indexOf(msg);
                        templateMessages = templateMessages.with(index, response.data);
                      },
                    };
                    $dialogData.type = dialogTypes.CREATE_EDIT_TEMPLATE_MESSAGE;
                  }
                },
              },
              {
                title: "DELETE",
                icon: "delete",
                executeFunction() {
                  $dialogData.data = {
                    title: translate("TEMPLATE_MESSAGE_U"),
                    async executeFunction() {
                      await deleteTemplateMessage(templateMessage.id);
                    },
                  };
                  $dialogData.type = dialogTypes.CONFIRM_DELETE;
                },
              },
            ]}
          />
        </div>
        <Divider width="100%" />
      </div>
    {/each}
    <div class="flex justify-center gap-4">
      <ButtonComponent isOutline on:click={onAddMessage}>
        {translate("ADD_MESSAGE")}
      </ButtonComponent>
      <ButtonComponent
        isOutline
        on:click={() => {
          isImageEdit = false;
          onAddImage();
        }}
      >
        {translate("ADD_IMAGE")}
      </ButtonComponent>
      <input
        class="hidden"
        type="file"
        accept=".jpg, .jpeg, .png, .mp4"
        on:change={(e) => onFileSelected(e)}
        bind:this={fileInput}
      />
    </div>
  </div>
</ExpansionPanel>
