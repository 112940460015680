<script lang="ts">
  import { onMount } from "svelte";
  import { deleteWithJwt, dialogTypes, getWithJwt, serverlessRoutes, translate } from "lib";
  import { dialogData, showAlert, user } from "stores";
  import { ButtonComponent, ExpansionPanel, More } from "ui";

  let defaultMeasurements: Array<any> = [];

  const onCreateDefaultMeasurement = (): void => {
    $dialogData.data = {
      isDefaultMeasurement: true,
      executeFunction(response: any) {
        defaultMeasurements = [...defaultMeasurements, response];
      },
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_MEASUREMENT;
  };

  const fetchDefaultMeasurements = async (): Promise<void> => {
    try {
      const response = await getWithJwt(
        `${serverlessRoutes.MEASUREMENT}?clientId=${$user.id}&isDefault=true`
      );
      defaultMeasurements = response.data.measurements;
    } catch (error) {
      console.error(error);
    }
  };

  onMount(fetchDefaultMeasurements);
</script>

<ExpansionPanel
  title={translate("DEFAULT_MEASUREMENTS")}
  subtitle="{translate('NUMBER_OF_ITEMS')}: {defaultMeasurements.length}"
>
  <div class="flex flex-col gap-4">
    <div class="text-xs text-justify">
      {translate("SETTINGS_DEFAULT_MEASUREMENTS_NOTE")}
    </div>
    <div class="flex flex-col gap-2">
      {#each defaultMeasurements as defaultMeasurement}
        <div class="flex items-center justify-between">
          <div class="text-xs">{defaultMeasurement.name}</div>
          <More
            menuItems={[
              {
                title: "EDIT",
                icon: "edit",
                executeFunction() {
                  $dialogData.data = {
                    isDefaultMeasurement: true,
                    defaultMeasurement,
                    executeFunction(response) {
                      const measurement = defaultMeasurements.find(
                        ({ id }) => id === response.data.id
                      );
                      const index = defaultMeasurements.indexOf(measurement);
                      defaultMeasurements = defaultMeasurements.with(index, response.data);
                    },
                  };
                  $dialogData.type = dialogTypes.CREATE_EDIT_MEASUREMENT;
                },
              },
              {
                title: "DELETE",
                icon: "delete",
                executeFunction() {
                  $dialogData.data = {
                    title: translate("MEASUREMENT_U"),
                    async executeFunction() {
                      try {
                        await deleteWithJwt(
                          `${serverlessRoutes.MEASUREMENT}/${defaultMeasurement.id}`
                        );
                        defaultMeasurements = defaultMeasurements.filter(
                          ({ id }) => id !== defaultMeasurement.id
                        );
                        $showAlert.color = "black";
                        $showAlert.message = `
                        ${translate("SUCCESSFULLY_DELETED")}
                        ${translate("MEASUREMENT").toLowerCase()}
                      `;
                      } catch (error) {
                        console.error(error);
                      }
                    },
                  };
                  $dialogData.type = dialogTypes.CONFIRM_DELETE;
                },
              },
            ]}
          />
        </div>
      {/each}
    </div>
    <div class="flex justify-center">
      <ButtonComponent isOutline on:click={onCreateDefaultMeasurement}>
        {translate("CREATE_MEASUREMENT")}
      </ButtonComponent>
    </div>
  </div>
</ExpansionPanel>
