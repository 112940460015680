<script lang="ts">
  import { scale } from "svelte/transition";
  import { dialogTypes, isClient } from "lib";
  import { dialogData, user } from "stores";
  // Client Dialogs
  import AssignMealPlan from "./ClientDialogs/AssignMealPlan.svelte";
  import AssignProgram from "./ClientDialogs/AssignProgram.svelte";
  import CreateAutoMessage from "./ClientDialogs/CreateAutoMessage.svelte";
  import CreateEditClient from "./ClientDialogs/CreateEditClient.svelte";
  import CreateEditGroup from "./ClientDialogs/CreateEditGroup.svelte";
  import CreateEditPayment from "./ClientDialogs/CreateEditPayment.svelte";
  import CreateNewReminder from "./ClientDialogs/CreateNewReminder.svelte";
  import EditClientMacros from "./ClientDialogs/EditClientMacros.svelte";
  import EditExpirationDate from "./ClientDialogs/EditExpirationDate.svelte";
  import SendToArchive from "./ClientDialogs/SendToArchive.svelte";
  import SetGoal from "./ClientDialogs/SetGoal.svelte";
  import SetTag from "./ClientDialogs/SetTag.svelte";
  import TransferToAnotherTrainer from "./ClientDialogs/TransferToAnotherTrainer.svelte";
  // Diet Dialogs
  import AssignToClients from "./DietDialogs/AssignToClients.svelte";
  import CreateEditHeaderMeal from "./DietDialogs/CreateEditHeaderMeal.svelte";
  import CreateEditIngredient from "./DietDialogs/CreateEditIngredient.svelte";
  import CreateEditMeal from "./DietDialogs/CreateEditMeal.svelte";
  import CreateEditMealPlan from "./DietDialogs/CreateEditMealPlan.svelte";
  import CreateEditPDFMeal from "./DietDialogs/CreateEditPDFMeal.svelte";
  import CreateEditRecipe from "./DietDialogs/CreateEditRecipe.svelte";
  import CreateEditRecipeMeal from "./DietDialogs/CreateEditRecipeMeal.svelte";
  import CreateEditTextMeal from "./DietDialogs/CreateEditTextMeal.svelte";
  import Fasting from "./DietDialogs/Fasting.svelte";
  import ImportIngredients from "./DietDialogs/ImportIngredients.svelte";
  import ImportRecipes from "./DietDialogs/ImportRecipes.svelte";
  import IngredientInfo from "./DietDialogs/IngredientInfo.svelte";
  import TemplateDialog from "./DietDialogs/TemplateDialog.svelte";
  // Workout Dialogs
  import CreateEditExercise from "./WorkoutDialogs/CreateEditExercise.svelte";
  import CreateEditFinishedWorkout from "./WorkoutDialogs/CreateEditFinishedWorkout.svelte";
  import CreateEditHeaderWorkout from "./WorkoutDialogs/CreateEditHeaderWorkout.svelte";
  import CreateEditPDFWorkout from "./WorkoutDialogs/CreateEditPDFWorkout.svelte";
  import CreateEditRestDay from "./WorkoutDialogs/CreateEditRestDay.svelte";
  import CreateEditTextWorkout from "./WorkoutDialogs/CreateEditTextWorkout.svelte";
  import CreateEditTrainingProgram from "./WorkoutDialogs/CreateEditTrainingProgram.svelte";
  import CreateEditWorkout from "./WorkoutDialogs/CreateEditWorkout.svelte";
  import ExercisePreview from "./WorkoutDialogs/ExercisePreview.svelte";
  import FinishExercise from "./WorkoutDialogs/FinishExercise.svelte";
  import FinishWorkout from "./WorkoutDialogs/FinishWorkout.svelte";
  import ImportExercise from "./WorkoutDialogs/ImportExercise.svelte";
  import ImportWorkouts from "./WorkoutDialogs/ImportWorkouts.svelte";
  import SelectMuscleGroup from "./WorkoutDialogs/SelectMuscleGroup.svelte";
  // Other
  import CantDoExercises from "./CantDoExercises.svelte";
  import ConfirmDelete from "./ConfirmDelete.svelte";
  import CreateEditGalleryEntry from "./CreateEditGalleryEntry.svelte";
  import ComparePhotos from "./ComparePhotos.svelte";
  import CreateEditGoal from "./CreateEditGoal.svelte";
  import ImageZoom from "./ImageZoom.svelte";
  import ConfirmAccountDeleteDialog from "./ConfirmAccountDeleteDialog.svelte";
  import AskForPhonenumberDialog from "./AskForPhonenumberDialog.svelte";
  import VideoZoom from "./VideoZoom.svelte";
  import TemplateMessageDialog from "./TemplateMessageDialog.svelte";
  import CreateEditHabitDialog from "./CreateEditHabitDialog.svelte";
  import GenerateWorkout from "./WorkoutDialogs/GenerateWorkout.svelte";
  import CreateEditMeasurementDialog from "./CreateEditMeasurementDialog.svelte";
  import CreateEditTag from "./CreateEditTag.svelte";
  import CreateEditTemplateMessage from "./CreateEditTemplateMessage.svelte";
  import BookmarkedMessagesDialog from "./BookmarkedMessagesDialog.svelte";
  import ConnectWithParentExerciseDialog from "./ConnectWithParentExerciseDialog.svelte";
  import EditComment from "./EditComment.svelte";
  import SetDateOfBirth from "./SetDateOfBirth.svelte";
  import ExerciseHistoryV2 from "./WorkoutDialogs/ExerciseHistoryV2.svelte";
  import FinishExerciseV2 from "./WorkoutDialogs/FinishExerciseV2.svelte";
  import FinishWorkoutV2 from "./WorkoutDialogs/FinishWorkoutV2.svelte";
  import ForceUpdateDialog from "./ForceUpdateDialog.svelte";
  import VideoZoomV2 from "./VideoZoomV2.svelte";
  import Svg from "../UI/Svg.svelte";
  import { get } from "svelte/store";
  import NotificationDialog from "./NotificationDialog.svelte";

  export let isScrollable = true;
</script>

<div
  id="dialog"
  data-cy="dialog"
  class="
    dialog
    bg-white
    dark:bg-zinc-800
    text-slate-900
    dark:text-slate-100
    border
    border-slate-200
    dark:border-zinc-600
  "
  class:overflow-y-scroll={isScrollable}
  bind:this={$dialogData.elem}
>
  <div class="absolute top-4 right-4">
    {#if isClient(get(user))}
      <Svg
        name="x"
        size={24}
        on:click={() => {
          dialogData.set({ type: "", data: {} });
        }}
      />
    {/if}
  </div>
  <!-- Client Dialogs -->
  {#if $dialogData.type === dialogTypes.ASSIGN_MEAL_PLAN}
    <AssignMealPlan data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.ASSIGN_WORKOUT_PLAN}
    <AssignProgram data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_AUTO_MESSAGE}
    <CreateAutoMessage data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_CLIENT}
    <CreateEditClient data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_GROUP}
    <CreateEditGroup data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_HABIT}
    <CreateEditHabitDialog data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_TAG}
    <CreateEditTag data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_TEMPLATE_MESSAGE}
    <CreateEditTemplateMessage data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_MEASUREMENT}
    <CreateEditMeasurementDialog data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_PAYMENT}
    <CreateEditPayment data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_NEW_REMINDER}
    <CreateNewReminder data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.EDIT_CLIENT_MACROS}
    <EditClientMacros data={$dialogData.data}/>
  {/if}
  {#if $dialogData.type === dialogTypes.EDIT_EXPIRATION_DATE}
    <EditExpirationDate data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.SEND_TO_ARCHIVE}
    <SendToArchive data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.SET_GOAL}
    <SetGoal />
  {/if}
  {#if $dialogData.type === dialogTypes.GENERATE_WORKOUT}
    <GenerateWorkout />
  {/if}
  {#if $dialogData.type === dialogTypes.SET_TAG}
    <SetTag />
  {/if}
  {#if $dialogData.type === dialogTypes.TRANSFER_TO_ANOTHER_TRAINER}
    <TransferToAnotherTrainer data={$dialogData.data} />
  {/if}
  <!-- Diet Dialogs -->
  {#if $dialogData.type === dialogTypes.ASSIGN_TO_CLIENTS}
    <AssignToClients data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_HEADER_MEAL}
    <CreateEditHeaderMeal data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_INGREDIENT}
    <div in:scale><CreateEditIngredient data={$dialogData.data} /></div>
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_MEAL}
    <CreateEditMeal data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_MEAL_PLAN}
    <CreateEditMealPlan data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_PDF_MEAL}
    <CreateEditPDFMeal data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_RECIPE}
    <div in:scale><CreateEditRecipe data={$dialogData.data} /></div>
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_RECIPE_MEAL}
    <CreateEditRecipeMeal data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_TEXT_MEAL}
    <CreateEditTextMeal data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.FASTING}
    <Fasting data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.IMPORT_INGREDIENTS}
    <ImportIngredients data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.IMPORT_RECIPES}
    <ImportRecipes data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.INGREDIENT_INFO}
    <IngredientInfo data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.TEMPLATE}
    <div in:scale><TemplateDialog data={$dialogData.data} /></div>
  {/if}
  <!-- Workout Dialogs -->
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_EXERCISE}
    <CreateEditExercise data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_FINISHED_WORKOUT}
    <CreateEditFinishedWorkout data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_HEADER_WORKOUT}
    <CreateEditHeaderWorkout data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_PDF_WORKOUT}
    <CreateEditPDFWorkout data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_REST_DAY}
    <CreateEditRestDay data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_TEXT_WOKROUT}
    <CreateEditTextWorkout data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_PROGRAM}
    <CreateEditTrainingProgram data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_WORKOUT}
    <CreateEditWorkout data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.EXERCISE_HISTORY_V2}
    <ExerciseHistoryV2 data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.EXERCISE_PREVIEW}
    <ExercisePreview data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.FINISH_EXERCISE}
    <FinishExercise data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.FINISH_EXERCISE_V2}
    <FinishExerciseV2 data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.FINISH_WORKOUT}
    <FinishWorkout />
  {/if}
  {#if $dialogData.type === dialogTypes.FINISH_WORKOUT_V2}
    <FinishWorkoutV2 data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.BOOKMARKED_MESSAGES}
    <BookmarkedMessagesDialog />
  {/if}
  {#if $dialogData.type === dialogTypes.IMPORT_EXERCISE}
    <ImportExercise data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.IMPORT_WORKOUTS}
    <ImportWorkouts data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.SELECT_MUSCLE_GROUP}
    <SelectMuscleGroup />
  {/if}
  <!-- Other -->
  {#if $dialogData.type === dialogTypes.CANT_DO_EXERCISES}
    <CantDoExercises />
  {/if}
  {#if $dialogData.type === dialogTypes.CONFIRM_DELETE}
    <ConfirmDelete data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CONNECT_WITH_PARENT_EXERCISE}
    <ConnectWithParentExerciseDialog data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_GALLERY_ENTRY}
    <CreateEditGalleryEntry data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.COMPARE_PHOTOS}
    <ComparePhotos />
  {/if}
  {#if $dialogData.type === dialogTypes.CREATE_EDIT_GOAL}
    <CreateEditGoal data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.EDIT_COMMENT}
    <EditComment data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.IMAGE_ZOOM}
    <ImageZoom data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.NOTIFICATION}
    <NotificationDialog data={$dialogData.data} />
  {/if}
  <!-- {#if $dialogData.type === dialogTypes.IMAGE_ZOOM}
    <ImageZoom data={$dialogData.data} />
  {/if} -->
  {#if $dialogData.type === dialogTypes.SET_DATE_OF_BIRTH}
    <SetDateOfBirth data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.VIDEO_ZOOM}
    <VideoZoom data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.VIDEO_ZOOM_V2}
    <VideoZoomV2 data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.TEMPLATE_MESSAGES}
    <TemplateMessageDialog />
  {/if}
  {#if $dialogData.type === dialogTypes.CONFIRM_DELETE_ACCOUNT}
    <ConfirmAccountDeleteDialog data={$dialogData.data} />
  {/if}
  {#if $dialogData.type === dialogTypes.ASK_FOR_PHONENUMBER}
    <AskForPhonenumberDialog />
  {/if}
  {#if $dialogData.type === dialogTypes.FORCE_UPDATE}
    <ForceUpdateDialog />
  {/if}
</div>

<style>
  .dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 90%;
    height: fit-content;
    width: 90%;
    margin: auto;
    border-radius: 0.375rem;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    z-index: 3;
    padding: 0;

    /*  */
    /* max-height: 90%; */
    /* height: fit-content; */
  }

  @media only screen and (min-width: 992px) {
    .dialog {
      width: 680px;
    }
  }
</style>
