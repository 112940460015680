<script lang="ts">
  import { afterUpdate } from "svelte";
  import { push } from "svelte-spa-router";

  import {
    deleteWithJwt,
    dialogTypes,
    filterMenu,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";

  import {
    alertStore,
    currentClient,
    dialogData,
    selectedIds,
    trainerClientPrograms,
    trainerPrograms,
    trainerProgramsCount,
  } from "stores";

  import { ButtonComponent, More, Svg, TemplateTagComponent } from "ui";
  import type { MenuItem, PartialItemMap, Program } from "interfaces";

  let program: Program;
  let isSelected = false;

  const src = program.fileHash || program.imageUrl || "logo.png";

  const saveToDatabase = async (): Promise<void> => {
    const partialProgramMap: PartialItemMap<Program> = {
      [program.id]: {
        clientId: null,
        default: 1,
        isTemplate: 0,
      },
    };

    const { error } = await postWithJwt(`${serverlessRoutes.PROGRAM}/copy`, {
      partialProgramMap,
    });

    if (error) {
      alertStore.show(error, "error");
      return console.error(error);
    }

    alertStore.show(translate("SUCCESSFULLY_SAVED_TO_DATABASE"));
  };

  const assignToClientsDialog = (): void => {
    $dialogData.data = { program };
    $dialogData.type = dialogTypes.ASSIGN_TO_CLIENTS;
  };

  const editProgramDialog = (): void => {
    $dialogData.data = { program };
    $dialogData.type = dialogTypes.CREATE_EDIT_PROGRAM;
  };

  const copyProgram = async (): Promise<void> => {
    const partialProgramMap: PartialItemMap<Program> = {
      [program.id]: {
        isTemplate: 0,
      },
    };

    const { data, error } = await postWithJwt(`${serverlessRoutes.PROGRAM}/copy`, {
      partialProgramMap,
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_COPYING_MEAL_PLAN"), "error");
      return console.error(error);
    }

    if (program.clientId) {
      $trainerClientPrograms = [...data.programs, ...$trainerClientPrograms];
    } else {
      $trainerPrograms = [...data.programs, ...$trainerPrograms];
      $trainerProgramsCount += 1;
    }

    alertStore.show(`${translate("SUCCESSFULLY_COPIED")} program.`);
  };

  const deleteProgram = async (): Promise<void> => {
    const { id, name } = program;

    if (name.includes("Cypress Program")) {
      await deleteWithJwt(`${serverlessRoutes.PROGRAM}/test`, { id });
    } else {
      await deleteWithJwt(serverlessRoutes.PROGRAM, {
        ids: [id],
      });
    }

    const p = $trainerPrograms.find((p) => p.id === id);
    const cp = $trainerClientPrograms.find((cp) => cp.id === id);

    if (p) {
      $trainerPrograms.splice($trainerPrograms.indexOf(p), 1);
      $trainerPrograms = $trainerPrograms;
      $trainerProgramsCount -= 1;
    }

    if (cp) {
      $trainerClientPrograms.splice($trainerClientPrograms.indexOf(cp), 1);
      $trainerClientPrograms = $trainerClientPrograms;
    }

    alertStore.show(`${translate("SUCCESSFULLY_DELETED")} program`);
  };

  const deleteProgramDialog = (): void => {
    $dialogData.data = {
      title: program.name,
      executeFunction: deleteProgram,
    };

    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const unfilteredMenuItems: Array<MenuItem> = [
    {
      title: "ASSIGN_TO_CLIENTS",
      icon: "assign",
      executeFunction: assignToClientsDialog,
    },
    {
      title: "ASSIGN_TO_ANOTHER_CLIENT",
      icon: "assign",
      executeFunction: assignToClientsDialog,
    },
    {
      title: "SAVE_TO_DATABASE",
      icon: "save",
      executeFunction: saveToDatabase,
    },
    {
      title: "EDIT",
      icon: "edit",
      executeFunction: editProgramDialog,
    },
    {
      title: "CREATE_COPY",
      icon: "copy",
      executeFunction: copyProgram,
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction: deleteProgramDialog,
    },
  ];

  let menuItems: Array<MenuItem> = [];

  const toggleSelect = (): void => {
    const { id } = program;

    if (isSelected) {
      $selectedIds = [...$selectedIds, id];
    } else {
      $selectedIds = $selectedIds.filter((f) => f !== id);
    }
  };

  const onGotoProgramScreen = (): void => {
    // if (isSelectable) {
    //   isSelected = !isSelected;
    //   return toggleSelect();
    // }

    const { id } = program;

    if (window.location.href.includes("/profile")) {
      push(`/profile/${$currentClient.id}/training/programs/${id}`);
    } else {
      push(`/training/programs/${id}`);
    }
  };

  const generateMenu = (): void => {
    const { isTemplate, clientId, default: isDefault } = program;

    if (isTemplate) {
      menuItems = filterMenu(unfilteredMenuItems, ["CREATE_COPY"]);
    } else if (isDefault) {
      menuItems = filterMenu(unfilteredMenuItems, [
        "ASSIGN_TO_CLIENTS",
        "EDIT",
        "CREATE_COPY",
        "DELETE",
      ]);
    } else if (clientId) {
      menuItems = filterMenu(unfilteredMenuItems, [
        "ASSIGN_TO_CLIENTS",
        "SAVE_TO_DATABASE",
        "EDIT",
        "CREATE_COPY",
        "DELETE",
      ]);
    }
  };

  const onImport = async (): Promise<void> => {};

  afterUpdate(generateMenu);

  export { program };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="
    relative
    flex
    flex-col
    border
    border-slate-200
    rounded-md
    transition-[border-color, opacity]
    linear
    duration-[333ms]
    dark:bg-zinc-800
    dark:border-zinc-600
  "
  class:isSelected
  data-cy="program"
  on:click={onGotoProgramScreen}
>
  {#if program && program.isTemplate}
    <TemplateTagComponent />
  {/if}

  <div class="flex items-center justify-center">
    <img {src} class="max-h-64 rounded-t-md" alt="Program" />
  </div>

  <div class="p-4 flex justify-between gap-4">
    <div class="flex flex-col gap-4">
      <div data-cy="program-name">{program.name}</div>
      {#if program.duration}
        <div class="text-xs">
          {translate("DURATION")}: {program.duration}
          {translate("DAYS").toLowerCase()}
        </div>
      {/if}
      {#if program.description}
        <div class="text-xs text-justify line-clamp-3">
          {program.description}
        </div>
      {/if}
      {#if program.default || program.isTemplate}
        <div>
          <ButtonComponent on:click={assignToClientsDialog}>
            <div class="flex flex-row items-center">
              <Svg myClass="mr-2" name="assign" size={20} customColor="bg-white" />
              {translate("ASSIGN_TO_CLIENTS")}
            </div>
          </ButtonComponent>
        </div>
      {/if}
    </div>

    <div class="flex items-start justify-between">
      {#if program.clientId}
        <div></div>
        <More {menuItems} />
      {:else if program.clientGroupId || program.groupId}
        <More {menuItems} />
      {:else if program.isTemplate}
        <ButtonComponent on:click={onImport}>
          <div class="flex flex-row items-center">
            <Svg myClass="mr-2" name="import" size={20} customColor="bg-white" />
            {translate("IMPORT")}
          </div>
        </ButtonComponent>
      {:else}
        <More {menuItems} />
      {/if}
    </div>
  </div>
</div>

<style>
  .isSelected {
    border-color: rgb(var(--primary));
  }
</style>
