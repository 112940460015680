<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { Capacitor } from "@capacitor/core";
  import { getWithJwt, serverlessRoutes, translate } from "lib";
  import { activeMealsStore, user } from "stores";
  import TopMenu from "../../components/TopMenu.svelte";
  import Macros from "../../components/Diet/Macros.svelte";
  import MealExpansionPanel from "../../components/Diet/MealExpansionPanel.svelte";
  import Spinner from "../../components/UI/Spinner.svelte";

  let macrosLatest = { calories: 0, carbs: 0, fats: 0, protein: 0 };
  let isLoading = false;

  const fetchMacros = async () => {
    if (macrosLatest?.calories === 0) {
      const url = `${serverlessRoutes.MACRO}?clientId=${$user.id}`;
      try {
        const data = await getWithJwt(url);
        const activeMacros = data.data.find((macros) => macros.to === null);
        if (activeMacros) {
          macrosLatest = activeMacros;
        }
      } catch (err) {}
    }
  };

  onMount(async () => {
    isLoading = true;
    await Promise.all([activeMealsStore.fetchData(), fetchMacros()]);
    activeMealsStore.calculateHeadersMacros();
    isLoading = false;
  });

  const onExportPdf = async (): Promise<void> => {
    // mzm kako da izvucem id od mealPlan ?
    // await exportToPdf($mealPlansStore.items[0].id, "meal_plan", "MealPlan.pdf")
  };
</script>

<TopMenu entity="DIET" />

<div class="p-4 bg-white dark:bg-zinc-800">
  {#if isLoading}
    <Spinner />
  {:else}
    <div class="mb-4 p-2 border rounded-md border-slate-200 dark:border-zinc-600 text-center">
      <div class="mb-4">
        {translate("SUGGESTED_MACROS")}
        <span class="text-amber-400">{macrosLatest?.calories}</span>
        kcal
      </div>
      <Macros
        carbs={macrosLatest?.carbs}
        fats={macrosLatest?.fats}
        calories={macrosLatest?.calories}
        protein={macrosLatest?.protein}
        isBarVisible
      />
    </div>
    {#if $activeMealsStore.items.length > 0}
      <div class="flex justify-center">
        <!-- <ButtonComponent myClasses="m-4" on:click={onExportPdf}>
          {translate("EXPORT_TO_PDF")}
        </ButtonComponent> -->
      </div>
    {/if}
    <div class="flex flex-col gap-4">
      {#each $activeMealsStore.items as meal}
        <MealExpansionPanel isCommentable {meal} />
      {/each}

      {#if Capacitor.getPlatform() === "ios"}
        <div class="mb-1"></div>
      {/if}
    </div>
  {/if}
  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
