<script lang="ts">
  import { onMount } from "svelte";
  import {
    Heading,
    Helper,
    Input,
    Label,
    Radio,
    Spinner,
    Checkbox,
    P,
    Search,
  } from "flowbite-svelte";
  import { postWithJwt, translate } from "lib";
  import { dialogData, selectedIds, showAlert, trainerClientGroupsCount } from "stores";
  import { trainerClientGroups, trainerClients } from "../../../stores/trainerStores";
  import ClientListItem from "../../../components/ClientListItem.svelte";
  import { ButtonComponent } from "ui";

  let data: any;
  let isLoading = false;
  let disabled = true;
  let step = 1;
  let value = "";
  let filteredClients = $trainerClients.filter((client) => $selectedIds.includes(client.id));

  const onSearch = (): void => {};

  const form = {
    name: { value: "", error: "" },
    clientsCanPost: { value: false, error: "" },
    autoPostFinishedWorkout: { value: false, error: "" },
    notifySettings: { value: 0, error: "" },
  };

  const onInput = (field: keyof typeof form): void => {
    if (field === "name") {
      const { value } = form[field];

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 40) {
        form[field].error = translate("FIELD_MAXIMUM_40");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onChange = (): void => {
    filteredClients = $trainerClients.filter((client) => $selectedIds.includes(client.id));
  };

  const onNextStep = (): void => {
    step += 1;
  };
  const onPreviousStep = (): void => {
    step -= 1;
  };

  const editGroup = async (): Promise<void> => {
    try {
      const response = {};

      const group = $trainerClientGroups.find((group): boolean => group.id === data.group.id);

      const index = $trainerClientGroups.indexOf(group);

      $trainerClientGroups = $trainerClientGroups.with(index, response);

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("GROUP_A")}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      isLoading = false;
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_EDITING_GROUP");
    }
  };

  const createGroup = async (): Promise<void> => {
    try {
      const response = await postWithJwt(``, {
        clientGroup: {
          name: form.name.value,
          clientsCanPost: form.clientsCanPost.value,
          autoPostFinishedWorkout: form.autoPostFinishedWorkout.value,
          autoPostCompletedCardio: true, // odakle ovo dolazi?
          notifySettings: form.notifySettings.value,
        },
        clientIds: $selectedIds,
      });

      $trainerClientGroups = [...$trainerClientGroups, response];
      $trainerClientGroupsCount += 1;

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_CREATED_GROUP");

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      isLoading = false;
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_GROUP");
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.group) {
      await editGroup();
    } else {
      await createGroup();
    }
  };

  onMount((): void => {
    if (data.group) {
      // treba iz selectedIds da se izbaci vukasin? nzm zasto je on hidden svuda
      $selectedIds = data.group.chatSession.participants
        .map(({ userId }) => userId)
        .filter((id) => id !== 17833); // ovo je vukasinov ID

      form.name.value = data.group.name;
      form.clientsCanPost.value = data.group.clientsCanPost;
      form.autoPostFinishedWorkout.value = data.group.autoPostFinishedWorkout;
      form.notifySettings.value = data.group.notifySettings;
      // fali autoPostCompletedCardio, ali ne znam odakle to dolazi...
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {data.group ? translate("EDIT_GROUP") : translate("CREATE_GROUP")}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    {#if step === 1}
      <P>1. {translate("SELECT_CLIENTS")}</P>

      <Search placeholder={translate("SEARCH_CLIENT")} bind:value on:input={onSearch} />

      <div class="pr-4 h-48 flex flex-col gap-4 overflow-y-scroll">
        {#each $trainerClients as client}
          <ClientListItem {client} isSelectMode on:change={onChange} />
        {/each}
      </div>

      <div class="flex justify-end">
        <ButtonComponent disabled={$selectedIds.length < 2} on:click={onNextStep}>
          {translate("NEXT_STEP")}
        </ButtonComponent>
      </div>
    {:else if step === 2}
      <P>2. {translate("CLIENTS_PREVIEW")}</P>

      <div class="pr-4 h-48 flex flex-col gap-4 overflow-y-scroll">
        {#each filteredClients as client}
          <ClientListItem {client} isSelectMode on:change={onChange} />
        {/each}
      </div>

      <div class="flex justify-between">
        <ButtonComponent on:click={onPreviousStep}>
          {translate("PREVIOUS_STEP")}
        </ButtonComponent>
        <ButtonComponent disabled={$selectedIds.length < 2} on:click={onNextStep}>
          {translate("NEXT_STEP")}
        </ButtonComponent>
      </div>
    {:else}
      <P>3. {translate("GROUP_SETTINGS")}</P>

      <div>
        <Label for="name">{translate("NAME")}:</Label>
        <Input
          id="name"
          placeholder={translate("NAME")}
          bind:value={form.name.value}
          on:input={() => onInput("name")}
        />
        <Helper color="red">
          {#if form.name.error}
            {form.name.error}
          {:else}
            ㅤ
          {/if}
        </Helper>
      </div>

      <div>
        <Label>{translate("GROUP_SETTINGS")}:</Label>
        <Checkbox bind:checked={form.clientsCanPost.value}>
          {translate("CLIENTS_CAN_POST")}
        </Checkbox>
        <Checkbox bind:checked={form.autoPostFinishedWorkout.value}>
          {translate("AUTO_POST_FINISHED_WORKOUT")}
        </Checkbox>
      </div>

      <div>
        <Label>{translate("NOTIFICATIONS_SETTINGS")}:</Label>
        <Radio value="0" bind:group={form.notifySettings.value}>
          {translate("ALWAYS")}
        </Radio>
        <Radio value="1" bind:group={form.notifySettings.value}>
          {translate("ON_MENTION_ONLY")}
        </Radio>
        <Radio value="2" bind:group={form.notifySettings.value}>
          {translate("NEVER")}
        </Radio>
      </div>

      {#if isLoading}
        <div class="h-10 flex justify-center">
          <Spinner size="10" color="green" />
        </div>
      {:else}
        <div class="flex justify-between">
          <ButtonComponent on:click={onPreviousStep}>
            {translate("PREVIOUS_STEP")}
          </ButtonComponent>
          <ButtonComponent type="submit" {disabled}>
            {data.group ? translate("EDIT") : translate("CREATE")}
          </ButtonComponent>
        </div>
      {/if}
    {/if}
  </form>
</div>
