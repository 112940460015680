<script lang="ts">
  import { ButtonComponent } from "ui";
  import { onMount } from "svelte";
  import {
    animations,
    deleteWithJwt,
    getNewPositions,
    getWithJwt,
    patchWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import Svg from "../UI/Svg.svelte";
  import Divider from "../UI/Divider.svelte";
  import { payload } from "../../stores/payloadStore";
  import { dialogData } from "../../stores/dialogDataStore";
  import { user } from "../../stores/userStore";
  import type { ITemplateMessage } from "../../interfaces/ITemplateMessage";
  import { alertStore, showAlert } from "stores";
  import { uploadImageCloudflare } from "../../lib/cloudflare/uploadImageCloudflare";
  import { dragHandle, dragHandleZone, type DndEvent } from "svelte-dnd-action";
  import { flip } from "svelte/animate";

  let templateMessages: ITemplateMessage[] = [];
  let deleteMessageId: number | null = null;
  let filter: string | null = null;
  let messageToAdd: string | null = null;
  let fileInput: HTMLElement;
  let file: File;

  const fetchTemplateMessages = async () => {
    try {
      const res = await getWithJwt(
        `${serverlessRoutes.TEMPLATE_MESSAGE}/list${filter ? `?filterBy=${filter}` : ``}`
      );

      templateMessages = res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTemplateMessage = async () => {
    try {
      await deleteWithJwt(`${serverlessRoutes.TEMPLATE_MESSAGE}/${deleteMessageId}`);
      templateMessages = templateMessages.filter(
        (templateMessage) => templateMessage.id !== deleteMessageId
      );
      deleteMessageId = null;
      $showAlert.message = `${translate("TEMPLATE_MESSAGE_DELETED_SUCCESSFULLY")}`;
    } catch (error) {
      console.error(error);
      deleteMessageId = null;
    }
  };

  const onFileSelected = (e: any) => {
    file = e.target.files[0];
    addTemplateMessage();
  };

  const addTemplateMessage = async () => {
    try {
      const templateMessage = { userId: $user.id, text: "" };

      if (!messageToAdd) messageToAdd = "";

      if (file) {
        const uploadResult = await uploadImageCloudflare(file, false);
        if (!uploadResult?.imageUrl) return;
        templateMessage.text = uploadResult.imageUrl;
      } else {
        templateMessage.text = messageToAdd;
      }

      await postWithJwt(serverlessRoutes.TEMPLATE_MESSAGE, templateMessage);

      fetchTemplateMessages();

      messageToAdd = null;
    } catch (error) {
      console.error(error);
      messageToAdd = null;
    }
  };

  const sendMessageOrFile = async (templateMessage: ITemplateMessage) => {
    if (templateMessage.text.includes("imagedelivery")) {
      await $dialogData.data.sendMessage(`:cloudflareimg:${templateMessage.text}`);
    } else {
      $payload = templateMessage.text;
    }

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onChangeFilter = async (selectedFilter: "messages" | "images" | "links") => {
    if (filter === selectedFilter) {
      filter = null;
    } else {
      filter = selectedFilter;
    }

    await fetchTemplateMessages();
  };

  const dndConsider = async (event: CustomEvent<DndEvent<any>>): Promise<void> => {
    templateMessages = event.detail.items;
  };

  const dndFinalize = async (event: CustomEvent<DndEvent<any>>): Promise<void> => {
    const partialTemplateMessageMap = getNewPositions(event.detail.items);

    if (!partialTemplateMessageMap) {
      return;
    }

    const { error, data } = await patchWithJwt(`${serverlessRoutes.TEMPLATE_MESSAGE}/v2`, {
      partialTemplateMessageMap,
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_CHANGING_ORDER"), "error");
      return console.error(error);
    }

    templateMessages = event.detail.items;

    alertStore.show(translate("SUCCESSFULLY_CHANGED_ORDER"));
  };

  onMount(fetchTemplateMessages);
</script>

<div class="p-4 flex flex-col gap-4">
  <div
    class="flex flex-row w-full justify-center sticky inset-0 bg-white dark:bg-zinc-800 p-3 pt-5 z-10"
  >
    <ButtonComponent isPill on:click={() => onChangeFilter("messages")}>
      <Svg name="message" size={19} customColor={"bg-white"} />
    </ButtonComponent>
    <ButtonComponent isPill on:click={() => onChangeFilter("images")}>
      <Svg name="camera" size={19} customColor={"bg-white"} />
    </ButtonComponent>
    <ButtonComponent isPill on:click={() => onChangeFilter("links")}>
      <Svg name="text" size={19} customColor={"bg-white"} />
    </ButtonComponent>
  </div>

  {#if messageToAdd !== null}
    <input
      class="w-4/5 border-none"
      placeholder={translate("TYPE_HERE")}
      type="text"
      bind:value={messageToAdd}
    />
    <Divider width={"80%"} />
  {/if}

  <div
    class="flex flex-col gap-4"
    use:dragHandleZone={{
      items: templateMessages,
      flipDurationMs: 0,
      dropTargetStyle: {},
      dropFromOthersDisabled: true,
      type: "templateMessages",
    }}
    on:consider={dndConsider}
    on:finalize={dndFinalize}
  >
    {#each templateMessages as templateMessage (templateMessage.id)}
      <div animate:flip={animations.flip}>
        <div class="flex gap-4 items-center justify-between">
          {#if !filter}
            <div use:dragHandle>
              <Svg name="drag" size={16} />
            </div>
          {/if}

          {#if templateMessage.text?.includes("imagedelivery")}
            <img alt="Template Message" class="w-20 h-20" src={templateMessage.text} />
          {:else if templateMessage.fileable}
            <img alt="Template Message" class="w-20 h-20" src={templateMessage.imageUrl} />
          {:else}
            <div class="basis-[66%] text-xxs text-justify break-all">
              {templateMessage.text}
            </div>
          {/if}

          <div
            class="flex gap-4 basis-[64px] items-end"
            class:flex-col={templateMessage.text && templateMessage.text.length > 64}
          >
            <div
              tabindex="0"
              role="button"
              on:click={() => (deleteMessageId = templateMessage.id ?? null)}
              on:keypress={() => (deleteMessageId = templateMessage.id ?? null)}
            >
              <Svg name="delete" size={24} customColor="bg-red-500" />
            </div>
            <div
              tabindex="0"
              role="button"
              on:click={() => sendMessageOrFile(templateMessage)}
              on:keypress={() => sendMessageOrFile(templateMessage)}
            >
              <Svg
                name="right-arrow-button"
                size={24}
                customColor="bg-primary-500 dark:bg-primary-500"
              />
            </div>
          </div>
        </div>

        <Divider width={"100%"} />
      </div>
    {/each}
  </div>

  <div class="flex flex-row sticky w-full justify-center inset-0 bg-white dark:bg-zinc-800 p-5">
    {#if deleteMessageId}
      <ButtonComponent myClasses="mr-1" customColor="bg-red-500" on:click={deleteTemplateMessage}>
        {translate("DELETE_TEMPLATE_MESSAGE")}
      </ButtonComponent>
      <ButtonComponent myClasses="ml-1" on:click={() => (deleteMessageId = null)}>
        {translate("CANCEL")}
      </ButtonComponent>
    {/if}
    {#if messageToAdd}
      <ButtonComponent on:click={addTemplateMessage}>
        {translate("ADD_TEMPLATE_MESSAGE")}
      </ButtonComponent>
      <ButtonComponent
        customColor="bg-red-500"
        myClasses="ml-1"
        on:click={() => (messageToAdd = null)}
      >
        {translate("CANCEL")}
      </ButtonComponent>
    {/if}
    {#if !deleteMessageId && !messageToAdd}
      <ButtonComponent myClasses="mr-1" on:click={() => (messageToAdd = "")}>
        {translate("ADD_MESSAGE")}
      </ButtonComponent>
      <ButtonComponent myClasses="ml-1" on:click={() => fileInput.click()}>
        {translate("ADD_PHOTO")}
      </ButtonComponent>
      <input
        style="display:none"
        type="file"
        accept=".jpg, .jpeg, .png, .mp4"
        on:change={(e) => onFileSelected(e)}
        bind:this={fileInput}
      />
    {/if}
  </div>
</div>

<style>
  input:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>
