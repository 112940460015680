<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { slide } from "svelte/transition";
  import { dragHandle, dragHandleZone } from "svelte-dnd-action";

  import {
    currentClient,
    dialogData,
    editingSupersetID,
    selectedWorkoutIds,
    showAlert,
    user,
    cantDoExercises,
    trainerPrograms,
    loadedWorkouts,
    selectedIdsStore,
    workoutsStore,
    trainerClientPrograms,
    alertStore,
    newSupersetStore,
  } from "stores";

  import {
    deleteWithJwt,
    dialogTypes,
    getWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate,
    isClient,
    parseDateWithTime,
    updateTrainerPrograms,
    animations,
    patchWithJwt,
    getNewPositions,
    storage,
  } from "lib";

  import { ButtonComponent, CheckboxComponent, More, Svg } from "ui";

  import CommentContainer from "../Comments/CommentContainer.svelte";
  import ExerciseInWorkout from "../Training/ExerciseInWorkout.svelte";
  import Superset from "../Training/Superset.svelte";

  import type { DndEvent, Item } from "svelte-dnd-action";
  import type { Exercise, MenuItem } from "interfaces";
  import { clientWorkouts } from "../../stores/clientStores";
  import { stats } from "../../stores/finishWorkoutStats";

  const dispatch = createEventDispatcher();

  export let showComments = false;
  export let workout: any;
  export let isSelectMode = false;
  export let isExpanded = false;
  export let isDraggable = false;
  export let isFinished = false;
  export let isReadonly = false;

  export let scrollToWorkoutComments = false;
  export let scrollToExerciseComments = 0; // id

  let workoutElement: HTMLDivElement;
  let scrollElement: HTMLElement;

  let expandedWorkoutId: any = null;
  let expandedExerciseId: any = null;

  let editingSupersetId: number | null = null;
  let expandedMoreSupersetId: number | null = null;
  let oldExerciseState: any;

  let isTrainer: boolean = false;

  $: disabled =
    (
      workout?.exercises?.filter(
        (e: any): boolean => e.type !== "Cardio" && e.modelType !== "superset"
      ) || []
    ).length < 2;

  const _menuItems: Array<MenuItem> = [
    {
      title: "FINISH_WORKOUT",
      icon: "save",
      async executeFunction() {
        await forceFetchData();
        const workoutId = workout.id;
        $dialogData.data = { data: $loadedWorkouts[workout.id], workoutId };
        $dialogData.type = dialogTypes.FINISH_WORKOUT_V2;
      },
    },
  ];

  const notFinishedmenuItems: Array<MenuItem> = [
    {
      title: "FINISH_WORKOUT",
      icon: "save",
      async executeFunction() {
        await forceFetchData();
        const workoutId = workout.id;
        $dialogData.data = { data: $loadedWorkouts[workout.id], workoutId };
        $dialogData.type = dialogTypes.FINISH_WORKOUT_V2;
      },
    },
  ];

  const finishedMenuItems: Array<MenuItem> = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        $dialogData.data = {
          name: workout.name,
          description: workout.description,
          finishedAt: workout.finishedAt,
          executeFunction: editWorkout,
        };
        $dialogData.type = dialogTypes.CREATE_EDIT_FINISHED_WORKOUT;
      },
    },
  ];

  let trainerMenuItems: Array<MenuItem> = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        if (workout.entityType === 2) {
          // Text workout
          $dialogData.data = {
            workout,
            executeFunction(response: any): void {
              workout = response;
            },
          };
          $dialogData.type = dialogTypes.CREATE_EDIT_TEXT_WOKROUT;
        } else if (workout.entityType === 3) {
          // PDF Workout
          $dialogData.data = {
            workout,
            executeFunction(response: any): void {
              workout = response;
            },
          };
          $dialogData.type = dialogTypes.CREATE_EDIT_PDF_WORKOUT;
        } else if (workout.entityType === 4) {
          // Rest day
          $dialogData.data = {
            workout,
            executeFunction(response: any): void {
              workout = response;
            },
          };
          $dialogData.type = dialogTypes.CREATE_EDIT_REST_DAY;
        } else {
          // Classic Workout
          $dialogData.data = {
            workout,
            executeFunction(response: any): void {
              workout = response;
            },
          };
          $dialogData.type = dialogTypes.CREATE_EDIT_WORKOUT;
        }
      },
    },
    {
      title: "CREATE_HEADER",
      icon: "article",
      executeFunction(): void {
        $dialogData.data = {
          workout,
          programId: workout.programId,
        };
        $dialogData.type = dialogTypes.CREATE_EDIT_HEADER_WORKOUT;
      },
    },
    {
      title: "CREATE_COPY",
      icon: "copy",
      async executeFunction(): Promise<void> {
        try {
          const url = `${serverlessRoutes.WORKOUT}/copy`;

          if (workout.default) {
            // Workout u workouts
            const response = await postWithJwt(url, {
              workoutIds: [workout.id],
            });

            workoutsStore.add(response.data);
          } else {
            // Workout u program
            const response = await postWithJwt(url, {
              workoutIds: [workout.id],
            });

            const p = $trainerPrograms.find(({ id }) => id === workout.programId);
            if (p) p.workouts = [...p.workouts, ...response.data];
            $trainerPrograms = $trainerPrograms;

            const p2 = $trainerClientPrograms.find(({ id }) => id === workout.programId);
            if (p2) p2.workouts = [...p2.workouts, ...response.data];
            $trainerClientPrograms = $trainerClientPrograms;

            /*
              let program u ProgramScreen nije store, pa se ne re-renderuje.
              Ovde saljemo event da tamo re-renderujemo ceo program.
            */
            dispatch("reloadProgram");
          }

          $showAlert.color = "black";
          $showAlert.message = `
            ${translate("SUCCESSFULLY_COPIED")}
            ${translate("WORKOUT").toLowerCase()}
          `;
        } catch (error) {
          console.error(error);
          $showAlert.color = "red-400";
          $showAlert.message = translate("ERROR_COPYING_WORKOUT");
        }
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: workout.name,
          async executeFunction(): Promise<void> {
            const ids = [workout.id];

            const { error } = await deleteWithJwt(serverlessRoutes.WORKOUT, {
              ids,
            });

            if (error) {
              alertStore.show(translate(error), "error");
              return console.error(error);
            }

            workoutsStore.remove(ids);

            updateTrainerPrograms(workout.programId, (p) => {
              const index = p.workouts.findIndex(({ id }) => id === workout.id);
              p.workouts.splice(index, 1);
            });

            $showAlert.color = "black";
            $showAlert.message = `
              ${translate("SUCCESSFULLY_DELETED")}
              ${translate("WORKOUT").toLowerCase()}
            `;
          },
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  let clientProgramMenu: Array<MenuItem> = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        if (workout.entityType === 2) {
          // Text workout
          $dialogData.data = {
            workout,
            executeFunction(response: any): void {
              workout = response;
            },
          };
          $dialogData.type = dialogTypes.CREATE_EDIT_TEXT_WOKROUT;
        } else if (workout.entityType === 3) {
          // PDF Workout
          $dialogData.data = {
            workout,
            executeFunction(response: any): void {
              workout = response;
            },
          };
          $dialogData.type = dialogTypes.CREATE_EDIT_PDF_WORKOUT;
        } else if (workout.entityType === 4) {
          // Rest day
          $dialogData.data = {
            workout,
            executeFunction(response: any): void {
              workout = response;
            },
          };
          $dialogData.type = dialogTypes.CREATE_EDIT_REST_DAY;
        } else {
          // Classic Workout
          $dialogData.data = {
            workout,
            executeFunction(response: any): void {
              workout = response;
            },
          };
          $dialogData.type = dialogTypes.CREATE_EDIT_WORKOUT;
        }
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: workout.name,
          async executeFunction(): Promise<void> {
            try {
              await deleteWithJwt(serverlessRoutes.WORKOUT, {
                ids: [workout.id],
              });

              updateTrainerPrograms(workout.programId, (p) => {
                const index = p.workouts.findIndex(({ id }) => id === workout.id);
                p.workouts.splice(index, 1);
              });

              $showAlert.color = "black";
              $showAlert.message = `
                ${translate("SUCCESSFULLY_DELETED")}
                ${translate("WORKOUT").toLowerCase()}
              `;
            } catch (error) {
              console.error(error);
              $showAlert.color = "red-400";
              $showAlert.message = translate("ERROR_DELETING_WORKOUT");
            }
          },
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  // $: isExpanded && fetchData();
  $: newCantDo = $cantDoExercises.workoutIds.includes(workout.id);

  $: menuItems = isTrainer
    ? trainerMenuItems
    : isFinished
      ? finishedMenuItems
      : notFinishedmenuItems;

  let lastSsexLength = 0;
  const selectExerciseUnsubscribe = selectedIdsStore.subscribe((ids): void => {
    if (!$newSupersetStore || $newSupersetStore.workoutId !== workout.id) {
      return;
    }

    if (lastSsexLength < ids.length) {
      ids.forEach((id) => {
        const exercise = workout?.exercises?.find((exercise: any): boolean => exercise.id === id);

        if (!exercise) return;
        if ($newSupersetStore.supersetExercises.find((exercise): boolean => exercise.id === id))
          return;

        // workoutsStore.removeExercises(workout.id, [id]);
        if (workout.exercises) {
          workout.exercises = workout.exercises.filter((e) => e.id !== id);
        }

        exercise.supersetId = editingSupersetId;
        $newSupersetStore.supersetExercises.push(exercise);
        $newSupersetStore = { ...$newSupersetStore };
      });
    } else {
      $newSupersetStore.supersetExercises.forEach((ssEx) => {
        const exToFilter = ids.find((e) => e === ssEx.id);

        if (!exToFilter) {
          ssEx.supersetId = null;
          $newSupersetStore.supersetExercises = $newSupersetStore.supersetExercises.filter(
            (e) => e.id !== ssEx.id
          );

          // workoutsStore.addExercises(workout.id, [ssEx]);

          // $trainerClientPrograms.forEach((program) => {
          //   const w = program.workouts.find((w) => w.id === workout.id);
          //   if (w) {
          //     if (w.exercises) {
          //       w.exercises = [...w.exercises, ssEx];
          //     } else {
          //       w.exercises = [ssEx];
          //     }
          //   }
          // });
          // $trainerClientPrograms = $trainerClientPrograms;

          if (workout.exercises) {
            workout.exercises = [...workout.exercises, ssEx];
          } else {
            workout.exercises = [ssEx];
          }
          // workout={...workout};

          // workout.exercises.push(ssEx);
          // workout.exercises.sort((a, b) => a.position - b.position);
          // workout.exercises = workout.exercises;

          // if (!editingSupersetId) {
          // workoutsStore.removeExercises(workout.id, [ssEx.id]);
          // workout.exercises = workout.exercises.filter((e) => e.id !== ssEx.id);
          // }
        }
        // if (exToFilter) {
        //   $newSupersetStore.supersetExercises = $newSupersetStore.supersetExercises.filter(
        //     (e) => e.id !== exToFilter
        //   );
        //   $newSupersetStore = { ...$newSupersetStore };
        // }
      });
    }

    lastSsexLength = ids.length;
  });

  const forceFetchData = async () => {
    if ((!$loadedWorkouts[workout.id] || !$loadedWorkouts[workout.id].exercises) && workout.id) {
      try {
        const res = await getWithJwt(`${serverlessRoutes.WORKOUT}/v2/${workout.id}`);

        // res.exercises?.sort(compare); // TODO do this on backend
        $loadedWorkouts[workout.id] = res;
      } catch (err) {}
    }
  };

  const editWorkout = async (dataToChange: any) => {
    try {
      const res = await patchWithJwt(serverlessRoutes.WORKOUT, {
        partialWorkoutMap: {
          [workout.id]: dataToChange,
        },
      });
      if (res.data.workouts[0].id) {
        $showAlert.message = `${translate("SUCCESSFULLY_EDITED")} ${translate(
          "WORKOUT"
        ).toLowerCase()}.`;
        $dialogData.type = "";
        $dialogData.data = {};
      }
    } catch (err) {}
  };

  const compare = (a: any, b: any): -1 | 0 | 1 => {
    if (a.position < b.position) {
      return -1;
    }

    if (a.position > b.position) {
      return 1;
    }

    return 0;
  };

  const onSelectWorkout = (): void => {
    if ($selectedWorkoutIds.includes(workout.id)) {
      $selectedWorkoutIds = $selectedWorkoutIds.filter((id) => id !== workout.id);
    } else {
      $selectedWorkoutIds = [workout.id, ...$selectedWorkoutIds];
    }
  };

  const fetchDataClient = async () => {
    const wres = await getWithJwt(`${serverlessRoutes.WORKOUT}/v2/${workout.id}`);

    // const {data, error} = await getWithJwt(
    //   `${serverlessRoutes.WORKOUT}/${workout.id}/exercises`
    // );

    // if (error && !data) {
    //   return console.error(error);
    // }

    // u ClientTrainingScreen.svelte je workouts local, mora ovako u medjuvremenu
    if (isClient($user)) {
      workout.exercises = wres.exercises;
      workout.commentable = wres.commentable;
    }

    if ($loadedWorkouts[workout.id]) {
      $loadedWorkouts[workout.id].exercises = wres.exercises;
      $loadedWorkouts[workout.id].commentable = wres.commentable;
    } else {
      $loadedWorkouts[workout.id] = {
        exercises: wres.exercises,
        commentable: wres.commentable,
      };
    }

    $clientWorkouts.forEach((w) => {
      if (w.id === workout.id) {
        w.exercises = wres.exercises;
        w.commentable = wres.commentable;
      }
    });
    $clientWorkouts = $clientWorkouts;

    $trainerPrograms.forEach((program) => {
      const w = program?.workouts?.find(({ id }) => id === workout.id);
      if (w) {
        w.exercises = wres.exercises;
        w.commentable = wres.commentable;
      }
    });
    $trainerPrograms = $trainerPrograms;

    $trainerClientPrograms.forEach((program) => {
      const w = program?.workouts?.find(({ id }) => id === workout.id);
      if (w) {
        w.exercises = wres.exercises;
        w.commentable = wres.commentable;
      }
    });
    $trainerClientPrograms = $trainerClientPrograms;
  };

  const onToggleExpanded = (): void => {
    if (!isSelectMode) {
      isExpanded = !isExpanded;

      if (isExpanded && !workout.exercises) {
        if (workout.default) {
          workoutsStore.fetchExercises(workout.id);
        } else {
          fetchDataClient();
        }
      }
    } else {
      // u select mode bilo gde da se klikne selektuje se item, nema expand
      onSelectWorkout();
    }
  };

  const onCreateExercise = (): void => {
    $dialogData.data = {
      workoutId: workout.id,
      programId: workout.programId,
    };

    $dialogData.type = dialogTypes.IMPORT_EXERCISE;
  };

  const startEmptySuperset = (): void => {
    if ($newSupersetStore === null) {
      $editingSupersetID = 0;
      //$loadedWorkouts[workout.id]
      oldExerciseState = JSON.stringify(workout.exercises);
      lastSsexLength = 0;
      $newSupersetStore = {
        id: null,
        name: "Superset",
        supersetExercises: [],
        workoutId: workout.id,
        details: { sets: 3, time: "", rest: "1min" },
      };
    } else {
      $newSupersetStore = null;
    }
  };

  const cancelCreateEditSuperset = (): void => {
    workout.exercises = JSON.parse(oldExerciseState);
    oldExerciseState = null;
    $newSupersetStore = null;
    editingSupersetId = null;
    $editingSupersetID = null;
    lastSsexLength = 0;
    selectedIdsStore.clear();
  };

  const onCreateSuperset = async (): Promise<void> => {
    try {
      const res1 = await postWithJwt(serverlessRoutes.EXERCISE, {
        partialExercise: {
          name: $newSupersetStore.name,
          details: $newSupersetStore.details,
          modelType: "superset",
          workoutId: workout.id,
          trainerId: null,
          default: 0,
          isTemplate: 0,
        },
      });

      const partialExerciseMap = {};

      $newSupersetStore.supersetExercises.forEach((supersetExercise) => {
        partialExerciseMap[supersetExercise.id] = {
          workoutId: null,
          supersetId: res1.data.exercise.id,
        };
      });

      const res2 = await patchWithJwt(serverlessRoutes.EXERCISE, {
        partialExerciseMap,
      });

      res2.data.exercises.forEach((exercise) => {
        exercise.details = JSON.parse(exercise.details);
      });

      res1.data.exercise.details = JSON.parse(res1.data.exercise.details);
      res1.data.exercise.supersetExercises = res2.data.exercises;

      workoutsStore.addExercises(workout.id, [res1.data.exercise]);

      $trainerPrograms.forEach((program) => {
        const w = program.workouts?.find(({ id }) => id === workout.id);
        if (!w?.exercises) return;
        w.exercises = [...w.exercises, res1.data.exercise];
      });
      $trainerPrograms = $trainerPrograms;

      $trainerClientPrograms.forEach((program) => {
        const w = program.workouts?.find(({ id }) => id === workout.id);
        if (!w?.exercises) return;
        w.exercises = [...w.exercises, res1.data.exercise];
      });
      $trainerClientPrograms = $trainerClientPrograms;

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")} superset
      `;
    } catch (err) {
      console.error(err);
    } finally {
      $newSupersetStore = null;
      selectedIdsStore.clear();
      $editingSupersetID = null;
      editingSupersetId = null;
      lastSsexLength = 0;
    }
  };

  const onEditSuperset = async (): Promise<void> => {
    if (!editingSupersetId) {
      return;
    }

    const otherPartial: any = {};

    // positions baguje
    $newSupersetStore.supersetExercises.forEach(({ id, details, position }: any) => {
      otherPartial[id] = {
        details,
        position,
        workoutId: null,
        supersetId: $newSupersetStore.id,
      };
    });

    workout.exercises.forEach(({ id, details, position }: any) => {
      otherPartial[id] = {
        details,
        position,
        workoutId: workout.id,
        supersetId: null,
      };
    });

    const partialExerciseMap = {
      [editingSupersetId]: { details: $newSupersetStore.details },
      ...otherPartial,
    };

    const res2 = await patchWithJwt(serverlessRoutes.EXERCISE, {
      partialExerciseMap,
    });

    const { data, error } = await getWithJwt(`${serverlessRoutes.WORKOUT}/v2/${workout.id}/exercises`);

    if (error && !data) {
      return console.error(error);
    }

    workout.exercises = data.exercises;

    $newSupersetStore = null;
    selectedIdsStore.clear();
    if ($loadedWorkouts[workout.id]?.exercises) {
      $loadedWorkouts[workout.id].exercises = $loadedWorkouts[workout.id].exercises.sort(
        (a, b) => a.order - b.order
      );
    }
    $showAlert.color = "black";
    $showAlert.message = `${translate("SUCCESSFULLY_EDITED")} superset`;
    editingSupersetId = null;
    $editingSupersetID = null;
    lastSsexLength = 0;
  };

  const onSubmit = async (): Promise<void> => {
    if (editingSupersetId) {
      await onEditSuperset();
    } else {
      await onCreateSuperset();
    }
  };

  const onSupersetEdit = (event: CustomEvent<any>): void => {
    lastSsexLength = event.detail.supersetExercises.length;
    $newSupersetStore = {
      id: event.detail.id,
      name: "Superset",
      supersetExercises: event.detail.supersetExercises,
      workoutId: event.detail.workoutId,
      details: { sets: 3, time: "", rest: "1min" },
    };
    oldExerciseState = JSON.stringify(workout.exercises);
    editingSupersetId = event.detail.id;
    $editingSupersetID = event.detail.id;
  };

  const mountWorkoutElement = (element: HTMLDivElement): void => {
    workoutElement = element;
  };

  const mountScrollElement = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  const exercisesDndConsider = (event: CustomEvent<DndEvent<Item>>): void => {
    workout.exercises = event.detail.items;
  };

  const exercisesDndFinalize = async (event: CustomEvent<DndEvent<Exercise>>): Promise<void> => {
    const { id, programId } = workout;
    const partialExerciseMap = getNewPositions(event.detail.items);

    if (!partialExerciseMap) {
      return;
    }

    const { error, data } = await patchWithJwt(serverlessRoutes.EXERCISE, {
      partialExerciseMap,
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_CHANGING_ORDER"), "error");
      return console.error(error);
    }

    const { exercises } = data;

    if (workout.default) {
      const w = $workoutsStore.items.find((workout) => workout.id === id);
      if (w) {
        w.exercises = event.detail.items;
      }
      // if (w) { w.exercises = []; }
      // await workoutsStore.fetchExercises(id);
    } else if (programId) {
      // const {data, error} = await getWithJwt(
      //   `${serverlessRoutes.WORKOUT}/${id}/exercises`
      // );

      // if (error && !data) {
      //   return console.error(error);
      // }

      const _program = $trainerPrograms.find((_program): boolean => _program.id === programId);
      const _program2 = $trainerClientPrograms.find(
        (_program): boolean => _program.id === programId
      );

      if (_program?.workouts) {
        const _workout = _program.workouts.find((_workout: any): boolean => _workout.id === id);

        _workout.exercises = event.detail.items;
      }
      if (_program2?.workouts) {
        const _workout = _program2.workouts.find((_workout: any): boolean => _workout.id === id);

        _workout.exercises = event.detail.items;
      }

      workout.exercises = event.detail.items;
    }

    alertStore.show(translate("SUCCESSFULLY_CHANGED_ORDER"));
  };

  onMount(async (): Promise<void> => {
    const { href } = window.location;

    isTrainer = !isClient($user);
    $stats = (await storage.get("stats")) || [];

    if (isClient($user)) {
      if (href.includes("scrollToExerciseComments")) {
        const workoutId = parseInt(href.split("?")[1].split("&")[0].split("=")[1]);
        const exerciseId = parseInt(href.split("?")[1].split("&")[1].split("=")[1]);

        if (workout.id === workoutId) {
          isExpanded = true;
          expandedExerciseId = exerciseId;
        }
      } else if (scrollToExerciseComments) {
        isExpanded = true;
        expandedExerciseId = scrollToExerciseComments;
        setTimeout((): void => {
          const el = document.getElementById(`scroll-${scrollToExerciseComments}`);
          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
          }
        }, 2000);
      } else if (href.includes("scrollToWorkoutComments")) {
        const workoutId = parseInt(href.split("?")[1].split("&")[0].split("=")[1]);

        if (workout.id === workoutId) {
          isExpanded = true;
          expandedWorkoutId = workoutId;
          setTimeout((): void => scrollElement.scrollIntoView({ behavior: "smooth" }), 2000);
        }
      } else if (scrollToWorkoutComments) {
        isExpanded = true;
        expandedWorkoutId = workout.id;
        setTimeout((): void => scrollElement.scrollIntoView({ behavior: "smooth" }), 2000);
      }
    } else {
      if (href.includes("scrollToExerciseComments")) {
        let workoutId: number;
        let exerciseId: number;

        if (href.includes("programId")) {
          workoutId = parseInt(href.split("&")[3].split("=")[1]);
          exerciseId = parseInt(href.split("&")[4].split("=")[1]);
        } else {
          workoutId = parseInt(href.split("?")[1].split("&")[0].split("=")[1]);
          exerciseId = parseInt(href.split("?")[1].split("&")[1].split("=")[1]);
        }

        if (workout.id === workoutId) {
          isExpanded = true;
          expandedExerciseId = exerciseId;
        }
      } else if (href.includes("scrollToWorkoutComments")) {
        let workoutId: number;

        if (href.includes("programs/")) {
          workoutId = parseInt(href.split("?")[1].split("&")[0].split("=")[1]);
        } else {
          workoutId = parseInt(href.split("?")[1].split("&")[2].split("=")[1]);
        }

        if (workout.id === workoutId) {
          isExpanded = true;
          expandedWorkoutId = workoutId;
          setTimeout((): void => scrollElement.scrollIntoView({ behavior: "smooth" }), 2000);
        }
      } else if (scrollToExerciseComments) {
        isExpanded = true;
        expandedExerciseId = scrollToExerciseComments;
        setTimeout((): void => {
          const el = document.getElementById(`scroll-${scrollToExerciseComments}`);
          if (!el) {
            return;
          }
          el.scrollIntoView({ behavior: "smooth" });
        }, 2000);
      } else if (scrollToWorkoutComments) {
        isExpanded = true;
        expandedWorkoutId = workout.id;
        setTimeout((): void => scrollElement.scrollIntoView({ behavior: "smooth" }), 2000);
      } else if (href.includes("workoutId")) {
        const workoutId = parseInt(href.split("&")[2].split("=")[1]);

        if (workout.id === workoutId) {
          isExpanded = true;
          setTimeout((): void => workoutElement.scrollIntoView({ behavior: "smooth" }), 2000);
        }
      }
    }

    if (workout.entityType !== 0) {
      // brisemo create header ako nije klasican workout
      trainerMenuItems.splice(1, 1);
      trainerMenuItems = trainerMenuItems;
    }

    if (!workout.default) {
      // dodajemo Save to Database ako je u program
      trainerMenuItems.unshift({
        title: "SAVE_TO_DATABASE",
        icon: "save",
        async executeFunction(): Promise<void> {
          try {
            await postWithJwt(`${serverlessRoutes.WORKOUT}/copy/v2`, {
              partialWorkoutMap: {
                [workout.id]: {
                  programId: null,
                  default: true,
                  isTemplate: false,
                },
              },
              shouldCopyExercises: true,
            });

            $showAlert.color = "black";
            $showAlert.message = `
              ${translate("WORKOUT")}
              ${translate("SAVED_TO_DATABASE").toLowerCase()}
            `;
          } catch (error) {
            console.error(error);
            $showAlert.color = "red-400";
            $showAlert.message = translate("ERROR_COPYING_WORKOUT");
          }
        },
      });
      trainerMenuItems = trainerMenuItems;
    }

    if ($currentClient.id) {
      trainerMenuItems = [
        {
          title: "EDIT",
          icon: "edit",
          executeFunction(): void {
            if (workout.entityType === 2) {
              // Text workout
              $dialogData.data = {
                workout,
                executeFunction(response: any): void {
                  workout = response;
                },
              };
              $dialogData.type = dialogTypes.CREATE_EDIT_TEXT_WOKROUT;
            } else if (workout.entityType === 3) {
              // PDF Workout
              $dialogData.data = {
                workout,
                executeFunction(response: any): void {
                  workout = response;
                },
              };
              $dialogData.type = dialogTypes.CREATE_EDIT_PDF_WORKOUT;
            } else if (workout.entityType === 4) {
              // Rest day
              $dialogData.data = {
                workout,
                executeFunction(response: any): void {
                  workout = response;
                },
              };
              $dialogData.type = dialogTypes.CREATE_EDIT_REST_DAY;
            } else {
              // Classic Workout
              $dialogData.data = {
                workout,
                executeFunction(response: any): void {
                  workout = response;
                },
              };
              $dialogData.type = dialogTypes.CREATE_EDIT_WORKOUT;
            }
          },
        },
        {
          title: "REPEAT_WORKOUT",
          icon: "refresh",
          async executeFunction() {
            await fetchDataClient(); // ako nije expandovan tj fetchovan pre repeat puca request

            const response = await postWithJwt(`${serverlessRoutes.WORKOUT}/copy/v2`, {
              partialWorkoutMap: {
                [workout.id]: { finishedAt: null },
              },
              shouldCopyExercises: false,
            });

            const partialExerciseMap = workout.exercises.reduce((acc, ex) => {
              // if (ex.modelType === "superset") {
              //   ex.supersetExercises.forEach((ssEx) => {
              //     acc[ssEx.id] = {finishedAt: null, workoutId: response.data.workouts[0].id};
              //   });
              // } else {
              acc[ex.id] = {
                finishedAt: null,
                workoutId: response.data.workouts[0].id,
              };
              // }

              return acc;
            }, {});

            const response2 = await postWithJwt(`${serverlessRoutes.EXERCISE}/copy/v2`, {
              partialExerciseMap,
            });

            $showAlert.message = "Trening ponovljen";
          },
        },
      ];
    }

    if (
      href.includes("/profile") &&
      href.includes("/training/programs") &&
      workout.entityType === 0
    ) {
      trainerMenuItems = [
        {
          title: "FINISH_WORKOUT",
          icon: "save",
          async executeFunction() {
            await forceFetchData();
            const workoutId = workout.id;
            $dialogData.data = { data: $loadedWorkouts[workout.id], workoutId };
            $dialogData.type = dialogTypes.FINISH_WORKOUT_V2;
          },
        },
        ...trainerMenuItems,
      ];
    }
  });

  onDestroy((): void => {
    selectExerciseUnsubscribe();

    editingSupersetId = null;
    $newSupersetStore = null;
    $editingSupersetID = null;
    lastSsexLength = 0;
  });
</script>

{#if workout.isTemplate && isSelectMode}
  <div class="hidden"></div>
{:else}
  <div data-cy="workout">
    {#if workout.finishedAt}
      <div class="text-xs">
        {translate("WORKOUT_FINISHED")}:
        <span class="font-bold">{parseDateWithTime(workout.finishedAt)}</span>
      </div>
    {/if}

    <div
      class="
        flex
        flex-col
        border
        rounded-md
        border-slate-200
        dark:border-zinc-600
        transition-shadow
        duration-[400ms]
        ease-linear
        shadow
        bg-white
        dark:bg-zinc-800
      "
      use:mountWorkoutElement
    >
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="relative p-2 flex justify-between" on:click={onToggleExpanded}>
        {#if workout.isTemplate}
          <div class="tag">
            <div class="tag__clip bg-primary-500 dark:bg-primary-700"></div>
            <div class="tag__icon">
              <Svg myClass="mr-2" name="star" size={10} customColor="bg-white" />
            </div>
          </div>
        {/if}

        <div
          class="flex items-center gap-2 text-slate-900 dark:text-slate-100"
          class:ml-2={workout.isTemplate}
        >
          {#if isDraggable && !isReadonly}
            <div use:dragHandle>
              <Svg name="drag" size={16} />
            </div>
          {/if}
          <div data-cy="workout-name">{workout.name}</div>
          {#if workout.entityType === 2}
            <Svg name="text" size={22} customColor="bg-emerald-600" />
          {/if}
          {#if workout.entityType === 3}
            <Svg name="pdf" size={22} customColor="bg-red-700" />
          {/if}
          {#if workout.finishedAt}
            <Svg name="check" size={16} customColor="bg-green-500" />
          {/if}
          {#if newCantDo && !isClient($user)}
            <Svg name="warning" size={16} customColor="bg-red-500" />
          {/if}
        </div>

        <div class="flex items-center gap-2">
          {#if isSelectMode}
            <CheckboxComponent value={$selectedWorkoutIds.includes(workout.id)} />
          {:else}
            {#if !isReadonly}
              <More {menuItems} />
            {/if}
            <div class="chevron" class:isExpanded>
              <Svg name="down-caret" size={16} />
            </div>
          {/if}
        </div>
      </div>

      {#if isExpanded && workout.exercises}
        {#if workout.entityType === 2 || workout.entityType === 4}
          <!-- textual / rest day -->
          <div
            class="p-4 pt-0 flex flex-col gap-4"
            in:slide={animations.slide}
            out:slide={animations.slide}
          >
            <div></div>

            <div>
              <div class="font-semibold">{translate("DESCRIPTION")}:</div>
              <div class="text-justify text-xs">
                {workout.description || translate("NO_DESCRIPTION")}
              </div>
            </div>
          </div>
        {:else if workout.entityType === 3}
          <!-- pdf -->
          <iframe height="480" title="PDF" src={workout.fileable.files[0].url} />
        {:else}
          <div
            class="p-4 pt-0 flex flex-col gap-4"
            in:slide={animations.slide}
            out:slide={animations.slide}
          >
            <div></div>

            {#if workout.description}
              <div>
                <div class="font-semibold">{translate("DESCRIPTION")}:</div>
                <div class="text-justify text-xs">{workout.description}</div>
              </div>
            {/if}

            {#if workout.protocol}
              <div>
                <div class="font-semibold">{translate("PROTOCOL")}:</div>
                <div class="text-justify text-xs">{workout.protocol}</div>
              </div>
            {/if}

            <!-- {#if workout.pdfUrl}
              <div class="flex-col center-center mgb-16">
                <iframe
                  title="PDF"
                  width="96%"
                  height="500"
                  src={workout.pdfUrl}
                />
              </div>
            {:else} -->
            <div>
              <!-- <div class="font-semibold">{translate("EXERCISES")}:</div> -->
              <div class="exercises">
                {#if workout.exercises}
                  <div
                    use:dragHandleZone={{
                      items: workout.exercises,
                      flipDurationMs: 0,
                      dropTargetStyle: {},
                      dropFromOthersDisabled: true,
                      type: "exercises",
                    }}
                    on:consider={exercisesDndConsider}
                    on:finalize={exercisesDndFinalize}
                  >
                    {#each workout.exercises as exercise (exercise.id)}
                      <div class="outline-none" animate:flip={animations.flip}>
                        {#if exercise.modelType === "superset"}
                          {#if exercise.id !== editingSupersetId && $newSupersetStore === null}
                            <Superset
                              {isReadonly}
                              {expandedMoreSupersetId}
                              {exercise}
                              workoutId={workout.id}
                              on:editMode={onSupersetEdit}
                              on:cancelEditMode{onCancelEditMode}
                            />
                          {/if}
                        {:else}
                          {#if ($newSupersetStore && exercise.type !== "Cardio") || !$newSupersetStore}
                            <!-- <div class="flex flex-col gap-4"> -->
                            <!-- {#key workout.exercises} -->
                            <ExerciseInWorkout
                              {isReadonly}
                              isInExpansionPanel
                              {exercise}
                              isSelectMode={$newSupersetStore !== null || editingSupersetId}
                              programId={workout.programId}
                            />
                          {/if}
                          <!-- {/key} -->
                          <!-- </div> -->
                        {/if}
                        <div style="position: relative;">
                          <!-- stavljamo -48px zbog header samo kod clienta -->
                          <div
                            id={`scroll-${exercise.id}`}
                            style="position: absolute; top: {isClient($user)
                              ? '-48px'
                              : '0px'}; left: 0"
                          />
                        </div>
                        {#if showComments && $newSupersetStore === null}
                          <div class="my-2">
                            <CommentContainer
                              {isReadonly}
                              isExpanded={exercise.id === +expandedExerciseId}
                              isHighlighted={exercise.id === +expandedExerciseId}
                              commentCount={exercise.commentCount}
                              commentableId={exercise.commentableId}
                              entity="EXERCISE"
                              entityId={exercise.id}
                              comments={[]}
                            />
                          </div>
                        {:else}
                          <div class="mb-4" />
                        {/if}
                      </div>
                    {/each}
                  </div>
                  <!-- {:else}
                    <div class="text-justify text-xs">{translate("NO_INGREDIENTS")}</div> -->
                {/if}
              </div>
            </div>
            <!-- {/if} -->

            <!-- Buttons -->
            {#if !isFinished && !isTrainer}
              <div class="flex-row center-center">
                <ButtonComponent
                  on:click={() => {
                    $dialogData.type = dialogTypes.FINISH_WORKOUT_V2;
                    $dialogData.data = {
                      data: workout,
                      workoutId: workout.id,
                    };
                  }}
                >
                  {translate("FINISH_WORKOUT")}
                </ButtonComponent>
              </div>
            {/if}
            {#if $newSupersetStore !== null}
              <Superset
                workoutId={workout.id}
                exercise={$newSupersetStore}
                {expandedMoreSupersetId}
                isBeingEdited={true}
                on:editMode={onSupersetEdit}
                on:cancelEditMode{onCancelEditMode}
              />
              <div class="mb-4" />
            {/if}
            {#if !isFinished && isTrainer}
              <div class="flex justify-evenly">
                {#if $newSupersetStore === null && !isReadonly}
                  <ButtonComponent on:click={onCreateExercise}>
                    {translate("ADD")}
                    {translate("EXERCISE_U").toLowerCase()}
                  </ButtonComponent>
                  <ButtonComponent {disabled} on:click={startEmptySuperset}>
                    {translate("CREATE")} superset
                  </ButtonComponent>
                {:else if $newSupersetStore !== null}
                  <!-- disabled={workout.exercises.length < 2} -->
                  <ButtonComponent customColor="bg-red-500" on:click={cancelCreateEditSuperset}>
                    {translate("CANCEL")}
                  </ButtonComponent>
                  <ButtonComponent
                    disabled={$newSupersetStore?.supersetExercises.length < 2}
                    on:click={onSubmit}
                  >
                    {translate("SAVE")}
                  </ButtonComponent>
                {/if}
              </div>
            {/if}
            <!-- End Buttons -->

            <div style="position: relative;">
              <div
                use:mountScrollElement
                style="position: absolute; top: {isClient($user) ? '-48px' : '0px'}; left: 0"
              />
            </div>

            {#if showComments}
              <CommentContainer
                {isReadonly}
                entity="WORKOUT"
                comments={[]}
                commentableId={workout.commentableId}
                commentCount={workout.commentCount}
                entityId={workout.id}
                isExpanded={expandedWorkoutId ? true : false}
                isHighlighted={expandedWorkoutId ? true : false}
              />
            {:else}
              <div class="mb-4"></div>
            {/if}
          </div>
        {/if}
      {/if}

      <!-- {#if isExpanded && $loadedWorkouts[workout.id] && workout}
        <div in:slide>
          {#if workout.description}
            <div
              class="p-4 flex flex-col gap-4 text-slate-900 dark:text-slate-100"
            >
              {workout.description}
            </div>
          {/if}
          <div class="p-4 flex flex-col gap-4" />
          {#if $loadedWorkouts[workout.id].pdfUrl}
            <div class="flex-col center-center mgb-16">
              <iframe
                title="PDF"
                width="96%"
                height="500"
                src={$loadedWorkouts[workout.id].pdfUrl}
              />
            </div>
          {:else}
            <div
              use:dragHandleZone={{
                items: $loadedWorkouts[workout.id].exercises,
                flipDurationMs: 0,
                dropTargetStyle: {},
                dropFromOthersDisabled: true,
                type: "exercises",
              }}
              on:consider={exercisesDndConsider}
              on:finalize={exercisesDndFinalize}
            >
              {#each $loadedWorkouts[workout.id].exercises as exercise (exercise.id)}
                <div animate:flip={{ duration: 333 }} class="outline-none">
                  {#if exercise.modelType === "superset"}
                    {#if exercise.id !== editingSupersetId && $newSupersetStore === null}
                      <Superset
                        {expandedMoreSupersetId}
                        {exercise}
                        workoutId={workout.id}
                        on:editMode={onSupersetEdit}
                        on:cancelEditMode{onCancelEditMode}
                      />
                    {/if}
                  {:else}
                    <div class="px-4 flex flex-col gap-4">
                      <ExerciseInWorkout
                        isInExpansionPanel
                        {exercise}
                        isSelectMode={$newSupersetStore !== null || editingSupersetId}
                      />
                    </div>
                  {/if}
                  <div style="position: relative;">
                    <div
                      id={`scroll-${exercise.id}`}
                      style="position: absolute; top: {isClient($user)
                        ? '-48px'
                        : '0px'}; left: 0"
                    />
                  </div>
                  {#if showComments && $newSupersetStore === null}
                    <div class="p-2">
                      <CommentContainer
                        isExpanded={exercise.id === +expandedExerciseId}
                        entity="EXERCISE"
                        entityId={exercise.id}
                        comments={exercise.commentable
                          ? exercise.commentable.comments
                          : []}
                      />
                    </div>
                  {:else}
                    <div class="mb-4" />
                  {/if}
                </div>
              {/each}
            </div>
          {/if}
          {#if !isFinished && !isTrainer}
            <div class="flex-row center-center">
              <ButtonComponent
                on:click={() => {
                  $dialogData.type = dialogTypes.FINISH_WORKOUT_V2;
                  $dialogData.data = {
                    data: $loadedWorkouts[workout.id],
                    workoutId: workout.id,
                  };
                }}
              >
                {translate("FINISH_WORKOUT")}
              </ButtonComponent>
            </div>
          {/if}
          {#if $newSupersetStore !== null}
            <Superset
              workoutId={workout.id}
              exercise={$newSupersetStore}
              {expandedMoreSupersetId}
              isBeingEdited={true}
              on:editMode={onSupersetEdit}
              on:cancelEditMode{onCancelEditMode}
            />
            <div class="mb-4" />
          {/if}
          {#if !isFinished && isTrainer}
            <div class="flex justify-evenly">
              {#if $newSupersetStore === null}
                <ButtonComponent on:click={onCreateExercise}>
                  {translate("ADD")}
                  {translate("EXERCISE_U").toLowerCase()}
                </ButtonComponent>
                <ButtonComponent
                  disabled={$loadedWorkouts[workout.id].exercises.length < 2}
                  on:click={startEmptySuperset}
                >
                  {translate("CREATE")} superset
                </ButtonComponent>
              {:else if $newSupersetStore !== null}
                <ButtonComponent
                  customColor="bg-red-500"
                  disabled={$loadedWorkouts[workout.id].exercises.length < 2}
                  on:click={cancelCreateEditSuperset}
                >
                  {translate("CANCEL")}
                </ButtonComponent>
                <ButtonComponent
                  disabled={$newSupersetStore?.supersetExercises.length < 2}
                  on:click={onSubmit}
                >
                  {translate("SAVE")}
                </ButtonComponent>
              {/if}
            </div>
          {/if}
          <div style="position: relative;">
            <div
              use:mountScrollElement
              style="position: absolute; top: {isClient($user)
                ? '-48px'
                : '0px'}; left: 0"
            />
          </div>
          {#if showComments}
            <div class="p-2">
              <CommentContainer
                entity="WORKOUT"
                comments={$loadedWorkouts[workout.id].commentable?.comments}
                entityId={workout.id}
                isExpanded={expandedWorkoutId ? true : false}
              />
            </div>
          {:else}
            <div class="mb-4"></div>
          {/if}
        </div>
      {/if} -->
    </div>
  </div>
{/if}

<style>
  .chevron {
    /* ista tranzicija kao default svelte:slide */
    transition: transform 400ms linear;
  }
  .isExpanded {
    transform: rotate(180deg);
  }

  .tag {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 24px;
    width: 24px;
    border-top-left-radius: 4px;
    overflow: hidden;
  }
  .tag__icon {
    position: absolute;
    top: 2px;
    left: 2px;
  }
  .tag__clip {
    height: 24px;
    width: 24px;
    clip-path: polygon(0px 0px, 24px 0px, 0px 24px);
    /* background-color: #6875f5; */
  }
</style>
