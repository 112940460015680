<script lang="ts">
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";

  import {
    dialogData,
    showAlert,
    trainerPrograms,
    trainerClientPrograms,
    trainerProgramsCount,
  } from "stores";

  import {
    ButtonComponent,
    CheckboxComponent,
    InputField,
    TextareaField,
  } from "ui";

  let data: any;
  let imageElem: HTMLImageElement;
  let imageInputElem: HTMLInputElement;
  let imageUrl: string;
  let disabled = true;
  let isLoading = false;
  let isImageSelected = false;

  const form = {
    name: { value: "", error: "" },
    image: { value: "", error: "" },
    description: { value: "", error: "" },
    duration: { value: null, error: "" },
    autoWorkoutDisplaying: { value: false, error: "" },
    numberOfWorkouts: { value: 3, error: "" },
  };

  const onSetImage = (): void => {
    if (imageInputElem.files && imageInputElem.files[0]) {
      const reader = new FileReader();

      reader.onload = (event): void => {
        imageElem.src = event.target?.result as string;
      };

      reader.readAsDataURL(imageInputElem.files[0]);

      isImageSelected = true;
    }
  };

  const onInput = (field: keyof typeof form): void => {
    if (field === "name") {
      const { length } = form[field].value;

      if (!length) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      const { length } = form[field].value;

      if (length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "duration") {
      const { value } = form[field];

      if (value < 1) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_1");
      } else if (value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "autoWorkoutDisplaying") {
      if (!form[field].value) {
        form.numberOfWorkouts.error = "";
      } else {
        if (form.numberOfWorkouts.value < 1) {
          form.numberOfWorkouts.error = translate("FIELD_MINIMUM_AMOUNT_1");
        } else if (form.numberOfWorkouts.value > 200) {
          form.numberOfWorkouts.error = translate("FIELD_MAXIMUM_AMOUNT_200");
        } else {
          form.numberOfWorkouts.error = "";
        }
      }
    }

    if (field === "numberOfWorkouts") {
      if (!form.autoWorkoutDisplaying.value) {
        form[field].error = "";
      } else {
        if (form[field].value < 1) {
          form[field].error = translate("FIELD_MINIMUM_AMOUNT_1");
        } else if (form[field].value > 200) {
          form[field].error = translate("FIELD_MAXIMUM_AMOUNT_200");
        } else {
          form[field].error = "";
        }
      }
    }

    disabled = Object.values(form).some(({ error }) => error !== "");
  };

  const editProgram = async () => {
    const {data: rData, error} = await patchWithJwt(serverlessRoutes.PROGRAM, {
      partialProgramMap: {
        [data.program.id]: {
          name: form.name.value,
          description: form.description.value,
          visibleWorkouts: form.numberOfWorkouts.value,
          visibleWorkoutsTotal: form.numberOfWorkouts.value,
          workoutDisplayOptions: form.autoWorkoutDisplaying.value,
          duration: form.duration.value,
        }
      }
    });

    if (error && !rData) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_EDITING_TRAINING_PROGRAM");
      return;
    }

    const _program = $trainerPrograms.find(
      (_program): boolean => _program.id === data.program.id
    );
    const _program2 = $trainerClientPrograms.find(
      (_program): boolean => _program.id === data.program.id
    );

    if (_program) {
      const index = $trainerPrograms.indexOf(_program);

      $trainerPrograms.splice(index, 1, rData.programs[0]);
      $trainerPrograms = $trainerPrograms;
    } else if (_program2) {
      const index = $trainerClientPrograms.indexOf(_program2);
      $trainerClientPrograms.splice(index, 1, rData.programs[0]);
      $trainerClientPrograms = $trainerClientPrograms;
    }

    $showAlert.color = "black";
    $showAlert.message = translate("SUCCESSFULLY_EDITED_PROGRAM");

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const createProgram = async (): Promise<void> => {
    const {data: rData, error} = await postWithJwt(serverlessRoutes.PROGRAM, {
      partialProgram: {
        name: form.name.value,
        description: form.description.value,
        visibleWorkouts: form.numberOfWorkouts.value,
        visibleWorkoutsTotal: form.numberOfWorkouts.value,
        workoutDisplayOptions: form.autoWorkoutDisplaying.value,
        duration: form.duration.value,
        clientId: data.clientId ? data.clientId : null,
        default: data.clientId ? 0 : 1,
        isTemplate: 0
      }
    });

    if (error && !rData) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_TRAINING_PROGRAM");
      return;
    }

    if (data.clientId) {
      $trainerClientPrograms = [rData.program, ...$trainerClientPrograms];
    } else {
      $trainerPrograms = [rData.program, ...$trainerPrograms];
      $trainerProgramsCount += 1;
    }

    $showAlert.color = "black";
    $showAlert.message = translate("SUCCESSFULLY_CREATED_PROGRAM");

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.program) {
      await editProgram();
    } else {
      await createProgram();
    }
  };

  onMount((): void => {
    // if (data.clientId) {
    //   form;
    // }

    if (data.program) {
      const {
        name,
        description,
        duration,
        workoutDisplayOptions,
        visibleWorkouts,
        thumbnailUrl,
      } = data.program;

      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }

      if (duration) {
        form.duration.value = duration;
        onInput("duration");
      }

      if (workoutDisplayOptions) {
        form.autoWorkoutDisplaying.value = workoutDisplayOptions;
        onInput("autoWorkoutDisplaying");
      }

      if (visibleWorkouts !== null || visibleWorkouts !== undefined) {
        form.numberOfWorkouts.value = visibleWorkouts;
        onInput("numberOfWorkouts");
      }

      if (thumbnailUrl) {
        imageUrl = thumbnailUrl;
      }
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-center text-lg font-semibold">
    {data.program ? translate("EDIT") : translate("CREATE")}
    {translate("PROGRAM").toLowerCase()}
  </h3>

  <div class="flex flex-col gap-4">
    <InputField
      label={translate("NAME")}
      error={form.name.error}
      bind:value={form.name.value}
      on:input={() => onInput("name")}
    />

    <!-- <div class="flex flex-col items-center justify-center">
      <img
        alt="Training Program"
        src={imageUrl ? imageUrl : "logo.png"}
        bind:this={imageElem}
      />

      <input
        class="hidden"
        type="file"
        accept=".png"
        bind:value={form.image.value}
        bind:this={imageInputElem}
        on:change={onSetImage}
      />

      <p class="leading-4 text-center text-gray-400 text-xs mt-2">
        {#if !data.executeFunction && !isImageSelected}
          {translate("DEFAULT_IMAGE_LOADED_AUTOMATICALLY")}<br />
        {/if}
        {translate("CLICK_ON_IMAGE_TO_CHANGE")}
      </p>
    </div> -->

    <TextareaField
      label={translate("DESCRIPTION")}
      error={form.description.error}
      bind:value={form.description.value}
      on:input={() => onInput("description")}
    />

    <InputField
      type="number"
      label={translate("DURATION_IN_DAYS")}
      error={form.duration.error}
      bind:value={form.duration.value}
      on:input={() => onInput("duration")}
    />

    <CheckboxComponent
      label={translate("AUTOMATIC_WORKOUT_DISPLAYING")}
      bind:value={form.autoWorkoutDisplaying.value}
      on:change={() => onInput("autoWorkoutDisplaying")}
    />

    {#if form.autoWorkoutDisplaying.value}
      <div in:slide>
        <InputField
          type="number"
          label={translate("NUMBER_OF_WORKOUTS")}
          error={form.numberOfWorkouts.error}
          bind:value={form.numberOfWorkouts.value}
          on:input={() => onInput("numberOfWorkouts")}
        />
      </div>
    {/if}

    <div class="text-xxs text-justify">
      <span class="text-primary-500">{translate("NOTE")}:</span>
      {translate("CREATE_EDIT_PROGRAM_NOTE")}
    </div>
  </div>

  <div class="flex justify-center">
    <ButtonComponent
      {disabled}
      {isLoading}
      cy="create-edit-program-button"
      on:click={onSubmit}
    >
      {data.program ? translate("EDIT") : translate("CREATE")}
    </ButtonComponent>
  </div>
</div>
