<script lang="ts">
  import { user } from "stores";
  import { serverlessRoutes } from "../../lib/constants";
  import { getWithJwt, postWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import Svg from "../UI/Svg.svelte";
  import Comment from "./Comment.svelte";
  import { onMount } from "svelte";

  export let entity: string;
  export let entityId: number;
  export let comments: any[];
  export let commentCount: number = 0;
  export let commentableId: number = 0;
  export let isExpanded: boolean = false;
  export let isHighlighted = false;
  export let isReadonly = false;

  let payload: string = "";

  const urls = {
    MEAL: `${serverlessRoutes.MEAL}/comment`,
    WORKOUT: `${serverlessRoutes.WORKOUT}/comment`,
    EXERCISE: `${serverlessRoutes.EXERCISE}/comment`,
  };

  const onToggleComments = async () => {
    isExpanded = !isExpanded;

    if (
      isExpanded &&
      !comments?.length &&
      commentableId &&
      commentCount > 0
    ) {
      const url = `${serverlessRoutes.COMMENT}/list?commentableId=${commentableId}`;
      const res = await getWithJwt(url);
      comments = res.data;
    }
  };

  const submit = async () => {
    try {
      const postObject = {
        text: payload,
      };
      postObject[`${entity.toLowerCase()}Id`] = entityId;
      const res = await postWithJwt(urls[entity], postObject);

      if (!res.data.avatarUrl) { res.data.avatarUrl = $user.avatarUrl; }
      if (!res.data.name) { res.data.name = $user.name; }

      if (comments && comments.length > 0) {
        comments = [...comments, res.data];
      } else {
        comments = [res.data];
      }

      commentCount = commentCount + 1;
    } catch (err) {}
  };

  const onDelete = (event: CustomEvent<{ id: number }>): void => {
    comments = comments.filter((comment) => comment.id !== event.detail.id);
    commentCount = commentCount - 1;
  };

  onMount(async () => {
    if (
      isExpanded &&
      !comments?.length &&
      commentableId &&
      commentCount > 0
    ) {
      const url = `${serverlessRoutes.COMMENT}/list?commentableId=${commentableId}`;
      const res = await getWithJwt(url);
      comments = res.data;
    }
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class:isHighlighted class="p-2 flex flex-col gap-2 rounded-md">
  <div class="flex">
    <div class="text-xs text-slate-900 dark:text-slate-100">
      {translate(`${entity}_COMMENTS`)} ({commentCount
        ? commentCount
        : comments
          ? comments.length
          : 0})
    </div>

    <div on:click={onToggleComments}>
      {#if !isExpanded}
        <Svg name="down-caret" size={16} />
      {:else}
        <Svg name="up-caret" size={16} />
      {/if}
    </div>
  </div>

  {#if isExpanded}
    <div class="flex flex-col gap-2">
      {#if comments}
        {#each comments as comment (comment.id)}
          <Comment {isReadonly} {comment} {entity} {entityId} on:delete={onDelete} />
        {/each}
      {/if}
    </div>
    <div class="flex items-center gap-4">
      <input
        type="text"
        class="input grow bg-slate-50 dark:bg-zinc-900 text-slate-900 dark:text-slate-100 border-slate-200 dark:border-zinc-600"
        bind:value={payload}
        style="width: 80%"
        placeholder={`${translate("COMMENT_HERE")}...`}
      />
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <Svg
        customColor="bg-primary-500 dark:bg-primary-500"
        name="right-arrow-button"
        size={26}
        on:click={() => {
          submit();
          payload = "";
        }}
      />
    </div>
  {/if}
</div>

<style>
  .isHighlighted {
    /* background-color: rgba(144, 161, 185, 0.066); */
    border: 2px solid rgb(var(--primary));
    /* border: 1px solid rgb(144, 161, 185); */
  }
</style>
