<script lang="ts">
  import Tag from "../Tag.svelte";

  let id: string;
  let label: string;
  let value: string;
  let inputElement: HTMLInputElement;

  const onShowPicker = (): void => {
    inputElement.showPicker();
  };

  export { id, label, value };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="relative flex flex-col items-start">
  <label for={id} class="block mb-1 text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
    {label}:
  </label>

  <input
    {id}
    type="color"
    class="invisible absolute top-14 left-0 w-0 h-0"
    bind:this={inputElement}
    bind:value
    on:input
  />

  <div class="self-center w-auto" on:click={onShowPicker}>
    <Tag color={value} />
  </div>
</div>
