<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import Goal from "../../components/Progress/Goal.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import Spinner from "../../components/UI/Spinner.svelte";
  import { dialogTypes, entities, serverlessRoutes } from "../../lib/constants";
  import { location } from "svelte-spa-router";
  import { getWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { clientGoals } from "../../stores/clientStores";
  import { dialogData } from "../../stores/dialogDataStore";
  import { user } from "../../stores/userStore";
  import { isClient } from "../../lib/roles";
  import { Capacitor } from "@capacitor/core";
  import { ButtonComponent } from "ui";

  let isLoading = false;

  const fetchData = async (): Promise<void> => {
    const userId = isClient($user) ? $user.id : $location.split("/").pop();

    if (!isLoading) {
      isLoading = true;
      const url = `${serverlessRoutes.GOAL}?clientId=${userId}`;
      try {
        const data = await getWithJwt(url);
        $clientGoals = data.data;
        isLoading = false;
      } catch (err) {}
    }
  };

  onMount(() => {
    fetchData();
  });

  const onCreateGoal = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_GOAL;
  };
</script>

{#if isClient($user)}
  <TopMenu entity={entities.PROGRESS} />
{/if}

<div class="p-4 flex flex-col gap-4">
  {#if isLoading}
    <Spinner />
  {:else}
    <div class="flex justify-center">
      <ButtonComponent on:click={onCreateGoal}>
        {translate("CREATE_GOAL")}
      </ButtonComponent>
    </div>

    {#each $clientGoals as goal}
      <Goal {goal} />
    {/each}
  {/if}

  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
