import {get, writable} from "svelte/store";
import {getWithJwt, MealEntityType, serverlessRoutes, sortItems} from "lib";
import type {Ingredient, Meal, MealPlan, Recipe} from "interfaces";

const mealPlanStoreCreate = () => {
  const {set, subscribe, update} = writable<MealPlan | null>(null);

  const calculateHeadersMacros = (meals: Array<Meal>): void => {
    const doesHeaderExist = meals.some((meal) => meal.entityType === MealEntityType.HEADER);

    if (!doesHeaderExist) { return; }

    let lastHeaderIndex = 0;

    meals.forEach((meal, index): void => {
      const {entityType} = meal;

      if (entityType === MealEntityType.HEADER) {
        lastHeaderIndex = index;

        const header = meals[lastHeaderIndex];

        header.carbs = 0;
        header.protein = 0;
        header.fats = 0;
        header.calories = 0;
      } else if (entityType === MealEntityType.NORMAL) {
        const header = meals[lastHeaderIndex];

        if (header.entityType !== MealEntityType.HEADER) { return; }

        header.carbs = (header.carbs || 0) + (meal.carbs || 0);
        header.protein = (header.protein || 0) + (meal.protein || 0);
        header.fats = (header.fats || 0) + (meal.fats || 0);
        header.calories = (header.calories || 0) + (meal.calories || 0);
      }
    });
  };

  const calculateRecipeMacros = (recipe: Recipe): void => {
    if (!recipe.ingredients) {
      return;
    }

    const ingredientMacros = recipe.ingredients.reduce((macro, ingredient) => {
      const {protein, carbs, fats, calories} = ingredient;

      macro.protein += protein || 0;
      macro.carbs += carbs || 0;
      macro.fats += fats || 0;
      macro.calories += calories || 0;

      return macro;
    }, {
      protein: 0,
      carbs: 0,
      fats: 0,
      calories: 0
    });

    recipe.protein = ingredientMacros.protein;
    recipe.carbs = ingredientMacros.carbs;
    recipe.fats = ingredientMacros.fats;
    recipe.calories = ingredientMacros.calories;
  };

  const calculateMealMacros = (meal: Meal): void => {
    let ingredientMacros = {
      protein: 0,
      carbs: 0,
      fats: 0,
      calories: 0
    };

    let recipeMacros = {
      protein: 0,
      carbs: 0,
      fats: 0,
      calories: 0
    };

    if (meal.ingredients) {
      ingredientMacros = meal.ingredients.reduce((macro, ingredient) => {
        const {protein, carbs, fats, calories} = ingredient;

        macro.protein += protein || 0;
        macro.carbs += carbs || 0;
        macro.fats += fats || 0;
        macro.calories += calories || 0;

        return macro;
      }, {
        protein: 0,
        carbs: 0,
        fats: 0,
        calories: 0
      });
    }

    if (meal.recipes) {
      recipeMacros = meal.recipes.reduce((macro, recipe) => {
        const {protein, carbs, fats, calories} = recipe;

        macro.protein += protein || 0;
        macro.carbs += carbs || 0;
        macro.fats += fats || 0;
        macro.calories += calories || 0;

        return macro;
      }, {
        protein: 0,
        carbs: 0,
        fats: 0,
        calories: 0
      });
    }

    meal.protein = ingredientMacros.protein + recipeMacros.protein;
    meal.carbs = ingredientMacros.carbs + recipeMacros.carbs;
    meal.fats = ingredientMacros.fats + recipeMacros.fats;
    meal.calories = ingredientMacros.calories + recipeMacros.calories;
  };

  const fetchMeal = async (mealId: any): Promise<void> => {
    const {data, error} = await getWithJwt(
      `${serverlessRoutes.MEAL}/${mealId}`
    );

    if (error && !data) {
      return console.error(error);
    }

    const {recipes, ingredients} = data;

    addMealRecipes(mealId, recipes);
    addMealIngredients(mealId, ingredients);
  };

  const fetchMealPlan = async (id: any): Promise<void> => {
    const {data, error} = await getWithJwt(
      `${serverlessRoutes.MEAL_PLANS}/${id}`
    );

    if (error && !data) {
      return console.error(error);
    }

    set(data.mealPlan);

    update((store) => {
      if (store?.meals) {
        calculateHeadersMacros(store.meals);
      }

      // store.meals = store.meals.filter((meal): boolean => {
      //   if (meal.finishedAt) {
      //     return false;
      //   }
      //   return true;
      // });

      // store.meals.sort(sortItems);

      return store;
    });
  };

  const fetchRecipeIngredients = async (
    mealId: number,
    recipeId: number
  ): Promise<void> => {
    const {data, error} = await getWithJwt(
      `${serverlessRoutes.RECIPE}/${recipeId}/ingredients`
    );

    if (error && !data) {
      return console.error(error);
    }

    addRecipeIngredients(mealId, recipeId, data.ingredients);
  };

  const getMealIdFromRecipeId = (
    recipeId: number
  ): number | undefined => get({subscribe})?.meals?.find(
    ({recipes}): boolean => recipes?.find(
      ({id}): boolean => id === recipeId
    ) ? true : false
  )?.id;



  const setMeals = (
    newMeals: Array<Meal>
  ): void => {
    update((store) => {
      if (store) {
        store.meals = newMeals;
      }

      return store;
    });
  };

  const addMeals = (newMeals: Array<Meal>): void => {
    update((store) => {
      if (!store) {
        return store;
      }

      if (store.meals) {
        store.meals.push(...newMeals);
      } else {
        store.meals = newMeals;
      }

      store.meals.sort(sortItems);

      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  const replaceMeals = (newMeals: Array<Meal>): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const {meals} = store;

      for (const newMeal of newMeals) {
        const mealIndex = meals.findIndex(({id}): boolean => id === newMeal.id);

        if (mealIndex > -1) {
          meals.splice(mealIndex, 1, newMeal);
        }
      }

      store.meals.sort(sortItems);

      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  const removeMeals = (mealIds: Array<number>): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      store.meals = store.meals.filter(
        ({id}): boolean => !mealIds.includes(id)
      );

      store.meals.sort(sortItems);

      calculateHeadersMacros(store.meals);

      return store;
    });
  };



  const setMealIngredients = (
    mealId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const meal = store?.meals?.find(({id}): boolean => id === mealId);

      if (meal) {
        meal.ingredients = newIngredients;
      }

      return store;
    });
  };

  const addMealIngredients = (
    mealId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);

      if (!meal) {
        return store;
      }

      if (meal.ingredients) {
        meal.ingredients.push(...newIngredients);
      } else {
        meal.ingredients = newIngredients;
      }

      meal.ingredients.sort(sortItems);

      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  const replaceMealIngredients = (
    mealId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);

      if (!meal?.ingredients) {
        return store;
      }

      const {ingredients} = meal;

      for (const newIngredient of newIngredients) {
        const ingredientIndex = ingredients.findIndex(
          ({id}): boolean => id === newIngredient.id
        );

        if (ingredientIndex > -1) {
          ingredients.splice(ingredientIndex, 1, newIngredient);
        }
      }

      meal.ingredients.sort(sortItems);

      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  const removeMealIngredients = (
    mealId: number,
    ingredientIds: Array<number>
  ): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);

      if (!meal?.ingredients) {
        return store;
      }

      meal.ingredients = meal.ingredients.filter(
        ({id}): boolean => !ingredientIds.includes(id)
      );

      meal.ingredients.sort(sortItems);

      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };



  const setMealRecipes = (
    mealId: number,
    newRecipes: Array<Recipe>
  ): void => {
    update((store) => {
      const meal = store?.meals?.find(({id}): boolean => id === mealId);

      if (meal) {
        meal.recipes = newRecipes;
      }

      return store;
    });
  };

  const addMealRecipes = (mealId: number, newRecipes: Array<Recipe>): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);

      if (!meal) {
        return store;
      }

      if (meal.recipes) {
        meal.recipes.push(...newRecipes);
      } else {
        meal.recipes = newRecipes;
      }

      meal.recipes.sort(sortItems);

      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  const replaceMealRecipes = (
    mealId: number,
    newRecipes: Array<Recipe>
  ): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);

      if (!meal?.recipes) {
        return store;
      }

      const {recipes} = meal;

      for (const newRecipe of newRecipes) {
        const recipeIndex = recipes.findIndex(
          ({id}): boolean => id === newRecipe.id
        );

        if (recipeIndex > -1) {
          recipes.splice(recipeIndex, 1, newRecipe);
        }
      }

      meal.recipes.sort(sortItems);

      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  const removeMealRecipes = (
    mealId: number,
    recipeIds: Array<number>
  ): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);

      if (!meal?.recipes) {
        return store;
      }

      meal.recipes = meal.recipes.filter(
        ({id}): boolean => !recipeIds.includes(id)
      );

      meal.recipes.sort(sortItems);

      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };



  const setRecipeIngredients = (
    mealId: number,
    recipeId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      const meal = store?.meals?.find(({id}): boolean => id === mealId);
      const recipe = meal?.recipes?.find(({id}): boolean => id === recipeId);

      if (recipe) {
        recipe.ingredients = newIngredients;
      }

      return store;
    });
  };

  const addRecipeIngredients = (
    mealId: number,
    recipeId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);
      const recipe = meal?.recipes?.find(({id}): boolean => id === recipeId);

      if (!meal || !recipe) {
        return store;
      }

      if (recipe.ingredients) {
        recipe.ingredients.push(...newIngredients);
      } else {
        recipe.ingredients = newIngredients;
      }

      recipe.ingredients.sort(sortItems);

      calculateRecipeMacros(recipe);
      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  const replaceRecipeIngredients = (
    mealId: number,
    recipeId: number,
    newIngredients: Array<Ingredient>
  ): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);
      const recipe = meal?.recipes?.find(({id}): boolean => id === recipeId);

      if (!meal || !recipe?.ingredients) {
        return store;
      }

      const {ingredients} = recipe;

      for (const newIngredient of newIngredients) {
        const ingredientIndex = ingredients.findIndex(
          ({id}): boolean => id === newIngredient.id
        );

        if (ingredientIndex > -1) {
          ingredients.splice(ingredientIndex, 1, newIngredient);
        }
      }

      recipe.ingredients.sort(sortItems);

      calculateRecipeMacros(recipe);
      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  const removeRecipeIngredients = (
    mealId: number,
    recipeId: number,
    ingredientIds: Array<number>
  ): void => {
    update((store) => {
      if (!store?.meals) {
        return store;
      }

      const meal = store.meals.find(({id}): boolean => id === mealId);
      const recipe = meal?.recipes?.find(({id}): boolean => id === recipeId);

      if (!meal || !recipe?.ingredients) {
        return store;
      }

      recipe.ingredients = recipe.ingredients.filter(
        ({id}): boolean => !ingredientIds.includes(id)
      );

      recipe.ingredients.sort(sortItems);

      calculateRecipeMacros(recipe);
      calculateMealMacros(meal);
      calculateHeadersMacros(store.meals);

      return store;
    });
  };

  return {
    set,
    subscribe,
    update,

    fetchMeal,
    fetchMealPlan,
    fetchRecipeIngredients,
    calculateHeadersMacros,
    getMealIdFromRecipeId,

    setMeals,
    addMeals,
    replaceMeals,
    removeMeals,

    setMealIngredients,
    addMealIngredients,
    replaceMealIngredients,
    removeMealIngredients,

    setMealRecipes,
    addMealRecipes,
    replaceMealRecipes,
    removeMealRecipes,

    setRecipeIngredients,
    addRecipeIngredients,
    replaceRecipeIngredients,
    removeRecipeIngredients,
  };
};

const mealPlanStore = mealPlanStoreCreate();

export {mealPlanStore};
