<script lang="ts">
  import { onMount } from "svelte";
  import { dialogTypes, getWithJwt, serverlessRoutes, translate } from "lib";
  import { dialogData } from "stores";
  import { ButtonComponent, ExpansionPanel, More } from "ui";

  let recipeTypes: Array<any> = [];

  const menuItems = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {},
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: translate("RECIPE"),
          async executeFunction(): Promise<void> {},
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  const onCreateRecipeType = (): void => {};

  const fetchRecipeTypes = async (): Promise<void> => {
    try {
      const result = await getWithJwt(`${serverlessRoutes.RECIPE}/food_types`);
      recipeTypes = result.data;
    } catch (error) {
      console.error(error);
    }
  };

  onMount(fetchRecipeTypes);
</script>

<ExpansionPanel
  title={translate("RECIPE_TYPES")}
  subtitle="{translate('NUMBER_OF_ITEMS')}: {recipeTypes.length}"
>
  <div class="flex flex-col gap-4">
    <div class="text-xs text-justify">
      {translate("SETTINGS_RECIPE_TYPES_NOTE")}
    </div>
    <div class="flex flex-col gap-2">
      {#each recipeTypes as recipeType}
        <div class="flex items-center justify-between">
          <div class="text-xs">{recipeType}</div>
          <More {menuItems} />
        </div>
      {/each}
    </div>
    <div class="flex justify-center">
      <ButtonComponent isOutline on:click={onCreateRecipeType}>
        {translate("CREATE_TYPE")}
      </ButtonComponent>
    </div>
  </div>
</ExpansionPanel>
