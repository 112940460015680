<script lang="ts">
  import { onMount } from "svelte";
  import { postWithJwt, translate, getWithJwt, serverlessRoutes } from "lib";
  import {
    dialogData,
    showAlert,
    trainerClientGroups,
    trainerPrograms,
    trainerProgramsCount,
    user,
  } from "stores";
  import InfiniteScroll from "../../UI/InfiniteScroll.svelte";
  import { trainerClientPrograms } from "stores";
  import { ButtonComponent, ButtonGroup, CheckboxComponent, Search } from "ui";

  let data: any;
  let timeout: NodeJS.Timeout;
  let page = 0;
  let search: string = "";
  let templateType: "MY" | "LIBRARY" | "GROUPS" = "MY";
  let isLoading = false;
  let scrollElem: HTMLDivElement;
  let selectedIds: Array<number> = [];
  let disabled = true;

  const sortWorkouts = (): void => {
    $trainerPrograms.forEach((plan): void => {
      plan.workouts = plan?.workouts?.sort((a: any, b: any): number => a.position - b.position);
    });
  };

  const createUrl = (): string => {
    let skip = `&skip=${page * 15}`;
    let name = "";
    let template = "";

    if (search) {
      name = `&name=${search}`;
    }

    if (templateType === "MY") {
      template = "&default=1&template=0&group=0";
    } else if (templateType === "LIBRARY") {
      template = "&default=0&template=1&group=0";
    } else {
      template = "&default=0&template=0&group=1";
    }

    return `${serverlessRoutes.PROGRAM}/list?take=15${skip}${template}${name}`;
  };

  const fetch = async (): Promise<void> => {
    if ($user) {
      page = 0;

      try {
        const response = await getWithJwt(createUrl());
        $trainerPrograms = response.data.data;
        $trainerProgramsCount = response.count;
        sortWorkouts();
      } catch (error) {
        console.error(error);
      }

      isLoading = false;
    }
  };

  const onSearch = async (): Promise<void> => {
    clearTimeout(timeout);
    timeout = setTimeout(async (): Promise<void> => {
      fetch();
    }, 1000);
  };

  const onMyPrograms = async (): Promise<void> => {
    templateType = "MY";
    await fetch();
  };

  const onLibrary = async (): Promise<void> => {
    templateType = "LIBRARY";
    await fetch();
  };

  const onGroups = async (): Promise<void> => {
    templateType = "GROUPS";
    await fetch();
  };

  const onChange = (id: number): void => {
    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    } else {
      selectedIds = [...selectedIds, id];
    }

    disabled = selectedIds.length === 0;
  };

  const onLoadMore = async (): Promise<void> => {
    if ($user) {
      page += 1;

      try {
        const response = await getWithJwt(createUrl());
        $trainerPrograms = [...$trainerPrograms, ...response.data];
        sortWorkouts();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const importToGroup = async (): Promise<void> => {
    try {
      const response = [];

      const group = $trainerClientGroups.find((group): boolean => group.id === data.group.id);

      group.clientGroupPrograms = [...group.clientGroupPrograms, ...response];

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_ASSIGNED_TRAINING_PROGRAMS");

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_ASSIGNING_TRAINING_PROGRAM");
      isLoading = false;
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.group) {
      await importToGroup();
    } else {
      try {
        const partialProgramMap = {};

        selectedIds.forEach(
          (id) =>
            (partialProgramMap[id] = {
              isTemplate: 0,
              default: 0,
              clientId: data.client.id,
            })
        );

        const response = await postWithJwt(`${serverlessRoutes.PROGRAM}/copy`, {
          partialProgramMap,
        });

        $trainerClientPrograms = [...response.data.programs, ...$trainerClientPrograms];

        $showAlert.color = "black";
        $showAlert.message = translate("SUCCESSFULLY_ASSIGNED_TRAINING_PROGRAMS");

        $dialogData.type = "";
        $dialogData.data = {};
      } catch (error) {
        console.error(error);
        $showAlert.color = "red-400";
        $showAlert.message = translate("ERROR_ASSIGNING_TRAINING_PROGRAM");
        isLoading = false;
      }
    }
  };

  onMount(async (): Promise<void> => {
    await fetch();
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {#if data.client}
      {translate("ASSIGN_PROGRAM_TO_CLIENT")}: {data.client.name}
    {:else if data.group}
      {translate("ASSIGN_WORKOUT_PLAN_TO_GROUP")}: {data.group.name}
    {:else}
      {translate("ASSIGN_WORKOUT_PLAN")}
    {/if}
  </div>

  <Search placeholder={translate("SEARCH_PROGRAM")} bind:value={search} on:input={onSearch} />

  <div class="flex justify-center">
    <ButtonGroup
      buttons={[
        { title: translate("MY_PROGRAMS"), onClick: onMyPrograms },
        { title: translate("LIBRARY"), onClick: onLibrary },
        { title: translate("GROUPS"), onClick: onGroups },
      ]}
    />
  </div>

  <div class="h-80 pr-4 flex flex-col gap-4 overflow-y-scroll" bind:this={scrollElem}>
    {#each $trainerPrograms as trainerProgram}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="p-2 flex items-center border rounded-md"
        class:isActive={selectedIds.includes(trainerProgram.id)}
        on:click={() => onChange(trainerProgram.id)}
      >
        <div class="h-16 w-16 mr-4 overflow-hidden flex items-center">
          <img class="rounded-full" src={trainerProgram.thumbnailUrl || "logo.png"} alt="Avatar" />
        </div>
        <div class="grow">{trainerProgram.name}</div>
        <CheckboxComponent label="" value={selectedIds.includes(trainerProgram.id)} />
      </div>
    {/each}

    <InfiniteScroll
      hasMore={$trainerPrograms.length <= $trainerProgramsCount}
      on:loadMore={onLoadMore}
    />
  </div>

  <div class="flex justify-center">
    <ButtonComponent {disabled} {isLoading} on:click={onSubmit}>
      {translate("ASSIGN")}
    </ButtonComponent>
  </div>
</div>

<style>
  .isActive {
    border-color: rgb(var(--primary));
  }
</style>
