<script lang="ts">
  import {translate} from "lib";
  import {createEventDispatcher, onMount} from "svelte";

  // export let carbs = 33;
  // export let protein = 34;
  // export let fats = 33;
  export let calories = 0;
  export let percentages = {carbs: 0, protein: 0, fats: 0};

  let fromSlider: HTMLInputElement;
  let toSlider: HTMLInputElement;

  const dispatch = createEventDispatcher();

  const controlFromSlider = (): void => {
    const [from, to] = getParsed();

    fillSlider("#C6C6C6", "#22897b");

    if (from > to) {
      percentages.carbs = to;
      percentages.protein = 0;
      fromSlider.value = to.toString();
    } else {
      percentages.carbs = from;
      percentages.protein = to - from;
    }

    percentages = percentages;

    const carbsg = parseFloat(((calories * (percentages.carbs / 100)) / 4).toFixed(1));
    const proteing = parseFloat(((calories * (percentages.protein / 100)) / 4).toFixed(1));
    const fatsg = parseFloat(((calories * (percentages.fats / 100)) / 9).toFixed(1));

    dispatch("values", {
      grams: {carbs: carbsg, protein: proteing, fats: fatsg},
      percents: {carbs: percentages.carbs, protein: percentages.protein, fats: percentages.fats}
    });
  };

  const controlToSlider = (): void => {
    const [from, to] = getParsed();

    fillSlider("#C6C6C6", "#22897b");
    setToggleAccessible();

    if (from <= to) {
      percentages.fats = 100 - to;
      percentages.protein = to - from;
      toSlider.value = to.toString();
    } else {
      percentages.fats = 100 - from;
      percentages.protein = 0;
      toSlider.value = from.toString();
    }

    percentages = percentages;

    const carbsg = parseFloat(((calories * (percentages.carbs / 100)) / 4).toFixed(1));
    const proteing = parseFloat(((calories * (percentages.protein / 100)) / 4).toFixed(1));
    const fatsg = parseFloat(((calories * (percentages.fats / 100)) / 9).toFixed(1));

    dispatch("values", {
      grams: {carbs: carbsg, protein: proteing, fats: fatsg},
      percents: {carbs: percentages.carbs, protein: percentages.protein, fats: percentages.fats}
    });
  };

  function getParsed () {
    const from = parseInt(fromSlider.value, 10);
    const to = parseInt(toSlider.value, 10);
    return [from, to];
  }

  const fillSlider = (sliderColor: string, rangeColor: string): void => {
    const rangeDistance = parseInt(toSlider.max) - parseInt(toSlider.min);
    const fromPosition = parseInt(fromSlider.value) - parseInt(toSlider.min);
    const toPosition = parseInt(toSlider.value) - parseInt(toSlider.min);

    toSlider.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition) / (rangeDistance) * 100}%,
      ${rangeColor} ${((fromPosition) / (rangeDistance)) * 100}%,
      ${rangeColor} ${(toPosition) / (rangeDistance) * 100}%,
      ${sliderColor} ${(toPosition) / (rangeDistance) * 100}%,
      ${sliderColor} 100%)`;
  }

  const setToggleAccessible = (): void => {
    toSlider.style.zIndex = parseInt(toSlider.value) <= 0 ? "2" : "0";
  }

  onMount((): void => {
    setTimeout(() => {
      fromSlider.value = percentages.carbs.toString();
      toSlider.value = (100 - percentages.fats).toString();

      fillSlider("#C6C6C6", "#22897b");
      setToggleAccessible();
    }, 100);
  });
</script>

<style>
  input[type=range]::-webkit-slider-thumb {
    appearance: none;
    pointer-events: all;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #C6C6C6;
    cursor: pointer;
  }

  input[type=range]::-moz-range-thumb {
    appearance: none;
    pointer-events: all;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #C6C6C6;
    cursor: pointer;
  }

  input[type=range]::-webkit-slider-thumb:hover {
    background: #f7f7f7;
  }

  input[type=range]::-webkit-slider-thumb:active {
    box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
  }

  input[type="range"] {
    appearance: none;
    position: absolute;
    top: 50%;
    height: 4px;
    width: 100%;
    background-color: #C6C6C6;
    pointer-events: none;
    transform: translateY(-50%);
  }

  #fromSlider {
    height: 0;
    z-index: 1;
  }

  .slider {height: 24px;}
</style>

<div class="p-2 flex flex-col gap-2 border-b border-slate-200 dark:border-zinc-700">
  <div class="slider relative">
    <input id="fromSlider" type="range" value="33" min="0" max="100" bind:this={fromSlider} on:input={controlFromSlider}/>
    <input id="toSlider" type="range" value="66" min="0" max="100" bind:this={toSlider} on:input={controlToSlider}/>
  </div>
  <div class="flex justify-evenly text-center">
    <div>
      <div class="text-xs">{translate("CARBS")}</div>
      <div class="font-bold text-xs text-green-400">{percentages.carbs}% | {((calories * (percentages.carbs / 100)) / 4).toFixed(1)}g</div>
    </div>
    <div>
      <div class="text-xs">{translate("PROTEIN")}</div>
      <div class="font-bold text-xs text-sky-400">{percentages.protein}% | {((calories * (percentages.protein / 100)) / 4).toFixed(1)}g</div>
    </div>
    <div>
      <div class="text-xs">{translate("FATS")}</div>
      <div class="font-bold text-xs text-red-400">{percentages.fats}% | {((calories * (percentages.fats / 100)) / 9).toFixed(1)}g</div>
    </div>
  </div>
</div>
