import { get } from "svelte/store";
import { user } from "stores";
import { getWithJwt, sortItems, storage } from "lib";
import type { Updater } from "svelte/store";
import type { ItemsStore } from "interfaces";

const loadCache =
  <Item, Store extends ItemsStore<Item>>(
    update: (this: void, updater: Updater<Store>) => void,
    cacheName: string
  ) =>
  async (): Promise<void> => {
    const cache = await storage.get(cacheName);

    if (cache) {
      const { items, count } = cache;

      update((store) => {
        store.items = items;
        store.count = count;
        store.hasMore = store.items.length < store.count;

        return store;
      });
    }
  };

const fetchData =
  <Item, Store extends ItemsStore<Item>>(
    update: (this: void, updater: Updater<Store>) => void,
    createUrl: () => string,
    cacheName: string
  ) =>
  async (): Promise<void> => {
    update((store) => {
      store.isFetching = true;
      store.skip = 0;
      return store;
    });

    try {
      const response = await getWithJwt(createUrl());
      const { items, count } = response.data;

      update((store) => {
        store.items = items;
        store.count = count;
        store.hasMore = store.items.length < store.count;

        return store;
      });

      await storage.set(cacheName, { items, count });
    } catch (error) {
      console.error(error);
    } finally {
      update((store) => {
        store.isFetching = false;
        return store;
      });
    }
  };

const fetchMoreData =
  <Item, Store extends ItemsStore<Item>>(
    update: (this: void, updater: Updater<Store>) => void,
    createUrl: () => string,
    skip: number
  ) =>
  async (): Promise<void> => {
    update((store) => {
      store.isFetchingMore = true;
      store.skip += skip;
      return store;
    });

    try {
      const response = await getWithJwt(createUrl());

      update((store) => {
        store.items.push(...response.data.items);
        store.isFetchingMore = false;
        return store;
      });
    } catch (error) {
      console.error(error);
    }
  };

const replace =
  <Item, Store extends ItemsStore<Item>>(update: (this: void, updater: Updater<Store>) => void) =>
  (newItems: Array<Item>): void => {
    update((store) => {
      newItems.forEach((newItem): void => {
        const itemIndex = store.items.findIndex(({ id }): boolean => id === newItem.id);

        if (itemIndex === -1) {
          return;
        }

        store.items.splice(itemIndex, 1, newItem);
      });

      // const {items} = store;
      // const index = items.findIndex((item): boolean => item.id === id);

      // if (index > -1) {
      //   store.items = items.with(index, item);
      // }

      return store;
    });
  };

// const remove =
//   <Item, Store extends ItemsStore<Item>>(
//     update: (this: void, updater: Updater<Store>) => void
//   ) =>
//   (ids: Array<number>): void => {
//     update((store) => {
//       store.items = store.items.filter(
//         (item): boolean => !ids.includes(item.id)
//       );

//       store.count -= ids.length;
//       store.skip -= ids.length;
//       store.hasMore = store.items.length < store.count;

//       return store;
//     });
//   };

// const add =
//   <Item, Store extends ItemsStore<Item>>(
//     update: (this: void, updater: Updater<Store>) => void
//   ) =>
//   (items: Array<Item>, isStart = true): void => {
//     update((store) => {
//       if (isStart) {
//         store.items.unshift(...items);
//       } else {
//         store.items.push(...items);
//       }

//       store.count += items.length;
//       store.skip += items.length;
//       store.hasMore = store.items.length < store.count;

//       return store;
//     });
//   };

//     // const {items} = store;
//     // const index = items.findIndex((item): boolean => item.id === id);

//     // if (index > -1) {
//     //   store.items = items.with(index, item);
//     // }

//     return store;
//   });
// };

const remove =
  <Item, Store extends ItemsStore<Item>>(update: (this: void, updater: Updater<Store>) => void) =>
  (ids: Array<number>): void => {
    update((store) => {
      store.items = store.items.filter((item): boolean => !ids.includes(item.id));

      store.count -= ids.length;
      store.skip -= ids.length;
      store.hasMore = store.items.length < store.count;

      return store;
    });
  };

const add =
  <Item, Store extends ItemsStore<Item>>(update: (this: void, updater: Updater<Store>) => void) =>
  (items: Array<Item>, isStart = true): void => {
    update((store) => {
      if (isStart) {
        store.items.unshift(...items);
      } else {
        store.items.push(...items);
      }

      store.count += items.length;
      store.skip += items.length;
      store.hasMore = store.items.length < store.count;

      return store;
    });
  };

const addItem =
  <Item, Store extends ItemsStore<Item>>(
    update: (this: void, updater: Updater<Store>) => void,
    key: string,
    calculateRecipeMacros: (item: any) => void
  ) =>
  (id: number, newItems: Array<Item>): void => {
    update((store) => {
      const item = store.items.find((item) => item.id === id);

      if (!item) {
        return store;
      }

      if (item[key]) {
        item[key].push(...newItems);
      } else {
        item[key] = newItems;
      }

      item[key].sort(sortItems);

      calculateRecipeMacros(recipe);

      return store;
    });
  };

const search =
  <Item, Store extends ItemsStore<Item>>(
    update: (this: void, updater: Updater<Store>) => void,
    fetchData: () => Promise<void>
  ) =>
  (): void => {
    update((store) => {
      clearTimeout(store.searchTimeout);
      store.searchTimeout = setTimeout(fetchData, 1000);
      return store;
    });
  };

const storeUtil = <Item, Store extends ItemsStore<Item>>(
  cacheName: string,
  update: (this: void, updater: Updater<Store>) => void,
  createUrl: () => string
) => {
  const fd = fetchData<Item, Store>(update, createUrl, cacheName);

  return {
    add: add<Item, Store>(update),
    replace: replace<Item, Store>(update),
    remove: remove<Item, Store>(update),
    fetchData: fd,
    fetchMoreData: fetchMoreData<Item, Store>(update, createUrl, 8),
    search: search<Item, Store>(update, fd),
    loadCache: loadCache<Item, Store>(update, cacheName),
  };
};

export { storeUtil, addItem };
