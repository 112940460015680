<script lang="ts">
  import { slide } from "svelte/transition";
  import { pop } from "svelte-spa-router";
  import { post, serverlessRoutes, translate } from "lib";
  import { alertStore } from "stores";
  import { ButtonComponent, InputField, Svg } from "ui";

  let email = "";
  let code = "";
  let newPassword = "";
  let isLoading = false;
  let isCodeSent = false;

  const createRecoveryCode = async (): Promise<void> => {
    isLoading = true;

    const {error} = await post(
      `${serverlessRoutes.AUTH}/recovery-code`,
      {email}
    );

    if (error) {
      isLoading = false;
      alertStore.show(error, "error");
      return console.error(error);
    }

    isCodeSent = true;
    isLoading = false;
  };

  const resetPassword = async (): Promise<void> => {
    isLoading = true;

    const {error} = await post(
      `${serverlessRoutes.AUTH}/reset-password`,
      {email, newPassword, code}
    );

    if (error) {
      isLoading = false;
      alertStore.show(error, "error");
      return console.error(error);
    }

    alertStore.show(translate("SUCCESSFULLY_RESET_PASSWORD"));

    await pop();
  };
</script>

<div
  class="relative h-[100vh] w-full flex items-center justify-center"
>

  <Svg
    myClass="absolute top-8 left-8"
    size={24}
    name="left-arrow-button"
    on:click={pop}
  />

  <div class="w-max-[320px] flex flex-col gap-4">
    <div class="mb-4 text-center">
      <div class="text-xl font-bold">{translate("RESET_PASSWORD")}</div>
      <div class="text-xs">{translate("RESET_PASSWORD_NOTE")}</div>
    </div>

    <form
      class="flex flex-col gap-4"
      on:submit|preventDefault={createRecoveryCode}
    >
      <InputField
        type="email"
        label="Email"
        disabled={isCodeSent}
        bind:value={email}
      />

      {#if !isCodeSent}
        <div class="flex justify-center" out:slide>
          <ButtonComponent {isLoading} type="submit" disabled={!email}>
            {translate("SEND_CODE")}
          </ButtonComponent>
        </div>
      {/if}
    </form>

    {#if isCodeSent}
      <form
        class="flex flex-col gap-4"
        on:submit|preventDefault={resetPassword}
        in:slide
      >
        <InputField type="number" label={translate("CODE")} bind:value={code}/>

        <InputField
          type="password"
          label={translate("NEW_PASSWORD")}
          bind:value={newPassword}
        />

        <div class="flex justify-center">
          <ButtonComponent type="submit" {isLoading}>
            {translate("RESET_PASSWORD")}
          </ButtonComponent>
        </div>
      </form>
    {/if}

  </div>

</div>
