<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { Capacitor } from "@capacitor/core";
  import { dialogTypes, entities, getWithJwt, translate, parseDate, serverlessRoutes } from "lib";
  import { dialogData, selectedIds, trainerClientsStore } from "stores";
  import { ButtonComponent, ButtonGroup, InfiniteScroll, Search, Svg } from "ui";
  import { showMenu } from "../../stores/menuStore";
  import ClientListItem from "../../components/ClientListItem.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import LoadMoreButton from "../../components/LoadMoreButton.svelte";
  import ExpansionPanel from "../../components/UI/ExpansionPanel.svelte";
  import SelectClientsMenu from "../../components/Menu/SelectClientsMenu.svelte";
  import ClientListFilter from "./ClientListFilter.svelte";

  import type { IButtonGroup } from "../../interfaces/IButtonGroup";

  interface IGroupedClients {
    date: string;
    count: number;
  }

  let showClientsByDay = false;
  let clientsByDay: IGroupedClients[] = [];
  let clientsByDayMap = {};
  let skip = 0;
  let isSelectMode = false;
  let selectedDays: Array<string> = [];

  const onAddClient = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_CLIENT;
  };

  const onAllClients = (): void => {
    if (!showClientsByDay) {
      return;
    }

    showClientsByDay = false;
    $selectedIds = [];
  };

  const onClientsByDay = async (): Promise<void> => {
    if (showClientsByDay) {
      return;
    }

    showClientsByDay = true;
    try {
      const response = await getWithJwt(
        `${serverlessRoutes.TRAINER_CLIENT}/grouped-by-date?take=15&skip=${skip}`
      );
      skip += 15;
      clientsByDay = [...clientsByDay, ...response.data];
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClientsForDay = async (day: string, loadMore = false) => {
    if (clientsByDayMap[day] && !loadMore) {
      return;
    }
    const fixedDate = new Date(day);
    fixedDate.setDate(fixedDate.getDate() + 1);
    const res = await getWithJwt(
      `${serverlessRoutes.TRAINER_CLIENT}?createdAt=${fixedDate.toISOString().slice(0, 10)}&skip=${clientsByDayMap[day]?.length || 0}`
    );
    if (!clientsByDayMap[day]) {
      clientsByDayMap[day] = [];
    }
    clientsByDayMap[day] = [...clientsByDayMap[day], ...res.data.data];
  };

  const onSelectAllDay = async (day: string): Promise<void> => {
    if (!clientsByDayMap[day]) {
      await fetchClientsForDay(day);
    }

    if (selectedDays.includes(day)) {
      selectedDays = selectedDays.filter((selectedDay) => selectedDay !== day);

      for (const client of clientsByDayMap[day]) {
        $selectedIds = $selectedIds.filter((id) => id !== client.id);
      }
    } else {
      selectedDays = [...selectedDays, day];

      for (const client of clientsByDayMap[day]) {
        $selectedIds = [...$selectedIds, client.id];
      }
    }
  };

  onMount(() => {
    trainerClientsStore.loadCache();
    trainerClientsStore.fetchData();
  });

  const buttonGroup: IButtonGroup[] = [
    {
      title: translate("ALL_CLIENTS"),
      onClick: onAllClients,
    },
    {
      title: translate("CLIENTS_BY_DAY"),
      onClick: () => {
        $selectedIds = [];
        onClientsByDay();
      },
    },
  ];

  const toggleSelectMode = () => {
    isSelectMode = !isSelectMode;
    $showMenu = !isSelectMode;

    $selectedIds = [];
  };
</script>

<TopMenu entity={entities.CLIENTS} />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="p-4 flex flex-col gap-4">
  <div class="flex gap-4">
    <Search
      placeholder={translate("SEARCH_CLIENTS")}
      bind:value={$trainerClientsStore.filter.search}
      on:input={trainerClientsStore.search}
    />
    <div
      class="flex items-center justify-center cursor-pointer"
      role="button"
      tabindex="0"
      on:click={toggleSelectMode}
    >
      <Svg name="check-square-offset" size={28} customColor="bg-primary-500 dark:bg-primary-500" />
    </div>
    <ButtonComponent myClasses="whitespace-nowrap" on:click={onAddClient}>
      {translate("ADD_CLIENT")}
    </ButtonComponent>
  </div>

  <div class="flex justify-center">
    <ButtonGroup buttons={buttonGroup} />
  </div>

  {#if !showClientsByDay}
    <ClientListFilter on:fetchData={trainerClientsStore.fetchData} />

    <div class="leading-none text-center">
      {translate("TOTAL_NUMBER_OF_CLIENTS")}:
      <span class="ml-1 font-bold">{$trainerClientsStore.count}</span>
    </div>

    {#key $trainerClientsStore.items}
      {#each $trainerClientsStore.items as client}
        <ClientListItem {client} {isSelectMode} />
      {/each}
    {/key}

    <InfiniteScroll
      hasMore={$trainerClientsStore.hasMore}
      isFetchingMore={$trainerClientsStore.isFetchingMore}
      on:loadMore={trainerClientsStore.fetchMoreData}
    />
    <!-- {/if} -->
  {:else}
    {#each clientsByDay as day}
      <ExpansionPanel
        {isSelectMode}
        expansionType="CLIENTS_BY_DAY"
        title={parseDate(day.date)}
        subtitle="{translate('NUMBER_OF_CLIENTS')}: {day.count}"
        on:expanded={() => fetchClientsForDay(day.date)}
        on:change={() => onSelectAllDay(day.date)}
      >
        {#if clientsByDayMap[day.date]}
          <div class="flex flex-col gap-4">
            {#each clientsByDayMap[day.date] as client}
              <ClientListItem {client} {isSelectMode} />
            {/each}
          </div>
          {#if clientsByDayMap[day.date].length < day.count}
            <LoadMoreButton fetchMoreData={() => fetchClientsForDay(day.date, true)} />
          {/if}
        {/if}
      </ExpansionPanel>
    {/each}
    <LoadMoreButton fetchMoreData={onClientsByDay} />
  {/if}
  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
{#if isSelectMode}
  <SelectClientsMenu areCheckboxesVisible={!showClientsByDay} bind:isSelectMode />
{/if}
