<script lang="ts">
  import { pop, push, replace } from "svelte-spa-router";
  import Svg from "../UI/Svg.svelte";
  import More from "../UI/More.svelte";
  import type { MenuItem } from "../../interfaces/MenuItem";
  import { onMount } from "svelte";
  import { dialogTypes, getWithJwt, isClient, serverlessRoutes } from "lib";
  import { dialogData, user } from "stores";
  import { bookmarkedMessages } from "../../stores/bookmarkedMessages";
  import Tag from "../Tag.svelte";
  import { Capacitor } from "@capacitor/core";
  import { onlineUsers } from "stores/onlineUsersStore";

  export let trainersName: string;
  export let avatarUrl: string;
  export let sessionId: number;
  export let clientId: number | null = null;
  export let searchMode: boolean;

  const position = Capacitor.isNativePlatform() ? "fixed" : "sticky";

  let location;

  let menuItems: Array<MenuItem> = [
    {
      title: "SEARCH_IN_CHAT",
      icon: "search",
      executeFunction(): void {
        searchMode = true;
      },
    },
    {
      title: "SHARED_CONTENT",
      icon: "archive",
      executeFunction(): void {
        push(`/chat/shared/${sessionId}`);
      },
    },
    {
      title: "BOOKMARKED_MESSAGES",
      icon: "bookmark-simple",
      executeFunction(): void {
        $dialogData.data = { sessionId };
        $dialogData.type = dialogTypes.BOOKMARKED_MESSAGES;
      },
    },
  ];

  const fetchBookmarkedMessages = async (): Promise<void> => {
    const url = `${serverlessRoutes.BOOKMARK}?sessionId=${sessionId}`;
    try {
      const res = await getWithJwt(url);
      $bookmarkedMessages = res.data;
    } catch (err) {}
  };

  onMount(() => {
    if (isClient($user))
      menuItems = menuItems.filter((item) => item.title !== "BOOKMARKED_MESSAGES");
    location = window.location.href;

    if (!isClient($user)) fetchBookmarkedMessages();
  });

  const myPop = () => {
    pop();
    setTimeout(() => {
      if (window.location.href === location) {
        myPop();
      }
    }, 100);
  };
</script>

<div
  class={`px-4 menu ${position} flex-row center-space-between bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 border-b border-slate-300 dark:border-zinc-700`}
>
  <div class="flex-row center-center">
    <div role="button" tabindex="0" on:click={() => myPop()} on:keypress={() => myPop()}>
      <Svg name="left-arrow-button" size={24} />
    </div>
    <div
      class="flex flex-row items-center"
      role="button"
      tabindex="0"
      on:click={() => (clientId ? replace(`/profile/${clientId}`) : null)}
      on:keypress={() => (clientId ? replace(`/profile/${clientId}`) : null)}
    >
      <img
        class="mgl-24"
        style="border-radius: 96px; width: 36px; height: 36px;"
        alt="Avatar"
        height="36"
        width="36"
        src={avatarUrl ? avatarUrl : "/default-avatar.png"}
      />
      <div class="mgl-24 flex flex-row items-center">
        {trainersName}
        {#if $onlineUsers.includes(clientId)}
          <Svg
            customColor="bg-green-700 dark:bg-green-300"
            myClass="ml-1"
            name="online"
            size={12}
          />
        {/if}
      </div>
    </div>
  </div>
  <div class="flex items-center gap-4">
    <!-- <Svg name="down-caret" size={20} myClass="mgr-12" /> -->
    <More {menuItems} />
    {#if !isClient($user) && $bookmarkedMessages?.length}
      <Tag color="#FF0000" height={16} />
    {/if}
  </div>
</div>

<style>
  .menu {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 720px;
    height: 48px;
    width: 100%;
    z-index: 1;
    padding-top: calc(env(safe-area-inset-top) * 1.25);
    padding-bottom: calc(env(safe-area-inset-top) / 2);
  }
</style>
