<script>
  import { entities, getWithJwt, serverlessRoutes, translate } from "lib";
  import TopMenu from "../../components/TopMenu.svelte";
  import { onMount } from "svelte";
  import ExpansionPanel from "../../components/UI/ExpansionPanel.svelte";
  import ClientListItem from "../../components/ClientListItem.svelte";

  let data = [];
  let selectDayData = [];
  let selectedOption = "ACCOUNT";

  const onFetchData = async () => {
    const url = `${serverlessRoutes.OVERVIEW}/client`;
    try {
      const res = await getWithJwt(url);
      const today = new Date();
      today.setDate(today.getDate() - 1);
      res.data.forEach((r) => {
        r.dayCount = Math.floor((new Date(r.expireAt) - today) / (1000 * 60 * 60 * 24));
      });
      data = res.data;
      data = data.sort((a, b) => a.dayCount - b.dayCount);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchForDay = async (expireAt) => {
    let url;
    const date = new Date(expireAt);
    date.setHours(date.getHours() + 2);
    const dateString = date.toISOString().slice(0, 10);
    url = `${serverlessRoutes.TRAINER_CLIENT}?take=15&skip=0&expireAt=${dateString}`;
    try {
      const res = await getWithJwt(url);
      selectDayData = res.data.data;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchData = (option) => {
    onFetchData();
  };

  const selectItems = [
    { name: "ACCOUNT", selected: selectedOption === "ACCOUNT" },
    { name: "TRAINING", selected: selectedOption === "TRAINING" },
    // { name: "TRAINING_PLAN", selected: selectedOption === "TRAINING_PLAN" },
    // { name: "MEAL_PLAN", selected: selectedOption === "MEAL_PLAN" },
  ];

  const onOptionChange = (changeValue) => {
    selectedOption = changeValue;
    selectDayData = [];
    fetchData(selectedOption);
  };

  const generateString = (dayCount) => {
    if (selectedOption === "ACCOUNT")
      return `${translate("EXPIRING")} ${dayCount === 0 ? translate("TODAY").toLowerCase() : dayCount === 1 ? translate("TOMORROW").toLowerCase() : `${translate("IN")}: ${dayCount} ${translate("DAYS").toLowerCase()}`}`;
    else return `${translate("TOTAL_NUMBER_OF_WORKOUTS")}:`;
  };

  onMount(onFetchData);
</script>

<TopMenu entity={entities.NOTIFICATIONS} />
<div class="mt-3 mb-4">
  <!-- <Select {onOptionChange} label="EXPIRY" {selectItems} /> -->
</div>
<div class="gap-4">
  {#each data as reminder}
    <div
      class="m-4"
      tabindex="0"
      role="button"
      on:click={() => fetchForDay(reminder.expireAt)}
      on:keypress={() => fetchForDay(reminder.expireAt)}
    >
      <ExpansionPanel
        title={generateString(reminder.dayCount)}
        subtitle={`${translate("ITEM_COUNT")}: ${reminder.count}`}
      >
        {#each selectDayData as item}
          <ClientListItem client={item} />
        {/each}
      </ExpansionPanel>
    </div>
  {/each}
</div>
