<script lang="ts">
  import { onMount } from "svelte";
  import { deleteWithJwt, getWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import {
    cantDoExercises,
    currentClient,
    selectedIdsStore,
    showAlert,
    trainerClientWorkouts,
    trainerExercisesStore,
  } from "stores";
  import { ButtonComponent, InfiniteScroll, Search } from "ui";
  import ExerciseInWorkout from "../Training/ExerciseInWorkout.svelte";
  import ExercisesFilter from "../Training/ExercisesFilter.svelte";

  const { EXERCISE, WORKOUT } = serverlessRoutes;
  let isAddExercise = false;
  let scrollElement: HTMLDivElement;

  const mountScrollElement = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  const onSwitch = (): void => {
    isAddExercise = !isAddExercise;

    if (isAddExercise) {
      $selectedIdsStore = $cantDoExercises.exerciseIds;
    } else {
      selectedIdsStore.clear();
    }
  };

  const onAddCantDoExercise = async (event: CustomEvent<any>): Promise<void> => {
    const { id, workoutId } = event.detail;
    const clientId = window.location.href.split("/")[5];

    try {
      const res = await postWithJwt(`${serverlessRoutes.EXERCISE}/cant-do-exercises`, {
        parentExerciseId: id,
        userId: $currentClient.id || clientId,
      });

      if (res.error === "Item with exerciseId and userId already exists.") {
        $showAlert.color = "red-500";
        $showAlert.message = translate("ERROR_ALREADY_ADDED_EXERCISE");
      } else {
        $cantDoExercises.exercises = [...$cantDoExercises.exercises, res.data.exercise];
        $cantDoExercises.exerciseIds = [...$cantDoExercises.exerciseIds, id];

        const workoutIds = `workoutIds=%5B${$trainerClientWorkouts.data.map((w) => w.id).join(",")}%5D`;
        const userId = `userId=${$currentClient.id || clientId}`;
        const response = await getWithJwt(`${WORKOUT}/cant-do-exercises?${workoutIds}&${userId}`);

        $cantDoExercises.workoutIds = response.data.cantDo;

        // if (!$cantDoExercises.workoutIds.includes(workoutId)) {
        //   $cantDoExercises.workoutIds = [
        //     ...$cantDoExercises.workoutIds,
        //     workoutId,
        //   ];
        // }

        $showAlert.color = "black";
        $showAlert.message = translate("SUCCESS_ADDING_EXERCISE");
      }
    } catch (error) {
      $showAlert.color = "red-500";
      $showAlert.message = translate("ERROR_ADDING_EXERCISE");
      console.error(error);
    }
  };

  const onDeleteCantDoExercise = async (event: CustomEvent<number>): Promise<void> => {
    const id = event.detail;

    try {
      await deleteWithJwt(`${EXERCISE}/cant-do-exercises`, {
        parentExerciseId: id,
        userId: $currentClient.id,
      });

      const workoutIds = `workoutIds=%5B${$trainerClientWorkouts.data.map((w) => w.id).join(",")}%5D`;
      const userId = `userId=${$currentClient.id}`;
      const response = await getWithJwt(`${WORKOUT}/cant-do-exercises?${workoutIds}&${userId}`);

      $cantDoExercises.exercises = $cantDoExercises.exercises.filter(
        (exercise) => exercise.id !== id
      );
      $cantDoExercises.exerciseIds = $cantDoExercises.exerciseIds.filter((eid) => eid !== id);
      $cantDoExercises.workoutIds = response.data.cantDo;

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESS_DELETING_EXERCISE");
    } catch (error) {
      $showAlert.color = "red-500";
      $showAlert.message = translate("ERROR_DELETING_EXERCISE");
      console.error(error);
    }
  };

  onMount(async (): Promise<void> => {
    await trainerExercisesStore.fetchData();
  });
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="font-medium text-center text-xxl">
    {translate("CANT_DO_EXERCISES")}
  </div>

  {#if isAddExercise}
    <Search
      placeholder={translate("SEARCH_EXERCISES")}
      bind:value={$trainerExercisesStore.filter.search}
      on:input={trainerExercisesStore.search}
    />

    <ExercisesFilter />

    <div
      class="exercises-list pr-4 h-64 flex flex-col gap-4 overflow-y-scroll"
      use:mountScrollElement
    >
      {#each $trainerExercisesStore.items as exercise}
        <ExerciseInWorkout
          type="CANT_DO_EXERCISES"
          isSelectMode
          {exercise}
          on:addCantDoExercise={onAddCantDoExercise}
          on:deleteCantDoExercise={onDeleteCantDoExercise}
        />
      {/each}
    </div>

    <InfiniteScroll
      {scrollElement}
      hasMore={$trainerExercisesStore.hasMore}
      isFetchingMore={$trainerExercisesStore.isFetchingMore}
      on:loadMore={trainerExercisesStore.fetchMoreData}
    />
  {:else if $cantDoExercises.exercises.length}
    {#each $cantDoExercises.exercises as exercise}
      <ExerciseInWorkout
        type="CANT_DO_EXERCISES"
        {exercise}
        on:deleteCantDoExercise={onDeleteCantDoExercise}
      />
    {/each}
  {:else}
    <div class="text-center">{translate("NO_CANT_DO_EXERCISES_YET")}</div>
  {/if}

  <div class="flex justify-center">
    <ButtonComponent on:click={onSwitch}>
      {#if isAddExercise}
        {translate("BACK")}
      {:else}
        {translate("ADD_EXERCISE")}
      {/if}
    </ButtonComponent>
  </div>
</div>

<style>
  .exercises-list::-webkit-scrollbar {
    width: 8px;
  }

  .exercises-list::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  .exercises-list::-webkit-scrollbar-thumb {
    background-color: #e2e8f0;
    border-radius: 8px;
  }
</style>
