<script lang="ts">
  import { Checkbox, P, Spinner } from "flowbite-svelte";
  import { postWithJwt } from "lib";
  import { selectedWorkoutIds } from "../../../stores/idSelectorStore";
  import { dialogData, showAlert } from "stores";
  import { ButtonComponent } from "ui";

  let isLoading = false;

  const tags = [
    "Trbuh",
    "Biceps",
    "Listovi",
    "Grudi",
    "Podlaktica",
    "Zadnjica",
    "Zadnja loža",
    "Donja leđa",
    "Prednja Loža",
    "Ramena",
    "Triceps",
    "Gornja leđa",
  ];
  let selected: Array<string> = [];

  const tagsMap = new Map([
    ["Trbuh", "ABS"],
    ["Biceps", "BICEPS"],
    ["Listovi", "CALVES"],
    ["Grudi", "CHEST"],
    ["Podlaktica", "FOREARM"],
    ["Zadnjica", "GLUTE"],
    ["Zadnja loža", "HAMSTRINGS"],
    ["Donja leđa", "LOWER_BACK"],
    ["Prednja Loža", "QUADS"],
    ["Ramena", "SHOULDERS"],
    ["Triceps", "TRICEPS"],
    ["Gornja leđa", "UPPER_BACK"],
  ]);

  const onSelect = (tag: string): void => {
    if (selected.includes(tag)) {
      selected = selected.filter((s) => s !== tag);
    } else {
      selected = [...selected, tag];
    }
  };

  const onEdit = async (): Promise<void> => {
    try {
      isLoading = true;
      // ovo ne radi na bekend?
      const response = await postWithJwt(``, {
        tags: selected.map((s) => tagsMap.get(s)),
        exerciseIds: $selectedWorkoutIds,
      });

      $dialogData.data = {};
      $dialogData.type = "";

      $showAlert.color = "black";
      $showAlert.message = `Izmenjena misicna grupa`;
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `Greska pri menjanju misicne grupe`;
    }
  };
</script>

<div class="p-4 flex flex-col gap-2">
  <P>Mišićna grupa</P>
  {#each tags as tag}
    <Checkbox checked={selected.includes(tag)} on:change={() => onSelect(tag)}>
      {tag}
    </Checkbox>
  {/each}
  <div class="h-10 flex justify-center">
    {#if isLoading}
      <Spinner size="10" color="green" />
    {:else}
      <ButtonComponent disabled={!selected.length} on:click={onEdit}>Izmeni</ButtonComponent>
    {/if}
  </div>
</div>
