<script lang="ts">
  import { afterUpdate, onDestroy, onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { slide } from "svelte/transition";

  import { dragHandle, dragHandleZone, type DndEvent, type Item } from "svelte-dnd-action";

  import {
    MealEntityType,
    dialogTypes,
    serverlessRoutes,
    deleteWithJwt,
    getWithJwt,
    postWithJwt,
    translate,
    isClient,
    patchWithJwt,
    animations,
    getNewPositions,
    storage,
  } from "lib";

  import Svg from "../UI/Svg.svelte";
  import More from "../UI/More.svelte";
  import Macros from "./Macros.svelte";
  import { IngredientComponent, RecipeComponent } from "diet";
  import CommentContainer from "../Comments/CommentContainer.svelte";
  import {
    alertStore,
    journalMealsStore,
    mealPlanStore,
    user,
    dialogData,
    activeMealsStore,
  } from "stores";
  import { urlify } from "../../lib/urlify";
  import { ButtonComponent, ExpandCollapseComponent } from "ui";
  import type { Ingredient, Meal, MenuItem, PartialItemMap, Recipe } from "interfaces";
  import ImageInputComponent from "../UI/ImageInputComponent.svelte";

  export let meal: Meal;
  export let isCommentable = false;
  export let isExpanded = false;
  export let isReadonly = false;
  export let scrollToMealComments = false;

  let expandedMealId: number | null = null;
  let scrollElement: HTMLDivElement;
  let mealElement: HTMLDivElement;
  let showUploadImage = false;
  let uploadedImageUrl = "";

  $: updateFileHash(uploadedImageUrl);

  const updateFileHash = async (uploadedImageUrl: string) => {
    if (uploadedImageUrl) {
      try {
        const url = `${serverlessRoutes.MEAL}`;
        const partialMealMap = { [meal.id]: { fileHash: uploadedImageUrl } };
        const result = await patchWithJwt(url, { partialMealMap });
        meal.fileHash = uploadedImageUrl;
      } catch (err) {
        console.log(err);
        alertStore.show(translate("SOMETHING_WENT_WRONG"), "error");
      }
    }
  };

  const saveToJournal = async (): Promise<void> => {
    const { error, data } = await postWithJwt(`${serverlessRoutes.MEAL}/copy`, {
      partialMealMap: {
        [meal.id]: {
          finishedAt: new Date(),
        },
      },
    });

    if (error && !data) {
      return console.error(error);
    }

    await journalMealsStore.fetchData();
    journalMealsStore.generateMealMap();

    alertStore.show(translate("SUCCESSFULLY_SAVED_TO_JOURNAL"));
  };

  const fetchData = async () => {
    try {
      if (meal.finishedAt) {
        const url2 = `${serverlessRoutes.MEAL}/${meal.id}`;
        const res = await getWithJwt(url2);
        const m2 = $journalMealsStore.items.find(({ id }) => id === meal.id);

        if (m2) {
          m2.recipes = res.data.recipes;
          m2.ingredients = res.data.ingredients;
          $journalMealsStore = $journalMealsStore;
        }
      } else {
        if (isClient($user)) {
          await activeMealsStore.fetchMeal(meal.id);
        } else {
          await mealPlanStore.fetchMeal(meal.id);
        }
      }
    } catch (error) {
      console.error(error);
    }
    // }
  };

  const deleteMeal = async (): Promise<void> => {
    const { error, data } = await deleteWithJwt(serverlessRoutes.MEAL, {
      ids: [meal.id],
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_DELETING_MEAL"), "error");
      return console.error(error);
    }

    const { ids } = data;

    // TODO kada trener obriše završeni klijentov obrok
    if (meal.finishedAt) {
      journalMealsStore.remove(ids);
    } else {
      mealPlanStore.removeMeals(ids);
    }

    alertStore.show(translate("SUCCESSFULLY_DELETED_MEAL"));
  };

  const copyMeal = async (): Promise<void> => {
    const partialMealMap: PartialItemMap<Meal> = {
      [meal.id]: {},
    };

    const { error, data } = await postWithJwt(`${serverlessRoutes.MEAL}/copy`, {
      partialMealMap,
    });

    if (error && !data) {
      console.error(error);
      alertStore.show(translate("ERROR_COPYING_MEAL"), "error");
      return;
    }

    const { meals } = data;

    if (meal.finishedAt) {
      journalMealsStore.add(meals);
    } else {
      mealPlanStore.addMeals(meals);
    }

    alertStore.show(translate("SUCCESSFULLY_COPIED_MEAL"));
  };

  const editMealDialog = (): void => {
    if (meal.entityType === 1) {
      $dialogData.data = { meal };
      $dialogData.type = dialogTypes.CREATE_EDIT_HEADER_MEAL;
    } else if (meal.entityType === 2) {
      $dialogData.data = { meal };
      $dialogData.type = dialogTypes.CREATE_EDIT_TEXT_MEAL;
    } else if (meal.entityType === 3) {
      $dialogData.data = {
        meal,
        executeFunction(res: any): void {
          meal = res;
        },
      };
      $dialogData.type = dialogTypes.CREATE_EDIT_PDF_MEAL;
    } else {
      $dialogData.data = { meal };
      $dialogData.type = dialogTypes.CREATE_EDIT_RECIPE_MEAL;
    }
  };

  const deleteMealDialog = (): void => {
    $dialogData.data = {
      title: meal.name,
      executeFunction: deleteMeal,
    };

    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const unfilteredMenuItems: Array<MenuItem> = [
    {
      icon: "save",
      title: "SAVE_TO_JOURNAL",
      executeFunction: saveToJournal,
    },
    {
      icon: "edit",
      title: "EDIT",
      executeFunction: editMealDialog,
    },
    {
      icon: "copy",
      title: "CREATE_COPY",
      executeFunction: copyMeal,
    },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction: deleteMealDialog,
    },
  ];

  let menuItems: Array<MenuItem> = [];

  const menuIs = (items: Array<string>): void => {
    menuItems = unfilteredMenuItems.filter(({ title }): boolean => items.includes(title));
  };

  const onAddRecipe = (): void => {
    $dialogData.data = { meal };
    $dialogData.type = dialogTypes.IMPORT_RECIPES;
  };

  const onAddIngredient = (): void => {
    $dialogData.data = { meal };
    $dialogData.type = dialogTypes.IMPORT_INGREDIENTS;
  };

  const onToggleExpand = (): void => {
    const { entityType, recipes, ingredients } = meal;

    if (entityType === MealEntityType.HEADER) {
      return;
    }

    isExpanded = !isExpanded;

    if (isExpanded && !recipes && !ingredients) {
      fetchData();
    }
  };

  const recipesDndConsider = (event: CustomEvent<DndEvent<Recipe>>): void => {
    mealPlanStore.setMealRecipes(meal.id, event.detail.items);
  };

  const recipesDndFinalize = async (event: CustomEvent<DndEvent<Recipe>>): Promise<void> => {
    const partialRecipeMap = getNewPositions(event.detail.items);

    if (!partialRecipeMap) {
      return;
    }

    const { data, error } = await patchWithJwt(serverlessRoutes.RECIPE, {
      partialRecipeMap,
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_CHANGING_ORDER"), "error");
      return console.error(error);
    }

    mealPlanStore.replaceMealRecipes(meal.id, data.recipes);
    alertStore.show(translate("SUCCESSFULLY_CHANGED_ORDER"));
  };

  const ingredientsDndConsider = (event: CustomEvent<DndEvent<Ingredient>>): void => {
    mealPlanStore.setMealIngredients(meal.id, event.detail.items);
  };

  const ingredientsDndFinalize = async (
    event: CustomEvent<DndEvent<Ingredient>>
  ): Promise<void> => {
    const partialIngredientMap = getNewPositions(event.detail.items);

    if (!partialIngredientMap) {
      return;
    }

    const { error, data } = await patchWithJwt(serverlessRoutes.INGREDIENT, {
      partialIngredientMap,
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_CHANGING_ORDER"), "error");
      return console.error(error);
    }

    mealPlanStore.replaceMealIngredients(meal.id, data.ingredients);
    alertStore.show(translate("SUCCESSFULLY_CHANGED_ORDER"));
  };

  onMount(async (): Promise<void> => {
    await setIsInRw();

    if (isExpanded) {
      await fetchData();
    }

    const { href } = window.location;

    if (meal.entityType === MealEntityType.HEADER) {
      isExpanded = true;
    }

    if (isClient($user)) {
      if (href.includes("scrollToMealComments")) {
        const mealId = parseInt(href.split("?")[1].split("=")[1]);

        if (meal.id === mealId) {
          isExpanded = true;
          expandedMealId = mealId;
          setTimeout((): void => scrollElement.scrollIntoView({ behavior: "smooth" }), 2000);
        }
      } else if (scrollToMealComments) {
        isExpanded = true;
        expandedMealId = meal.id;
        setTimeout((): void => scrollElement.scrollIntoView({ behavior: "smooth" }), 2000);
      }
    } else {
      if (href.includes("scrollToMealComments")) {
        let mealId: number;

        if (!href.includes("view=diet")) {
          mealId = parseInt(href.split("?")[1].split("&")[0].split("=")[1]);
        } else {
          mealId = parseInt(href.split("?")[1].split("&")[2].split("=")[1]);
        }

        if (meal.id === mealId) {
          isExpanded = true;
          expandedMealId = mealId;
          setTimeout((): void => scrollElement.scrollIntoView({ behavior: "smooth" }), 2000);
        }
      } else if (href.includes("mealId")) {
        const mealId = parseInt(href.split("?")[1].split("=")[1]);

        if (meal.id === mealId) {
          isExpanded = true;
          setTimeout((): void => mealElement.scrollIntoView({ behavior: "smooth" }), 2000);
        }
      } else if (scrollToMealComments) {
        isExpanded = true;
        expandedMealId = meal.id;
        setTimeout((): void => scrollElement.scrollIntoView({ behavior: "smooth" }), 2000);
      }
    }
  });

  afterUpdate((): void => {
    if (isClient($user)) {
      if (meal.entityType !== MealEntityType.HEADER) {
        if (meal.finishedAt) {
          menuIs(["DELETE"]);
        } else {
          menuIs(["SAVE_TO_JOURNAL"]);
        }
      }
    } else {
      if (meal.finishedAt) {
        menuIs(["EDIT", "CREATE_COPY", "DELETE"]);
      } else {
        if (
          meal.entityType === MealEntityType.NORMAL &&
          window.location.href.includes("/profile")
        ) {
          menuIs(["SAVE_TO_JOURNAL", "EDIT", "CREATE_COPY", "DELETE"]);
        } else {
          menuIs(["EDIT", "CREATE_COPY", "DELETE"]);
        }
      }
    }
  });

  let isInRw = false;

  const setIsInRw = async () => {
    const url = `${serverlessRoutes.PATCH_VERSION}/is-in-rw`;
    const res = await getWithJwt(url);
    isInRw = res.isInRw;
  };

  const links = `
  https://pubmed.ncbi.nlm.nih.gov/29763005/
  https://pmc.ncbi.nlm.nih.gov/articles/PMC7480775/
  https://pubmed.ncbi.nlm.nih.gov/37836444/
  `;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->

<!-- {#if meal.entityType === MealEntityType.HEADER && meal.position && meal.position > 0}
  <div class="mt-8 border border-t-1 border-slate-200"></div>
{/if} -->
<div
  class="
    flex
    flex-col
    bg-white
    border
    border-slate-200
    rounded-md
    dark:bg-zinc-800
    dark:border-zinc-600
  "
  bind:this={mealElement}
>
  <!-- class:mt-2={meal.entityType === MealEntityType.HEADER && meal.position && meal.position > 0} -->
  <div
    class="{meal.entityType === MealEntityType.HEADER ? 'p-4' : 'p-2'} flex items-center gap-4"
    on:click={onToggleExpand}
  >
    {#if !isClient($user) && !meal.finishedAt && !isReadonly}
      <div use:dragHandle>
        <Svg name="drag" size={16} />
      </div>
    {/if}

    <div class="flex items-center grow gap-4">
      <div class="flex items-center grow gap-4 leading-tight">
        {#if meal.entityType === MealEntityType.PDF}
          <svg
            class="w-4 h-4 text-red-500 dark:text-slate-100"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
            />
          </svg>
        {:else if meal.entityType === MealEntityType.TEXT}
          <svg
            class="w-4 h-4 text-green-500 dark:text-slate-100"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4.828 10h6.239m-6.239 4h6.239M6 1v4a1 1 0 0 1-1 1H1m14-4v16a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2Z"
            />
          </svg>
        {/if}
        {meal.name}
      </div>

      {#if meal.finishedAt}
        <Svg name="check" size={16} customColor="bg-green-500" />
      {/if}

      {#if meal.showMacros && (meal.entityType === MealEntityType.NORMAL || meal.entityType === MealEntityType.HEADER) && meal.calories}
        <div class="text-xs flex gap-1">
          <div class="text-amber-400">{Math.round(meal.calories || 0)}</div>
          <div>kcal</div>
        </div>
      {/if}
    </div>

    {#if !isReadonly}
      <More {menuItems} />
    {/if}

    {#if meal.entityType !== MealEntityType.HEADER}
      <ExpandCollapseComponent {isExpanded} />
    {/if}
  </div>

  {#if isExpanded}
    <div
      class="{meal.entityType === MealEntityType.HEADER && !meal.showMacros && !meal.description
        ? ''
        : 'p-4 pt-0'} flex flex-col gap-4"
      in:slide={{ duration: 333 }}
      out:slide={{ duration: 333 }}
    >
      {#if meal.entityType !== MealEntityType.HEADER}
        <div></div>
      {/if}

      {#if meal.entityType === MealEntityType.NORMAL}
        {#if meal.showMacros}
          <Macros
            carbs={meal.carbs || 0}
            protein={meal.protein || 0}
            fats={meal.fats || 0}
            calories={meal.calories || 0}
          />
        {/if}

        <div>
          <div class="font-semibold">{translate("DIRECTIONS")}:</div>
          <div class="text-justify text-xs">
            {#if meal.description}
              {@html urlify(meal.description)}
            {:else if !isInRw}
              {translate("NO_DIRECTIONS")}
            {/if}
            <p>
              {#if isInRw}
                {@html urlify(links)}
              {/if}
            </p>
          </div>
        </div>

        {#if meal.fileHash}
          <!-- <img
            src={`https://moj-trening-bucket.s3.eu-north-1.amazonaws.com/${meal.fileable.files[0].hash}`}
            alt="Meal"
          /> -->
          <img src={meal.fileHash} alt="Meal" />
        {/if}

        <div>
          <div class="font-semibold">{translate("RECIPES")}:</div>
          {#if meal.recipes?.length}
            <div
              class="flex flex-col gap-4"
              use:dragHandleZone={{
                items: meal.recipes,
                flipDurationMs: 0,
                dropTargetStyle: {},
                dropFromOthersDisabled: true,
                type: "recipes",
              }}
              on:consider={recipesDndConsider}
              on:finalize={recipesDndFinalize}
            >
              {#each meal.recipes as recipe (recipe.id)}
                <div class="outline-none" animate:flip={animations.flip}>
                  <RecipeComponent {isReadonly} {recipe} />
                </div>
              {/each}
            </div>
          {:else}
            <div class="text-justify text-xs">{translate("NO_RECIPES")}</div>
          {/if}
        </div>

        <div>
          <div class="font-semibold">{translate("INGREDIENTS")}:</div>
          {#if meal.ingredients?.length}
            <div
              class="flex flex-col gap-4"
              use:dragHandleZone={{
                items: meal.ingredients,
                flipDurationMs: 0,
                dropTargetStyle: {},
                dropFromOthersDisabled: true,
                type: "mealIngredients",
              }}
              on:consider={ingredientsDndConsider}
              on:finalize={ingredientsDndFinalize}
            >
              {#each meal.ingredients as ingredient (ingredient.id)}
                <div class="outline-none" animate:flip={animations.flip}>
                  <IngredientComponent {isReadonly} {ingredient} />
                </div>
              {/each}
            </div>
          {:else}
            <div class="text-justify text-xs">
              {translate("NO_INGREDIENTS")}
            </div>
          {/if}
        </div>

        {#if !isReadonly}
          {#if meal.finishedAt}
            <div class="flex flex-col gap-4 items-center">
              <ButtonComponent myClasses="w-1/2" on:click={onAddRecipe}>
                {translate("ADD_RECIPE")}
              </ButtonComponent>
              <ButtonComponent
                myClasses="w-1/2"
                on:click={() => {
                  $dialogData.type = dialogTypes.IMPORT_INGREDIENTS;
                  $dialogData.data = { meal };
                }}
              >
                {translate("ADD_INGREDIENT")}
              </ButtonComponent>
              {#if !meal.fileHash}
                <ButtonComponent
                  myClasses="w-1/2"
                  on:click={() => (showUploadImage = !showUploadImage)}
                >
                  {translate("ADD_IMAGE")}
                </ButtonComponent>
                {#if showUploadImage}
                  <ImageInputComponent
                    bind:value={uploadedImageUrl}
                    requireSignedURLs={false}
                    imageUrl={""}
                    onDeleteFile={async () => console.log("delte se desi")}
                  />
                {/if}
              {/if}
            </div>
          {:else if !isClient($user)}
            <div class="flex justify-between">
              <ButtonComponent on:click={onAddRecipe}>
                {translate("ADD")}
                {translate("RECIPE").toLowerCase()}
              </ButtonComponent>
              <ButtonComponent on:click={onAddIngredient}>
                {translate("ADD")}
                {translate("INGREDIENT_U").toLowerCase()}
              </ButtonComponent>
            </div>
          {/if}
        {/if}

        <!-- stavljamo -48px zbog header -->
        <!-- ovo sluzi kad se klikne na notifikaciju pa treba skroluje do komentar -->
        <div style="position: relative">
          <div
            bind:this={scrollElement}
            style="position: absolute; top: {isClient($user) ? '-48px' : '0px'}; left: 0"
          />
        </div>

        {#if isCommentable}
          <CommentContainer
            {isReadonly}
            commentableId={meal.commentableId}
            commentCount={meal.commentCount}
            entity="MEAL"
            comments={[]}
            entityId={meal.id}
            isExpanded={meal.id === +expandedMealId}
            isHighlighted={meal.id === +expandedMealId}
          />
        {/if}
      {:else if meal.entityType === MealEntityType.HEADER}
        {#if meal.showMacros}
          <Macros
            carbs={meal.carbs || 0}
            protein={meal.protein || 0}
            fats={meal.fats || 0}
            calories={meal.calories || 0}
          />
        {/if}
        {#if meal.description}
          <div>
            <div class="font-semibold">{translate("DIRECTIONS")}:</div>
            <div class="text-justify text-xs">
              {@html urlify(meal.description)}
            </div>
          </div>
        {/if}
      {:else if meal.entityType === MealEntityType.TEXT}
        <div>
          <div class="font-semibold">{translate("DIRECTIONS")}:</div>
          <div class="text-justify text-xs whitespace-pre-wrap">
            {#if meal.description}
              {@html urlify(meal.description)}
            {:else}
              {translate("NO_DIRECTIONS")}
            {/if}
          </div>
        </div>
      {:else if meal.entityType === MealEntityType.PDF}
        <iframe height="480" title="PDF" src={meal.pdfUrl} />
      {/if}
    </div>
  {/if}
</div>
