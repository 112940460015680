<script lang="ts">
  import RadioList from "../../../components/UI/RadioList.svelte";

  const radioListItems = [
    { name: "Full body" },
    { name: "Upper body" },
    { name: "Lower body [Legs]" },
    { name: "Push" },
    { name: "Pull" },
  ];

  let step = 1;
</script>

<div>
  {#if step === 1}
    <h3>Izaberi tip treninga</h3>
    <RadioList {radioListItems} />
  {/if}
</div>
