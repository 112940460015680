<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { dialogTypes, getWithJwt, serverlessRoutes, translate } from "lib";
  import { dialogData, ingredientsStore } from "stores";
  import { tags as tagStore } from "../../stores/trainerStores";
  import { ButtonComponent, CheckboxComponent, RadioField, Search, Svg } from "ui";
  import FilterTag from "../FilterTag.svelte";
  import DualSlider from "../UI/DualSlider.svelte";

  let isExpanded = false;
  let isAddVisible = false;
  let tags: Array<string> = [];
  let templateGroup: "ALL" | "MY" | undefined = "ALL";
  let valuesTimeout: NodeJS.Timeout;

  const templateTags = ["ALL", "MY"];

  const unsubscribeTagStore = tagStore.subscribe((store): void => {
    tags = store.map(({ note }): string => note);
  });

  const fetchIngredientTags = async () => {
    try {
      const result = await getWithJwt(`${serverlessRoutes.INGREDIENT}/food_types`);
      tags = result.data;
    } catch (error) {
      console.error(error);
    }
  };

  const onAddIngredient = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_INGREDIENT;
  };

  const onRemoveMacroTag = async (): Promise<void> => {
    $ingredientsStore.filter.macroRatio = undefined;
    await ingredientsStore.fetchData();
  };

  const onRemoveTemplateTag = async (): Promise<void> => {
    $ingredientsStore.filter.template = undefined;
    templateGroup = undefined;
    await ingredientsStore.fetchData();
  };

  const onRemoveTag = async (toRemove: string): Promise<void> => {
    $ingredientsStore.filter.tags = $ingredientsStore.filter.tags.filter((tag) => tag !== toRemove);
    await ingredientsStore.fetchData();
  };

  const onMacroBarChange = (event: CustomEvent<any>): void => {
    $ingredientsStore.filter.macroRatio = {
      carbs: event.detail.carbs,
      protein: event.detail.protein,
      fats: event.detail.fats,
    };

    if (valuesTimeout) {
      clearTimeout(valuesTimeout);
    }

    valuesTimeout = setTimeout((): void => {
      ingredientsStore.fetchData();
    }, 1000);
  };

  const onTemplateTagChange = async (): Promise<void> => {
    if (templateGroup === "ALL") {
      $ingredientsStore.filter.template = 1;
    } else {
      $ingredientsStore.filter.template = 0;
    }

    await ingredientsStore.fetchData();
  };

  const onTagChange = async (checkedTag: string): Promise<void> => {
    if ($ingredientsStore.filter.tags.includes(checkedTag)) {
      $ingredientsStore.filter.tags = $ingredientsStore.filter.tags.filter(
        (tag) => tag !== checkedTag
      );
    } else {
      $ingredientsStore.filter.tags = [checkedTag, ...$ingredientsStore.filter.tags];
    }

    await ingredientsStore.fetchData();
  };

  onDestroy(unsubscribeTagStore);
  onMount(fetchIngredientTags);

  export { isAddVisible };
</script>

<div class="flex gap-4">
  <Search
    placeholder={translate("SEARCH_INGREDIENTS")}
    bind:value={$ingredientsStore.filter.search}
    on:input={ingredientsStore.search}
  />
  {#if isAddVisible}
    <ButtonComponent myClasses="whitespace-nowrap" on:click={onAddIngredient}>
      {translate("ADD")}
      {translate("INGREDIENT_U").toLowerCase()}
    </ButtonComponent>
  {/if}
</div>

<div class="flex flex-col gap-4">
  <div class="flex items-start gap-2">
    <div class="flex flex-wrap items-center grow gap-2">
      {#if $ingredientsStore.filter.template !== undefined}
        <FilterTag
          on:removeFilter={onRemoveTemplateTag}
          title={translate($ingredientsStore.filter.template === 0 ? "MY" : "ALL")}
        />
      {/if}
      {#if $ingredientsStore.filter.macroRatio}
        <FilterTag on:removeFilter={onRemoveMacroTag} title={translate("BAR")} />
      {/if}
      {#each $ingredientsStore.filter.tags as filter}
        <FilterTag on:removeFilter={() => onRemoveTag(filter)} title={filter} />
      {/each}
    </div>

    <ButtonComponent on:click={() => (isExpanded = !isExpanded)} isOutline>
      {translate("FILTER")}
      {#if isExpanded}
        <Svg name="up-caret" size={16} customColor="bg-slate-700 dark:bg-slate-200" />
      {:else}
        <Svg name="down-caret" size={16} customColor="bg-slate-700 dark:bg-slate-200" />
      {/if}
    </ButtonComponent>
  </div>

  {#if isExpanded}
    <div
      class="flex flex-col border-[1px] rounded-md border-slate-200 dark:border-zinc-700"
      in:slide
      out:slide
    >
      <DualSlider on:values={onMacroBarChange} />

      <div class="flex">
        <div class="p-2 basis-1/2 flex flex-col gap-2">
          {#each templateTags as templateTag}
            <RadioField
              fontSize="xxs"
              value={templateTag}
              bind:group={templateGroup}
              on:change={onTemplateTagChange}
            >
              {translate(templateTag)}
            </RadioField>
          {/each}
        </div>

        <div class="border-l border-slate-200 dark:border-zinc-700"></div>

        <div class="p-2 basis-1/2 flex flex-col gap-2">
          {#each tags as tag}
            <CheckboxComponent
              label={tag}
              value={$ingredientsStore.filter.tags.includes(tag)}
              on:change={() => onTagChange(tag)}
            />
          {/each}
        </div>
      </div>
    </div>
  {/if}

  <div class="text-center text-xs">
    {translate("TOTAL_INGREDIENT_COUNT")}:
    <span class="font-bold">{$ingredientsStore.count}</span>
  </div>
</div>
