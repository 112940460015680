<script lang="ts">
  // import { push } from "svelte-spa-router";
  import { entities } from "lib";
  // import { dialogData, trainerClientGroups } from "stores";
  // import Svg from "../../components/UI/Svg.svelte";
  // import More from "../../components/UI/More.svelte";
  // import type { MenuItem } from "interfaces";
  import TopMenu from "../../components/TopMenu.svelte";
  // import TrainersList from "./TrainersScreenViews/TrainersList.svelte";
  // import TrainersMealPlans from "./TrainersScreenViews/TrainersMealPlans.svelte";
  // import TrainersWorkouts from "./TrainersScreenViews/TrainersWorkouts.svelte";
  // import { onMount } from "svelte";
  // import { trainerList } from "../../stores/trainerStores";
  // import { ButtonComponent } from "ui";

  // let params: any;
  // // const group = $trainerClientGroups.find(({id}) => id === parseInt(params.id));
  // let props = {};

  // let menuItems: MenuItem[] = [
  //   {
  //     title: "ASSIGN_MEAL_PLAN",
  //     icon: "utensils",
  //     executeFunction(): void {
  //       $dialogData.data = {};
  //       $dialogData.type = dialogTypes.ASSIGN_MEAL_PLAN;
  //     },
  //   },
  //   {
  //     title: "ASSIGN_TRAINING_PROGRAM",
  //     icon: "dumbbell",
  //     executeFunction(): void {
  //       $dialogData.data = {};
  //       $dialogData.type = dialogTypes.ASSIGN_WORKOUT_PLAN;
  //     },
  //   },
  //   {
  //     title: "CREATE_TRAINER",
  //     icon: "user",
  //     executeFunction(): void {},
  //   },
  //   {
  //     title: "EDIT",
  //     icon: "edit",
  //     executeFunction(): void {},
  //   },
  // ];

  // let view: any;

  // const navLinks = [
  //   {
  //     icon: "users",
  //     isActive: true,
  //     onView(): void {
  //       navLinks[0].isActive = true;
  //       view = TrainersList;
  //     },
  //   },
  //   {
  //     icon: "utensils",
  //     isActive: false,
  //     onView(): void {
  //       navLinks[1].isActive = true;
  //       view = TrainersMealPlans;
  //     },
  //   },
  //   {
  //     icon: "dumbbell",
  //     isActive: false,
  //     onView(): void {
  //       navLinks[2].isActive = true;
  //       view = TrainersWorkouts;
  //     },
  //   },
  // ];

  // const resetActive = (): void => {
  //   navLinks.forEach((navLink): void => {
  //     navLink.isActive = false;
  //   });
  // };

  // const onGoBack = (): void => {
  //   push("/clients/groups");
  // };

  // onMount(async () => {
  // //   fetch trainer list
  //   $trainerList = response;
  //   view = TrainersList;
  // });

  // export { params };
</script>

<TopMenu entity={entities.CLIENTS} />
<!-- 
<div class="">
  <div class="p-4 relative flex flex-col border-b">
    <div class="absolute top-4 left-4">
      <Svg name="left-arrow-button" size={16} on:click={onGoBack} />
    </div>
    <div class="flex flex-col items-center justify-center">
      <img class="h-32 w-32" src="default-group.png" alt="Group Avatar" />
      <div>{$trainerList.name}</div>
    </div>
    <div class="absolute top-4 right-4">
      <More {menuItems} />
    </div>
  </div>

  <div class="mt-4 flex flex-col gap-4">
    <div class="flex justify-evenly">
      {#each navLinks as navLink}
        <ButtonComponent
          isPill
          on:click={() => {
            resetActive();
            navLink.onView();
          }}
        >
          <Svg
            name={navLink.icon}
            size={16}
            customColor={navLink.isActive ? "bg-white" : "bg-black"}
          />
        </ButtonComponent>
      {/each}
    </div>

    <div>
      <svelte:component this={view} {...props} />
    </div>
  </div>
</div> -->
