<script lang="ts">
  import type { Notification } from "../interfaces/Notification";
  import Divider from "./UI/Divider.svelte";
  import Avatar from "./UI/Avatar.svelte";
  import { translate } from "../lib/translate";
  import { parseDate } from "../lib/parseDate";
  import { dialogTypes, serverlessRoutes } from "../lib/constants";
  import { patchWithJwt } from "../lib/requests";
  import { unreadNotifications } from "../stores/notificationStore";
  import { push } from "svelte-spa-router";
  import { dialogData, user } from "stores";
  import { clientTrainerAvatarUrl } from "../stores/clientStores";
  import SeenButton from "./SeenButton.svelte";
  import { isClient } from "../lib/roles";
  import { beforeUpdate } from "svelte";
  import { TrainersClientNotification } from "enums";

  export let notification: Notification;
  const avatarUrl = notification.emitterAvatarUrl;
  // const avatarUrl = isClient($user) ? $clientTrainerAvatarUrl : notification.emitterAvatarUrl;
  let newNotification: any;

  const parseNotificationText = (text: string): string => {
    if (text.includes("za danas ima podsetnik")) return text;
    if (text.includes("ti je dodelio")) return text;
    if (text.includes("je završio")) return text;
    if (text.includes("ACCOUNT_EXPIRES_TOMORROW") && text.length < 28) {
      return "Tvoj nalog ističe sutra.";
    }
    const start = text.search("{");
    const end = text.search("}");
    const textToTranslate = text.slice(start + 1, end);
    const additionalText = text.slice(end + 1);
    return `${text.slice(0, start)} ${translate(textToTranslate)} ${
      additionalText ? additionalText : ""
    }`;
  };

  const openNotification = async () => {
    if (!notification.seenAt) { await toggleSeen(); }

    const {type, data} = newNotification;

    if (
      type === TrainersClientNotification.REMINDER ||
      type === TrainersClientNotification.ACCOUNT_EXPIRES_TODAY ||
      type === TrainersClientNotification.ACCOUNT_EXPIRES_TOMORROW
    ) {
      await push(`/profile/${data.clientId}`)
    } else if (type === TrainersClientNotification.FINISHED_WORKOUT) {
      await push(`/profile/${data.clientId}?view=training&type=diary`);
    } else if (type === TrainersClientNotification.FINISHED_MEAL) {
      await push(`/profile/${data.clientId}?view=diet&type=diary`);
    } else if (type === TrainersClientNotification.COMMENTED_EXERCISE) {
      $dialogData.type = dialogTypes.NOTIFICATION;
      $dialogData.data = {type, ...data};
    } else if (type === TrainersClientNotification.COMMENTED_WORKOUT) {
      $dialogData.type = dialogTypes.NOTIFICATION;
      $dialogData.data = {type, ...data};
    } else if (type === TrainersClientNotification.COMMENTED_MEAL) {
      $dialogData.type = dialogTypes.NOTIFICATION;
      $dialogData.data = {type, ...data};
    } else if (
      type === TrainersClientNotification.FINISHED_ALL_WORKOUTS ||
      type === TrainersClientNotification.PROGRAM_EXPIRES_TODAY ||
      type === TrainersClientNotification.PROGRAM_EXPIRES_TOMORROW
    ) {
      await push(`/profile/${data.clientId}/training/programs/${data.programId}`)
    } else if (
      type === TrainersClientNotification.MEAL_PLAN_EXPIRES_TODAY ||
      type === TrainersClientNotification.MEAL_PLAN_EXPIRES_TOMORROW
    ) {
      await push(`/profile/${data.clientId}/diet/meal-plans/${data.mealPlanId}`);
    } else if (type === TrainersClientNotification.NEW_MEASUREMENT) {
      await push(`/profile/${data.clientId}?view=progress&type=measurements`)
    } else if (type === TrainersClientNotification.NEW_HABIT) {
      await push(`/profile/${data.clientId}?view=progress&type=habits`)
    } else if (type === TrainersClientNotification.NEW_GALLERY_ENTRY) {
      await push(`/profile/${data.clientId}?view=progress&type=gallery`)
    } else if (type === TrainersClientNotification.ASSIGNED_MEAL_PLAN) {
      await push("/diet/meal-plan");
    } else if (type === TrainersClientNotification.ASSIGNED_PROGRAM) {
      await push("/training/program");
    } else if (type === TrainersClientNotification.ACCOUNT_EXPIRES) {
      await push("/notifications");
    }
  };

  const newifyNotificationClient = (): void => {
    const link = notification.relativeLink;

    if (link === "workout-plan") {
      // {name} ti je dodelio novi trening program
      newNotification = {
        type: TrainersClientNotification.ASSIGNED_PROGRAM,
        data: {}
      };
    } else if (link === "meal-plan") {
      // {name} ti jedodelio novi plan ishrane
      newNotification = {
        type: TrainersClientNotification.ASSIGNED_MEAL_PLAN,
        data: {}
      };
    } else if (link.includes("workout-plan")) {
      if (link.includes("#active")) {
        if (link.includes("#exercise")) {
          // {name} je komentarisao vezbu {name} #active
          const workoutId = link.split("#")[1].split("-")[1];
          const exerciseId = link.split("#")[1].split("-")[2];

          newNotification = {
            type: TrainersClientNotification.COMMENTED_EXERCISE,
            data: {clientId: null, workoutId, exerciseId}
          };
        } else if (link.includes("#workout")) {
          // {name} je komentarisao trening {name} #active
          const workoutId = parseInt(link.split("#")[1].split("-")[1]);

          newNotification = {
            type: TrainersClientNotification.COMMENTED_WORKOUT,
            data: {clientId: null, workoutId}
          };
        }
      } else if (link.includes("#journal")) {
        if (link.includes("#exercise")) {
          // {name} je komentarisao vezbu {name} #active
          const workoutId = link.split("#")[1].split("-")[1];
          const exerciseId = link.split("#")[1].split("-")[2];

          newNotification = {
            type: TrainersClientNotification.COMMENTED_EXERCISE,
            data: {clientId: null, workoutId, exerciseId}
          };
        } else if (link.includes("#workout")) {
          // {name} je komentarisao trening {name} #active
          const workoutId = parseInt(link.split("#")[1].split("-")[1]);

          newNotification = {
            type: TrainersClientNotification.COMMENTED_WORKOUT,
            data: {clientId: null, workoutId}
          };
        }
      }
    } else if (link.includes("meal-plan")) {
      if (link.includes("#active")) {
        // {name} je komentarisao obrok {name} #active
        const mealId = parseInt(link.split("#")[1].split("-")[1]);

        newNotification = {
          type: TrainersClientNotification.COMMENTED_MEAL,
          data: {clientId: null, mealId}
        };
      } else if (link.includes("#journal")) {
        // {name} je komentarisao obrok {name} #journal
        const mealId = parseInt(link.split("#")[1].split("-")[1]);

        newNotification = {
          type: TrainersClientNotification.COMMENTED_MEAL,
          data: {clientId: null, mealId}
        };
      }
    } else if (link.includes("account-expires")) {
      // Nalog ti istice dana {day}
      newNotification = {
        type: TrainersClientNotification.ACCOUNT_EXPIRES,
        data: {}
      };
    }
  };

  const newifyNotification = (): void => {
    const link = notification.relativeLink;

    if (link.endsWith("#workout-plans")) {
      // {name} za danas ima podsetnik {name}
      const clientId = link.split("/")[1].split("#")[0];

      newNotification = {
        type: TrainersClientNotification.REMINDER,
        data: {clientId}
      };
    } else if (
      link.includes("workout-plans") &&
      !link.endsWith("#workout-plans")
    ) {
      if (
        link.includes("#workout") &&
        !link.includes("#active") &&
        !link.includes("#journal")
      ) {
        // Klijent {name} je zavrsio trening {name}
        const clientId = link.split("/")[1];
        newNotification = {
          type: TrainersClientNotification.FINISHED_WORKOUT,
          data: {clientId}
        };
      } else if (link.includes("#active")) {
        if (link.includes("#exercise")) {
          // {name} je komentarisao/la vezbu {name} #active
          const clientId = parseInt(link.split("/")[1]);
          const workoutId = parseInt(link.split("/")[3].split("#")[1].split("-")[1]);
          const exerciseId = parseInt(link.split("/")[3].split("#")[1].split("-")[2]);

          newNotification = {
            type: TrainersClientNotification.COMMENTED_EXERCISE,
            data: {clientId, workoutId, exerciseId}
          };
        } else {
          // {name} je komentarisao/la trening {name} #active
          const clientId = parseInt(link.split("/")[1]);
          const workoutId = parseInt(link.split("/")[3].split("#")[1].split("-")[1]);

          newNotification = {
            type: TrainersClientNotification.COMMENTED_WORKOUT,
            data: {clientId, workoutId}
          };
        }
      } else if (link.includes("#journal")) {
        if (link.includes("#exercise")) {
          // {name} je komentarisao/la vezbu {name} #journal
          const clientId = parseInt(link.split("/")[1]);
          const workoutId = parseInt(link.split("/")[3].split("#")[1].split("-")[1]);
          const exerciseId = parseInt(link.split("/")[3].split("#")[1].split("-")[2]);

          newNotification = {
            type: TrainersClientNotification.COMMENTED_EXERCISE,
            data: {clientId, workoutId, exerciseId}
          };
        } else {
          // {name} je komentarisao/la trening {name} #journal
          const clientId = parseInt(link.split("/")[1]);
          const workoutId = parseInt(link.split("/")[3].split("#")[1].split("-")[1]);

          newNotification = {
            type: TrainersClientNotification.COMMENTED_WORKOUT,
            data: {clientId, workoutId}
          };
        }
      } else {
        // Klijent {name} je zavrsio sve treninge
        // {name} plan treninga istice danas & sutra
        const clientId = parseInt(link.split("/")[1]);
        const programId = parseInt(link.split("/")[3]);

        newNotification = {
          type: TrainersClientNotification.FINISHED_ALL_WORKOUTS,
          data: {clientId, programId}
        };
      }
    } else if (link.includes("meal-plans")) {
      if (link.includes("#meal-")) {
        if (notification.text.includes("HAS_FINISHED_MEAL")) {
          // {name} je zavrsio/la obrok {name}
          const clientId = link.split("/")[1];
          newNotification = {
            type: TrainersClientNotification.FINISHED_MEAL,
            data: {clientId}
          };
        } else if (notification.text.includes("COMMENTED_MEAL")) {
          // {name} je komentarisao obrok
          if (link.includes("#active")) {
            const clientId = link.split("/")[1];
            const mealId = link.split("/")[3].split("#")[1].split("-")[1];
            newNotification = {
              type: TrainersClientNotification.COMMENTED_MEAL,
              data: {clientId, mealId}
            };
          } else {
            const clientId = link.split("/")[1];
            const mealId = link.split("/")[3].split("-")[1].split("#")[0];
            newNotification = {
              type: TrainersClientNotification.COMMENTED_MEAL,
              data: {clientId, mealId}
            };
          }
        }
      } else {
        // {name} plan ishrane istice danas & sutra
        const clientId = link.split("/")[1];
        const mealPlanId = parseInt(link.split("/")[3]);
        newNotification = {
          type: TrainersClientNotification.MEAL_PLAN_EXPIRES_TODAY,
          data: {clientId, mealPlanId}
        };
      }
    } else if (link.includes("client")) {
      if (link.includes("progress")) {
        // {name} je poceo da prati novu meru
        const linkArray = link.split("/");
        newNotification = {
          type: TrainersClientNotification.NEW_MEASUREMENT,
          data: {clientId: linkArray[1].split("#")[0]}
        };
      } else if (link.split("/").length === 2) {
        // {name} nalog istice danas & sutra
        const linkArray = link.split("/");
        newNotification = {
          type: TrainersClientNotification.ACCOUNT_EXPIRES_TODAY,
          data: {clientId: linkArray[1]}
        };
      }
    } else if (link.includes("profile")) {
      if (link.includes("habits")) {
        // {name} prati novu naviku
        const clientId = parseInt(link.split("/")[1].split("?")[0]);
        newNotification = {
          type: TrainersClientNotification.NEW_HABIT,
          data: {clientId}
        };
      } else {
        // {name} je dodao/la novi unos u galeriju
        const clientId = parseInt(link.split("/")[1].split("?")[0]);
        newNotification = {
          type: TrainersClientNotification.NEW_GALLERY_ENTRY,
          data: {clientId}
        };
      }
    }
  };

  const toggleSeen = async () => {
    const {id} = notification;
    let seenAt = null;

    if (notification.seenAt === null) {
      seenAt = new Date().toISOString().slice(0, 10);
    } else {
      seenAt = null;
    }

    const res = await patchWithJwt(serverlessRoutes.NOTIFICATIONS, {
      partialNotificationMap: {
        [id]: {seenAt}
      }
    });

    if (res.error) { return; }

    notification.seenAt = seenAt;

    if (seenAt === null) {
      $unreadNotifications += 1;
    } else {
      $unreadNotifications -= 1;
    }
  };

  beforeUpdate((): void => {
    if (isClient($user)) {
      newifyNotificationClient();
    } else {
      newifyNotification();
    }
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
{#if newNotification !== undefined}
  <div class="flex-col center-center text-slate-900 dark:text-slate-100">
    <div style="width: 100%;" class="flex-row center-space-between">
      <div class="flex-row custom-row" on:click={async () => await openNotification()}>
        <Avatar {avatarUrl} alt="User Avatar" size={48} myClasses="mg-8" />
        <span class="payload">{parseNotificationText(notification.text)}</span>
      </div>
      <SeenButton isSeen={notification.seenAt} {toggleSeen} />
    </div>
    <span class="date-text text-xxs">{parseDate(notification.createdAt)}</span>
    <Divider />
  </div>
{/if}

<style>
  .date-text {
    align-self: flex-end;
    margin-top: -16px;
    margin-right: 12px;
    margin-bottom: 8px;
  }
  .payload {
    font-size: small;
    max-width: 76%;
  }
  .custom-row {
    align-items: center;
    justify-content: flex-start;
  }
</style>
