<script lang="ts">
  import {onMount} from "svelte";
  import {patchWithJwt, postWithJwt, serverlessRoutes, translate} from "lib";

  import {
    alertStore,
    currentClient,
    dialogData,
    paymentsStore,
    trainerClients,
  } from "stores";

  import {ButtonComponent, InputField, SelectField, TextareaField} from "ui";

  let data: any;
  const type = data.payment ? translate("EDIT") : translate("CREATE");

  const form = {
    amount: { value: 0, error: "" },
    currency: { value: "EUR", error: "" },
    date: { value: "", error: "" },
    description: { value: "", error: "" },
    expireAt: { value: "", error: "" },
  };

  let disabled = false;
  let isLoading = false;

  const currencies = [
    { name: "EUR", value: "EUR" },
    { name: "RSD", value: "RSD" },
    { name: "USD", value: "USD" },
  ];

  const onInput = (field: keyof typeof form): void => {};

  const changeExpireAt = async (): Promise<void> => {
    if (form.expireAt.value === data.client.expireAt?.slice(0, 10)) { return; }

    const response = await patchWithJwt(serverlessRoutes.TRAINER_CLIENT, {
      partialClientMap: {
        [data.client.id]: {
          expireAt: new Date(form.expireAt.value).toISOString().slice(0, 10),
        }
      }
    });

    const client = $trainerClients.find(
      (client): boolean => client.id === data.client.id
    );

    if (client) {
      client.expireAt = response.data.clients[0].expireAt;
    }

    if ($currentClient) {
      $currentClient.expireAt = response.data.clients[0].expireAt;
    }

    // U ClientListItem, client je passovan kao prop pa se ne menja kad
    // se promeni store, moramo da ga promenimo rucno
    if (data.executeFunction) {
      data.executeFunction(response.data.clients[0]);
    }
  };

  const editPayment = async (): Promise<void> => {
    try {
      const response = await patchWithJwt(serverlessRoutes.PAYMENT, {
        partialPaymentMap: {
          [data.payment.id]: {
            amount: form.amount.value,
            currency: form.currency.value,
            date: new Date(form.date.value).toISOString().slice(0, 10),
            description: form.description.value,
          }
        }
      });

      await changeExpireAt();

      paymentsStore.replace(response.data.payments);
      paymentsStore.sort();
      alertStore.show(translate("SUCCESSFULLY_EDITED_PAYMENT"));

      $dialogData.data = {};
      $dialogData.type = "";
    } catch (error) {
      console.error(error);
      isLoading = false;
      alertStore.show(translate("ERROR_EDITING_PAYMENT"), "error");
    }
  };

  const createPayment = async (): Promise<void> => {
    try {
      const response = await postWithJwt(serverlessRoutes.PAYMENT, {
        partialPayment: {
          amount: form.amount.value,
          currency: form.currency.value,
          date: new Date(form.date.value).toISOString().slice(0, 10),
          description: form.description.value,
          clientId: data.client.id,
        },
      });

      await changeExpireAt();

      paymentsStore.add([response.data.payment]);
      paymentsStore.sort();
      alertStore.show(translate("SUCCESSFULLY_CREATED_PAYMENT"));

      $dialogData.data = {};
      $dialogData.type = "";
    } catch (error) {
      console.error(error);
      isLoading = false;
      alertStore.show(translate("ERROR_CREATING_PAYMENT"), "error");
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.payment) {
      await editPayment();
    } else {
      await createPayment();
    }
  };

  onMount((): void => {
    const defaultDate = new Date().toISOString().slice(0, 10);

    if (data.client) {
      if (data.client.expireAt) {
        // !!!!!!!!!!!!!! HOTFIX ZA POGRESAN DATE TIME ZONE !!!!!!!!!!!
        const date = new Date(data.client.expireAt);
        date.setHours(date.getHours() + 2);
        form.expireAt.value = date.toISOString().slice(0, 10) || defaultDate;
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        // form.expireAt.value = data.client.expireAt.slice(0, 10) || defaultDate;
      }
    }

    if (data.payment) {
      form.amount.value = data.payment.amount;
      form.currency.value = data.payment.currency;
      form.date.value = data.payment.date.slice(0, 10);
      form.description.value = data.payment.description;
    } else {
      form.date.value = defaultDate;
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {type} {translate("PAYMENT_U").toLowerCase()}
  </div>

  <div class="flex flex-col gap-4">

    <div class="flex gap-4">

      <div class="grow">
        <InputField
          type="number"
          label={translate("CURRENCY_AMOUNT")}
          error={form.amount.error}
          bind:value={form.amount.value}
          on:input={() => onInput("amount")}
        />
      </div>

      <div class="basis-2/5">
        <SelectField
          label="{translate("CURRENCY")}"
          items={currencies}
          bind:value={form.currency.value}
          on:change={() => onInput("currency")}
        />
      </div>

    </div>

    <InputField
      type="date"
      label={translate("PAYMENT_DATE")}
      error={form.date.error}
      bind:value={form.date.value}
      on:input={() => onInput("date")}
    />

    <TextareaField
      label={translate("DESCRIPTION")}
      error={form.description.error}
      bind:value={form.description.value}
      on:input={() => onInput("description")}
    />

    <InputField
      type="date"
      label={translate("ACCOUNT_EXPIRATION_DATE")}
      error={form.expireAt.error}
      bind:value={form.expireAt.value}
      on:input={() => onInput("expireAt")}
    />

    <div class="flex justify-center">
      <ButtonComponent {disabled} {isLoading} on:click={onSubmit}>
        {type}
      </ButtonComponent>
    </div>

  </div>

</div>
