<script lang="ts">
  import { push } from "svelte-spa-router";
  import { entities } from "../lib/constants";
  import { isClient } from "../lib/roles";
  import { translate } from "../lib/translate";
  import { location } from "svelte-spa-router";
  import { Capacitor } from "@capacitor/core";
  import { user } from "stores";
  import { onMount } from "svelte";

  export let entity: string;

  let selected = $location;
  let tabs = [];

  onMount(() => {
    if (entity === entities.PROGRESS) {
      tabs = [];
      tabs.push({ title: "HABITS", route: "/progress/habits" });
      tabs.push({ title: "MEASUREMENTS", route: "/progress/measurements" });
      tabs.push({ title: "GOALS", route: "/progress/goals" });
      tabs.push({ title: "GALLERY", route: "/progress/gallery" });
    } else if (entity === entities.DIET && !isClient($user)) {
      tabs = [];
      tabs.push({ title: "MEAL_PLANS", route: "/diet/meal-plans" });
      tabs.push({ title: "RECIPES", route: "/diet/recipes" });
      tabs.push({ title: "INGREDIENTS", route: "/diet/ingredients" });
    } else if (entity === entities.DIET && isClient($user)) {
      tabs = [];
      tabs.push({ title: "MEAL_PLAN", route: "/diet/meal-plan" });
      tabs.push({ title: "DIET_JOURNAL", route: "/diet/journal" });
      tabs.push({ title: "IF_TRACKING", route: "/diet/fasting" });
    } else if (entity === entities.TRAINING && !isClient($user)) {
      tabs = [];
      tabs.push({ title: "PROGRAMS", route: "/training/programs" });
      tabs.push({ title: "WORKOUTS", route: "/training/workouts" });
      tabs.push({ title: "EXERCISES", route: "/training/exercises" });
    } else if (entity === entities.TRAINING && isClient($user)) {
      tabs = [];
      tabs.push({ title: "TRAINING_PLAN", route: "/training/program" });
      tabs.push({ title: "TRAINING_JOURNAL", route: "/training/journal" });
    } else if (entity === entities.CLIENTS) {
      tabs = [];
      tabs.push({ title: "CLIENTS", route: "/clients/list" });
      tabs.push({ title: "PAYMENTS", route: "/clients/payments" });
      tabs.push({ title: "GROUPS", route: "/clients/groups" });
      tabs.push({ title: "TRAINERS", route: "/clients/trainers" });
    } else if (entity === entities.NOTIFICATIONS) {
      tabs = [];
      tabs.push({ title: "EXPIRY_VIEW", route: "/notifications/expiry" });
      tabs.push({ title: "REMINDERS", route: "/notifications/reminders" });
      tabs.push({ title: "NOTIFICATIONS", route: "/notifications" });
    }
  });

  const select = (tab) => {
    push(tab.route);
  };
</script>

<div
  class="menu flex-row center-space-around bg-white dark:bg-zinc-800 border-b border-slate-300 dark:border-zinc-700"
>
  {#each tabs as tab}
    <div
      on:click={() => select(tab)}
      on:keypress={() => select(tab)}
      class={`
      ${
        selected === tab.route
          ? `text-teal-500 dark:text-white font-bold`
          : "text-slate-900 dark:text-slate-100"
      }
      `}
    >
      {translate(tab.title)}
    </div>
  {/each}
</div>
{#if Capacitor.isNativePlatform()}
  <div class="mb-6" />
{/if}

<style>
  .menu {
    position: sticky;
    top: 0;
    max-width: 720px;
    left: 0;
    right: 0;
    margin: auto;
    height: 48px;
    width: 100%;
    z-index: 2;
    padding-top: calc(env(safe-area-inset-top) * 1.25);
    padding-bottom: calc(env(safe-area-inset-top) / 2);
  }
</style>
