<script lang="ts">
  import { alertStore, dialogData } from "stores";
  import { onMount } from "svelte";
  import Avatar from "../../components/UI/Avatar.svelte";
  import Svg from "../../components/UI/Svg.svelte";
  import { dialogTypes, serverlessRoutes } from "../../lib/constants";
  import { deleteWithJwt, patchWithJwt, postWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { showAlert } from "../../stores/showAlertStore";
  import { user } from "../../stores/userStore";
  import { clientAvatarUrl } from "../../stores/clientStores";
  import { ButtonComponent, InputField, Spinner } from "ui";
  import type { User } from "src/interfaces/User";
  import { uploadImageCloudflare } from "../../lib/cloudflare/uploadImageCloudflare";

  let name: string = "";
  let email: string = "";
  let password: string = "";
  let repeatPassword: string;

  onMount(() => {
    name = $user.name;
    email = $user.email;
  });

  const update = async () => {
    const url = serverlessRoutes.USER;
    const partialUser: Partial<User> = { id: $user.id, name, email };
    if (password !== "" && password !== repeatPassword) {
      $showAlert.message = "Passwords don't match.";
      return;
    }
    if (password !== "" && password === repeatPassword) partialUser.password = password;

    try {
      await patchWithJwt(url, { user: partialUser });
      $showAlert.message = "User profile updated successfully.";
      $user.name = name;
      $user.email = email;
    } catch (err: any) {
      $showAlert.message = err.data.message;
    }
  };

  const deleteProfile = async () => {
    $showAlert.message = `${translate("DISABLED_TEMPORARILY")}`;
  };

  const toggleDeleteProfileDialog = () => {
    $dialogData.type = dialogTypes.CONFIRM_DELETE_ACCOUNT;
    $dialogData.data = { executeFunction: deleteProfile };
  };

  let image: any;
  let avatarInputElement: HTMLInputElement;

  const onChangeAvatar = (): void => avatarInputElement.click();

  let isUploading = false;

  type $Input = Event & {
    currentTarget: EventTarget & HTMLInputElement;
  };

  const onUploadAvatar = async (event: $Input): Promise<void> => {
    isUploading = true;

    const {currentTarget} = event;

    if (!currentTarget.files) {
      isUploading = false;
      return alertStore.show("Otpremanje slike nije uspelo", "error");
    }

    const [file] = currentTarget.files;
    const uploadResult = await uploadImageCloudflare(file, false);
    const avatarUrl = uploadResult.imageUrl;
    const oldAvatarUrl = $user.avatarUrl ? $user.avatarUrl.split("/")[4] : "";

    if (!avatarUrl) {
      isUploading = false;
      return alertStore.show("Otpremanje slike nije uspelo", "error");
    }

    const {data, error} = await patchWithJwt(serverlessRoutes.USER, {
      user: {id: $user.id, avatarUrl}
    });

    if (error) {
      await deleteWithJwt(`${serverlessRoutes.FILE}/image`, {
        imageId: avatarUrl
      });

      isUploading = false;

      return alertStore.show("Otpremanje slike nije uspelo", "error");
    }

    if (oldAvatarUrl) {
      await deleteWithJwt(`${serverlessRoutes.FILE}/image`, {
        imageId: oldAvatarUrl
      });
    }

    $clientAvatarUrl = avatarUrl;
    $user.avatarUrl = avatarUrl;
    isUploading = false;
  };


  // const onMigrate = async () => {
  //   const res = await postWithJwt(`${serverlessRoutes.USER}/migrate-comments`);
  //   console.log(res);
  // };
</script>

<svelte:head>
  <style>
    body {
      overflow: hidden;
    }
  </style>
</svelte:head>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="h-screen p-4 flex flex-col items-center justify-center gap-4">
  <div class="pb-8 w-full flex justify-center" on:click={onChangeAvatar}>
    {#if isUploading}
      <Spinner height={"88"}/>
    {:else}
      <Avatar avatarUrl={$user.avatarUrl} alt="User Avatar" size={88} />
    {/if}
    <input
      class="hidden"
      type="file"
      accept=".png,.jpeg,.jpg,.webp,.svg"
      bind:value={image}
      bind:this={avatarInputElement}
      on:input={onUploadAvatar}
    />
  </div>

  <!-- <button on:click={onMigrate}>MIGRATE COMMENTS</button> -->

  <div class="px-12 flex flex-col pb-8 w-full">
    <InputField label={translate("NAME")} bind:value={name} />
    <InputField label={translate("EMAIL")} bind:value={email} />
    <InputField label={translate("PASSWORD")} bind:value={password} />
    <InputField label={translate("REPEAT_PASSWORD")} bind:value={repeatPassword} />

    <div class="flex justify-center mt-2">
      <ButtonComponent on:click={update}>
        {translate("UPDATE")}
      </ButtonComponent>
    </div>
  </div>

  <ButtonComponent isOutline on:click={toggleDeleteProfileDialog}>
    <div class="flex gap-2">
      {translate("DELETE_ACCOUNT")}
      <Svg name="delete" size={16} customColor="bg-red-500"/>
    </div>
  </ButtonComponent>
</div>
