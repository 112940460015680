<script lang="ts">
  import { afterUpdate, onDestroy, onMount } from "svelte";
  import ChatInput from "../../components/Chat/ChatInput.svelte";
  import ChatTopMenu from "../../components/Chat/ChatTopMenu.svelte";
  import FileMessage from "../../components/Chat/FileMessage.svelte";
  import Message from "../../components/Chat/Message.svelte";
  import { dialogTypes, serverlessRoutes } from "../../lib/constants";
  import { getWithJwt, patchWithJwt, postWithJwt } from "../../lib/requests";
  import { user } from "../../stores/userStore";
  import { showAlert } from "../../stores/showAlertStore";
  import { clientChatSessionId } from "../../stores/chatSessionsStore";
  import MessageMenu from "../../components/Chat/MessageMenu.svelte";
  import { translate } from "../../lib/translate";
  import { dialogData } from "../../stores/dialogDataStore";
  import * as Sentry from "@sentry/browser";
  import { App } from "@capacitor/app";
  import { chatMessages } from "../../stores/chatMessagesStore";
  import { clientTrainerAvatarUrl, clientTrainerId } from "../../stores/clientStores";
  import { isClient } from "../../lib/roles";
  import { trainerChatWith } from "../../stores/trainerStores";
  import { Capacitor } from "@capacitor/core";
  import { isLoadingMore } from "../../stores/isLoadingMoreStore";
  import { payload } from "../../stores/payloadStore";
  import { parseDateWithTime } from "../../lib/parseDateWithTime";
  import AiSummary from "../../components/Chat/AiSummary.svelte";
  import { bookmarkedMessages } from "../../stores/bookmarkedMessages";
  import AiSuggestion from "../../components/Chat/AiSuggestion.svelte";
  import { uploadImageCloudflare } from "../../lib/cloudflare/uploadImageCloudflare";
  import {
    isVideoReady,
    uploadVideoToCloudflareStreams,
  } from "../../lib/cloudflare/uploadVideoCloudflare";
  import { IndeterminateProgressBarComponent } from "ui";
  import ChatSearch from "../../components/Chat/ChatSearch.svelte";
  import { socketV2 } from "../../stores/socketStore";
  import type { IMessage } from "../../interfaces/Chat.ts";
  import { fetchCloudflareStreamsVideo } from "../../lib/cloudflare/fetchStreamsVideo";
  import { storage } from "lib";
  import axios from "axios";

  let isFileUploading = false;
  let showAiSummary: boolean;
  let showAiSuggestion: boolean;
  let avatarUrl = "";
  let isLoading = false;
  let trainersName = "";
  let selectedMessage: Partial<IMessage> | null = null;
  let isEditingMessage: boolean = false;
  let timer;
  let sessionId: number;
  const messageSendingIds: number[] = [];
  let searchMode = false;

  $: searchMode && fetchData(20000);

  const touchduration = 500;
  let total: number;
  let skip = 0;
  let unSeenMessageId: number | null = null;

  const isMine = (id: number, payload) => {
    return $user.id === id;
  };

  const uploadFile = async (files: File[] | null) => {
    if (!files) return;

    for (const file of files) {
      isFileUploading = true;

      let tmpPayload = "";
      const isVideo = file.type.includes("video");

      if (isVideo) {
        const uploadResult = await uploadVideoToCloudflareStreams(file, true);

        if (!uploadResult.videoUrl && uploadResult.error) {
          isFileUploading = false;
          $showAlert.message = uploadResult?.error;
        }

        const interval = setInterval(async (): Promise<void> => {
          const isReady = await isVideoReady(uploadResult.videoUrl);

          if (isReady === true) {
            tmpPayload = `:video:${uploadResult?.videoUrl}`;
            isFileUploading = false;
            await sendMessage(tmpPayload);
            clearInterval(interval);
          }
        }, 4000);
      } else {
        const uploadRes = await uploadImageCloudflare(file, true);
        isFileUploading = false;
        if (!uploadRes.imageUrl) return;
        tmpPayload = `:cloudflareimg:${uploadRes.imageUrl}`;
        await sendMessage(tmpPayload);
      }
    }
  };

  const sendMessage = async (payload: string) => {
    if (isEditingMessage && selectedMessage?.id) {
      editMessageRequest(payload);
    } else {
      const otherUserId = isClient($user) ? $clientTrainerId : $trainerChatWith.clientId;
      if (!otherUserId) {
        const storedTrainerId = await storage.get("clientTrainerId");
        if (storedTrainerId) $clientTrainerId = storedTrainerId;
      }
      const url = `${serverlessRoutes.MESSAGE}`;
      const message = {
        payload,
        sessionId,
        senderId: $user.id,
      };
      const data = {
        message,
        receiverId: otherUserId,
        senderName: $user.name,
      };

      let retries = 0;
      const maxRetries = 3;
      const retryDelay = 500;

      const attemptSend = async (): Promise<void> => {
        try {
          const result = await axios.post(url, data);
          const res = result.data;
          if (!$chatMessages.find((m) => m.id === res.id)) $chatMessages = [...$chatMessages, res];
          setSeen();
          setTimeout(() => {
            forceScrollToBottom();
          });
        } catch (err: any) {
          if (retries < maxRetries) {
            retries++;
            console.log(`Attempt ${retries} failed. Retrying in ${retryDelay}ms...`);
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            return attemptSend();
          }

          // All retries failed, log the error
          try {
            const url = serverlessRoutes.FRONTEND_LOG;
            const errorDetails = {
              message: err.message,
              stack: err.stack,
              ...err,
            };
            await postWithJwt(url, {
              error: JSON.stringify(errorDetails),
              data: JSON.stringify(data),
            });
          } catch (error) {}

          $showAlert.color = "red-400";
          $showAlert.message = "Došlo je do greške";
        }
      };

      await attemptSend();
    }
  };

  const socketConnect = () => {
    if ($socketV2.connected === true && sessionId) {
      $socketV2.off("message");
      $socketV2.off("updateMessage");
      $socketV2.emit("subscribe", { event: "message", id: sessionId });
      $socketV2.emit("subscribe", { event: "updateMessage", id: sessionId });
      $socketV2.on("message", async (message: IMessage) => {
        if (!$chatMessages.find((m) => m.id === message.id))
          $chatMessages = [...$chatMessages, message];
        setSeen();
        setTimeout(() => {
          forceScrollToBottom();
        });
      });
      $socketV2.on("updateMessage", (message: IMessage) => {
        $chatMessages = $chatMessages.map((msg) => (msg.id === message.id ? message : msg));
      });
    }
  };

  const unsubscribeSocket = socketV2.subscribe((sock) => {
    socketConnect();
  });

  const socketDisconnect = () => {
    if ($socketV2.connected === true && sessionId) {
      $socketV2.emit("unsubscribe", { event: "message", id: sessionId });
      $socketV2.emit("unsubscribe", { event: "updateMessage", id: sessionId });
      $socketV2.off("message");
      $socketV2.off("updateMessage");
    }
  };

  const copyToClipboard = (payload: string) => {
    navigator.clipboard.writeText(payload);
    selectedMessage = null;
    $showAlert.message = `${translate("SUCCESSFULLY_COPIED")} ${translate(
      "MESSAGE"
    ).toLowerCase()}`;
  };

  const editMessage = () => {
    if (!selectedMessage) return;
    $payload = selectedMessage.payload;
    isEditingMessage = true;
  };

  const editMessageRequest = async (payload: string) => {
    if (!selectedMessage || !selectedMessage.id) return;
    try {
      const url = serverlessRoutes.MESSAGE;
      const partialMessage = {
        id: selectedMessage.id,
        payload,
      };
      const res = await patchWithJwt(url, { partialMessage });
      if (res.data.message === "Success.") {
        $showAlert.message = `${translate("SUCCESSFULLY_EDITED")} ${translate("MESSAGE")}`;
        selectedMessage = null;
      }
    } catch (err) {}
  };

  const touchstart = (
    messageId: number,
    messagePayload: string,
    timestamp: any,
    isMine: boolean,
    deletedAt: string | null
  ) => {
    if (deletedAt) return;
    timer = setTimeout(() => {
      selectedMessage = {
        id: messageId,
        payload: messagePayload,
        timestamp,
        isMine,
      };
    }, touchduration);
  };

  const touchend = () => {
    if (timer) clearTimeout(timer);
  };

  const clearSlectedMessage = () => {
    selectedMessage = null;
    isEditingMessage = false;
  };

  const deleteMessage = async () => {
    if (!selectedMessage || !selectedMessage.id) return;
    try {
      const url = serverlessRoutes.MESSAGE;
      const partialMessage = {
        id: selectedMessage.id,
        deletedAt: new Date().toISOString(),
      };
      const res = await patchWithJwt(url, { partialMessage });
      if (res.data.message === "Success.") {
        $showAlert.message = `${translate("SUCCESSFULLY_DELETED")} ${translate("MESSAGE")}`;
        selectedMessage = null;
      }
    } catch (err) {}
  };

  const showConfirmDeleteDialog = () => {
    $dialogData.type = dialogTypes.CONFIRM_DELETE;
    $dialogData.data = {
      executeFunction: deleteMessage,
      title: translate("MESSAGE"),
    };
  };

  const setSeen = async () => {
    const url = `${serverlessRoutes.SESSION}/set-seen`;
    try {
      await patchWithJwt(url, { sessionId, notSeen: 0 });
    } catch (err) {}
  };

  const fetchData = async (take = 25) => {
    if (!isLoading && sessionId && sessionId !== 0) {
      isLoading = true;
      const url = `${serverlessRoutes.FETCH_MESSAGES}/v2?take=${take}&skip=${skip}&sessionId=${sessionId}`;
      try {
        const data = await getWithJwt(url);

        if (!isClient($user)) {
          unSeenMessageId = data.data.unSeenMessageId;
        }

        total = data.data.count;
        data.data.messages.forEach((msg) => {
          if (!$chatMessages.find((m) => m.id === msg.id)) {
            $chatMessages.push(msg);
            //$chatMessages = $chatMessages;
          }
        });
        $chatMessages = $chatMessages.sort((msgA, msgB) => msgA.id - msgB.id);
        // $chatMessages.forEach((message) => {

        // });
        isLoading = false;

        // forceScrollToBottom();
        setTimeout(() => {
          // console.log(data.data.cout)
          if (data.data.count >= 5) {
            forceScrollToBottom();
          }
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  };

  const calculateTimeDifference = (d1, d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);

    let differenceInMilliseconds = date2 - date1;
    let differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    return differenceInHours >= 8;
  };

  const timeHeaderMap: any = {};

  const unsubscribeMessages = chatMessages.subscribe((res) => {
    if (res && res.length > 0) {
      // setTimeout(() => {
      //   if (window.scrollY > 300) {
      //     forceScrollToBottom();
      //   }
      // }, 1000);
      if (res.length > 1) {
        for (let i = 1; i < res.length; i++) {
          if (calculateTimeDifference(res[i - 1].createdAt, res[i].createdAt)) {
            timeHeaderMap[res[i].id] = true;
          }
        }
      }
    }
  });

  const fetchMoreData = async () => {
    $isLoadingMore = true;
    skip += 15;
    const url = `${serverlessRoutes.FETCH_MESSAGES}/v2?take=25&skip=${skip}&sessionId=${sessionId}`;
    try {
      const data = await getWithJwt(url);
      // $chatMessages = [...data.data.messages, ...$chatMessages];
      data.data.messages.forEach((msg) => {
        if (!$chatMessages.find((m) => m.id === msg.id)) {
          $chatMessages.push(msg);
          // $chatMessages = $chatMessages;
        }
      });
      $chatMessages = $chatMessages.sort((msgA, msgB) => msgA.id - msgB.id);
      $isLoadingMore = false;
    } catch (err) {
      $isLoadingMore = false;
    }
  };

  const unsubscribeAvatarUrl = clientTrainerAvatarUrl.subscribe((res) => {
    if (!res) return;
    avatarUrl = isClient($user) ? res : "/default-avatar.png";
  });

  // const unsubscribeSessionId = clientChatSessionId.subscribe((res) => {
  //   if (res) {
  //     sessionId = res;
  //     socketConnect();
  //   }
  //   if (res && skip === 0) fetchData();
  // });

  const bookmarkMessage = async () => {
    if (!selectedMessage) return;
    const url = `${serverlessRoutes.BOOKMARK}`;
    try {
      const createdAt = selectedMessage.timestamp.replace("T", " ").replace("Z", "");
      const response = await postWithJwt(url, {
        messageId: selectedMessage.id,
        sessionId,
        createdAt,
        payload: selectedMessage.payload,
      });
      const id = response.data[0];

      $bookmarkedMessages = [
        ...$bookmarkedMessages,
        {
          id,
          messageId: selectedMessage.id,
          sessionId,
          createdAt,
          payload: selectedMessage.payload,
        },
      ];

      $showAlert.message = `${translate("SUCCESSFULLY_BOOKMARKED_MESSAGE")}`;
    } catch (err) {
    } finally {
      selectedMessage = null;
    }
  };

  let lastScrollHeight: number;
  let currentScrollHeight: number;
  let blockScroll = false;

  onMount(async (): Promise<void> => {
    if (isClient($user)) {
      trainersName = $user.trainer?.name ? $user.trainer?.name : "Trener";
      sessionId = $clientChatSessionId;
      if (!sessionId) {
        const storedSessionId = await storage.get("clientChatSessionId");
        sessionId = storedSessionId;
      }
    } else {
      if (!$trainerChatWith.sessionId) {
        $trainerChatWith = await storage.get("trainerChatWith");
      }

      sessionId = $trainerChatWith.sessionId;
      trainersName = $trainerChatWith.clientName;
      $chatMessages = [];
      avatarUrl = $trainerChatWith.avatarUrl;
      socketConnect();
    }
    fetchData();

    if (Capacitor.isNativePlatform()) {
      App.addListener("appStateChange", async ({ isActive }) => {
        if (isActive) {
          skip = 0;
          await fetchData();
        }
      });
    }

    window.onscroll = async (): Promise<void> => {
      if (!lastScrollHeight && !currentScrollHeight) {
        lastScrollHeight = document.body.scrollHeight;
        currentScrollHeight = document.body.scrollHeight;
      }

      if (window.scrollY <= 0) {
        if ($chatMessages.length < total && !blockScroll) {
          blockScroll = true;

          await fetchMoreData();

          currentScrollHeight = document.body.scrollHeight;

          const top = currentScrollHeight - lastScrollHeight;

          window.scrollTo({ top });

          lastScrollHeight = currentScrollHeight;
        }
      } else if (window.scrollY > 0) {
        blockScroll = false;
      }
    };

    showAiSuggestion = (await storage.get("showAiSuggestion")) === true;
    showAiSummary = (await storage.get("showAiSummary")) === true;

    // setTimeout(forceScrollToBottom);
    // setTimeout(forceScrollToBottom, 500);
    if (sessionId) setSeen();
  });

  const scrollToBottom = () => {
    if (skip !== 0) return; // Ovo mora zbog fetch load more jer se skroluje na gore
    window.scrollTo(0, document.body.scrollHeight);
  };

  const forceScrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  // afterUpdate(() => {
  //   if (Capacitor.isNativePlatform()) {
  //     //scrollToBottom();
  //   }
  // });

  onDestroy(async () => {
    App.removeAllListeners();
    // unsubscribeSessionId();
    unsubscribeAvatarUrl();
    unsubscribeMessages();
    unsubscribeSocket();
    socketDisconnect();
    $trainerChatWith = {};

    await storage.remove("trainerChatWith");

    window.onscroll = (): void => {};
    $bookmarkedMessages = [];
  });

  const isFile = (message) => {
    return (
      (message.files && message.files.length > 0) ||
      (message.payload && message.payload.includes(":video:")) ||
      (message.payload && message.payload.includes("cloudflarestream")) ||
      (message.payload && message.payload.includes(":cloudflareimg:"))
    );
  };
</script>

{#if !searchMode && sessionId}
  <ChatTopMenu
    bind:searchMode
    {trainersName}
    {avatarUrl}
    {sessionId}
    clientId={!isClient($user) ? $trainerChatWith.clientId : null}
  />
{:else}
  <ChatSearch bind:searchMode />
{/if}
<div class="bg-white dark:bg-zinc-800">
  <!-- {#if isLoading}
    <Spinner />
  {:else} -->
  {#if Capacitor.isNativePlatform()}
    <div class="mt-20"></div>
  {/if}
  {#if Capacitor.getPlatform() === "ios"}
    <div class="pt-10"></div>
  {/if}
  <div class="mt-4"></div>
  <!-- {#if $chatMessages.length < total}
      <LoadMoreButton isReverse={true} {fetchMoreData} />
    {/if} -->

  <!-- <InfiniteScroll hasMore={$chatMessages.length < total} on:loadMore={fetchMoreData} reverse /> -->
  <div class="relative flex-col custom-height">
    {#each $chatMessages as message (message.id)}
      {#if isFile(message)}
        <FileMessage
          {message}
          {scrollToBottom}
          {touchstart}
          {touchend}
          isBottomImage={$chatMessages[$chatMessages.length - 1]?.id === message.id}
          isMine={isMine(message.senderId)}
        />
        <!-- {scrollToBottom} -->
      {:else}
        <div class="relative flex flex-col">
          {#if timeHeaderMap[message.id]}
            <span class="text-xxs text-center mt-4 mb-2 text-slate-900 dark:text-slate-300"
              >{parseDateWithTime(message.createdAt)}</span
            >
          {/if}
          <Message
            {message}
            {touchstart}
            {touchend}
            isMine={isMine(message.senderId, message.payload)}
          />
          {#if !isClient($user) && unSeenMessageId && unSeenMessageId === message.id}
            <img
              class="mt-1 mr-2 h-4 w-4 rounded-full self-end"
              src={avatarUrl ? avatarUrl : "/default-avatar.png"}
              alt="Avatar"
            />
          {/if}
        </div>
      {/if}
    {/each}
    {#if isFileUploading}
      <!-- <div class="flex flex-row items-center justify-center my-4">
          <MiniSpinner />
        </div> -->
      <div
        class="bg-white dark:bg-zinc-800 fixed bottom-[57px] left-[50%] translate-x-[-50%] w-full pt-2 max-w-[640px]"
      >
        <div class="text-center text-xxs">{translate("UPLOADING_FILE")}</div>
        <IndeterminateProgressBarComponent />
      </div>
    {/if}
  </div>
  <!-- {/if} -->
  {#if selectedMessage}
    <MessageMenu
      {bookmarkMessage}
      {copyToClipboard}
      {clearSlectedMessage}
      {editMessage}
      {showConfirmDeleteDialog}
      {selectedMessage}
    />
  {/if}
  <ChatInput {avatarUrl} {isFileUploading} clientName={trainersName} {sendMessage} {uploadFile} />
  {#if showAiSummary}
    <AiSummary {sessionId} />
  {/if}
  {#if showAiSuggestion && !isClient($user) && $chatMessages[$chatMessages.length - 1]?.senderId !== $user.id}
    <AiSuggestion {sendMessage} {sessionId} />
  {/if}
</div>

<style>
  .custom-height {
    min-height: calc(100vh - 48px - 48px);
  }
</style>
