<script lang="ts">
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";

  import {
    getWithJwt,
    isClient,
    patchWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";

  import { alertStore, currentClient, dialogData, user } from "stores";
  import { ButtonComponent, InputField, SelectField, TextareaField } from "ui";
  import { clientGoals, clientMeasurements } from "../../stores/clientStores";

  export let data: any;

  let selectedMeasurement;
  let startDate;
  let startValue;
  let finishDate;
  let finishValue;
  let description = "";
  let dropdownOpen = false;

  const form = {
    measurementName: {
      value: {
        id: 0,
        isDefault: false,
        measurementUnit: "",
        name: "",
        position: null,
        userId: 0,
      },
      error: "",
    },
    startingValue: { value: 1, error: "" },
    createdAt: { value: "", error: "" },
    goalValue: { value: 1, error: "" },
    expireAt: { value: "", error: "" },
    description: { value: "", error: "" },
  };

  let items: any[] = [];
  let isLoading = false;
  let disabled = false;
  let dialogType = data.goal ? translate("EDIT") : translate("CREATE");

  if ($clientMeasurements.length === 0) {
    if (isClient($user)) {
      push(`/progress/measurements`);
    }
  }

  const onInput = (field: keyof typeof form): void => {
    if (field === "startingValue") {
      const { value } = form[field];

      if (value < 1) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_1");
      } else if (value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "goalValue") {
      const { value } = form[field];

      if (value < 1) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_1");
      } else if (value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      const { value } = form[field];

      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const fetchMeasurements = async (): Promise<void> => {
    try {
      const clientId = $currentClient.id || $user.id;

      const response = await getWithJwt(
        `${serverlessRoutes.MEASUREMENT}?clientId=${clientId}`
      );

      $clientMeasurements = response.data.measurements;

      items = response.data.measurements.map((measurement) => ({
        value: measurement,
        name: measurement.name,
      }));

      form.measurementName.value = items[0].value;
    } catch (error) {
      console.error(error);
    }
  };

  const editGoal = async (): Promise<void> => {
    try {
      const response = await patchWithJwt(serverlessRoutes.GOAL, {
        partialGoalMap: {
          [data.goal.id]: {
            goalValue: form.goalValue.value,
            expireAt: new Date(form.expireAt.value).toISOString(),
            description: form.description.value,
          }
        }
      });

      const index = $clientGoals.findIndex((goal) => goal.id === response.data.goals[0].id);

      $clientGoals[index] = {...$clientGoals[index], ...response.data.goals[0]};
      $clientGoals = $clientGoals;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      isLoading = false;
      console.error(error);
      alertStore.show(translate("ERROR_EDITING_GOAL"), "error");
    }
  };

  const createGoal = async (): Promise<void> => {
    try {
      const goalRes = await postWithJwt(serverlessRoutes.GOAL, {
        partialGoal: {
          clientId: $currentClient.id || $user.id,
          measurementId: form.measurementName.value.id,
          description: form.description.value,
          currentValue: form.startingValue.value,
          goalValue: form.goalValue.value,
          createdAt: new Date(form.createdAt.value).toISOString(),
          expireAt: new Date(form.expireAt.value).toISOString(),
          startingValue: form.startingValue.value,
        }
      });

      goalRes.data.goal.measurement = {
        id: form.measurementName.value.id,
        measurementUnit: form.measurementName.value.measurementUnit,
        name: form.measurementName.value.name
      };

      $clientGoals = [...$clientGoals, goalRes.data.goal];

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      isLoading = false;
      console.error(error);
      alertStore.show(translate("ERROR_CREATING_GOAL"), "error");
    }
  };

  const onSubmit = async () => {
    isLoading = true;

    if (data.goal) {
      await editGoal();
    } else {
      await createGoal();
    }
  };

  onMount(async () => {
    await fetchMeasurements();

    if (data.goal) {
      form.measurementName.value = items.find(
        (item) => item.value.name === data.goal.measurement.name
      ).value;
      form.startingValue.value = data.goal.startingValue;
      form.createdAt.value = data.goal.createdAt.slice(0, 10);
      form.goalValue.value = data.goal.goalValue;
      form.expireAt.value = data.goal.expireAt.slice(0, 10);
      form.description.value = data.goal.description;
    }
  });

  // const unsubscribe = clientMeasurements.subscribe((res) => {
  //   if (res.length > 0) {
  //     selectedMeasurement = $clientMeasurements[0];
  //   }
  // });

  // onDestroy(unsubscribe);
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {dialogType}
    {translate("GOAL").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>

    <SelectField
      {items}
      label="{translate("MEASUREMENT")}"
      disabled={data.goal !== undefined}
      bind:value={form.measurementName.value}
    />

    {#if !data.goal}
      <div class="flex gap-4">
        <InputField
          type="number"
          label="{translate("CURRENTLY")}"
          suffix="{form.measurementName.value.measurementUnit}"
          error={form.startingValue.error}
          bind:value={form.startingValue.value}
          on:input={() => onInput("startingValue")}
        />

        <InputField
          type="date"
          label={translate("START_DATE")}
          error=""
          bind:value={form.createdAt.value}
        />
      </div>
    {/if}

    <div class="flex gap-4">
      <InputField
        type="number"
        label="{translate("GOAL")}"
        suffix="{form.measurementName.value.measurementUnit}"
        error={form.goalValue.error}
        bind:value={form.goalValue.value}
        on:input={() => onInput("goalValue")}
      />

      <InputField
        type="date"
        label="{translate("END_DATE")}"
        error=""
        bind:value={form.expireAt.value}
      />
    </div>

    <TextareaField
      label={translate("DESCRIPTION")}
      error={form.description.error}
      bind:value={form.description.value}
      on:input={() => onInput("description")}
    />

    <div class="flex justify-center">
      <ButtonComponent {disabled} {isLoading} type="submit">
        {dialogType}
      </ButtonComponent>
    </div>

  </form>
</div>
