<script lang="ts">
  import { onMount } from "svelte";
  import { serverlessRoutes } from "../../../lib/constants";
  import { postWithJwt } from "../../../lib/requests";
  import { translate } from "../../../lib/translate";
  import { dialogData } from "../../../stores/dialogDataStore";
  import { ButtonComponent, InputField, TextareaField } from "ui";
  import { alertStore, journalMealsStore } from "stores";
  import type { Meal } from "interfaces";

  export let data: any;

  let isLoading = false;
  let disabled = true;

  const form = {
    name: { value: "", error: "" },
    description: { value: "", error: "" },
    date: { value: "", error: "" },
  };

  const onInput = (field: keyof typeof form): void => {
    const { value } = form[field];

    if (field === "name") {
      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.executeFunction) {
      data.executeFunction({
        name: form.name.value,
        description: form.description.value,
      });

      return;
    }

    const partialMeal: Partial<Meal> = {
      name: form.name.value,
      description: form.description.value,
      entityType: 0,
      mealPlanId: data.mealPlanId,
      finishedAt: form.date.value
        ? new Date(form.date.value).toISOString()
        : new Date().toISOString(),
    };

    const { data: resData, error } = await postWithJwt(serverlessRoutes.MEAL, {
      partialMeal,
    });

    if (error && !resData) {
      console.error(error);
      alertStore.show(translate("ERROR_CREATING_MEAL"), "error");
      isLoading = false;
      return;
    }

    journalMealsStore.add([resData.meal]);

    alertStore.show(translate("SUCCESSFULLY_CREATED_MEAL"));

    $dialogData.data = {};
    $dialogData.type = "";
  };

  onMount((): void => {
    // if (data) {
    // }
    if (data.name) {
      form.name.value = data.name;
    }
    if (data.description) {
      form.description.value = data.description;
    }
  });
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {data.executeFunction ? translate("EDIT") : translate("CREATE")}
    {translate("MEAL").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <InputField
      label={translate("NAME")}
      error={form.name.error}
      bind:value={form.name.value}
      on:input={() => onInput("name")}
    />

    <TextareaField
      label={translate("DESCRIPTION")}
      error={form.description.error}
      bind:value={form.description.value}
      on:input={() => onInput("description")}
    />

    <InputField label={translate("DATE")} type="date" error={""} bind:value={form.date.value} />

    <div class="flex justify-center">
      <ButtonComponent {disabled} {isLoading} type="submit">
        {data.executeFunction ? translate("EDIT") : translate("CREATE")}
      </ButtonComponent>
    </div>
  </form>
</div>
