<script lang="ts">
  import { dialogTypes, getNewPositions, isClient, serverlessRoutes, translate } from "lib";
  import { pop, replace } from "svelte-spa-router";
  import {
    cantDoExercises,
    trainerClientPrograms,
    trainerPrograms,
  } from "../../stores/trainerStores";
  import Svg from "../../components/UI/Svg.svelte";
  import More from "../../components/UI/More.svelte";
  import WorkoutExpansionPanel from "../../components/UI/WorkoutExpansionPanel.svelte";
  import { alertStore, dialogData, showAlert, user } from "stores";
  import { deleteWithJwt, getWithJwt, patchWithJwt } from "../../lib/requests";
  import type { MenuItem } from "../../interfaces/MenuItem";
  import { onMount } from "svelte";
  import Spinner from "../../components/UI/Spinner.svelte";
  import { writable } from "svelte/store";
  import { Capacitor } from "@capacitor/core";
  import { dragHandleZone } from "svelte-dnd-action";
  import type { DndEvent, Item } from "svelte-dnd-action";
  import { flip } from "svelte/animate";
  import { ButtonComponent } from "ui";
  import type { Workout } from "interfaces";

  let params: any; // spa-router postavlja params.id programa (string) i params.clientId (string)

  const program = writable([], (set) => {
    const firstUnsubscribe = trainerPrograms.subscribe((value) => {
      const p = value.find(({ id }) => id === parseInt(params.id));
      if (p) {
        if (p.workouts) p.workouts.sort((a: any, b: any) => a.position - b.position);
        set(p);
      }
    });
    const secondUnsubscribe = trainerClientPrograms.subscribe((value) => {
      const p = value.find(({ id }) => id === parseInt(params.id));
      if (p) {
        if (p.workouts) p.workouts.sort((a: any, b: any) => a.position - b.position);
        set(p);
      }
    });
    return () => {
      firstUnsubscribe();
      secondUnsubscribe();
    };
  });

  const reloadProgram = (): void => {};

  let cantDo = [] as any[];

  const fetchCantDoExercises = async (): Promise<void> => {
    const userId = window.location.href.split("/")[5];
    const url = `${serverlessRoutes.EXERCISE}/cant-do-exercises?userId=${userId}&take=10&skip=0`;
    try {
      const res = await getWithJwt(url);
      $cantDoExercises.exercises = res.data.exercises.map((exercise: any) => ({
        ...exercise,
        details: JSON.parse(exercise.details),
      }));

      $cantDoExercises.exerciseIds = res.data.exercises.map(({ id }) => id);
    } catch (error) {
      console.error(error);
    }
  };

  onMount(async () => {
    if (!$program || !$program.workouts) {
      await fetchProgram();
    }

    if (
      window.location.href.includes("/profile") &&
      !isClient($user) &&
      !$cantDoExercises.exercises.length
    ) {
      await fetchCantDoExercises();
    }

    if (window.location.href.includes("/profile") && !isClient($user)) {
      const userId = window.location.href.split("/")[5];
      const workoutIds = $program.workouts.map((workout) => workout.id);

      const response = await getWithJwt(
        `${serverlessRoutes.WORKOUT}/cant-do-exercises?workoutIds=%5B${workoutIds.join(",")}%5D&userId=${userId}`
      );
      $cantDoExercises.workoutIds = [...$cantDoExercises.workoutIds, ...response.data.cantDo];

      cantDo = response.data.cantDo;
    }
  });

  const fetchProgram = async () => {
    try {
      const response = await getWithJwt(`${serverlessRoutes.PROGRAM}/${params.id}`);
      const programResponse = response.data;

      if (programResponse.default) {
        $trainerPrograms = $trainerPrograms.filter(({ id }) => id === +params.id);
        $trainerPrograms = [programResponse, ...$trainerPrograms];
      } else {
        $trainerClientPrograms = $trainerClientPrograms.filter(({ id }) => id === +params.id);
        $trainerClientPrograms = [programResponse, ...$trainerClientPrograms];
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onReloadProgram = (): void => {
    reloadProgram();
  };

  const onCreateTextProgram = (): void => {
    $dialogData.data = {
      programId: $program.id,
      executeFunction: reloadProgram,
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_TEXT_WOKROUT;
  };

  const onCreatePDFWorkout = (): void => {
    $dialogData.data = {
      programId: $program.id,
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_PDF_WORKOUT;
  };

  const onCreateRestDay = (): void => {
    $dialogData.data = {
      programId: $program.id,
      executeFunction: reloadProgram,
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_REST_DAY;
  };

  const onImportWorkout = (): void => {
    $dialogData.data = {
      programId: $program.id,
      position: $program?.workouts?.length,
    };
    $dialogData.type = dialogTypes.IMPORT_WORKOUTS;
  };

  const onCreateWorkout = (): void => {
    $dialogData.data = {
      programId: $program.id,
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_WORKOUT;
  };

  const onGenerateWorkout = async (): Promise<void> => {
    $dialogData.data = {
      programId: $program.id,
    };
    $dialogData.type = dialogTypes.GENERATE_WORKOUT;
  };

  const onGoBack = (): void => {
    pop();
  };

  const menuItems: MenuItem[] = [
    {
      icon: "user",
      title: "CLIENT_PROFILE",
      async executeFunction(): Promise<void> {
        await replace(`/profile/${$program.clientId}`);
      },
    },
    {
      icon: "assign",
      title: "ASSIGN_TO_CLIENTS",
      executeFunction(): void {
        $dialogData.data = { program };
        $dialogData.type = dialogTypes.ASSIGN_TO_CLIENTS;
      },
    },
    {
      icon: "check-square-offset",
      title: "SELECT_MORE",
      executeFunction(): void {},
    },
    {
      icon: "edit",
      title: "EDIT",
      executeFunction(): void {
        $dialogData.data = { program: $program };
        $dialogData.type = dialogTypes.CREATE_EDIT_PROGRAM;
      },
    },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction(): void {
        $dialogData.data = {
          title: $program.name,
          async executeFunction(): Promise<void> {
            try {
              await deleteWithJwt(serverlessRoutes.PROGRAM, {
                ids: [$program.id],
              });

              onGoBack();

              const p = $trainerPrograms.find((p) => p.id === $program.id);

              $trainerPrograms.splice($trainerPrograms.indexOf(p), 1);
              $trainerPrograms = $trainerPrograms;
              $showAlert.color = "black";
              $showAlert.message = `${translate("SUCCESSFULLY_DELETED")} program.`;
            } catch (error) {
              console.error(error);
              $showAlert.color = "red-400";
              $showAlert.message = `Greska pri brisanju programa.`;
            }
          },
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  const workoutsDndConsider = (event: CustomEvent<DndEvent<Item>>): void => {
    $program.workouts = event.detail.items;
  };

  const workoutsDndFinalize = async (event: CustomEvent<DndEvent<Workout>>): Promise<void> => {
    const partialWorkoutMap = getNewPositions(event.detail.items);

    if (!partialWorkoutMap) {
      return;
    }

    const { error, data } = await patchWithJwt(serverlessRoutes.WORKOUT, {
      partialWorkoutMap,
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_CHANGING_ORDER"), "error");
      return console.error(error);
    }

    $program.workouts = event.detail.items;

    alertStore.show(translate("SUCCESSFULLY_CHANGED_ORDER"));
  };

  export { params };
</script>

{#if !$program}
  <Spinner />
{:else}
  {#if Capacitor.getPlatform() === "ios"}
    <div class="mt-16" />
  {/if}
  <div class="mb-4 flex-col gap-4">
    <div class="relative flex justify-center text-white">
      <img class="h-64" src={$program.thumbnailUrl} alt="Thumbnail" />

      <div class="p-4 bg-black bg-opacity-50 absolute w-full h-full">
        <div class="flex items-center justify-between">
          <div on:click={() => onGoBack()}>
            <Svg name="left-caret" customColor="bg-white" size={24} />
          </div>
          <h3 class="text-xxl font-semibold">{$program.name}</h3>
          <div>
            <More myClasses="bg-white" {menuItems} />
          </div>
        </div>

        <div class="flex-col gap-4">
          {#if $program.duration}
            <p>Duration: {$program.duration} days</p>
          {/if}
          <!-- <div class="flex flex-wrap gap-2">
          {#each mealPlan.foodType as foodType}
            <Badge large rounded color=green>{foodType}</Badge>
          {/each}
        </div> -->
          {#if $program.description}
            <p>{$program.description}</p>
          {/if}
        </div>
      </div>
    </div>

    <div class="px-4 flex justify-between">
      <div class="flex flex-col items-start gap-4">
        <ButtonComponent on:click={onCreateTextProgram}
          >{translate("TYPE_TEXTUALLY")}</ButtonComponent
        >
        <!-- <ButtonComponent on:click={onCreatePDFWorkout}
          >{translate("UPLOAD_PDF")}</ButtonComponent
        > -->
        <ButtonComponent on:click={onCreateRestDay}>{translate("CREATE_REST_DAY")}</ButtonComponent>
      </div>
      <div class="flex flex-col items-end gap-4">
        <ButtonComponent on:click={onImportWorkout}>{translate("IMPORT_WORKOUT")}</ButtonComponent>
        <ButtonComponent on:click={onCreateWorkout}
          >{translate("CREATE")}
          {translate("WORKOUT").toLowerCase()}</ButtonComponent
        >
        <!-- <ButtonComponent on:click={onExportToPDF}
          >{translate("EXPORT_TO_PDF")}</ButtonComponent
        > -->{#if $user.id === 17833}
          <ButtonComponent on:click={onGenerateWorkout}
            >{translate("GENERATE_WORKOUT")}</ButtonComponent
          >
        {/if}
      </div>
    </div>

    <p class="text-center">
      {translate("TOTAL_NUMBER_OF_WORKOUTS")}:
      <!-- <b>{program.workouts.reduce((acc, meal) => meal.entityType === 1 ? acc : acc += 1, 0)}</b> -->
      <b>{$program?.workouts?.length}</b>
    </p>

    <!-- <div class=""> -->
    {#if $program?.workouts?.length > 0}
      <div
        class="px-4 flex flex-col gap-4"
        use:dragHandleZone={{
          items: $program.workouts,
          flipDurationMs: 0,
          dropTargetStyle: {},
          dropFromOthersDisabled: true,
          type: "workouts",
        }}
        on:consider={workoutsDndConsider}
        on:finalize={workoutsDndFinalize}
      >
        {#each $program.workouts as workout (workout.id)}
          <div animate:flip={{ duration: 333 }} class="outline-none">
            <WorkoutExpansionPanel
              cantDo={cantDo.includes(workout.id)}
              showComments={!$program.default}
              {workout}
              isDraggable
              on:reloadProgram={onReloadProgram}
            />
          </div>
        {/each}
      </div>
    {/if}
    <!-- </div> -->
  </div>
  {#if Capacitor.getPlatform() === "ios"}
    <div class="mb-2" />
  {/if}
{/if}
