<script lang="ts">
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";

  import { getWithJwt, patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";

  import {
    dialogData,
    showAlert,
    trainerExercisesStore,
    loadedWorkouts,
    workoutsStore,
  } from "stores";

  import { trainerPrograms } from "../../../stores/trainerStores";

  import {
    ButtonComponent,
    ButtonGroup,
    InputField,
    InfiniteScroll,
    SelectField,
    TextareaField,
  } from "ui";

  import ExercisesFilter from "../../../components/Training/ExercisesFilter.svelte";

  let data: any;

  const defaultSrc = "https://img.youtube.com/vi/null/hqdefault.jpg";
  const dialogType = data.exercise ? translate("EDIT") : translate("CREATE");
  const youtube = { src: "", name: "", description: "" };
  const exercise = { src: "", name: "", description: "" };

  let selectedExerciseId: number | null = null;
  let isLoading = false;
  let isYoutube = false;
  let isExpanded = false;
  let disabled = true;
  let scrollElement: HTMLDivElement;
  let youtubeId = "";
  let timeout: NodeJS.Timeout;
  let page = 0;

  // const selectedTypes = data.exercise
  //   ? data.exercise.muscleGroups
  //       .split(",")
  //       .map((tag: any) => reverseMuscleGroupRequest.get(tag))
  //   : [];
  const selectedTypes: Array<any> = [];

  const items = [
    { value: "Endurance", name: translate("ENDURANCE") },
    { value: "Strength", name: translate("STRENGTH") },
    { value: "Cardio", name: translate("CARDIO") },
    { value: "Timed Longer Better", name: translate("TIMED_LONGER_BETTER") },
    { value: "Timed Faster Better", name: translate("TIMED_FASTER_BETTER") },
    { value: "Timed Strength", name: translate("TIMED_STRENGTH") },
  ];

  const form = {
    youtube: { value: "", error: "" },
    exercise: { value: "", error: "" },
    name: { value: "", error: "" },
    type: { value: "Endurance", error: "" },
    reps: { value: "10", error: "" },
    sets: { value: 3, error: "" },
    rest: { value: "1 min", error: "" },
    time: { value: "3 min", error: "" },
    distance: { value: "500 m", error: "" },
    muscleGroups: { value: [] as Array<string>, error: "" },
    description: { value: "", error: "" },
  };

  const youtubeRegex =
    /^(?:https?:)?(?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed|shorts)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]{7,15})(?:[\?&][a-zA-Z0-9\_-]+=[a-zA-Z0-9\_-]+)*(?:[&\/\#].*)?$/;

  const onYoutubeScreen = (): void => {
    isYoutube = true;
    form.name.value = youtube.name;
    form.description.value = youtube.description;

    onInput("name");
    onInput("description");
  };

  const onExercisesScreen = (): void => {
    isYoutube = false;
    form.name.value = exercise.name;
    form.description.value = exercise.description;

    onInput("name");
    onInput("description");
  };

  const onExpandExercises = (): void => {
    isExpanded = true;
  };

  const onCollapseExercises = (): void => {
    isExpanded = false;
  };

  const onSelectExercise = async (item: any) => {
    isExpanded = false;

    try {
      const response = await getYoutubeData(item.youtubeId);

      youtubeId = response.id;

      exercise.src = response.snippet.thumbnails.medium.url;
      exercise.name = response.snippet.title;
      exercise.description = response.snippet.description;

      form.name.value = exercise.name;
      form.description.value = exercise.description;

      selectedExerciseId = item.id;

      onInput("name");
      onInput("description");
    } catch (error) {
      console.error(error);
    }
  };

  const updateExerciseFromProgramState = (exercise, updatedExercise) => {
    const index = $loadedWorkouts[exercise.workoutId].exercises.indexOf(exercise);

    $loadedWorkouts[exercise.workoutId].exercises.splice(index, 1, updatedExercise);
    $loadedWorkouts = $loadedWorkouts;
  };

  const onTypes = (event: CustomEvent<Array<string>>): void => {
    form.muscleGroups.value = event.detail;
  };

  const getYoutubeData = async (id: any): Promise<any> => {
    try {
      const response = await getWithJwt(`${serverlessRoutes.EXERCISE}/youtube-data/${id}`);
      return response.data.items[0];
    } catch (error) {
      console.error(error);
    }
  };

  const onInput = async (field: keyof typeof form): Promise<void> => {
    if (field === "youtube") {
      const isValid = youtubeRegex.test(form.youtube.value);

      if (isValid) {
        let id: string;

        if (form.youtube.value.includes("shorts")) {
          id = form.youtube.value.split("shorts/")[1];
        } else {
          id = form.youtube.value.split("watch?v=")[1];
        }

        try {
          const response = await getYoutubeData(id);

          youtubeId = response.id;

          youtube.src = response.snippet.thumbnails.medium.url;
          youtube.name = response.snippet.title;
          youtube.description = response.snippet.description;

          form.name.value = youtube.name;
          form.description.value = youtube.description;

          onInput("name");
          onInput("description");

          form.youtube.error = "";
        } catch (error) {
          console.error(error);
        }
      } else {
        form.youtube.error = translate("YOUTUBE_LINK_INVALID");
      }
    }
    if (field === "exercise") {
      clearTimeout(timeout);

      timeout = setTimeout(async (): Promise<void> => {
        $trainerExercisesStore.filter.search = form.exercise.value;
        await trainerExercisesStore.fetchData();
      }, 1000);
    }

    if (field === "name") {
      if (form.name.value.length === 0) {
        form.name.error = translate("FIELD_REQUIRED");
      } else if (form.name.value.length < 2) {
        form.name.error = translate("FIELD_MINIMUM_2");
      } else if (form.name.value.length > 320) {
        form.name.error = translate("FIELD_MAXIMUM_320");
      } else {
        form.name.error = "";
      }
    }

    if (field === "reps") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "sets") {
      if (form[field].value < 0) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_0");
      } else if (form[field].value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "rest") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "time") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "distance") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (form[field].value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    const formKeys = Object.keys(form) as Array<keyof typeof form>;

    // mora timeout jer mi treba form.type.value a on se refresuje posle onInput
    setTimeout((): void => {
      disabled = formKeys.some((key): boolean => {
        if (
          key === "reps" ||
          key === "sets" ||
          key === "rest" ||
          key === "time" ||
          key === "distance"
        ) {
          if (form.type.value === "Endurance" || form.type.value === "Strength") {
            if (key === "reps" || key === "sets" || key === "rest") {
              return form[key].error !== "";
            } else {
              return false;
            }
          } else if (form.type.value === "Cardio") {
            if (key === "time" || key === "distance") {
              return form[key].error !== "";
            } else {
              return false;
            }
          } else {
            if (key === "time" || key === "sets" || key === "rest") {
              return form[key].error !== "";
            } else {
              return false;
            }
          }
        } else {
          return form[key].error !== "";
        }
      });
    }, 100);
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    const name = form.name.value;
    const type = form.type.value;
    const reps = form.reps.value;
    const sets = form.sets.value;
    const rest = form.rest.value;
    const time = form.time.value;
    const distance = form.distance.value;

    // console.log(form.muscleGroups);
    // const muscleGroups = JSON.parse(form.muscleGroups.value).map((tag) =>
    //   muscleGroupRequestMap.get(tag)
    // ) || [];

    const description = form.description.value;

    let details = {};

    if (type === "Strength" || type === "Endurance") {
      details = { reps, sets, rest };
    } else if (type === "Cardio") {
      details = { time, distance };
    } else {
      details = { time, sets, rest };
    }

    try {
      if (!data.exercise && !data.workoutId) {
        // create
        const partialExercise = {
          name,
          type,
          youtubeId,
          description,
          details,
          // muscleGroups,
          default: 1,
          isTemplate: 0,
        };

        const { data: rData, error } = await postWithJwt(serverlessRoutes.EXERCISE, {
          partialExercise,
        });

        if (error && !rData) {
          return console.error(error);
        }

        rData.exercise.details = JSON.parse(rData.exercise.details);

        trainerExercisesStore.add([rData.exercise]);
      } else if (data.exercise && !data.workoutId) {
        // edit in exercises
        const partialExerciseMap = {
          [data.exercise.id]: {
            name,
            type,
            youtubeId,
            description,
            details,
            // muscleGroups,
            isTemplate: 0,
          },
        };

        const { data: rData, error } = await patchWithJwt(serverlessRoutes.EXERCISE, {
          partialExerciseMap,
        });

        if (error && !rData) {
          return console.error(error);
        }

        rData.exercises.forEach((exercise) => {
          exercise.details = JSON.parse(exercise.details);
        });

        trainerExercisesStore.replace(rData.exercises);
      } else if (!data.exercise && data.workoutId) {
        // import in workout
        if (!selectedExerciseId) {
          return;
        }
        const details = {
          time: form.time.value,
          sets: form.sets.value,
          rest: form.rest.value,
          reps: form.reps.value,
          distance: form.distance.value,
        };
        const response = await postWithJwt(`${serverlessRoutes.EXERCISE}/copy/v2`, {
          partialExerciseMap: {
            [selectedExerciseId]: {
              workoutId: data.workoutId,
              parentExerciseId: selectedExerciseId,
              position: data.position,
              details: JSON.stringify(details),
              default: 0,
              isTemplate: 0,
              trainerId: null,
            },
          },
          // exerciseIds: [selectedExerciseId],
          // propertiesToOverride: {
          //   // name,
          //   // type,
          //   // youtubeId,
          //   // description,
          //   // details,
          //   // muscleGroups,
          //   workoutId: data.workoutId,
          //   parentExerciseId: selectedExerciseId,
          //   position: data.position,
          //   details: JSON.stringify(details),
          //   default: 0,
          //   isTemplate: 0,
          //   trainerId: null,
          // },
        });

        if (data.programId) {
          // inside program
          const _program = $trainerPrograms.find(
            (_program): boolean => _program.id === data.programId
          );

          if (_program?.workouts) {
            const _workout = _program.workouts.find(
              (_workout: any): boolean => _workout.id === data.workoutId
            );

            _workout.exercises.push(response);
            data.executeFunction();
          }
        } else {
          workoutsStore.addExercises(data.workoutId, response.data);
        }
      } else {
        // edit in workout
        const id = data.exercise.id;
        const response = await patchWithJwt(serverlessRoutes.EXERCISE, {
          partialExerciseMap: {
            [id]: {
              name,
              type,
              youtubeId,
              description,
              details,
              parentExerciseId: selectedExerciseId,
            },
          },
        });

        // const updatedExercise = response.data.exercises[0];
        // updatedExercise.details = JSON.parse(updatedExercise.details);
        // updateExerciseFromProgramState(data.exercise, updatedExercise);
        workoutsStore.replaceExercises(data.workoutId, updatedExercise);
      }

      $showAlert.color = "black";

      if (data.exercise) {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_EDITED")} ${translate("EXERCISE")}
        `;
      } else {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_CREATED")} ${translate("EXERCISE")}
        `;
      }

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";

      if (data.exercise) {
        $showAlert.message = translate("ERROR_EDITING_EXERCISE");
      } else {
        $showAlert.message = translate("ERROR_CREATING_EXERCISE");
      }
    }
  };

  const mountScrollElement = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  onMount(async (): Promise<void> => {
    if (data.exercise) {
      isYoutube = false;

      const { name, type, muscleGroups, description, details } = data.exercise;

      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (type) {
        form.type.value = type;
        onInput("type");
      }

      if (muscleGroups) {
        form.muscleGroups.value = muscleGroups;
        onInput("muscleGroups");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }

      if (details) {
        const detailKeys = Object.keys(data.exercise.details) as [
          "time",
          "sets",
          "rest",
          "reps",
          "distance",
        ];

        detailKeys.forEach((key): void => {
          form[key].value = data.exercise.details[key];
          onInput(key);
        });
      }

      try {
        const response = await getYoutubeData(data.exercise.youtubeId);

        youtubeId = response.id;

        exercise.src = response.snippet.thumbnails.medium.url;
        exercise.name = response.snippet.title;
        exercise.description = response.snippet.description;
      } catch (error) {
        console.error(error);
      }
    } else {
      isYoutube = false;
    }

    await trainerExercisesStore.fetchData();
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {dialogType}
    {translate("EXERCISE_U").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    {#if !data.workoutId}
      <div class="flex justify-center">
        <ButtonGroup
          selectedIndex={isYoutube ? 0 : 1}
          buttons={[
            {
              title: "Youtube",
              onClick: onYoutubeScreen,
              cy: "youtube-button",
            },
            {
              title: translate("MY_EXERCISES"),
              onClick: onExercisesScreen,
              cy: "my-exercises-button",
            },
          ]}
        />
      </div>
    {/if}

    {#if isYoutube}
      <InputField
        label={translate("YOUTUBE_LINK")}
        error={form.youtube.error}
        bind:value={form.youtube.value}
        on:input={() => onInput("youtube")}
      />
    {:else}
      <div>
        <InputField
          label={translate("EXERCISE")}
          error=""
          bind:value={form.exercise.value}
          on:input={() => onInput("exercise")}
          on:click={onExpandExercises}
        />
        <!-- <Label for="exercise">{translate("EXERCISE")}:</Label>
        <Input
          id="exercise"
          placeholder={translate("EXERCISE")}
          bind:value={form.exercise.value}
          on:input={() => onInput("exercise")}
          on:click={onExpandExercises}
        /> -->

        {#if isExpanded}
          <div class="mt-2 p-2 flex flex-col gap-4 border rounded-md" in:slide out:slide>
            <ExercisesFilter />

            <div class="h-64 flex flex-col gap-2 overflow-y-scroll" use:mountScrollElement>
              {#each $trainerExercisesStore.items as exercise}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div
                  class="flex items-center gap-2 border rounded-md bg-slate-100 dark:bg-zinc-800 dark:border-zinc-500"
                  on:click={() => onSelectExercise(exercise)}
                >
                  <img
                    class="h-12 rounded-l-md"
                    src="https://i.ytimg.com/vi/{exercise.youtubeId}/mqdefault.jpg"
                    alt="Youtube"
                  />
                  <div class="text-xs">{exercise.name}</div>
                </div>
              {/each}

              <InfiniteScroll
                {scrollElement}
                hasMore={$trainerExercisesStore.hasMore}
                isFetchingMore={$trainerExercisesStore.isFetchingMore}
                on:loadMore={trainerExercisesStore.fetchMoreData}
              />
            </div>

            <div class="flex justify-center">
              <ButtonComponent isOutline on:click={onCollapseExercises}>
                {translate("CLOSE")}
              </ButtonComponent>
            </div>
          </div>
        {/if}
      </div>
    {/if}

    {#if youtubeId || selectedExerciseId}
      {#if isYoutube}
        {#if youtube.src}
          <img src={youtube.src} alt="Exercise" />
        {:else}
          <img src={defaultSrc} alt="Exercise" />
        {/if}
      {:else if exercise.src}
        <img src={exercise.src} alt="Exercise" />
      {:else}
        <img src={defaultSrc} alt="Exercise" />
      {/if}

      <InputField
        label={translate("NAME")}
        error={form.name.error}
        bind:value={form.name.value}
        on:input={() => onInput("name")}
      />

      <SelectField label={translate("TYPE")} {items} bind:value={form.type.value} />

      <div class="flex gap-4">
        {#if form.type.value === "Strength" || form.type.value === "Endurance"}
          <InputField
            label={translate("REPETITION")}
            error={form.reps.error}
            bind:value={form.reps.value}
            on:input={() => onInput("reps")}
          />

          <InputField
            label={translate("SERIES")}
            type="number"
            error={form.sets.error}
            bind:value={form.sets.value}
            on:input={() => onInput("sets")}
          />

          <InputField
            label={translate("BREAK")}
            error={form.rest.error}
            bind:value={form.rest.value}
            on:input={() => onInput("rest")}
          />
        {:else if form.type.value === "Cardio"}
          <InputField
            label={translate("TIME")}
            error={form.time.error}
            bind:value={form.time.value}
            on:input={() => onInput("time")}
          />

          <InputField
            label={translate("DISTANCE")}
            error={form.distance.error}
            bind:value={form.distance.value}
            on:input={() => onInput("distance")}
          />
        {:else if form.type.value === "Timed Longer Better" || form.type.value === "Timed Faster Better" || form.type.value === "Timed Strength"}
          <InputField
            label={translate("TIME")}
            error={form.time.error}
            bind:value={form.time.value}
            on:input={() => onInput("time")}
          />

          <InputField
            label={translate("SERIES")}
            type="number"
            error={form.sets.error}
            bind:value={form.sets.value}
            on:input={() => onInput("sets")}
          />

          <InputField
            label={translate("BREAK")}
            error={form.rest.error}
            bind:value={form.rest.value}
            on:input={() => onInput("rest")}
          />
        {/if}
      </div>

      <!-- <SetEditTypeComponent
        types={[
          "Trbuh",
          "Biceps",
          "Listovi",
          "Grudi",
          "Podlaktica",
          "Zadnjica",
          "Zadnja loža",
          "Donja leđa",
          "Prednja loža",
          "Ramena",
          "Triceps",
          "Gornja leđa",
        ]}
        {selectedTypes}
        on:types={onTypes}
      /> -->

      <TextareaField
        label={translate("DESCRIPTION")}
        error={form.description.error}
        bind:value={form.description.value}
        on:input={() => onInput("description")}
      />

      <div class="h-10 flex justify-center">
        <ButtonComponent cy="create-edit-exercise-button" type="submit" {disabled} {isLoading}>
          {dialogType}
        </ButtonComponent>
      </div>
    {/if}
  </form>
</div>
