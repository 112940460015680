<script lang="ts">
  import { onMount } from "svelte";
  import { dialogTypes, getWithJwt, serverlessRoutes, translate } from "lib";
  import { dialogData } from "stores";
  import { ButtonComponent, ExpansionPanel, More } from "ui";

  let ingredientTypes: Array<any> = [];

  const menuItems = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {},
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: translate("INGREDIENT_U"),
          async executeFunction(): Promise<void> {},
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  const onCreateIngredientType = (): void => {};

  const fetchIngredientTypes = async (): Promise<void> => {
    try {
      const result = await getWithJwt(`${serverlessRoutes.INGREDIENT}/food_types`);
      ingredientTypes = result.data;
    } catch (error) {
      console.error(error);
    }
  };

  onMount(fetchIngredientTypes);
</script>

<ExpansionPanel
  title={translate("INGREDIENT_TYPES")}
  subtitle="{translate('NUMBER_OF_ITEMS')}: {ingredientTypes.length}"
>
  <div class="flex flex-col gap-4">
    <div class="text-xs text-justify">
      {translate("SETTINGS_INGREDIENT_TYPES_NOTE")}
    </div>
    <div class="flex flex-col gap-2">
      {#each ingredientTypes as ingredientType}
        <div class="flex items-center justify-between">
          <div class="text-xs">{ingredientType}</div>
          <More {menuItems} />
        </div>
      {/each}
    </div>
    <div class="flex justify-center">
      <ButtonComponent isOutline on:click={onCreateIngredientType}>
        {translate("CREATE_TYPE")}
      </ButtonComponent>
    </div>
  </div>
</ExpansionPanel>
