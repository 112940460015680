<!-- <script lang="ts">
  import { translate } from "lib";
  import { onMount } from "svelte";
  import "cordova-plugin-purchase"; // This seems to add `CdvPurchase` to the global scope.

  onMount(() => {
    document.addEventListener(
      "deviceready",
      function () {
        init();
      },
      false
    );
  });

  function init() {
    const { store, ProductType, Platform } = CdvPurchase;

    store.verbosity = store.DEBUG;

    store.register([
      {
        type: ProductType.CONSUMABLE,
        id: "com.strongman.app.subscription",
        platform: Platform.APPLE_APPSTORE,
      },
    ]);

    store.error((e) => {
      console.log("error", e);
    });

    store
      .when()
      .productUpdated((product) => {
        console.log("product updated", product);
        alert(`store.productUpdated: ${JSON.stringify(product)}`);
      })
      .approved((value) => {
        console.log("approved", value);
      })
      .verified((value) => {
        console.log("verified", value);
      })
      .finished((value) => {
        console.log("finished", value);
      });

    store.ready(() => {
      console.log("ready", store.products);
      alert(`store.ready: ${JSON.stringify(store.products)}`);

      // store.order("subscription");
    });

    store.initialize(Platform.APPLE_APPSTORE).then(() => {
      console.log("initialize resolved", store.products);
      alert(`store.initialize: ${JSON.stringify(store.products)}`);
      // store.order("subscription");
    });
  }
</script>

<main>
  <h1>{translate("SUBSCRIPTION")}</h1>
</main> -->
<script lang="ts">
  import { onMount } from "svelte";
  import "cordova-plugin-purchase";

  onMount(() => {
    document.addEventListener("deviceready", onDeviceReady, false);
  });

  function onDeviceReady() {
    const store = CdvPurchase.store;
    const { ProductType, Platform, LogLevel, TransactionState } = CdvPurchase;

    // Register your iOS product – ensure the product id exactly matches your App Store Connect setup.
    store.register([
      {
        id: "com.strongman.app.test", // Update with your actual product id
        type: ProductType.NON_CONSUMABLE,
        platform: Platform.APPLE_APPSTORE,
      },
    ]);

    store.verbosity = LogLevel.DEBUG;

    // Optionally, set an application username if needed
    // store.applicationUsername = () => "my_username_2";

    // Handle errors
    store.error(errorHandler);

    // Define event handlers for product state changes.
    store
      .when()
      .productUpdated((object) => {
        console.log("Updated: " + JSON.stringify(object));
        renderUI();
      })
      .approved((transaction) => {
        // Verify approved transactions
        store.verify(transaction);
      })
      .verified((receipt) => {
        // Finish transactions after verification
        store.finish(receipt);
        renderUI();
      });

    // Initialize only for the Apple App Store.
    store.initialize([Platform.APPLE_APPSTORE]).then(() => {
      console.log("Store is initialized", store.products);
      renderUI();
    });
  }

  // Render the UI based on product status.
  function renderUI() {
    const store = CdvPurchase.store;
    const statusElement = document.getElementById("status");
    const productsElement = document.getElementById("products");
    if (!statusElement || !productsElement) return;

    // Filter for subscription products.
    const subscriptions = store.products.filter(
      (p) => p.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION
    );

    // Update status display.
    if (isOwned(subscriptions)) statusElement.textContent = "Subscribed";
    else if (isApproved(subscriptions) || isInitiated(subscriptions))
      statusElement.textContent = "Processing...";
    else statusElement.textContent = "Not Subscribed";

    // Render the products if they have valid offers.
    const validProducts = store.products.filter(
      (product) => product.offers && product.offers.length > 0
    );
    productsElement.innerHTML = validProducts
      .map(
        (product) =>
          `<div id="${product.id}-purchase" style="margin-top: 30px">
            <strong>${product.title || product.id}</strong>
            <button onclick="orderOffer('${CdvPurchase.Platform.APPLE_APPSTORE}', '${product.id}', '${product.offers[0].id}')">
              Buy
            </button>
          </div>`
      )
      .join("");
  }

  // Helper functions for checking purchase states.
  function findVerifiedPurchase(products) {
    const store = CdvPurchase.store;
    for (const product of products) {
      const purchase = store.findInVerifiedReceipts(product);
      if (!purchase) continue;
      if (!purchase.isExpired) return purchase;
    }
  }

  function findLocalTransaction(products, filter) {
    const store = CdvPurchase.store;
    for (const product of products) {
      const transaction = store.findInLocalReceipts(product);
      if (!transaction) continue;
      if (filter(transaction)) return transaction;
    }
  }

  function isOwned(products) {
    return !!findVerifiedPurchase(products);
  }

  function isApproved(products) {
    return !!findLocalTransaction(
      products,
      (t) => t.state === CdvPurchase.TransactionState.APPROVED
    );
  }

  function isInitiated(products) {
    return !!findLocalTransaction(
      products,
      (t) => t.state === CdvPurchase.TransactionState.INITIATED
    );
  }

  // Order a product offer.
  function orderOffer(platform, productId, offerId) {
    const store = CdvPurchase.store;
    const offer = store.get(productId, platform)?.getOffer(offerId);
    if (offer) store.order(offer);
  }

  // Error handler to display errors on the UI.
  function errorHandler(error) {
    const errorElement = document.getElementById("error");
    if (!errorElement) return;
    errorElement.textContent = `ERROR ${error.code}: ${error.message}`;
    setTimeout(() => {
      errorElement.innerHTML = "<br/>";
    }, 10000);
  }
</script>

<main class="mt-32">
  <h1>In-App Purchases (iOS)</h1>
  <div id="status" style="margin-bottom: 20px;"></div>
  <div id="products"></div>
  <div id="error" style="color: red; margin-top: 20px;"></div>
</main>
