<script lang="ts">
  import { DatePicker } from "date-picker-svelte";
  import { slide } from "svelte/transition";
  import { Svg } from "ui";

  let passwordInputElement: HTMLInputElement;
  let datePickerElement: HTMLInputElement;
  let passwordIcon = "eye-slash";

  let type: "text" | "email" | "password" | "date" | "number" = "text";
  let label: string;
  let placeholder = "";
  let disabled = false;
  let value: string | number;
  let error = "";
  let hint = "";
  let step = "1";

  let suffix = "";

  let min = "1950-01-01";
  let max = "2030-01-01";

  let className = `
    block w-full dark:bg-zinc-700 rounded-md border-0 py-2 px-4 ${suffix || type === "password" ? "pr-12" : ""} text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6
  `;

  const onTogglePasswordVisible = (): void => {
    if (passwordInputElement.type === "password") {
      passwordInputElement.type = "text";
      passwordIcon = "eye";
    } else {
      passwordInputElement.type = "password";
      passwordIcon = "eye-slash";
    }
  };

  export let parsedDates: Array<string> = [];

  const repaintDates = (curMonth: string, curYear: string) => {
    Array.from(document.getElementsByClassName("cell")).forEach((item) => {
      // if (!item) { return; }

      const curMonthInt = parseInt(curMonth);
      const curDateInt = parseInt(item.textContent || "0");
      const curDate = item.textContent.length > 1 ? curDateInt.toString() : `0${curDateInt}`;
      const dateString = `${curYear}-${curMonth.length > 1 ? curMonthInt + 1 : `0${curMonthInt + 1}`}-${curDate}`;

      if (!item.classList.contains("other-month") && parsedDates.includes(dateString)) {
        item.classList.add("date-highlighter");
      } else {
        item.classList.remove("date-highlighter");
      }
    });
  };

  let unsubMonthSelect: () => void;
  let unsubYearSelect: () => void;
  let unsubPrevButton: () => void;
  let unsubNextButton: () => void;

  const unsubDatePicker = (): void => {
    unsubMonthSelect();
    unsubYearSelect();
    unsubPrevButton();
    unsubNextButton();
  };

  let curMonth = "0";
  let curYear = "0";

  const highlightDates = () => {
    // mora timeout jer kad se pozove ova funkcija elementi jos uvek nisu renderovani u dom
    setTimeout(() => {
      const monthSelectElement =
        document.querySelector<HTMLSelectElement>(".dropdown.month select");
      const yearSelectElement = document.querySelector<HTMLSelectElement>(".dropdown.year select");
      const prevButtonElement = document.querySelector<HTMLButtonElement>(
        'button[aria-label="Previous month"]'
      );
      const nextButtonElement = document.querySelector<HTMLButtonElement>(
        'button[aria-label="Next month"]'
      );

      if (monthSelectElement) {
        curMonth = monthSelectElement.value;

        const onChange = (event: Event): void => {
          curMonth = (event.target as HTMLSelectElement).value;
          repaintDates((event.target as HTMLSelectElement).value, curYear);
        };
        monthSelectElement.addEventListener("change", onChange);
        unsubMonthSelect = () => monthSelectElement.removeEventListener("change", onChange);
      }
      if (yearSelectElement) {
        curYear = yearSelectElement.value;

        const onChange = (event: Event): void => {
          curYear = (event.target as HTMLSelectElement).value;
          repaintDates(curMonth, (event.target as HTMLSelectElement).value);
        };
        yearSelectElement.addEventListener("change", onChange);
        unsubYearSelect = () => yearSelectElement.removeEventListener("change", onChange);
      }
      const onClick = () => {
        curMonth = monthSelectElement?.value || "0";
        curYear = yearSelectElement?.value || "0";
        repaintDates(curMonth, curYear);
      };
      if (prevButtonElement) {
        prevButtonElement.addEventListener("click", onClick);
        unsubPrevButton = () => prevButtonElement.removeEventListener("click", onClick);
      }
      if (nextButtonElement) {
        nextButtonElement.addEventListener("click", onClick);
        unsubNextButton = () => nextButtonElement.removeEventListener("click", onClick);
      }

      repaintDates(curMonth, curYear);
    }, 100);
  };

  const onSelectDate = (event: any): void => {
    isCalendarVisible = false;
    datePickerElement.value = event.detail.toISOString().slice(0, 10);
    datePickerElement.dispatchEvent(new Event("input", { bubbles: true }));
  };

  let isCalendarVisible = false;

  const onShowDatePicker = (): void => {
    isCalendarVisible = !isCalendarVisible;

    if (isCalendarVisible) {
      highlightDates();
    } else {
      unsubDatePicker();
    }
  };

  const onCloseDatePicker = (): void => {
    isCalendarVisible = false;
    unsubDatePicker();
  };

  export { type, label, placeholder, error, hint, disabled, value, suffix, min, max };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="relative pb-4 flex flex-col" on:click>
  <!-- {#if label && label !== ""} -->
  <label
    for={label}
    class="h-4 ml-1 text-xs flex items-center leading-none font-medium text-gray-900 dark:text-gray-100"
  >
    {label}:
  </label>
  <!-- {/if} -->
  <!-- <div class={`relative rounded-md shadow-sm ${label !== "" ? "mt-2" : ""}`}> -->
  {#if type === "password"}
    <input
      {disabled}
      class={className}
      type="password"
      id={label}
      placeholder={placeholder || label}
      autocomplete="on"
      bind:this={passwordInputElement}
      bind:value
      on:input
    />
    <div class="absolute top-[50%] right-4 translate-y-[-50%]">
      <Svg name={passwordIcon} size={16} on:click={onTogglePasswordVisible} />
    </div>
  {:else if type === "email"}
    <input
      bind:value
      {disabled}
      type="email"
      id={label}
      autocomplete="email"
      class={className}
      placeholder={placeholder || label}
      on:input
    />
  {:else if type === "date"}
    {#if isCalendarVisible}
      <div
        class="fixed top-0 left-0 w-full h-full z-[49]"
        style="background-color: rgba(0, 0, 0, 0.66)"
        on:click={onCloseDatePicker}
      ></div>
      <div class="fixed z-50 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <DatePicker
          value={value ? new Date(value) : new Date()}
          min={new Date(min)}
          max={new Date(max)}
          on:select={onSelectDate}
        />
      </div>
    {/if}
    <input
      class={className}
      type="text"
      id={label}
      {min}
      {max}
      bind:this={datePickerElement}
      bind:value
      {disabled}
      on:input
      on:click|preventDefault={onShowDatePicker}
    />
  {:else if type === "number"}
    <input
      type="number"
      class={className}
      id={label}
      placeholder={placeholder || label}
      step="0.1"
      bind:value
      on:input
    />
  {:else}
    <input
      bind:value
      {disabled}
      type="text"
      id={label}
      autocomplete="off"
      class={className}
      placeholder={placeholder || label}
      on:input
    />
  {/if}
  {#if suffix}
    <div class="absolute top-[50%] right-4 translate-y-[-50%]">
      {suffix}
      <!-- <Svg name={passwordIcon} size={16} on:click={onTogglePasswordVisible}/> -->
    </div>
  {/if}
  <!-- </div> -->
  {#if error}
    <div
      in:slide
      out:slide
      class="h-4 ml-1 flex items-center leading-none absolute bottom-0 left-0 text-xs text-red-500"
    >
      {error}
    </div>
  {:else}
    <div
      in:slide
      out:slide
      class="h-4 ml-1 flex items-center leading-none absolute bottom-0 left-0 text-xs"
    >
      {hint}
    </div>
  {/if}
</div>

<style>
  :global(.date-time-picker) {
    width: 288px;
    border-radius: 8px !important;
    padding: 16px !important;
    padding-top: 20px !important;
  }

  :global(.date-time-picker select) {
    box-shadow: none !important;
  }

  :global(.date-time-picker option) {
    background-color: var(--date-picker-background);
    color: var(--date-picker-foreground);
  }
</style>
