<script>
  import { entities, getWithJwt, serverlessRoutes, translate } from "lib";
  import TopMenu from "../../components/TopMenu.svelte";
  import { onMount } from "svelte";
  import ExpansionPanel from "../../components/UI/ExpansionPanel.svelte";
  import ClientListItem from "../../components/ClientListItem.svelte";

  let data = [];
  let selectDayData = {};

  const fetchData = async () => {
    try {
      const res = await getWithJwt(`${serverlessRoutes.REMINDERS}/list`);
      data = res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchForDay = async (date) => {
    try {
      const res = await getWithJwt(`${serverlessRoutes.REMINDERS}/${date}`);
      const dateObject = new Date(date);
      dateObject.setDate(dateObject.getDate() + 1);
      selectDayData[date] = res.data;
    } catch (err) {
      console.error(err);
    }
  };

  onMount(() => {
    fetchData();
  });
</script>

<TopMenu entity={entities.NOTIFICATIONS} />
<div class="gap-4">
  {#each data as reminder}
    <div
      class="m-4"
      tabindex="0"
      role="button"
      on:click={() => fetchForDay(reminder.items[0].date.slice(0, 10))}
      on:keypress={() => fetchForDay(reminder.items[0].date.slice(0, 10))}
    >
      <ExpansionPanel
        title={`${translate("REMINDER_IN")} ${+reminder.daysFromToday === 0 ? translate("TODAY").toLowerCase() : +reminder.daysFromToday === 1 ? translate("TOMORROW").toLowerCase() : `${translate("IN")}: ${reminder.daysFromToday} ${translate("DAYS").toLowerCase()}`}`}
        subtitle={`${translate("ITEM_COUNT")}: ${reminder.items.length}`}
      >
        {#if selectDayData[reminder.items[0].date.slice(0, 10)]}
          {#each selectDayData[reminder.items[0].date.slice(0, 10)] as item}
            <ClientListItem client={item} />
            <div class="my-2">
              <p>{translate("REMINDER")}: <strong>{item.note}</strong></p>
            </div>
          {/each}
        {/if}
      </ExpansionPanel>
    </div>
  {/each}
</div>
