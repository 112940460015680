<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import FastingPanel from "../../components/Diet/FastingPanel.svelte";
  import { ButtonComponent } from "ui";
  import TopMenu from "../../components/TopMenu.svelte";
  import Gauge from "../../components/UI/Gauge.svelte";
  import { entities, serverlessRoutes } from "../../lib/constants";
  import { getWithJwt, patchWithJwt, postWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { dialogData } from "../../stores/dialogDataStore";
  import { user } from "../../stores/userStore";
  import { clientFasts } from "../../stores/clientStores";
  import { showAlert } from "../../stores/showAlertStore";
  import FastingDetails from "../../components/Diet/FastingDetails.svelte";
  import { App } from "@capacitor/app";
  import { Capacitor } from "@capacitor/core";

  let detailedView: boolean = false;
  let fastingStats;
  let currentFast;
  let interval;

  $: fetchFastingStats(detailedView);

  const fetchData = async () => {
    const url = `${serverlessRoutes.FASTING}?clientId=${$user.id}&take=15&skip=0`;
    try {
      const data = await getWithJwt(url);
      $clientFasts = data.data;
      $clientFasts.forEach((fast) => {
        if (fast.to === null) {
          currentFast = fast;
          if (!interval) interval = setInterval(() => fetchData(), 60 * 1000);
        }
      });
    } catch (err) {}
  };

  const fetchFastingStats = async (bool: boolean) => {};

  const startFasting = async (goalMinutes: number) => {
    try {
      const url = serverlessRoutes.FASTING;
      const res = await postWithJwt(url, { goalMinutes });
      currentFast = res.data;
      $dialogData = {};
      $showAlert.message = `${translate("YOU_STARTED")} fasting.`;
    } catch (err) {}
  };

  const stopFasting = async () => {
    try {
      const url = serverlessRoutes.FASTING;
      const data = {
        id: currentFast.id,
        to: new Date().toISOString(),
      };
      const res = await patchWithJwt(url, { partialFast: data });
      currentFast = null;
      clearInterval(interval);
      $clientFasts = [res.data, ...$clientFasts];
      $showAlert.message = `${translate("SUCCESSFULLY_COMPLETED")} fast.`;
    } catch (err) {}
  };
  onDestroy(() => {
    clearInterval(interval);
  });

  onMount(() => {
    fetchData();
    App.addListener("appStateChange", ({ isActive }) => {
      if (isActive) {
        fetchData();
      }
    });
  });
</script>

<TopMenu entity={entities.DIET} />
<div
  class="p-4 flex flex-col justify-center items-center bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
>
  <div class="mt-5" />
  <Gauge
    width={256}
    complete={currentFast ? (currentFast.totalMinutesFasted / currentFast.goalMinutes) * 100 : 0}
  />
  <div style="margin-top: -48px">
    <span class="text-4xl font-medium"
      >{currentFast
        ? Math.floor((currentFast.totalMinutesFasted / currentFast.goalMinutes) * 100)
        : 0}</span
    ><span class="text-2xl">%</span>
  </div>
  <div class="mt-4" />
  {#if !currentFast}
    <span class="text-xl">16h 00m</span>
  {/if}
  {#if currentFast}
    <span class="text-xl">{currentFast.totalTimeFasted} / {currentFast.formattedGoal}</span>
  {/if}
  <div class="my-5">
    {#if !currentFast}
      <ButtonComponent
        on:click={() => {
          $dialogData.type = entities.FASTING;
          $dialogData.data = { executeFunction: startFasting };
        }}>{translate("START_FASTING")}</ButtonComponent
      >
    {/if}
    {#if currentFast}
      <ButtonComponent on:click={() => stopFasting()}>{translate("STOP_FASTING")}</ButtonComponent>
    {/if}
  </div>
  <!-- <div class="w-full">
    <div class="m-4">
      <Toggle bind:checked={detailedView}>{translate("DETAILED_VIEW")}</Toggle>
    </div>
  </div> -->
  <div class="w-full flex flex-col gap-4">
    {#if !detailedView}
      {#each $clientFasts as fast}
        {#if fast.to !== null}
          <FastingPanel
            fastId={fast.id}
            totalTimeFasted={fast.totalTimeFasted}
            date={fast.to.slice(0, 10)}
          />
        {/if}
      {/each}
    {:else}
      <FastingDetails {fastingStats} />
    {/if}
  </div>
  {#if Capacitor.isNativePlatform()}
    <div class="mb-12" />
  {/if}
</div>
