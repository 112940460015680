<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { getWithJwt, serverlessRoutes, translate } from "lib";
  import { Badge, ButtonComponent, CheckboxComponent, InputField } from "ui";

  const dispatch = createEventDispatcher();

  interface Type {
    name: string;
    checked: boolean;
  }

  let selectedTypes: Array<string> = [];
  let types: Array<Type> = [];
  let isEditVisible = false;
  let entity: "ingredient" | "recipe" | "mealPlan";
  let value: string;

  const form = {
    newType: { value: "", error: "" },
  };

  const urls = {
    ingredient: `${serverlessRoutes.INGREDIENT}/food_types`,
    recipe: `${serverlessRoutes.RECIPE}/food_types`,
    mealPlan: null,
  };

  $: disabled = form.newType.value ? false : true;
  $: isTypeSelected = types.some(({ checked }): boolean => checked);

  const getSelectedTypes = (): Array<string> =>
    types.filter(({ checked }): boolean => checked).map(({ name }) => name);

  const onInsertType = (): void => {
    types = [
      ...types,
      {
        name: form.newType.value,
        checked: true,
      },
    ];

    dispatch("types", getSelectedTypes());
    value = getSelectedTypes().join();

    form.newType.value = "";
  };

  const onSelectType = (): void => {
    dispatch("types", getSelectedTypes());
    value = getSelectedTypes().join();
  };

  const onToggle = (): void => {
    isEditVisible = !isEditVisible;
  };

  onMount(async (): Promise<void> => {
    if (urls[entity]) {
      const response = await getWithJwt(urls[entity]);
      types = response.map((name: any) => ({ name, checked: false }));

      types.forEach((type): void => {
        if (selectedTypes.includes(type.name)) {
          type.checked = true;
        }
      });
    }
  });

  export { entity, selectedTypes, value };
</script>

<div>
  <div class="h-4 flex items-center leading-none text-xs font-medium">Tip:</div>

  <div
    class="flex flex-col gap-2 ring-1 ring-inset ring-gray-300 dark:ring-slate-600 dark:bg-zinc-700 rounded-md"
    class:ring-2={isEditVisible}
    class:ring-primary-600={isEditVisible}
  >
    {#if types.length}
      <div class="p-2 flex flex-wrap gap-2">
        {#each types as { name, checked }}
          {#if checked}
            <Badge>{name}</Badge>
          {/if}
        {/each}
      </div>
    {/if}

    {#if isEditVisible}
      <div class="p-4 pt-0 flex flex-col gap-2" in:slide out:slide>
        <div class="flex flex-col gap-2">
          {#each types as { name, checked }}
            <CheckboxComponent label={name} bind:value={checked} on:change={onSelectType} />
          {/each}
        </div>

        <div class="flex items-center gap-2">
          <div class="grow">
            <InputField label={translate("NEW_TYPE")} error={""} bind:value={form.newType.value} />
          </div>
          <ButtonComponent {disabled} on:click={onInsertType}>
            {translate("ADD")}
          </ButtonComponent>
        </div>
      </div>
    {/if}

    <div class="flex justify-center pb-2">
      <ButtonComponent isOutline on:click={onToggle}>
        {isEditVisible
          ? translate("CLOSE")
          : isTypeSelected
            ? translate("EDIT_TYPE")
            : translate("SET_TYPE")}
      </ButtonComponent>
    </div>
  </div>
</div>
