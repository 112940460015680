<script lang="ts">
  import { trainerChatWith } from "../../stores/trainerStores";
  import { parseDateWithTime } from "../../lib/parseDateWithTime";
  import { notSeenSessionIds } from "../../stores/chatSessionsStore";
  import Avatar from "../UI/Avatar.svelte";
  import SeenButton from "../SeenButton.svelte";
  import { patchWithJwt, serverlessRoutes, translate, storage } from "lib";
  import Svg from "../UI/Svg.svelte";
  import { onlineUsers } from "stores/onlineUsersStore";

  export let session;

  $: unread = $notSeenSessionIds?.find((notSeenClientId) => notSeenClientId === session.sessionId);

  const route = async () => {
    $trainerChatWith = {
      clientId: session.clientId,
      clientName: session.clientName,
      avatarUrl: session.avatarUrl,
      sessionId: session.sessionId,
    };
    await storage.set("trainerChatWith", $trainerChatWith);
  };

  const toggleSeen = async () => {
    const url = `${serverlessRoutes.SESSION}/set-seen`;
    try {
      const updateObject = {
        sessionId: session.sessionId,
        notSeen: unread ? 0 : 1,
      };
      await patchWithJwt(url, updateObject);
    } catch (err) {}
  };

  const getLastMessage = (lastMsg: string) => {
    if (lastMsg?.includes("imagedelivery")) {
      return "Je poslao sliku";
    } else if (lastMsg?.includes(":video:")) {
      return "Je poslao video";
    } else if (lastMsg?.includes("{DELETED_MESSAGE}")) {
      return translate("DELETED_MESSAGE");
    } else if (lastMsg) {
      return lastMsg;
    } else {
      return "Je poslao poruku";
    }
  };
</script>

<a
  href={`/#/chat`}
  on:click={route}
  class={`link ${unread ? "bg-slate-50 dark:bg-zinc-700" : "bg-white dark:bg-zinc-800"}`}
>
  <div class="flex-row center-space-between">
    <div class="flex-row center-center">
      <Avatar avatarUrl={session.avatarUrl} alt="User Avatar" size={56} myClasses="mg-8" />
      <div class="flex-col">
        <div class="flex-row">
          <span class={`flex flex-row items-center ${unread ? "font-medium" : ""}`}>
            {session.clientName}
            {#if $onlineUsers.includes(session.clientId)}
              <Svg
                customColor="bg-green-700 dark:bg-green-300"
                myClass="ml-1"
                name="online"
                size={12}
              />
            {/if}
            {#if session.clientArchivedAt}
              <span class="text-xxs mx-1"> (Arhiviran) </span>
              <Svg name="warning" customColor="bg-red-500" size={14} />
            {/if}
          </span>
          {#if unread}
            <div class="ml-2 rounded-full bg-red-500 h-3 w-3" />
          {/if}
        </div>
        <span class={`last-message ${unread ? "font-medium" : ""}`}>
          {getLastMessage(session.lastMsg)}
          <!-- {session.lastMsg?.includes(":video:") ? "Video" : session.lastMsg} -->
        </span>
      </div>
    </div>
    <div class="flex flex-col items-end">
      <SeenButton isSeen={unread ? false : true} {toggleSeen} />
      <div class="mr-2 text-xxs">
        {parseDateWithTime(session.updatedAt)}
      </div>
    </div>
  </div>
</a>

<style>
  .last-message {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
    font-size: small;
  }
  .link {
    width: 100%;
    text-decoration: none;
  }
</style>
