<script lang="ts">
  import { serverlessRoutes, post, storage, initiateUser } from "lib";
  import { translate } from "../../lib/translate";
  import { replace } from "svelte-spa-router";
  import { showAlert } from "../../stores/showAlertStore";
  import { ButtonComponent, Input, InputField } from "ui";
  import { fetchUser } from "../../lib/fetchUser";
  import { isClient } from "../../lib/roles";

  let gsiButton: any;
  let gsiSecButton: any;
  let email = "";
  let password = "";
  let isLoading = false;

  const renderButton = () => {
    gsiButton.setAttribute("id", "g_id_onload");
    gsiButton.setAttribute(
      "data-client_id",
      "581540261434-miks1j69jmru5j6g2q69kqqqv2pc54h9.apps.googleusercontent.com"
    );
    gsiButton.setAttribute("data-callback", "handleGoogleSignIn");
    gsiButton.setAttribute("data-auto_prompt", "false");
    gsiSecButton.setAttribute("class", "g_id_signin");
    gsiSecButton.setAttribute("data-type", "standard");
    gsiSecButton.setAttribute("data-size", "large");
    gsiSecButton.setAttribute("data-theme", "outline");
    gsiSecButton.setAttribute("data-text", "sign_in_with");
    gsiSecButton.setAttribute("data-shape", "rectangular");
    gsiSecButton.setAttribute("data-logo_alignment", "left");
  };

  const initGoogleScript = () => {
    let script = document.createElement("script");
    (window as any).handleGoogleSignIn = (res: any) => {
      console.log(res);
    };
    script.src = "https://accounts.google.com/gsi/client";
    renderButton();
    document.head.appendChild(script);
  };

  const scrollToTop = () => {
    const scrollBoxObj = document.getElementById("login-container");
    scrollBoxObj?.scrollIntoView(false);
  };

  const signin = async () => {
    const data = { email, password };
    const url = `${serverlessRoutes.AUTH}/login`;
    scrollToTop();
    try {
      isLoading = true;
      const res = await post(url, data);
      if (res.accessToken) {
        await storage.set("authJwt", res.accessToken);
        const fetchedUser = await fetchUser();
        initiateUser();
        isLoading = false;
        if (!isClient(fetchedUser)) {
          await replace("/clients/list");
        } else {
          await replace("/training/program");
        }
      } else {
        isLoading = false;
        $showAlert.message = res.error;
      }
    } catch (err: any) {
      isLoading = false;
      $showAlert.message = err.error;
    }
  };
</script>

<div id="login-container" class="py-8 h-[100vh] flex flex-col items-center justify-between">
  <img class="logo mt-1" src="/logo.png" alt="Logo" />

  <div class="flex flex-col gap-4 w-3/4">
    <div class="text-center font-bold">
      {translate("WELCOME_BACK")}!
    </div>

    <form class="flex flex-col gap-2" on:submit|preventDefault={signin}>
      <InputField type="email" label="Email" bind:value={email} />

      <InputField type="password" label={translate("PASSWORD")} bind:value={password} />

      <div class="flex justify-center">
        <ButtonComponent {isLoading} type="submit">
          {translate("SIGN_IN")}
        </ButtonComponent>
      </div>
    </form>
  </div>

  <div class="flex flex-col gap-2 text-center">
    <a href="/#/auth/reset-password" class="text-blue-500">
      {translate("RESET_PASSWORD")}
    </a>
    <a href="/#/auth/signin-with-code" class="text-blue-500">
      {translate("SIGN_IN_WITH_EMAIL_CODE")}
    </a>
  </div>
</div>

<style>
  .logo {
    height: 176px;
    width: 176px;
  }
</style>
