<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { postWithJwt, translate, serverlessRoutes } from "lib";
  import { dialogData, selectedIds, showAlert, trainerClientsStore } from "stores";
  import { ButtonComponent, InfiniteScroll, Search } from "ui";
  import ClientListItem from "../../../components/ClientListItem.svelte";
  import ClientListFilter from "../../../screens/Clients/ClientListFilter.svelte";
  import type { PartialItemMap, Program } from "interfaces";

  let data: any;
  let scrollElement: HTMLDivElement;
  const name = data.program?.name || data.mealPlan?.name;

  const onSubmit = async (): Promise<void> => {
    if (data.program) {
      const partialProgramMap: PartialItemMap<Program> = {};

      $selectedIds.forEach((id): void => {
        partialProgramMap[data.program.id] = {
          isTemplate: 0,
          default: 0,
          clientId: id,
        };
      });

      await postWithJwt(`${serverlessRoutes.PROGRAM}/copy`, {
        partialProgramMap,
      });

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_ASSIGNED_TRAINING_PROGRAMS");
    } else {
      const partialMealPlanMap: { [id: string]: any } = {};

      $selectedIds.forEach((id): void => {
        partialMealPlanMap[data.mealPlan.id] = {
          isTemplate: 0,
          isDefault: 0,
          clientId: id,
        };
      });

      await postWithJwt(`${serverlessRoutes.MEAL_PLANS}/copy`, {
        partialMealPlanMap,
      });

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_ASSIGNED_MEAL_PLANS");
    }

    $dialogData.type = "";
    $dialogData.data = {};
  };

  onDestroy((): void => {
    $selectedIds = [];
  });

  onMount(async (): Promise<void> => {
    // trainerClientsStore.loadCache();
    await trainerClientsStore.fetchData();
  });

  export { data };
</script>

<div class="h-full p-4 flex flex-col gap-4">
  <div class="font-bold text-center">
    {translate("ASSIGN_TO_CLIENTS")}
    {name}
  </div>

  <Search
    placeholder={translate("SEARCH_CLIENT")}
    bind:value={$trainerClientsStore.filter.search}
    on:input={trainerClientsStore.search}
  />

  <ClientListFilter on:fetchData={trainerClientsStore.fetchData} />

  <div class="h-80 pr-4 flex flex-col gap-4 overflow-y-scroll" bind:this={scrollElement}>
    {#each $trainerClientsStore.items as client (client.id)}
      <div animate:flip={{ duration: 333 }}>
        <ClientListItem isSelectMode {client} />
      </div>
    {/each}
  </div>

  <InfiniteScroll
    {scrollElement}
    hasMore={$trainerClientsStore.hasMore}
    isFetchingMore={$trainerClientsStore.isFetchingMore}
    on:loadMore={trainerClientsStore.fetchMoreData}
  />

  <div class="flex justify-center">
    <ButtonComponent on:click={onSubmit}>
      {translate("ASSIGN")}
    </ButtonComponent>
  </div>
</div>
