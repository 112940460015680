<script lang="ts">
  import { onMount } from "svelte";
  import { storage, translate } from "lib";
  import { ExpansionPanel, Toggle } from "ui";

  let showChatHelper: boolean;
  let showAiSummary: boolean;
  let showAiSuggestion: boolean;
  let showOnlineIndicator: boolean;

  const toggleChatHelper = async (): Promise<void> => {
    showChatHelper = !showChatHelper;
    await storage.set("showChatHelper", showChatHelper);
  };

  const toggleOnlineIndicator = async (): Promise<void> => {
    showOnlineIndicator = !showOnlineIndicator;
    await storage.set("showOnlineIndicator", showOnlineIndicator);
  };

  const toggleAiSummary = async (): Promise<void> => {
    showAiSummary = !showAiSummary;
    await storage.set("showAiSummary", showAiSummary);
  };

  const toggleAiSuggestion = async (): Promise<void> => {
    showAiSuggestion = !showAiSuggestion;
    await storage.set<boolean>("showAiSuggestion", showAiSuggestion);
  };

  onMount(async (): Promise<void> => {
    showChatHelper = (await storage.get<boolean>("showChatHelper")) === true;
    showAiSummary = (await storage.get<boolean>("showAiSummary")) === true;
    showAiSuggestion = (await storage.get<boolean>("showAiSuggestion")) === true;
    showOnlineIndicator = (await storage.get<boolean>("showOnlineIndicator")) === true;
  });
</script>

<ExpansionPanel title={translate("CHAT_HELPERS")}>
  <Toggle label="SHOW_CHAT_HELPER" value={showChatHelper} toggleFunction={toggleChatHelper} />
  <Toggle label="SHOW_AI_SUMMARY" value={showAiSummary} toggleFunction={toggleAiSummary} />
  <Toggle label="SHOW_AI_SUGGESTION" value={showAiSuggestion} toggleFunction={toggleAiSuggestion} />
  <Toggle
    label="SHOW_ONLINE_INDICATOR"
    value={showOnlineIndicator}
    toggleFunction={toggleOnlineIndicator}
  />
</ExpansionPanel>
