<script lang="ts">
  import Svg from "../UI/Svg.svelte";
  import { dialogTypes, serverlessRoutes } from "../../lib/constants";
  import { deleteWithJwt, patchWithJwt, postWithJwt } from "../../lib/requests";
  import { showAlert } from "../../stores/showAlertStore";
  import { user } from "../../stores/userStore";
  import MeasurementChart from "./MeasurementChart.svelte";
  import dateString from "../../lib/dateString";
  import { slide } from "svelte/transition";
  import { clientMeasurementDataMap, clientMeasurements } from "../../stores/clientStores";
  import More from "../UI/More.svelte";
  import type { MenuItem } from "interfaces";
  import { currentClient, dialogData } from "stores";
  import { translate } from "lib";
  import { ButtonComponent } from "ui";

  export let title: string;
  export let entityId: number;
  export let entityValue: number;
  export let isExpanded: boolean = false;
  export let isDraggable: boolean = false;
  export let hasGoal: boolean = false;
  export let selectedDate;
  export let refetchMeasurementData: () => {};

  let isBeingEdited: boolean = false;

  const deleteMeasurementData = async (foundObject: any): Promise<void> => {
    const measurementId = Object.keys(foundObject)[0];
    const measurementDataId = foundObject[measurementId].measurementDataId;
    const clientId = $currentClient.id || $user.id;

    try {
      const res = await deleteWithJwt(serverlessRoutes.MEASUREMENT_DATA, {
        clientId,
        id: measurementDataId,
      });

      refetchMeasurementData();
      $showAlert.message = "Measurement updated successfully.";
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
    }
  };

  const createOrUpdateMeasurementData = async (value: number) => {
    if (!entityValue) {
      return;
    }

    const objects = $clientMeasurementDataMap[dateString(selectedDate)];
    const foundObject = objects?.find((obj) => Object.keys(obj)[0] === `${entityId}`);

    if (!value && foundObject) {
      return await deleteMeasurementData(foundObject);
    }

    if (foundObject) {
      await updateMeasurementData(value, foundObject);
    } else {
      await createMeasurementData(value);
    }
  };

  const updateMeasurementData = async (value: number, foundObject: any) => {
    const measurementId = Object.keys(foundObject)[0];
    const measurementDataId = foundObject[measurementId].measurementDataId;
    const clientId = $currentClient.id || $user.id;

    try {
      const res = await patchWithJwt(serverlessRoutes.MEASUREMENT_DATA, {
        clientId,
        id: measurementDataId,
        value,
      });

      refetchMeasurementData();
      $showAlert.message = "Measurement updated successfully.";
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
    }
  };

  const createMeasurementData = async (value: number): Promise<void> => {
    const clientId = $currentClient.id || $user.id;

    try {
      const res = await postWithJwt(serverlessRoutes.MEASUREMENT_DATA, {
        clientId,
        measurementId: entityId,
        createdAt: dateString(selectedDate),
        value,
      });

      refetchMeasurementData();
      $showAlert.message = "Measurement updated successfully.";
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
    }
  };

  const deleteMeasurement = async () => {
    const url = `${serverlessRoutes.MEASUREMENT}/${entityId}`;
    try {
      await patchWithJwt(url, {
        measurement: {
          deletedAt: new Date(),
        },
      });
      // await deleteWithJwt(url);
      $clientMeasurements = $clientMeasurements.filter(
        (measurement) => measurement.id !== entityId
      );
      $showAlert.message = translate("MEASUREMENT_DELETED_SUCCESSFULLY");
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
    }
  };

  const updateMeasurement = async (measurement) => {
    const url = `${serverlessRoutes.MEASUREMENT}/${measurement.id}`;
    try {
      await patchWithJwt(url, { measurement });
      $clientMeasurements = $clientMeasurements.map((m) =>
        m.id === measurement.id ? { ...m, ...measurement } : m
      );
      $showAlert.message = translate("MEASUREMENT_UPDATED_SUCCESSFULLY");
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
    }
  };

  const menuItems: MenuItem[] = [
    {
      icon: "edit",
      title: "EDIT",
      executeFunction(): void {
        $dialogData.type = dialogTypes.CREATE_EDIT_MEASUREMENT;
        const measurementUnit = $clientMeasurements.find((m) => m.id === entityId)?.measurementUnit;
        if (!measurementUnit) return;
        $dialogData.data = {
          measurementToUpdate: { id: entityId, title, measurementUnit },
          executeFunction: updateMeasurement,
        };
      },
    },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction(): void {
        $dialogData.data = {
          title: translate("MEASUREMENTU"),
          executeFunction: deleteMeasurement,
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];
</script>

<div
  class="p-2 flex flex-col border rounded-md border-slate-200 dark:border-zinc-600 transition-shadow duration-[400ms] ease-linear text-slate-900 dark:text-slate-100"
  class:shadow={isExpanded}
>
  <!-- class={`flex-row center-space-between`} -->
  <div class="flex items-center">
    <div class="title flex-row">
      <div
        on:click={() => (isExpanded = !isExpanded)}
        on:keypress={() => (isExpanded = !isExpanded)}
        class="flex-row center-center"
      >
        {#if isDraggable}
          <Svg name="drag" size={16} myClass="mr-2" />
        {/if}
        {title}
      </div>
    </div>
    {#if hasGoal}
      <Svg myClass="mr-2" name="flag" size={20} customColor="bg-green-500" />
    {/if}
    <input
      type="number"
      bind:value={entityValue}
      class="measurement-input border-t-0 border-x-0 border-b-1 mgr-12 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500"
      on:focus={() => (isBeingEdited = true)}
      on:blur={() => setTimeout(() => (isBeingEdited = false), 100)}
    />
    {#if isBeingEdited}
      <!-- <div
        on:click={() => createOrUpdateMeasurementData(entityValue)}
        on:keydown={() => createOrUpdateMeasurementData(entityValue)}
      > -->
      <ButtonComponent isOutline on:click={() => createOrUpdateMeasurementData(entityValue)}>
        <Svg customColor="bg-primary-500" name="save" size={16} />
        <span class="text-xxs">{translate("SAVE")}</span>
      </ButtonComponent>
      <!-- </div> -->
    {:else}
      <More {menuItems} />
      <div
        class="ml-2"
        on:click={() => (isExpanded = !isExpanded)}
        on:keypress={() => (isExpanded = !isExpanded)}
      >
        <!-- {#if isExpanded}
          <Svg name="up-caret" size={16} myClass="mgr-8" />
        {:else}
          <Svg name="down-caret" size={16} myClass="mgr-8" />
        {/if} -->
        <div class="chevron" class:isExpanded>
          <Svg name="down-caret" size={16} />
        </div>
      </div>
    {/if}
  </div>
  {#if isExpanded}
    <div in:slide out:slide>
      <MeasurementChart
        clientId={$user.id}
        date={dateString(selectedDate)}
        measurementId={entityId}
      />
    </div>
  {/if}
</div>

<style>
  .measurement-input {
    width: 56px;
    padding: 0px;
    background-color: rgba(1, 1, 1, 0);
    text-align: center;
  }
  .measurement-input::-webkit-outer-spin-button,
  .measurement-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .measurement-input[type="number"] {
    -moz-appearance: textfield;
  }
  input:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  @keyframes expand {
    from {
      height: 40px;
    }
    to {
      height: fit-content;
    }
  }
  @keyframes contract {
    from {
      height: 400px;
    }
    to {
      height: 40px;
    }
  }
  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 288px; /* some width */
  }

  .chevron {
    /* ista tranzicija kao default svelte:slide */
    transition: transform 400ms linear;
  }
  .isExpanded {
    transform: rotate(180deg);
  }
</style>
