<script lang="ts">
  import { slide } from "svelte/transition";
  import { pop, replace } from "svelte-spa-router";

  import { fetchUser, isClient, post, serverlessRoutes, storage, translate } from "lib";

  import { alertStore } from "stores";
  import { ButtonComponent, InputField, Svg } from "ui";

  let email = "";
  let code = "";
  let isLoading = false;
  let isCodeSent = false;

  const createRecoveryCode = async (): Promise<void> => {
    isLoading = true;

    const { error } = await post(`${serverlessRoutes.AUTH}/recovery-code`, { email });

    if (error) {
      isLoading = false;
      alertStore.show(error, "error");
      return console.error(error);
    }

    isCodeSent = true;
    isLoading = false;
  };

  const loginWithCode = async (): Promise<void> => {
    isLoading = true;

    const { data, error } = await post(`${serverlessRoutes.AUTH}/login-with-code`, {
      email,
      code,
    });

    if (error) {
      isLoading = false;
      alertStore.show(error, "error");
      return console.error(error);
    }

    const { accessToken } = data;

    await storage.set("authJwt", accessToken);

    const fetchedUser = await fetchUser();

    if (!isClient(fetchedUser)) {
      replace("/clients/list");
    } else {
      replace("/training/program");
    }
  };
</script>

<div class="relative h-[100vh] w-full flex items-center justify-center">
  <Svg myClass="absolute top-8 left-8" size={24} name="left-arrow-button" on:click={pop} />

  <div class="w-max-[320px] flex flex-col gap-4">
    <div class="mb-4 text-center">
      <div class="text-xl font-bold">{translate("SIGN_IN_WITH_EMAIL_CODE")}</div>
      <div class="text-xs">{translate("RESET_PASSWORD_NOTE")}</div>
    </div>

    <form class="flex flex-col gap-4" on:submit|preventDefault={createRecoveryCode}>
      <InputField type="email" label="Email" disabled={isCodeSent} bind:value={email} />

      {#if !isCodeSent}
        <div class="flex justify-center">
          <ButtonComponent {isLoading} type="submit" disabled={!email}>
            {translate("SEND_CODE")}
          </ButtonComponent>
        </div>
      {/if}
    </form>

    {#if isCodeSent}
      <form class="flex flex-col gap-4" on:submit|preventDefault={loginWithCode} in:slide>
        <InputField type="number" label={translate("CODE")} bind:value={code} />

        <div class="flex justify-center">
          <ButtonComponent type="submit" {isLoading} disabled={!code}>
            {translate("SIGN_IN")}
          </ButtonComponent>
        </div>
      </form>
    {/if}
  </div>
</div>
