import {get, writable} from "svelte/store";
import {serverlessRoutes} from "lib";
import {storeUtil} from "../lib/createItemsStore";
import {currentClient} from "./currentClient";
import {user} from "./userStore";
import type {Habit, ItemsStore} from "interfaces";

interface HabitsStore extends ItemsStore<Habit> {}

const habitsStoreCreate = () => {
  const {set, subscribe, update} = writable<HabitsStore>({
    items: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined
  });

  const createUrl = (): string => {
    const {skip} = get({subscribe});
    const params = new URLSearchParams();

    params.append("take", "8");
    params.append("skip", `${skip}`);
    params.append("userId", `${get(currentClient).id || get(user).id}`);

    return `${serverlessRoutes.HABITS}/v2?${params.toString()}`;
  }

  const {
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  } = storeUtil<Habit, HabitsStore>("habitsCache", update, createUrl);

  return {
    set,
    subscribe,
    update,
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  };
};

const habitsStore = habitsStoreCreate();

export {habitsStore};
