<script lang="ts">
  import { onMount } from "svelte";
  import { serverlessRoutes } from "../../../lib/constants";
  import { getWithJwt } from "../../../lib/requests";
  import { parseDate, translate } from "lib";
  import { currentClient, user } from "stores";

  export let data: any;

  let history = [];

  const fetchHistory = async () => {
    const url = `${serverlessRoutes.EXERCISE}/history/${$currentClient?.id || $user.id}/${data.exercise.parentExerciseId}`;
    try {
      const res = await getWithJwt(url);
      history = res.data;
    } catch (err) {}
  };

  onMount(() => {
    fetchHistory();
  });
</script>

<div class="p-4 flex flex-col items-center gap-4">
  <!-- <h3 class="text-lg text-center mt-4">
    {translate("EXERCISE_HISTORY")}:
    <span class="font-medium">{data.exercise.name}</span>
  </h3> -->

  <div class="text-center font-semibold">
    {translate("EXERCISE_HISTORY")}
  </div>

  <div class="w-full flex flex-col gap-4">
    <div class="text-center font-bold">{data.exercise.name}</div>
    {#if history?.length > 0}
      {#each history as exerciseHistoryArr}
        <div class="border dark:border-zinc-600 rounded-md p-4" data-cy="history-entry">
          <div class="text-sm font-medium mb-1" data-cy="history-sets">
            {translate("SETS")}:
          </div>
          {#each exerciseHistoryArr as exerciseHistory, index}
            <div class="flex flex-row justify-between items-end">
              <div>
                <span class="mr-3">{index + 1}.</span>
                {#if exerciseHistory.weight}
                  <span data-cy="history-entry-{index}">{exerciseHistory.weight}</span>
                  kg x
                {:else}
                  Nema unosa x
                {/if}
                <span data-cy="history-entry-{index + data.exercise.details.sets}"
                  >{exerciseHistory.reps}</span
                >
              </div>
              {#if index === exerciseHistoryArr.length - 1}
                <div class="text-xs font-medium text-right">
                  {parseDate(exerciseHistoryArr[0].finishedAt)}
                </div>
              {/if}
            </div>
          {/each}
        </div>
      {/each}
    {:else}
      <div class="text-center text-sm mb-4 font-medium">
        {translate("NO_EXERCISE_HISTORY")}
      </div>
    {/if}
  </div>
</div>
