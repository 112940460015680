import { language } from "../stores/languageStore";
import {
  cantDoExercises,
  clientTagsMap,
  remindersMap,
  tags,
  trainerChatSessions,
  trainerChatWith,
  trainerClientGroups,
  trainerClientPrograms,
  trainerClients,
  trainerClientWorkouts,
  trainerList,
  trainerPrograms,
  trainerWorkouts,
} from "../stores/trainerStores";

import { serverlessRoutes } from "./constants";
import { replace } from "svelte-spa-router";
import { deleteWithJwt } from "./requests";
import {
  chatSessions,
  chatSessionsCount,
  clientChatSessionId,
  notSeenSessionIds,
  showOnlyUnreadChat,
  unreadMessages,
} from "../stores/chatSessionsStore";
import {
  clientWorkouts,
  clientGalleryEntries,
  clientGoals,
  clientFasts,
  clientMeasurements,
  clientMeasurementDataMap,
  clientGalleryEntriesCount,
  clientTrainerAvatarUrl,
  clientAvatarUrl,
  clientTrainerId,
} from "../stores/clientStores";
import { showMenu } from "../stores/menuStore";
import {
  notificationCount,
  notificationQuery,
  notifications,
  unreadNotifications,
} from "../stores/notificationStore";
import { socketV2 } from "../stores/socketStore";
import { trainerWorkoutsCount } from "../stores/trainerWorkoutsCount";
import { user } from "../stores/userStore";
import { get } from "svelte/store";

import {
  bookmarkedMessages,
  chatMessages,
  trainerExercisesStore,
  ingredientsStore,
  mealPlansStore,
  mealPlanStore,
  recipesStore,
  journalMealsStore,
  activeMealsStore,
  countersStore,
  currentClient,
  dialogData,
  editingSupersetID,
  selectedIds,
  selectedWorkoutIds,
  isLoadingMore,
  loadedWorkouts,
  selectedIdsStore,
  showAlert,
  trainerClientGroupsCount,
  trainerClientsStore,
  trainerProgramsCount,
  paymentsStore,
  // workoutsStore
} from "stores";

import { stats } from "../stores/finishWorkoutStats";
import { payload } from "../stores/payloadStore";
import { refetchGallery } from "../stores/refetchStore";
import { storage } from "./storage";

const reset = (store: any) => {
  store.items = [];
  store.count = 0;
  store.skip = 0;

  return store;
};

export const logout = async () => {
  await storage.clearAll();

  await unsubscribePushNotifications();

  get(socketV2).disconnect();
  activeMealsStore.update(reset);
  bookmarkedMessages.set([]);
  chatMessages.set([]);

  // --- chatSessionsStore ---
  chatSessions.set([]);
  showOnlyUnreadChat.set(-1);
  chatSessionsCount.set(0);
  notSeenSessionIds.set([]);
  clientChatSessionId.set(0);
  unreadMessages.set(0);
  // -------------------------

  // --- clientStores ---
  clientWorkouts.set([]);
  clientMeasurements.set([]);
  clientMeasurementDataMap.set({});
  clientGoals.set([]);
  clientFasts.set([]);
  clientGalleryEntries.set([]);
  clientGalleryEntriesCount.set(0);
  paymentsStore.update(reset);
  clientTrainerAvatarUrl.set("");
  clientAvatarUrl.set("");
  clientTrainerId.set("");
  // --------------------

  countersStore.set({
    trainerClientPrograms: 0,
  });

  currentClient.set({});

  dialogData.set({
    type: "",
    data: {},
    isCloseable: true,
    elem: undefined,
    component: undefined,
  });

  editingSupersetID.set(null);

  stats.set([]);
  selectedIds.set([]);
  selectedWorkoutIds.set([]);
  ingredientsStore.update(reset);
  isLoadingMore.set(false);
  journalMealsStore.update(reset);
  language.set("sr");
  loadedWorkouts.set({});
  mealPlansStore.update(reset);
  mealPlanStore.set(null);
  showMenu.set(false);

  // --- notificationsStore ---
  notifications.set([]);
  notificationCount.set(0);
  unreadNotifications.set(0);
  notificationQuery.set("");
  // --------------------------

  payload.set("");
  recipesStore.update(reset);
  refetchGallery.set(false);
  selectedIdsStore.clear();

  showAlert.set({
    message: "",
    color: "black",
  });

  trainerClientGroupsCount.set(0);
  trainerClientsStore.update(reset);
  trainerExercisesStore.update(reset);
  trainerProgramsCount.set(0);

  // --- trainerStores ---
  trainerClients.set([]);
  trainerPrograms.set([]);
  trainerWorkouts.set([]);
  trainerChatSessions.set([]);
  trainerClientGroups.set([]);
  trainerClientPrograms.set([]);
  trainerClientWorkouts.update(reset);
  trainerList.set(undefined);
  trainerChatWith.set({});
  tags.set([]);
  remindersMap.set({});
  clientTagsMap.set({});

  cantDoExercises.set({
    exercises: [],
    exerciseIds: [],
    workoutIds: [],
  });
  // ---------------------

  trainerWorkoutsCount.set(0);
  user.set(undefined);
  replace("/auth");
  // workoutsStore.update(reset);
};

const unsubscribePushNotifications = async () => {
  let token = await storage.get("pushToken");
  if (!token) return;
  const url = `${serverlessRoutes.WEB_PUSH}`;
  try {
    deleteWithJwt(url, { token });
  } catch (err) {}
};
