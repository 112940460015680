<script lang="ts">
  import { onMount } from "svelte";
  import {
    getWithJwt,
    isClient,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import {
    cantDoExercises,
    dialogData,
    showAlert,
    trainerWorkouts,
    trainerWorkoutsCount,
    user,
    workoutsStore,
  } from "stores";
  import InfiniteScroll from "../../../components/UI/InfiniteScroll.svelte";
  import { updateTrainerPrograms } from "../../../lib/updateTrainerPrograms";
  import { ButtonComponent, CheckboxComponent } from "ui";
  import WorkoutsToolbarComponent from "../../../components/Training/WorkoutsToolbarComponent.svelte";

  let data: any;
  let value = "";
  let isLoading = false;
  let page = 0;
  let scrollElement: HTMLDivElement;
  let selectedIds: Array<number> = [];
  // let timeout: NodeJS.Timeout;
  $: disabled = !selectedIds.length;
  let mutexRequestId = 0;

  const onSubmit = async (): Promise<void> => {
    try {
      isLoading = true;

      const url = `${serverlessRoutes.WORKOUT}/copy`;
      const response = await postWithJwt(url, {
        workoutIds: selectedIds,
        propertiesToOverride: {
          programId: data.programId,
          position: data.position,
          default: 0,
          isTemplate: 0,
          trainerId: null,
        },
      });

      // if (response.data) {
      updateTrainerPrograms(
        data.programId,
        (p) => (p.workouts = [...p.workouts, ...response.data])
      );

      if (window.location.href.includes("/profile") && !isClient($user)) {
        const userId = window.location.href.split("/")[5];
        const workoutIds = [response.data[0].id];
        const res = await getWithJwt(
          `${serverlessRoutes.WORKOUT}/cant-do-exercises?workoutIds=%5B${workoutIds.join(",")}%5D&userId=${userId}`
        );

        $cantDoExercises.workoutIds = [
          ...$cantDoExercises.workoutIds,
          ...res.data.cantDo,
        ];
      }
      // }

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_IMPORTED")}
        ${translate("WORKOUT")}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_IMPORTING_WORKOUT");
    }
  };

  const onChange = (id: number): void => {
    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter(
        (selectedId): boolean => selectedId !== id
      );
    } else {
      selectedIds = [...selectedIds, id];
    }
  };

  onMount(async () => { await workoutsStore.fetchData(); });

  const mountScroll = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  export { data };
</script>

<div class="p-4 flex flex-col gap-4 overflow-hidden">
  <div class="text-center font-semibold">{translate("IMPORT_WORKOUT")}</div>

  <WorkoutsToolbarComponent/>

  <div class="h-80 p-1 pr-2 flex flex-col gap-4 overflow-y-scroll" use:mountScroll>
    {#each $workoutsStore.items as workout}
      <CheckboxComponent
        value={selectedIds.includes(workout.id)}
        label={workout.name}
        on:change={() => onChange(workout.id)}
      ></CheckboxComponent>
    {/each}

    <InfiniteScroll
      {scrollElement}
      hasMore={$workoutsStore.hasMore}
      isFetchingMore={$workoutsStore.isFetchingMore}
      on:loadMore={workoutsStore.fetchMoreData}
    />
  </div>


  <div class="flex justify-center">
    <ButtonComponent
      {disabled}
      {isLoading}
      cy="import-workouts-button"
      on:click={onSubmit}
    >
      {translate("IMPORT")}
    </ButtonComponent>
  </div>
</div>
