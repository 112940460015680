<script lang="ts">
  import { serverlessRoutes } from "../../lib/constants";
  import { translate } from "../../lib/translate";
  import Svg from "../UI/Svg.svelte";
  import { fly } from "svelte/transition";
  import { user } from "../../stores/userStore";
  import { postWithJwt } from "../../lib/requests";
  import { createEventDispatcher, onMount } from "svelte";
  import { clientGalleryEntries } from "../../stores/clientStores";
  import Spinner from "../UI/Spinner.svelte";
  import { location } from "svelte-spa-router";
  import { uploadImageCloudflare } from "../../lib/cloudflare/uploadImageCloudflare";
  import { showAlert } from "stores";

  const dispatch = createEventDispatcher();

  let unique = {};
  let isLoading: boolean = false;

  interface GalleryEntryFiles {
    front: File;
    back: File;
    side: File;
  }

  export let date;
  let selected = "front";
  let fileInput;
  let files: GalleryEntryFiles = { front: null, back: null, side: null };
  let alreadyUploadedFiles;

  const animate = () => {
    unique = {};
  };

  const onFileSelected = (e, side) => {
    files[side] = new File([e.target.files[0]], selected);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      files[side].htmlRender = fileReader.result;
    };
    fileReader.readAsDataURL(e.target.files[0]);
    uploadPhoto();
  };

  const back = () => {
    animate();
    selected = selected === "front" ? "side" : selected === "back" ? "front" : "back";
  };

  const forward = () => {
    animate();
    selected = selected === "front" ? "back" : selected === "back" ? "side" : "front";
  };

  const uploadPhoto = async () => {
    try {
      isLoading = true;
      const uploadRes = await uploadImageCloudflare(files[selected], true);
      if (!uploadRes.imageUrl) return;
      const imageUrl = uploadRes.imageUrl;
      const dateObj = new Date(date).toISOString();
      const clientId = $location.includes("profile") ? $location.split("/")[2] : $user.id;
      const res = await postWithJwt(`${serverlessRoutes.GALLERY}`, {
        date: dateObj,
        clientId,
        pose: selected,
        imageUrl,
      });
      let parsedSelect = `${selected.toUpperCase()}_POSE`;
      $showAlert.message = `${translate(parsedSelect)} ${translate(
        "SUCCESSFULLY_UPLOADED"
      ).toLowerCase()}.`;

      res.data.entry.files = []; // bugfix-bez ovoga puca GalleryEntry.svelte

      const index = $clientGalleryEntries.findIndex((entry) => entry.date.slice(0, 10) === date);

      if (index !== -1) {
        $clientGalleryEntries[index] = {
          ...$clientGalleryEntries[index],
          [selected]: res.data.entry[selected],
        };
      } else {
        const lastEntryDate = new Date(
          $clientGalleryEntries[$clientGalleryEntries.length - 1].date.slice(0, 10)
        );
        const curEntryDate = new Date(res.data.entry.date.slice(0, 10));

        if (lastEntryDate < curEntryDate) {
          $clientGalleryEntries.push(res.data.entry);
          $clientGalleryEntries.sort((a, b) => new Date(b.date) - new Date(a.date));
          $clientGalleryEntries = [...$clientGalleryEntries];
        }
      }

      forward();
    } catch (err) {
      console.log(err);
    } finally {
      isLoading = false;
    }
  };

  onMount(() => {
    const alreadyUploaded = $clientGalleryEntries.find(
      (galleryEntry) => galleryEntry.date.slice(0, 10) === date
    );
    if (alreadyUploaded) {
      const front = alreadyUploaded.gallery?.files?.find((file) => file.name === "front.png") || {
        url: alreadyUploaded.front,
      };
      const back = alreadyUploaded.gallery?.files?.find((file) => file.name === "back.png") || {
        url: alreadyUploaded.back,
      };
      const side = alreadyUploaded.gallery?.files?.find((file) => file.name === "side.png") || {
        url: alreadyUploaded.side,
      };
      alreadyUploadedFiles = { front, side, back };
    }
  });
</script>

<div class="flex-col center-center">
  <!-- <span class="mgl-24">{parseDate(date)}</span> -->
  <h3>{translate(`${selected.toUpperCase()}_POSE`)}</h3>
  {#if isLoading}
    <Spinner height={"40vh"} />
  {:else}
    <div class="flex-row center-center mt-3 mgb-16">
      <div on:click={() => back()} on:keypress={() => back()}>
        <Svg name="circle-left" size={32} />
      </div>
      <div
        class="flex-row center-center"
        on:click={() => fileInput.click()}
        on:keypress={() => fileInput.click()}
      >
        <input
          style="display:none"
          type="file"
          on:change={(e) => onFileSelected(e, selected)}
          bind:this={fileInput}
        />
        {#key unique}
          <img
            in:fly={{ x: 1000 }}
            src={alreadyUploadedFiles
              ? alreadyUploadedFiles[selected]?.url
              : files[selected]?.htmlRender
                ? files[selected].htmlRender
                : "/logo.png"}
            on:error={(el) => {
              el.target.src = "/logo.png";
            }}
            alt="Front Pose"
            class="gallery-entry-image"
          />
        {/key}
      </div>
      <div on:click={() => forward()} on:keypress={() => forward()}>
        <Svg name="circle-right" size={32} />
      </div>
    </div>
  {/if}
  <p class="mgb-16 text-smaller">*{translate("GALLERY_TUTORIAL")}</p>
</div>

<style>
  .gallery-entry-image {
    width: 92%;
    max-height: 448px;
  }
</style>
