<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { Button } from "flowbite-svelte";
  import { theme, user } from "stores";
  import { Svg } from "ui";
  import { isClient } from "lib";

  export let selectedMessage;
  export let clearSlectedMessage: () => void;
  export let bookmarkMessage: () => void;
  export let copyToClipboard: (payload: string) => void;
  export let editMessage: () => void;
  export let showConfirmDeleteDialog: () => void;

  let menuItems = [
    {
      icon: "x",
      method: clearSlectedMessage,
    },
    {
      icon: "bookmark-simple",
      method: bookmarkMessage,
    },
    {
      icon: "copy",
      method() {
        copyToClipboard(selectedMessage.payload);
      },
    },
    {
      icon: "edit",
      method: editMessage,
    },
    {
      icon: "delete",
      method: showConfirmDeleteDialog,
    },
  ];

  onMount((): void => {
    const { isMine, payload } = selectedMessage;

    if (isMine) {
      menuItems = menuItems.filter(({ icon }) => {
        if (icon === "bookmark-simple") {
          return false;
        } else if (
          (icon === "copy" || icon === "edit") &&
          payload === "FILE_MESSAGE"
        ) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      menuItems = menuItems.filter(({ icon }) => {
        if (icon === "bookmark-simple" && isClient($user)) {
          return false;
        } else if (icon === "edit" || icon === "delete") {
          return false;
        } else if (
          (icon === "copy" || icon === "bookmark-simple") &&
          payload === "FILE_MESSAGE"
        ) {
          return false;
        } else {
          return true;
        }
      });
    }
  });
</script>

<div
  class="message-menu flex flex-row items-center justify-end"
  in:fade={{ duration: 300 }}
  out:fade={{ duration: 300 }}
>
  {#each menuItems as { icon, method }}
    <Button
      class="!p-2 mr-2"
      color={$theme === "dark" ? "dark" : "light"}
      on:click={method}
    >
      <Svg name={icon} size={20} />
    </Button>
  {/each}
  <!-- <Button
    on:click={() => clearSlectedMessage()}
    color={$theme === "dark" ? "dark" : "light"}
    class="!p-2 mr-2"><Svg name="x" size={20} /></Button
  >
  <Button
    on:click={() => bookmarkMessage()}
    color={$theme === "dark" ? "dark" : "light"}
    class="!p-2 mr-2"><Svg name="bookmark-simple" size={20} /></Button
  >
  {#if selectedMessage.payload !== "FILE_MESSAGE"}
    <Button
      on:click={() => copyToClipboard(selectedMessage.payload)}
      color={$theme === "dark" ? "dark" : "light"}
      class="!p-2 mr-2"><Svg name="copy" size={20} /></Button
    >
  {/if}
  {#if selectedMessage.isMine}
    {#if selectedMessage.payload !== "FILE_MESSAGE"}
      <Button
        on:click={() => editMessage()}
        color={$theme === "dark" ? "dark" : "light"}
        class="!p-2 mr-2"><Svg name="edit" size={20} /></Button
      >
    {/if}
    <Button
      on:click={() => showConfirmDeleteDialog()}
      color={$theme === "dark" ? "dark" : "light"}
      class="!p-2 mr-2"><Svg name="delete" size={20} /></Button
    >
  {/if} -->
</div>

<style>
  .message-menu {
    position: fixed;
    bottom: 52px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 64px;
    max-width: 720px;
  }
</style>
