import {get, writable} from "svelte/store";
import {getWithJwt, serverlessRoutes, sortItems} from "lib";
import {storeUtil} from "../lib/createItemsStore"
import type {Exercise, ItemsStore, Workout} from "interfaces";

interface WorkoutsStore extends ItemsStore<Workout> {
  filter: {
    search: string;
    template: number | undefined;
    sort: string | undefined;
    tags: Array<string>;
  };
}

const workoutsStoreCreate = () => {
  const {set, subscribe, update} = writable<WorkoutsStore>({
    items: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined,
    filter: {
      search: "",
      template: 0,
      sort: undefined,
      tags: []
    }
  });

  const createUrl = (): string => {
    const {
      skip,
      filter: {search, template, sort, tags}
    } = get({subscribe});

    const params = new URLSearchParams();

    params.append("skip", skip.toString());
    params.append("default", "1");

    if (template !== undefined) {
      params.append("template", `${template}`);
    }

    if (sort !== undefined) {
      if (sort === "NAME_ASC" || sort === "NAME_DESC") {
        params.append("orderBy", "name");
      } else {
        params.append("orderBy", "id");
      }

      if (sort === "NAME_ASC" || sort === "DATE_ASC") {
        params.append("order", "ASC");
      } else {
        params.append("order", "DESC");
      }
    }

    if (search) {
      params.append("name", search);
    }

    if (tags.length) {
      params.append("muscleGroup", tags.map((tag): string => tag).join());
    }

    return `${serverlessRoutes.WORKOUT}/list/v2?${params.toString()}`;
  };

  const {
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  } = storeUtil<Workout, WorkoutsStore>("workoutsCache", update, createUrl);

  const addExercises = (
    workoutId: number,
    newExercises: Array<Exercise>
  ): void => {
    update((store) => {
      const workout = store.items.find(({id}): boolean => id === workoutId);

      if (!workout) {
        return store;
      }

      if (workout.exercises) {
        workout.exercises.push(...newExercises);
      } else {
        workout.exercises = newExercises;
      }

      workout.exercises.sort(sortItems);

      return store;
    });
  };

  const replaceExercises = (
    workoutId: number,
    newExercises: Array<Exercise>
  ): void => {
    update((store) => {
      const workout = store.items.find(({id}): boolean => id === workoutId);

      if (!workout?.exercises) { return store; }

      const {exercises} = workout;

      for (const newExercise of newExercises) {
        let index: number;

        if (newExercise.supersetId) {
          const exercise = exercises.find(({supersetId}): boolean => supersetId === newExercise.supersetId);

          index = exercise.supersetExercises.findIndex(({id}): boolean => id === newExercise.id);

          if (index > -1) {
            exercise.supersetExercises.splice(index, 1, newExercise);
          }
        } else {
          const exerciseIndex = exercises.findIndex(
            ({id}): boolean => id === newExercise.id
          );

          if (exerciseIndex > -1) {
            exercises.splice(exerciseIndex, 1, newExercise);
          }
        }
      }

      workout.exercises.sort(sortItems);

      return store;
    });
  };

  const removeExercises = (
    workoutId: number,
    exerciseIds: Array<number>
  ): void => {
    update((store) => {
      const workout = store.items.find(({id}): boolean => id === workoutId);

      if (!workout?.exercises) {
        return store;
      }

      workout.exercises = workout.exercises.filter(
        ({id}): boolean => !exerciseIds.includes(id)
      );

      workout.exercises.sort(sortItems);

      return store;
    });
  };


  const replaceSupersetExercises = (supersetId: number, newExercises: Array<Exercise>): void => {
    update((store) => {
      store.items.forEach((workout): void => {
        if (!workout.exercises) {
          return;
        }

        const supersetExercise = workout.exercises.find(
          ({id}): boolean => id === supersetId
        );

        if (!supersetExercise) {
          return;
        }

        for (const newExercise of newExercises) {
          const exerciseIndex = supersetExercise.supersetExercises.findIndex(
            ({id}): boolean => id === newExercise.id
          );

          if (exerciseIndex > -1) {
            supersetExercise
              .supersetExercises
              .splice(exerciseIndex, 1, newExercise);
          }
        }

        supersetExercise.supersetExercises.sort(sortItems);
      });

      return store;
    });
  };

  const removeSupersetExercises = (supersetId: number, exerciseIds: Array<number>): void => {
    update((store) => {
      store.items.forEach((workout): void => {
        if (!workout.exercises) {
          return;
        }

        const supersetExercise = workout.exercises.find(
          (exercise): boolean => exercise.id === supersetId
        );

        if (!supersetExercise) {
          return;
        }

        supersetExercise.supersetExercises = supersetExercise.supersetExercises.filter(
          ({id}): boolean => !exerciseIds.includes(id)
        );

        supersetExercise.supersetExercises.sort(sortItems);
      });

      return store;
    });
  };


  const fetchExercises = async (workoutId: number): Promise<void> => {
    const {data, error} = await getWithJwt(
      `${serverlessRoutes.WORKOUT}/v2/${workoutId}/exercises`
    );

    if (error && !data) {
      return console.error(error);
    }

    addExercises(workoutId, data.exercises);
  };

  return {
    set,
    subscribe,
    update,
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    loadCache,
    search,
    addExercises,
    replaceExercises,
    removeExercises,

    replaceSupersetExercises,
    removeSupersetExercises,

    fetchExercises
  };
};

const workoutsStore = workoutsStoreCreate();

export {workoutsStore};