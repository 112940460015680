<script lang="ts">
  import MiniSpinner from "./MiniSpinner.svelte";

  export let cy = "";
  export let id = "";
  export let myClasses = "";
  export let customColor = "";
  export let type: "button" | "submit" = "button";
  export let disabled = false;
  export let isOutline = false;
  export let isPill = false;
  export let isActive = false;
  export let isLoading = false;

  $: classes =
    disabled || isOutline || isPill
      ? ""
      : `button text-white focus:ring-4 focus:outline-none ${customColor === "" ? "bg-primary-500 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-500 dark:hover:bg-primary-700 dark:focus:ring-primary-800" : customColor}  font-medium rounded-lg px-4 py-2.5`;

  // `button
  //       px-4
  //       py-2.5
  //       rounded-lg
  //       font-medium
  //       text-primary-500
  //       border
  //       border-primary-500
  //       hover:bg-primary-800
  //       focus:ring-1
  //       focus:ring-primary-300
  //       focus:outline-none
  //       dark:border-zinc-500
  //       dark:text-slate-100
  //       dark:hover:ring-primary-700
  //       dark:focus:ring-primary-800`

  $: outlineClasses =
    isOutline === true
      ? `button
      px-4
      py-2
      rounded-lg
      font-medium
      text-slate-700
      ring-inset
      ring-1
      ring-slate-200

      focus:ring-2
      focus:ring-slate-400
      focus:outline-none

      hover:ring-slate-400

      dark:ring-zinc-600
      dark:text-slate-200

      dark:focus:ring-zinc-500

      dark:hover:ring-zinc-300`
      : "";

  $: disabledClasses =
    disabled === true
      ? "button text-slate-200 bg-slate-500 font-medium rounded-lg px-5 py-2.5"
      : "";

  $: pillClasses =
    isPill === true
      ? `${isActive ? "bg-primary-700 dark:bg-primary-700" : "bg-primary-500 hover:bg-primary-700 dark:bg-primary-500 dark:hover:bg-primary-700"} focus:ring-primary-300 dark:focus:ring-primary-800 focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-2.5 py-2.5 text-center me-2 mb-2`
      : "";
</script>

{#if isLoading}
  <div class={myClasses}>
    <MiniSpinner />
  </div>
{:else if type === "button"}
  <button
    data-cy={cy}
    {id}
    {disabled}
    class=" {myClasses} {classes} {disabledClasses} {outlineClasses} {pillClasses} flex items-center gap-2"
    type="button"
    on:click|stopPropagation
  >
    <slot />
  </button>
{:else if type === "submit"}
  <button
    data-cy={cy}
    {id}
    {disabled}
    class=" {myClasses} {classes} {disabledClasses} {outlineClasses} {pillClasses} flex items-center gap-2"
    type="submit"
    on:click|stopPropagation
  >
    <slot />
  </button>
{/if}

<style>
  .button {
    font-size: 0.825rem; /* 12px */
    line-height: 1rem; /* 16px */
  }
  /* .button {
    height: 36px;
    white-space: nowrap;
    overflow: hidden;
    width: fit-content;
    padding-left: 24px;
    padding-right: 24px;
    font-size: small;
    background-color: #319795;
    border-radius: 0.375rem;
  }
  .outline-button {
    height: 36px;
    width: fit-content;
    padding-left: 12px;
    padding-right: 12px;
    font-size: small;
    background-color: #fff;
    border: 2px solid #319795;
    border-radius: 0.375rem;
  } */
</style>
