<script lang="ts">
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { getWithJwt, isClient, serverlessRoutes, translate } from "lib";
  import { theme, user } from "stores";
  import { language } from "../../stores/languageStore";
  import { version } from "../../lib/constants";
  import { logout } from "../../lib/logout";
  import SettingsItem from "../../components/SettingsItem.svelte";
  import Avatar from "../../components/UI/Avatar.svelte";

  // mora user? jer kada se pozove logout, users se setuje na null

  let finishedWorkoutCount = 0;

  const fetchFinishedWorkoutCount = async (): Promise<void> => {
    try {
      const response = await getWithJwt(`${serverlessRoutes.WORKOUT}/finished-count`);

      finishedWorkoutCount = response.data.count;
    } catch (error) {
      console.error(error);
    }
  };

  onMount(async (): Promise<void> => {
    if (isClient($user)) {
      await fetchFinishedWorkoutCount();
    }
  });
</script>

<svelte:head>
  <style>
    body {
      overflow: hidden;
    }
  </style>
</svelte:head>

{#key $language}
  {#key $theme}
    <!-- Ovo je da se force updatuje settings screen kada se promeni jezik -->
    <div class="h-screen flex flex-col items-center justify-center">
      <Avatar avatarUrl={$user?.avatarUrl} alt="User Avatar" size={88} />
      <br />
      <h3>
        {translate("WELCOME")}, {$user?.name}
      </h3>
      <br />
      <br />
      {#if isClient($user)}
        <div class="flex-row center-center" style="width: 97%;">
          <SettingsItem
            title={"TOTAL_NUMBER_OF_WORKOUTS"}
            iconName={"trophy"}
            custom={"workout_count"}
            execFunction={() => {}}
          />
          <span class="text-bold">{finishedWorkoutCount}</span>
        </div>
      {/if}
      <SettingsItem
        title={"PROFILE"}
        iconName={"user"}
        execFunction={() => {
          push("/settings/profile");
        }}
      />
      {#if $user?.id === 15 || $user?.id === 13 || $user?.id === 17833 || $user?.id === 87649}
        <SettingsItem
          title={"ADVANCED_SETTINGS"}
          iconName={"advanced-settings"}
          execFunction={() => {
            push("/settings/advanced-settings");
          }}
        />
      {/if}
      <!-- <SettingsItem
        title={"NOTIFICATIONS"}
        iconName={"bell"}
        execFunction={() => {}}
      /> -->
      <SettingsItem
        title={"LANGUAGE"}
        iconName={"globe"}
        custom={"language"}
        execFunction={() => {}}
      />
      <SettingsItem title={"THEME"} iconName={"theme"} custom={"theme"} execFunction={() => {}} />
      <!-- <SettingsItem
        title={"SUBSCRIPTION"}
        iconName={"money"}
        execFunction={() => push("/settings/subscription")}
      /> -->
      <SettingsItem
        title={"REPORT_A_BUG_SUGGESTION"}
        iconName={"bug"}
        execFunction={() => {
          push("/settings/bug-suggestion");
        }}
      />
      <!-- <SettingsItem
        title={"HELP"}
        iconName={"help"}
        execFunction={() => {}}
      /> -->
      <SettingsItem title={"SIGN_OUT"} iconName={"logout"} execFunction={logout} />
      <div class="mt-8 text-xxs">
        {translate("APP_VERSION")}: {version}
      </div>
    </div>
  {/key}
{/key}
