<script lang="ts">
  import { onMount } from "svelte";
  import { Spinner } from "flowbite-svelte";
  import { getWithJwt, patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import { tags } from "../../../stores/trainerStores";
  import { currentClient, dialogData, showAlert, trainerClientsStore } from "stores";
  import { ButtonComponent, InputField } from "ui";
  import type { User } from "../../../interfaces/User";

  let data: any;
  let disabled = true;
  let isLoading = false;
  let isPasswordVisible = false;
  let clientTags: any[] = [];

  const dialogType = data.client ? translate("EDIT") : translate("CREATE");

  const genders = ["MALE", "FEMALE"]; //based
  // const dailyGoals = ["BY_CALORIES", "BY_GRAMS_OF_MACRONUTRIENTS"];

  const areaCodes = [
    { value: "+1", name: "+1" },
    { value: "+30", name: "+30" },
    { value: "+31", name: "+31" },
    { value: "+32", name: "+32" },
    { value: "+33", name: "+33" },
    { value: "+34", name: "+34" },
    { value: "+36", name: "+36" },
    { value: "+39", name: "+39" },
    { value: "+43", name: "+43" },
    { value: "+44", name: "+44" },
    { value: "+45", name: "+45" },
    { value: "+46", name: "+46" },
    { value: "+47", name: "+47" },
    { value: "+48", name: "+48" },
    { value: "+49", name: "+49" },
    { value: "+351", name: "+351" },
    { value: "+352", name: "+352" },
    { value: "+353", name: "+353" },
    { value: "+354", name: "+354" },
    { value: "+355", name: "+355" },
    { value: "+356", name: "+356" },
    { value: "+358", name: "+358" },
    { value: "+359", name: "+359" },
    { value: "+370", name: "+370" },
    { value: "+371", name: "+371" },
    { value: "+381", name: "+381" },
    { value: "+382", name: "+382" },
    { value: "+385", name: "+385" },
    { value: "+386", name: "+386" },
    { value: "+387", name: "+387" },
    { value: "+389", name: "+389" },
  ];

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const phoneNumberRegex = /^\+\d+$/;

  const form = {
    // Core
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    expireAt: { value: "", error: "" },
    // gender: { value: "MALE", error: "" },
    dateOfBirth: { value: "", error: "" },
    height: { value: "", error: "" },
    areaCode: { value: "", error: "" },
    phoneNumber: { value: "", error: "" },
  };

  let emailLookupTimeout: NodeJS.Timeout;

  const onInput = async (field: keyof typeof form) => {
    if (field === "name") {
      const { value } = form[field];

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 40) {
        form[field].error = translate("FIELD_MAXIMUM_40");
      } else {
        form[field].error = "";
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    if (field === "email") {
      clearTimeout(emailLookupTimeout);

      const { value } = form[field];
      const isValid = emailRegex.test(value);

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (!isValid) {
        form[field].error = translate("EMAIL_INVALID");
      } else {
        // ako je edit, i ako je client.email === value, error = "";
        // ako je edit, i ako client.email != value, error = lookup;
        // ako je create, error = lookup
        if (data.client) {
          if (data.client.email !== form[field].value) {
            emailLookupTimeout = setTimeout(async (): Promise<void> => {
              const response = await getWithJwt(
                `${serverlessRoutes.EMAIL_LOOKUP}?email=${form[field].value}`
              );

              if (response.data !== "USER_NOT_FOUND") {
                form[field].error = translate("EMAIL_TAKEN");
              } else {
                form[field].error = "";
              }

              disabled = Object.values(form).some((field): boolean => field.error !== "");
            }, 500);
          } else {
            form[field].error = "";
            disabled = Object.values(form).some((field): boolean => field.error !== "");
          }
        } else {
          emailLookupTimeout = setTimeout(async (): Promise<void> => {
            const response = await getWithJwt(
              `${serverlessRoutes.EMAIL_LOOKUP}?email=${form[field].value}`
            );

            if (response.data !== "USER_NOT_FOUND") {
              form[field].error = translate("EMAIL_TAKEN");
            } else {
              form[field].error = "";
            }

            disabled = Object.values(form).some((field): boolean => field.error !== "");
          }, 500);
        }
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    if (field === "password") {
      const { value } = form[field];

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 4) {
        form[field].error = translate("FIELD_MINIMUM_4");
      } else if (value.length > 20) {
        form[field].error = translate("FIELD_MAXIMUM_20");
      } else {
        form[field].error = "";
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    if (field === "phoneNumber") {
      const { value } = form[field];

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (!phoneNumberRegex.test(value)) {
        form[field].error = translate("PHONE_NUMBER_INVALID");
      } else if (value.length > 15) {
        form[field].error = translate("FIELD_MAXIMUM_15");
      } else {
        form[field].error = "";
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    if (field === "height") {
      const { value } = form[field];
      if (value < 100 || value > 999) {
        form[field].error = translate("FIELD_THREE_DIGITS");
      } else {
        form[field].error = "";
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }
  };

  const createClient = async (): Promise<void> => {
    try {
      const partialUser: Partial<User> = {};
      Object.keys(form).forEach((key) => {
        if (form[key].value !== "") {
          partialUser[key] = form[key].value;
        }
      });
      const editingClient = data.client ? data.client.id : "";

      if (editingClient) {
        partialUser.id = editingClient;
        const url = serverlessRoutes.USER;
        await patchWithJwt(url, { user: partialUser });
        if ($currentClient.id === data.client.id) {
          $currentClient = { ...$currentClient, ...partialUser };
        }
        let foundUser = $trainerClientsStore.items.find((client) => client.id === editingClient);
        foundUser = { ...foundUser, ...partialUser };
        trainerClientsStore.replace([foundUser]);
        $showAlert.message = `
          ${translate("SUCCESSFULLY_EDITED")}
          ${translate("CLIENT").toLowerCase()}
        `;
      } else {
        const url = serverlessRoutes.TRAINER_CLIENT;
        const response = await postWithJwt(url, partialUser);
        trainerClientsStore.add([response.data]);
        $showAlert.message = `
          ${translate("SUCCESSFULLY_CREATED")}
          ${translate("CLIENT").toLowerCase()}
        `;
      }
      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_CLIENT");
    }
  };

  const resetPassword = async () => {
    if (!data?.client) return;
    try {
      const url = serverlessRoutes.USER;
      const partialUser: Partial<User> = {
        id: data.client.id,
        password: "treniraj",
      };
      await patchWithJwt(url, { user: partialUser });
      $showAlert.message = translate("SUCCESSFULLY_RESET_PASSWORD");
      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;
    await createClient();
  };

  onMount(async () => {
    clientTags = $tags.map(({ id, color, note }) => ({
      id,
      color: `bg-[${color}]`,
      note,
    }));

    if (data.client) {
      form.name.value = data.client.name;
      await onInput("name");
      form.email.value = data.client.email;
      await onInput("email");
      form.password = { value: "", error: "" };
      form.phoneNumber = { value: data.client.phoneNumber, error: "" };
    } else {
      form.password.value = "treniraj";
    }
  });

  export { data };
</script>

<div class="p-4">
  <h3 class="text-bold text-xl text-center">
    {dialogType}
    {translate("CLIENT_A").toLowerCase()}
  </h3>

  <form autocomplete="off" class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <InputField
      label={translate("NAME")}
      error={form.name.error}
      bind:value={form.name.value}
      on:input={() => onInput("name")}
    />

    <InputField
      label={translate("EMAIL")}
      error={form.email.error}
      bind:value={form.email.value}
      on:input={() => onInput("email")}
    />

    <InputField
      type="password"
      label={translate("PASSWORD")}
      error={form.password.error}
      bind:value={form.password.value}
      on:input={() => onInput("password")}
    />

    <InputField
      label={translate("PHONE_NUMBER")}
      placeholder="+381 1234 567 890"
      error={form.phoneNumber.error}
      bind:value={form.phoneNumber.value}
      on:input={() => onInput("phoneNumber")}
    />

    <InputField
      type="date"
      label={translate("EXPIRATION_DATE")}
      error={form.expireAt.error}
      bind:value={form.expireAt.value}
      on:input={() => onInput("expireAt")}
    />

    <InputField
      type="date"
      label={translate("DATE_OF_BIRTH")}
      error={form.dateOfBirth.error}
      bind:value={form.dateOfBirth.value}
      on:input={() => onInput("dateOfBirth")}
    />

    <InputField
      type="number"
      label={translate("HEIGHT")}
      placeholder="180"
      suffix="cm"
      error={form.height.error}
      bind:value={form.height.value}
      on:input={() => onInput("height")}
    />

    <div class="h-10 flex justify-around">
      {#if data.client}
        <ButtonComponent customColor="bg-red-700" on:click={resetPassword}
          >{translate("RESET_PASSWORD")}</ButtonComponent
        >
      {/if}
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent on:click={onSubmit} {disabled}>{dialogType}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
