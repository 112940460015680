<script lang="ts">
  import { onMount } from "svelte";
  import { Input, Label, Textarea, Helper, Heading, P, Spinner } from "flowbite-svelte";
  import { patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import {
    dialogData,
    showAlert,
    trainerClientPrograms,
    trainerPrograms,
    workoutsStore,
  } from "stores";
  import { ButtonComponent } from "ui";

  let data: any;

  const form = {
    name: { value: "", error: "" },
    description: { value: "", error: "" },
  };

  let disabled = true;
  let isLoading = false;
  const dialogType = data.exercise ? translate("EDIT") : translate("CREATE");

  const onInput = (field: keyof typeof form): void => {
    const { value } = form[field];

    if (field === "name") {
      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some(({ error }) => error !== "");
  };

  const editHeaderWorkout = async (): Promise<void> => {
    isLoading = true;

    try {
      const response = await patchWithJwt(serverlessRoutes.EXERCISE, {
        partialExerciseMap: {
          [data.exercise.id]: {
            name: form.name.value,
            description: form.description.value,
          },
        },
      });

      if (data.programId) {
        $trainerPrograms.forEach((program) => {
          if (program.id === data.programId && program.workouts) {
            program.workouts.forEach((w) => {
              if (w.id === data.exercise.workoutId && w.exercises) {
                const i = w.exercises.indexOf(data.exercise);
                if (i > -1) w.exercises.splice(i, 1, response.data.exercises[0]);
              }
            });
          }
        });

        $trainerClientPrograms.forEach((program) => {
          if (program.id === data.programId && program.workouts) {
            program.workouts.forEach((w) => {
              if (w.id === data.exercise.workoutId && w.exercises) {
                const i = w.exercises.indexOf(data.exercise);
                if (i > -1) w.exercises.splice(i, 1, response.data.exercises[0]);
              }
            });
          }
        });

        $trainerPrograms = $trainerPrograms;
        $trainerClientPrograms = $trainerClientPrograms;
      } else {
        workoutsStore.replaceExercises(data.exercise.workoutId, [response.data.exercises[0]]);
      }

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")} ${translate("WORKOUT")}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_EDITING_WORKOUT");
    }
  };

  const createHeaderWorkout = async (): Promise<void> => {
    isLoading = true;

    try {
      const response = await postWithJwt(serverlessRoutes.EXERCISE, {
        partialExercise: {
          name: form.name.value,
          description: form.description.value,
          entityType: 1,
          workoutId: data.workout.id,
        },
      });

      if (data.workout.default) {
        workoutsStore.addExercises(data.workout.id, [response.data.exercise]);
      } else {
        // Workout u program
        $trainerPrograms.forEach((program) => {
          if (program.id === data.programId && program.workouts) {
            program.workouts.forEach((w) => {
              if (w.id === data.workout.id && w.exercises) {
                w.exercises.push(response.data.exercise);
              } else {
                w.exercises = [response.data.exercise];
              }
            });
          }
        });
        $trainerPrograms = $trainerPrograms;

        $trainerClientPrograms.forEach((program) => {
          if (program.id === data.programId && program.workouts) {
            program.workouts.forEach((w) => {
              if (w.id === data.workout.id && w.exercises) {
                w.exercises.push(response.data.exercise);
              } else {
                w.exercises = [response.data.exercise];
              }
            });
          }
        });
        $trainerClientPrograms = $trainerClientPrograms;
      }

      $dialogData.type = "";
      $dialogData.data = {};

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")} ${translate("WORKOUT")}
      `;
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_WORKOUT");
    }
  };

  const onSubmit = async (): Promise<void> => {
    if (data.exercise) {
      await editHeaderWorkout();
    } else {
      await createHeaderWorkout();
    }
  };

  onMount((): void => {
    if (data.exercise) {
      const { name, description } = data.exercise;

      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {dialogType}
    {translate("HEADER").toLowerCase()}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <div>
      <Label for="name">{translate("NAME")}:</Label>
      <Input
        id="Naziv"
        placeholder={translate("NAME")}
        bind:value={form.name.value}
        on:input={() => onInput("name")}
      />
      <Helper color="red">
        {#if form.name.error}
          {form.name.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div>
      <Label for="description">
        {translate("DESCRIPTION")}:
      </Label>
      <Textarea
        id="Opis"
        placeholder={translate("DESCRIPTION")}
        rows="4"
        bind:value={form.description.value}
        on:input={() => onInput("description")}
      />
      <Helper color="red">
        {#if form.description.error}
          {form.description.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <P size="xs" justify>
      <b>*{translate("NOTE")}:</b>
      {translate("HEADERS_WORKOUT_NOTE")}
    </P>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent cy="create-edit-header-button" type="submit" {disabled}
          >{dialogType}</ButtonComponent
        >
      {/if}
    </div>
  </form>
</div>
