import { serverlessRoutes } from "./constants";
import { getWithJwt } from "./requests";
import { tags } from "../stores/trainerStores";
import { user } from "stores";
import { get } from "svelte/store";
import { isClient } from "./roles";

export const fetchTags = async () => {
  const currentUser = get(user);
  if (isClient(currentUser)) return;
  try {
    const response = await getWithJwt(`${serverlessRoutes.TAGS}/list`);
    tags.set(response.data);
  } catch (error) {
    tags.set([]);
    console.error(error);
  }
};
