<script lang="ts">
  import { onMount } from "svelte";
  import { Heading, Label, Radio } from "flowbite-svelte";
  import { postWithJwt, translate, getWithJwt, serverlessRoutes } from "lib";
  import { dialogData, showAlert } from "stores";
  import { ButtonComponent } from "ui";

  let data: any;
  let users: any[] = [];
  let selectedUserId: number;

  const onSubmit = async (): Promise<void> => {
    try {
      const response = await postWithJwt(`${serverlessRoutes.TRAINER_CLIENT}/change-trainer`, {
        newTrainerId: selectedUserId,
        clientId: data.client.id,
      });

      $showAlert.color = "black";
      $showAlert.message = "Klijent uspesno dodeljen drugom treneru";

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = "Doslo je do greske pri dodeljivanju klijenta";
    }
  };

  onMount(async (): Promise<void> => {
    const response = await getWithJwt(serverlessRoutes.TRAINER_GROUP);
    users = response.data;
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {translate("TRANSFER_TO_ANOTHER_TRAINER")}
  </Heading>

  <div class="h-80 pr-4 flex flex-col gap-4 overflow-y-scroll">
    {#each users as user}
      <Label class="flex items-center border rounded-md">
        <div class="h-16 w-16 mr-4 overflow-hidden flex items-center">
          <img class="rounded-full" src={user.avatarUrl} alt="Avatar" />
        </div>
        <div class="grow">
          {user.name}<br />
          {user.email}
        </div>
        <Radio value={user.id} bind:group={selectedUserId} />
      </Label>
    {/each}
  </div>

  <div class="flex justify-center">
    <ButtonComponent on:click={onSubmit}>{translate("ASSIGN")}</ButtonComponent>
  </div>
</div>
