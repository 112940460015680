<script lang="ts">
  import { Heading, Helper, Label, Spinner, Textarea } from "flowbite-svelte";
  import { postWithJwt, serverlessRoutes, translate } from "lib";
  import { dialogData, showAlert } from "stores";
  import { ButtonComponent } from "ui";

  let data: any;
  let isLoading = false;
  let disabled = false;

  const form = {
    dateOfDispatch: { value: "", error: "" },
    title: { value: "", error: "" },
    message: { value: "", error: "" },
    email: { value: false, error: "" },
    sms: { value: false, error: "" },
    inApp: { value: false, error: "" },
    sendNow: { value: false, error: "" },
  };

  const getAction = (): number => {
    // action:

    // samo email:	0
    // samo sms:	1
    // samo in-app:	2
    // email + sms:	3
    // email + in-app:	4
    // sms + in-app:	5

    // email_many
    // sms_many
    // message_many
    const { email, sms, inApp } = form;

    if (email.value && !sms.value && !inApp.value) {
      return 0;
    } else if (!email.value && sms.value && !inApp.value) {
      return 1;
    } else if (!email.value && !sms.value && inApp.value) {
      return 2;
    } else if (email.value && sms.value && !inApp.value) {
      return 3;
    } else if (email.value && !sms.value && inApp.value) {
      return 4;
    } else {
      return 5;
    }
  };

  const onInput = (field: keyof typeof form): void => {};

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.ids) {
      const url = `${serverlessRoutes.MESSAGE}/queue`;
      const postData = {
        clientIds: data.ids,
        payload: form.message.value,
      };

      try {
        const res = await postWithJwt(url, postData);

        if (res.data.message === "Success.") {
          $showAlert.color = "black";
          $showAlert.message = translate("MESSAGES_SENT");

          $dialogData.type = "";
          $dialogData.data = {};
        }
      } catch (error) {
        isLoading = false;
        console.error(error);
      }
    }
  };

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {translate("SEND_MESSAGE_U")}
  </Heading>

  <form on:submit|preventDefault={onSubmit}>
    <!-- {#if !form.sendNow.value}
      <DatePicker  bind:date="{form.dateOfDispatch.value}"/>
    {/if}

    {#if form.email.value}
      <Label for="title">{translate("TITLE")}:</Label>
      <Input
        id="title"
        placeholder="{translate("TITLE")}"
        bind:value="{form.title.value}"
        on:input="{() => onInput("title")}"/>
      <Helper color="red">
        {#if form.title.error}
          {form.title.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    {/if} -->

    <div>
      <Label for="message">{translate("MESSAGE")}:</Label>
      <Textarea
        id="message"
        placeholder={translate("MESSAGE")}
        rows="4"
        bind:value={form.message.value}
        on:input={() => onInput("message")}
      />
      <Helper color="red">
        {#if form.message.error}
          {form.message.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <!-- <Label>{translate("DISPATCH_METHOD")}:</Label>
    <Checkbox bind:checked="{form.email.value}">Email</Checkbox>
    <Checkbox bind:checked="{form.sms.value}">SMS</Checkbox>
    <Checkbox bind:checked="{form.inApp.value}">{translate("IN_APP")}</Checkbox>

    {#if data.ids && data.ids.length > 1}
      <Checkbox bind:checked="{form.sendNow.value}">{translate("SEND_NOW")}</Checkbox>
    {/if} -->

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent type="submit" {disabled}>{translate("SEND")}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
