<script lang="ts">
  import { onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { animations, deleteWithJwt, dialogTypes, translate } from "lib";
  import { dialogData, selectedIdsStore, showAlert, trainerExercisesStore, user } from "stores";
  import { ButtonComponent, InfiniteScroll, Search } from "ui";
  import TopMenu from "../../components/TopMenu.svelte";
  import ExerciseInWorkout from "../../components/Training/ExerciseInWorkout.svelte";
  import SelectMany from "../../components/Training/SelectMany.svelte";
  import { serverlessRoutes } from "../../lib/constants";
  import { Capacitor } from "@capacitor/core";
  import ExercisesFilter from "../../components/Training/ExercisesFilter.svelte";

  let isSelectMode = false;

  const onEditMode = (): void => {
    selectedIdsStore.clear();
    isSelectMode = !isSelectMode;
  };

  const onSubmit = (event: CustomEvent<string>): void => {
    const value = event.detail;

    if (value === "SAVE") {
      $dialogData.data = { exerciseIds: $selectedIdsStore };
      $dialogData.type = dialogTypes.CREATE_EDIT_WORKOUT;
    } else if (value === "DELETE") {
      $dialogData.data = {
        title: "više vežbi",
        async executeFunction(): Promise<void> {
          try {
            await deleteWithJwt(serverlessRoutes.EXERCISE, {
              ids: $selectedIdsStore,
            });

            trainerExercisesStore.remove($selectedIdsStore);

            selectedIdsStore.clear();
            $showAlert.color = "black";
            $showAlert.message = `Obrisano više vežbi.`;
          } catch (error) {
            console.error(error);
            $showAlert.color = "red-400";
            $showAlert.message = `Greška pri brisanju.`;
          }
        },
      };
      $dialogData.type = dialogTypes.CONFIRM_DELETE;
    } else if (value === "MARK") {
      $dialogData.data = {};
      $dialogData.type = dialogTypes.SELECT_MUSCLE_GROUP;
    }
  };

  const onAddExercise = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_EXERCISE;
  };

  onMount(() => {
    trainerExercisesStore.loadCache();
    trainerExercisesStore.fetchData();
  });
</script>

<svelte:head>
  {#if Capacitor.isNativePlatform()}
    <style>
      body {
        overflow: hidden;
      }
    </style>
  {/if}
</svelte:head>

<TopMenu entity="TRAINING" />

<div class="p-4 flex flex-col gap-4">
  <div class="flex gap-4">
    <Search
      cy="search-exercises-input"
      placeholder={translate("SEARCH_EXERCISES")}
      bind:value={$trainerExercisesStore.filter.search}
      on:input={trainerExercisesStore.search}
    />
    <ButtonComponent
      cy="add-exercise-button"
      myClasses="whitespace-nowrap"
      on:click={onAddExercise}
    >
      {translate("ADD")}
      {translate("EXERCISE_U").toLowerCase()}
    </ButtonComponent>
  </div>

  <ExercisesFilter />

  {#each $trainerExercisesStore.items as exercise (exercise.id)}
    <div animate:flip={animations.flip}>
      <ExerciseInWorkout {isSelectMode} {exercise} />
    </div>
  {/each}

  <InfiniteScroll
    hasMore={$trainerExercisesStore.hasMore}
    isFetchingMore={$trainerExercisesStore.isFetchingMore}
    on:loadMore={trainerExercisesStore.fetchMoreData}
  />

  {#if isSelectMode}
    <SelectMany
      items={[
        { name: "Sačuvaj u novi trening", value: "SAVE" },
        { name: "Obriši", value: "DELETE" },
        { name: "Oznaka", value: "MARK" },
      ]}
      count={$selectedIdsStore.length}
      on:selectMany={onSubmit}
    />
  {/if}

  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
