<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import Router, { replace, location } from "svelte-spa-router";
  import Menu from "./components/Menu/Menu.svelte";
  import { dialogTypes, serverlessRoutes } from "./lib/constants";
  import { getWithJwt } from "./lib/requests";
  import { user } from "./stores/userStore";
  import { theme } from "./stores/themeStore";
  import { routes } from "./lib/routes";
  import Dialog from "./components/Dialogs/Dialog.svelte";
  import { dialogData } from "./stores/dialogDataStore";
  import { socketV2 } from "./stores/socketStore";
  import Overlay from "./components/UI/Overlay.svelte";
  import Alert from "./components/UI/Alert.svelte";
  import { showAlert } from "./stores/showAlertStore";
  import { Capacitor } from "@capacitor/core";
  import { StatusBar, Style } from "@capacitor/status-bar";
  import { Keyboard } from "@capacitor/keyboard";
  import { App } from "@capacitor/app";
  import { fetchUser } from "./lib/fetchUser";
  import { fetchChatSessions } from "./lib/fetchSessions";
  import { chatSessions, chatSessionsCount, showOnlyUnreadChat } from "./stores/chatSessionsStore";
  import { isClient } from "./lib/roles";
  import { alertStore } from "stores";
  import { flip } from "svelte/animate";
  import { storage } from "lib";
  import OnlineIndicator from "./components/OnlineIndicator.svelte";

  let interval;
  let showOnlineIndicator = false;

  const setTheme = async () => {
    $theme = await storage.get("theme");

    if ($theme === "dark") {
      document.documentElement.classList.add("dark");
      if (Capacitor.isNativePlatform()) await StatusBar.setStyle({ style: Style.Dark });
    } else {
      document.documentElement.classList.remove("dark");
      if (Capacitor.isNativePlatform()) await StatusBar.setStyle({ style: Style.Light });
    }
  };

  let unsubscribeLocation = location.subscribe((): void => {
    window.scrollTo({ top: 0 });
  });

  onMount(async () => {
    setTimeout(async () => {
      if (isClient($user)) {
        if ($user.id !== 216072) {
          // cypress
          let dontShowSetDateOfBirth = await storage.get("dontShowSetDateOfBirth");

          const response = await getWithJwt(serverlessRoutes.USER_DATA);

          if (!response.data.user?.dateOfBirth) {
            if (
              !dontShowSetDateOfBirth ||
              (dontShowSetDateOfBirth && dontShowSetDateOfBirth === false)
            ) {
              $dialogData.data = {};
              $dialogData.type = dialogTypes.SET_DATE_OF_BIRTH;
            }
          }
        }
      }
    }, 1000);

    if (Capacitor.isNativePlatform()) {
      App.addListener("appStateChange", async ({ isActive }) => {
        if (isActive) {
          await fetchUser();
          await fetchChatSessions();
        }
      });
    }
    App.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        App.exitApp();
      } else {
        window.history.back();
      }
    });
    if ($location === "/" && $user) {
      if (isClient($user)) await replace("/training/program");
      else await replace("/clients/list");
    }

    const root = document.documentElement;
    if ($theme === "light") {
      root.setAttribute("data-theme", "light");
      root.style.setProperty("--date-picker-background", "#FFFFFF");
      root.style.setProperty("--date-picker-foreground", "#27272A");
    } else {
      root.setAttribute("data-theme", "dark");
      root.style.setProperty("--date-picker-background", "#27272A");
      root.style.setProperty("--date-picker-foreground", "#FFFFFF");
    }

    await setTheme();
    showOnlineIndicator = await storage.get("showOnlineIndicator");
  });

  const unsubscribeSocket = socketV2.subscribe((res) => {
    if (res && res.connected === true && $user && isClient($user)) {
      $socketV2.emit("subscribe", { event: "changeTrainer", id: $user.id });
      $socketV2.on("changeTrainer", (event) => {
        fetchUser();
        replace("/training/program");
      });
    }
  });

  setTimeout(() => {
    showOnlyUnreadChat.subscribe((res) => {
      if (res !== -1) {
        chatSessions.set([]);
        chatSessionsCount.set(0);
        fetchChatSessions();
      }
    });
  }, 3000);

  const socketDisconnect = () => {
    if ($socketV2.connected === true && $user) {
      $socketV2.emit("unsubscribe", { event: "changeTrainer", id: $user.id });
      $socketV2.off("changeTrainer");
    }
  };

  onDestroy(() => {
    unsubscribeLocation();
    unsubscribeSocket();
    socketDisconnect();
    $socketV2.disconnect();
    clearInterval(interval);
  });

  const conditionsFailed = (event) => {
    // console.error('conditionsFailed event', event.detail)
    replace("/auth");
  };
</script>

<div
  class={`${Capacitor.getPlatform() === "android" ? "android-margin" : ""} relative flex-col center-center bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 ${Capacitor.isNativePlatform() ? "extra-height" : ""}`}
>
  <div class="content-container w-full min-h-screen max-w-screen-sm" id="contentContainer">
    <Router {routes} on:conditionsFailed={conditionsFailed} />
  </div>
  {#if $dialogData.type}
    <Overlay />
    <Dialog isScrollable={$dialogData.type === dialogTypes.COMPARE_PHOTOS ? false : true} />
  {/if}
  <Menu />
  {#if showOnlineIndicator}
    <OnlineIndicator />
  {/if}
  {#if $showAlert.message !== ""}
    <Alert />
  {/if}
  {#if $alertStore.length > 0}
    <div class="p-2 fixed bottom-12 left-0 w-full flex flex-col gap-2">
      {#each $alertStore as alert (alert.id)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="p-2 bg-white text-center border border-slate-300 rounded shadow-lg text-{alert.type ===
          'primary'
            ? 'black'
            : 'red-400'}"
          animate:flip={{ duration: 333 }}
          on:click={() => alertStore.hide(alert.id)}
        >
          {alert.message}
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
  .android-margin {
    margin-top: env(safe-area-inset-top);
  }
  .extra-height {
    height: calc(100vh - 48px);
  }
</style>
