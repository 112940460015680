<script lang="ts">
  import { socketV2 } from "stores/socketStore";

  let online = false;

  socketV2.subscribe((socket) => {
    if (socket) online = socket.connected;
  });
</script>

<!-- Online indicator dot -->
<div class="online-indicator" class:online></div>

<style>
  .online-indicator {
    position: fixed;
    top: 4px;
    right: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(222, 4, 4); /* Offline color */
    z-index: 1000;
  }
  .online-indicator.online {
    background-color: green; /* Online color */
  }
</style>
