<script lang="ts">
  import { onMount } from "svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import { entities, serverlessRoutes } from "../../lib/constants";
  import { ExpansionPanel } from "ui";
  import { getWithJwt, parseDate } from "lib";
  import { push } from "svelte-spa-router";

  let selectedDate = new Date();
  const today = new Date();
  let incrementWeekCounter = 1;
  let paymentsByDateMap: { [key: string]: Array<any> } = {};

  const incrementDate = (n: number) => {
    if (n > 0 && selectedDate >= today) {
      return;
    }
    selectedDate.setDate(selectedDate.getDate() + n);
    selectedDate = selectedDate;
    const passedWeek = new Date();
    passedWeek.setDate(passedWeek.getDate() - incrementWeekCounter * 7);
    if (selectedDate < passedWeek) {
      // do stuff?
    }
  };

  const getTotalForDate = (paymentsByDate: Array<any>): string => {
    const { EUR, RSD, USD } = paymentsByDate.reduce(
      (acc, cur) => {
        acc[cur.currency] += cur.amount;
        return acc;
      },
      { EUR: 0, RSD: 0, USD: 0 }
    );

    let finalString = "";

    if (EUR) finalString += `${EUR} EUR`;
    if (RSD) finalString += ` ${RSD} RSD`;
    if (USD) finalString += ` ${USD} USD`;

    return finalString;
  };

  const onGotoClientProfile = async (clientId: number): Promise<void> => {
    await push(`/profile/${clientId}?view=payment`);
  };

  onMount(async () => {
    const response = await getWithJwt(`${serverlessRoutes.PAYMENT}/all`);

    response.data.items.forEach((payment: any): void => {
      const slicedDate = parseDate(payment.date.slice(0, 10));
      let paymentsByDate = paymentsByDateMap[slicedDate];

      if (paymentsByDate) {
        paymentsByDate.push(payment);
      } else {
        paymentsByDateMap[slicedDate] = [payment];
      }
    });
  });
</script>

<TopMenu entity={entities.CLIENTS} />
<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="p-4 flex flex-col gap-4">
  <!-- ----- OVO TREBA DA SE VRATI KAD SE SREDI REQUEST ZA OVAJ SCREEN ----- -->

  <!-- <DateIncrementer
    incrementDate="{incrementDate}"
    selectedDateDecremented="{selectedDate}"
  />

  <div class="flex justify-center">
    <ButtonGroup buttons={[
      {title: "Nedelja", onClick: () => {}},
      {title: "Mesec", onClick: () => {}},
      {title: "Tri meseca", onClick: () => {}},
    ]}/>
  </div> -->

  <!-- --------------------------------------------------------------------- -->

  {#each Object.keys(paymentsByDateMap) as date}
    <ExpansionPanel title={date} subtitle={getTotalForDate(paymentsByDateMap[date])}>
      <div class="flex flex-col gap-4">
        {#each paymentsByDateMap[date] as payment}
          <div class="w-full flex justify-between">
            <div class="underline" on:click={() => onGotoClientProfile(payment.clientId)}>
              {payment.name}
            </div>
            <div>{payment.amount} {payment.currency}</div>
          </div>
        {/each}
      </div>
    </ExpansionPanel>
  {/each}
</div>
