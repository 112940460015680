<script lang="ts">
  import {onMount} from "svelte";
  import {dialogTypes, translate} from "lib";
  import {dialogData, habitsStore} from "stores";
  import {ButtonComponent, ExpansionPanel} from "ui";
  import DefaultHabitComponent from "./DefaultHabit.svelte";

  const onCreateDefaultHabit = (): void => {
    $dialogData.type = dialogTypes.CREATE_EDIT_HABIT;
    $dialogData.data = {
      isDefaultHabit: true,
    };
  };

  onMount(habitsStore.fetchData);
</script>

<ExpansionPanel
  title="{translate("DEFAULT_HABITS")}"
  subtitle="{translate("NUMBER_OF_ITEMS")}: {$habitsStore.count}"
>
  <div class="flex flex-col gap-4">
    <div class="text-xs text-justify">
      {translate("SETTINGS_DEFAULT_HABITS_NOTE")}
    </div>
    <div class="flex flex-col gap-2">
      {#each $habitsStore.items as defaultHabit}
        <DefaultHabitComponent {defaultHabit}/>
      {/each}
    </div>
    <div class="flex justify-center">
      <ButtonComponent isOutline on:click={onCreateDefaultHabit}>
        {translate("CREATE_HABIT")}
      </ButtonComponent>
    </div>
  </div>
</ExpansionPanel>
