<script lang="ts">
  import { getWithJwt, postWithJwt, serverlessRoutes } from "lib";
  import Svg from "../UI/Svg.svelte";
  import MiniSpinner from "../UI/MiniSpinner.svelte";
  import { onMount } from "svelte";
  import Message from "./Message.svelte";
  import { payload } from "../../stores/payloadStore";

  export let sessionId;
  export let sendMessage: (payload: string) => Promise<void>;

  let isExpanded = false;

  let now = new Date().toISOString();

  let suggestion = "";

  const fetchSuggestion = async () => {
    try {
      const response = await getWithJwt(
        `${serverlessRoutes.SESSION}/${sessionId}/suggest`
      );
      suggestion = response.data;
    } catch (e) {
      console.error(e);
    }
  };

  const logAction = async (action: string) => {
    try {
      await postWithJwt(`${serverlessRoutes.AI_LOG}`, {
        valueToIncrement: action,
      });
    } catch (e) {
      console.error(e);
    }
  };

  onMount(() => {
    fetchSuggestion();
  });
</script>

{#if !isExpanded}
  <div
    class="fixed flex flex-row text-center justify-center top-16 right-0 left-0 h-fit w-11/12 text-xs bg-white dark:bg-zinc-900 rounded-xl m-auto gap-4 p-4 border border-slate-300 dark:border-zinc-600"
  >
    <div class="flex flex-col justify-center grow">
      <h6 class="text-md font-medium mb-4">Predlog odgovora</h6>
      {#if suggestion === ""}
        <div class="max-w-80 flex items-center justify-center">
          <MiniSpinner />
        </div>
      {:else}
        <div class="flex flex-row">
          <div class="h-full w-full">
            <Message
              message={{
                id: 0,
                senderId: 0,
                ref: "",
                sessionId: 0,
                payload: suggestion,
                createdAt: now,
                updatedAt: now,
                deletedAt: null,
              }}
              isMine={true}
              touchstart={() => {}}
              touchend={() => {}}
            />
          </div>
        </div>
      {/if}
    </div>
    <div
      class="inline-block min-h-fit w-0.5 self-stretch bg-neutral-100 dark:bg-white/10"
    ></div>
    <div class="flex flex-col shrink">
      <div
        tabindex="0"
        role="button"
        class="px-1 py-3"
        on:click={() => (isExpanded = !isExpanded)}
        on:keypress={() => (isExpanded = !isExpanded)}
      >
        <Svg name="collapse" size={24} />
      </div>
      <div
        class="px-1 py-3"
        role="button"
        tabindex="2"
        on:click={() => {
          suggestion = "";
          fetchSuggestion();
          logAction("regenerateCount");
        }}
        on:keydown={() => {
          suggestion = "";
          fetchSuggestion();
          logAction("regenerateCount");
        }}
      >
        <Svg
          name="refresh"
          size={18}
          customColor="bg-slate-900 dark:bg-zinc-200"
        />
      </div>
      <div
        class="px-1 py-3"
        role="button"
        tabindex="2"
        on:click={() => {
          $payload = suggestion;
          logAction("editCount");
        }}
        on:keydown={() => {
          $payload = suggestion;
          logAction("editCount");
        }}
      >
        <Svg
          name="edit"
          size={24}
          customColor="bg-slate-900 dark:bg-zinc-200"
        />
      </div>
      <!-- svelte-ignore a11y-positive-tabindex -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="px-1 py-3"
        role="button"
        tabindex="2"
        on:click={() => {
          //$payload = suggestion;
          sendMessage(suggestion);
          //$payload = "";
          logAction("useCount");
        }}
      >
        <Svg
          name="right-arrow-button"
          size={26}
          customColor="bg-primary-500 dark:bg-primary-500"
        />
      </div>
    </div>
  </div>
{:else}
  <div
    class="fixed flex items-center justify-center top-16 right-4 h-10 w-10 bg-white dark:bg-zinc-900 rounded-xl border border-slate-300 dark:border-zinc-600"
    tabindex="0"
    role="button"
    on:click={() => (isExpanded = !isExpanded)}
    on:keydown={() => (isExpanded = !isExpanded)}
  >
    <Svg name="ai-magic" size={24} />
  </div>
{/if}
