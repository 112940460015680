<script lang="ts">
  import { deleteWithJwt, dialogTypes, serverlessRoutes, translate } from "lib";
  import { dialogData, showAlert, tags } from "stores";
  import { ButtonComponent, ExpansionPanel, More } from "ui";
  import Tag from "../../../../components/Tag.svelte";

  const onCreateTag = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_TAG;
  };
</script>

<ExpansionPanel
  title={translate("CLIENT_TAGS")}
  subtitle="{translate('NUMBER_OF_ITEMS')}: {$tags.length}"
>
  <div class="flex flex-col gap-4">
    <div class="text-xs text-justify">
      {translate("SETTINGS_CLIENT_TAGS_NOTE")}
    </div>
    <div class="flex flex-col gap-2">
      {#each $tags as tag}
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <Tag color={tag.color} />
            <div class="text-xxs">{tag.note}</div>
          </div>
          <More
            menuItems={[
              {
                title: "EDIT",
                icon: "edit",
                executeFunction() {
                  $dialogData.data = { tag };
                  $dialogData.type = dialogTypes.CREATE_EDIT_TAG;
                },
              },
              {
                title: "DELETE",
                icon: "delete",
                executeFunction() {
                  $dialogData.data = {
                    title: translate("TAG_U"),
                    async executeFunction() {
                      try {
                        await deleteWithJwt(`${serverlessRoutes.TAGS}/${tag.id}`);
                        $tags = $tags.filter(({ id }) => id !== tag.id);
                        $showAlert.color = "black";
                        $showAlert.message = `
                        ${translate("SUCCESSFULLY_DELETED")}
                        ${translate("TAG").toLowerCase()}
                      `;
                      } catch (error) {
                        console.error(error);
                      }
                    },
                  };
                  $dialogData.type = dialogTypes.CONFIRM_DELETE;
                },
              },
            ]}
          />
        </div>
      {/each}
    </div>
    <div class="flex justify-center">
      <ButtonComponent isOutline on:click={onCreateTag}>
        {translate("CREATE_TAG")}
      </ButtonComponent>
    </div>
  </div>
</ExpansionPanel>
