<script lang="ts">
  import { dialogTypes, getWithJwt, serverlessRoutes } from "lib";
  import { urlify } from "../../lib/urlify";
  import { dialogData } from "stores";
  import { onMount } from "svelte";
  import Svg from "../../components/UI/Svg.svelte";

  export let params = {};

  let selected = "photo";
  let photoCount = 0;
  let photoSkip = 0;
  let photoData = [];
  let videoCount = 0;
  let videoSkip = 0;
  let videoData = [];
  let linkCount = 0;
  let linkSkip = 0;
  let linkData = [];

  $: fetchData(selected);

  const fetchData = (s) => {
    if (s === "video") fetchVideos();
    if (s === "video") fetchVideos();
    if (s === "link") fetchLinks();
  };

  const fetchImages = async () => {
    const url = `${serverlessRoutes.SESSION}/${params.sessionId}/media?type=image&take=30&skip=${photoSkip}`;
    try {
      const res = await getWithJwt(url);
      res.data = res.data.map((item) => {
        item.payload = item.payload.replace(":cloudflareimg:", "");
        return item;
      });
      photoCount = res.count;
      photoSkip += 15;
      photoData = [...photoData, ...res.data];
    } catch (err) {
      console.error(err);
    }
  };

  const fetchVideos = async () => {
    const url = `${serverlessRoutes.SESSION}/${params.sessionId}/media?type=video&take=30&skip=${videoSkip}`;
    try {
      const res = await getWithJwt(url);
      res.data = res.data.map((item) => {
        item.payload = item.payload.replace(":video:", "");
        return item;
      });
      videoCount = res.count;
      videoSkip += 15;
      videoData = [...videoData, ...res.data];
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLinks = async () => {
    const url = `${serverlessRoutes.SESSION}/${params.sessionId}/media?type=link&take=30&skip=${linkSkip}`;
    try {
      const res = await getWithJwt(url);
      linkCount = res.count;
      linkSkip += 15;
      linkData = [...linkData, ...res.data];
    } catch (err) {
      console.error(err);
    }
  };

  onMount(() => {
    fetchImages();
  });
</script>

<div
  class="w-full h-12 mt-11 flex flex-row justify-around items-center bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 border-b border-slate-300 dark:border-zinc-700"
>
  <div
    on:click={() => (selected = "photo")}
    on:keypress={() => (selected = "photo")}
    class={`
    ${
      selected === "photo"
        ? `text-teal-500 dark:text-primary-500 font-semibold`
        : "text-slate-900 dark:text-slate-100"
    }
    `}
  >
    Fotografije
  </div>
  <div
    on:click={() => (selected = "video")}
    on:keypress={() => (selected = "video")}
    class={`
    ${
      selected === "video"
        ? `text-teal-500 dark:text-primary-500 font-semibold`
        : "text-slate-900 dark:text-slate-100"
    }
    `}
  >
    Video zapisi
  </div>
  <div
    on:click={() => (selected = "link")}
    on:keypress={() => (selected = "link")}
    class={`
    ${
      selected === "link"
        ? `text-teal-500 dark:text-primary-500 font-semibold`
        : "text-slate-900 dark:text-slate-100"
    }
    `}
  >
    Linkovi
  </div>
</div>
{#if selected === "photo"}
  <div class="grid">
    {#each photoData as item}
      <div
        on:click={() => {
          $dialogData.data = { src: item.payload };
          $dialogData.type = dialogTypes.IMAGE_ZOOM;
        }}
        on:keypress={() => {
          $dialogData.data = { src: item.payload };
          $dialogData.type = dialogTypes.IMAGE_ZOOM;
        }}
        class="image-container"
        key={item.id}
      >
        <img src={item.payload} alt={item.name} class="image" />
      </div>
    {/each}
  </div>
  {#if photoCount > photoSkip}
    <div class="w-full flex items-center justify-center mt-5 mb-5">
      <div
        class="flex items-center justify-center h-9 w-9 bg-white dark:bg-black rounded-xl border border-slate-500 dark:border-zinc-300"
        on:click={() => fetchImages()}
        on:keypress={() => fetchImages()}
      >
        <Svg name="down-caret" size={22} customColor="bg-slate-500 dark:bg-zinc-300" />
      </div>
    </div>
  {/if}
{:else if selected === "video"}
  <div class="grid">
    {#each videoData as item}
      <div
        on:click={() => {
          $dialogData.data = {
            src: item.payload.replace("watch", "thumbnails/thumbnail.jpg"),
          };
          $dialogData.type = dialogTypes.VIDEO_ZOOM;
        }}
        on:keypress={() => {
          $dialogData.data = {
            src: item.payload.replace("watch", "thumbnails/thumbnail.jpg"),
          };
          $dialogData.type = dialogTypes.VIDEO_ZOOM;
        }}
        class="image-container"
        key={item.id}
      >
        <video>
          <source src={item.payload} type="video/mp4" />
          <source src={item.payload} type="video/avi" />
          <source src={item.payload} type="video/ogg" />
          <source src={item.payload} type="video/webm" />
        </video>
      </div>
    {/each}
  </div>
  {#if videoCount > videoSkip}
    <div class="w-full flex items-center justify-center mt-5 mb-5">
      <div
        class="flex items-center justify-center h-9 w-9 bg-white dark:bg-black rounded-xl border border-slate-500 dark:border-zinc-300"
        on:click={() => fetchVideos()}
        on:keypress={() => fetchVideos()}
      >
        <Svg name="down-caret" size={22} customColor="bg-slate-500 dark:bg-zinc-300" />
      </div>
    </div>
  {/if}
{:else if selected === "link"}
  {#each linkData as item}
    <div class="border border-slate-300 dark:border-zinc-700 p-3 m-2 rounded-md">
      {@html urlify(item.payload)}
    </div>
  {/each}
  {#if linkCount > linkSkip}
    <div class="w-full flex items-center justify-center mt-5 mb-5">
      <div
        class="flex items-center justify-center h-9 w-9 bg-white dark:bg-black rounded-xl border border-slate-500 dark:border-zinc-300"
        on:click={() => fetchLinks()}
        on:keypress={() => fetchLinks()}
      >
        <Svg name="down-caret" size={22} customColor="bg-slate-500 dark:bg-zinc-300" />
      </div>
    </div>
  {/if}
{/if}

<style>
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  .image-container {
    position: relative;
    overflow: hidden;
    background-color: #eee; /* Replace with your desired background color */
    aspect-ratio: 1 / 1;
  }

  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
</style>
