<script lang="ts">
  import type { IMessage } from "src/interfaces/Chat";
  import { parseDateWithTime } from "../../lib/parseDateWithTime";
  import { urlify } from "../../lib/urlify";
  import { user } from "stores";
  import { translate } from "lib";

  export let message: IMessage;
  export let isMine: boolean;
  export let touchstart: (
    messageId: number,
    messagePayload: string,
    timestamp: any,
    isMine: boolean,
    deletedAt: string | null
  ) => void;
  export let touchend: () => void;

  let showTimestamp = false;

  $: align = isMine ? "flex-end" : "flex-start";
  $: backgroundColor = message.deletedAt
    ? "bg-slate-100 dark:bg-zinc-800"
    : isMine
      ? showTimestamp
        ? "bg-primary-700 dark:bg-primary-700"
        : "bg-primary-500 dark:bg-primary-500"
      : showTimestamp
        ? "bg-slate-100 dark:bg-zinc-900"
        : "";
  $: color = message.deletedAt
    ? "text-red-900 dark:text-red-200"
    : isMine
      ? "text-slate-100 dark:text-slate-100"
      : "text-slate-900 dark:text-slate-100";
  $: border = message.deletedAt
    ? "dark:border dark:border-zinc-600"
    : isMine
      ? ""
      : "border border-slate-300 dark:border-zinc-600";
</script>

<div
  unselectable="on"
  id={`${message?.id}`}
  on:mousedown={touchstart(
    message.id,
    message.payload,
    message.createdAt,
    isMine,
    message.deletedAt
  )}
  on:mouseup={touchend()}
  on:touchend={touchend()}
  on:touchstart={touchstart(
    message.id,
    message.payload,
    message.createdAt,
    isMine,
    message.deletedAt
  )}
  on:click={() => (showTimestamp = !showTimestamp)}
  on:keypress={() => (showTimestamp = !showTimestamp)}
  role="button"
  tabindex="0"
  class={`message max-w-80 font-sans whitespace-pre-wrap mgl-8 mgr-8 mt-1 ${color} ${backgroundColor} ${border} `}
  style={`
  font-size: ${$user.id === 13 ? 13 : 14}px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  align-self: ${align};`}
>
  {#if message?.deletedAt}
    <p class="italic">{translate("DELETED_MESSAGE")}</p>
  {:else}
    {@html urlify(message?.payload)}
  {/if}
</div>
{#if showTimestamp}
  <span
    class={`ml-3 mr-3 mt-1 text-xs font-medium text-slate-900 dark:text-slate-100`}
    style={`align-self: ${align}`}>{parseDateWithTime(message.createdAt)}</span
  >
{/if}

<style>
  .message {
    align-self: flex-end;
    padding: 6px 10px 6px 10px;
    border-radius: 16px;
    width: fit-content;
  }
</style>
