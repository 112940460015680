<script lang="ts">
  import { onMount } from "svelte";
  import { patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import { dialogData, showAlert } from "stores";
  import { ButtonComponent, Textarea } from "ui";

  export let data;

  let file: File;
  let disabled = true;
  let isLoading = false;

  const dialogTitle = data.templateMessage
    ? translate("EDIT_TEMPLATE_MESSAGE")
    : translate("CREATE_TEMPLATE_MESSAGE");

  const buttonTitle = data.templateMessage
    ? translate("EDIT")
    : translate("CREATE");

  const form = {
    message: { value: "", error: "" },
  };

  const createTemplateMessage = async (): Promise<void> => {
    const templateMessage = {
      text: form.message.value,
    };

    try {
      const res = await postWithJwt(
        serverlessRoutes.TEMPLATE_MESSAGE,
        templateMessage
      );

      data.executeFunction(res);

      $dialogData.type = "";
      $dialogData.data = {};

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("TEMPLATE_MESSAGE")}
      `;
    } catch (error) {
      console.error(error);
    }
  };

  const editTemplateMessage = async (): Promise<void> => {
    const partialTemplateMessage = {
      text: form.message.value,
      id: data.templateMessage.id,
    };

    try {
      const response = await patchWithJwt(serverlessRoutes.TEMPLATE_MESSAGE, {
        partialTemplateMessage,
      });

      data.executeFunction(response);

      $dialogData.type = "";
      $dialogData.data = {};

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("TEMPLATE_MESSAGE")}
      `;
    } catch (error) {
      console.error(error);
    }
  };

  const onInput = (key: keyof typeof form): void => {
    if (form.message.value === "") {
      form.message.error = "FIELD_REQUIRED";
    } else if (form.message.value.length < 2) {
      form.message.error = "FIELD_MINIMUM_2";
    } else if (form.message.value.length > 320) {
      form.message.error = "FIELD_MAXIMUM_320";
    } else {
      form.message.error = "";
    }

    disabled = Object.values(form).some(({ error }) => error !== "");
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.templateMessage) {
      await editTemplateMessage();
    } else {
      await createTemplateMessage();
    }

    isLoading = false;
  };

  onMount((): void => {
    // ovaj dialog otvaramo samo ako nije fileable
    if (data.templateMessage) {
      form.message.value = data.templateMessage.text;
    }
  });
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-xxl font-medium text-center mt-4">{dialogTitle}</h3>

  <!-- {#if data.templateMessage && data.templateMessage.fileable}
    <img
      alt="Template Message"
      class="w-20 h-20"
      src={data.templateMessage.imageUrl}
    />
    <div>{translate("CLICK_ON_IMAGE_TO_CHANGE")}</div>
  {:else} -->
  <div>
    <p class="mb-1">{translate("MESSAGE")}:</p>
    <Textarea
      placeholder={translate("MESSAGE")}
      bind:value={form.message.value}
      on:input={() => onInput("message")}
    />
    {#if form.message.error}
      <div class="text-red-500 text-xs">{translate(form.message.error)}</div>
    {/if}
  </div>
  <!-- {/if} -->

  <div class="flex justify-center mb-2">
    <ButtonComponent on:click={onSubmit} {isLoading} {disabled}
      >{buttonTitle}</ButtonComponent
    >
  </div>
</div>
