<script lang="ts">
  import { onMount } from "svelte";
  import type { FinishWorkoutStats } from "../../../interfaces/FinishWorkoutStats";
  import { serverlessRoutes } from "../../../lib/constants";
  import { postWithJwt } from "../../../lib/requests";
  import { translate } from "../../../lib/translate";
  import { dialogData } from "../../../stores/dialogDataStore";
  import { showAlert } from "../../../stores/showAlertStore";
  import { generateFinishExerciseArray } from "../../../lib/generateFinishExerciseArray";
  import { loadedWorkouts } from "../../../stores/loadedWorkoutsStore";
  import { user } from "stores";
  import { ButtonComponent } from "ui";

  export let data: any;

  let stats: FinishWorkoutStats[] = [];

  const generateStats = () => {
    const exercise = data.exercise;
    if (exercise.modelType === "superset") {
      exercise.supersetExercises.forEach((ex) => {
        const finishWorkoutStat: FinishWorkoutStats = {
          name: ex.name,
          exerciseId: ex.id,
          stats: [],
        };
        finishWorkoutStat.stats = generateFinishExerciseArray(ex);
        stats.push(finishWorkoutStat);
        ex = ex;
      });
    } else {
      const finishWorkoutStat: FinishWorkoutStats = {
        exerciseId: exercise.id,
        name: exercise.name,
        stats: [],
      };
      finishWorkoutStat.stats = generateFinishExerciseArray(exercise);
      stats.push(finishWorkoutStat);
    }

    if ($user.trainer.id === 47803) {
      stats.forEach((stat) => {
        stat.stats.forEach((stat) => {
          stat.reps = null;
        });
      });
    }

    stats = stats;
  };

  const finishExercise = async () => {
    const url = `${serverlessRoutes.FINISH_EXERCISES}`;
    try {
      const res = await postWithJwt(url, { stats });
      stats.forEach((s) => {
        $loadedWorkouts[data.exercise.workoutId].exercises.forEach((e) => {
          if (e.modelType === "superset") {
            e.supersetExercises.forEach((ssE) => {
              if (ssE.id === s.exerciseId) {
                ssE.stats = s.stats;
                ssE.finishedAt = new Date().toISOString();
              }
            });
          } else if (e.id === s.exerciseId) {
            e.stats = s.stats;
            e.finishedAt = new Date().toISOString();
          }
        });
      });
      $loadedWorkouts = $loadedWorkouts;
      $showAlert.message = `${translate("RESULTS_ENTERED_SUCCESSFULLY")}`;
      $dialogData = {};
    } catch (err) {}
  };

  onMount(() => {
    generateStats();
  });
</script>

<div class="flex-col text-center items-center">
  <div class="text-center font-semibold">{translate("ENTER_RESULTS")}</div>

  {#each stats as stat}
    <div>{stat.name}</div>
    <table class="mg-8" style="border: 1px solid black; width: 90%">
      <tr>
        <th>{translate("REPS")}</th>
        <th>{translate("REST")}</th>
        <th>{translate("WEIGHT")}</th>
      </tr>
      {#each stat.stats as set}
        <tr>
          <td>
            <input
              type="number"
              bind:value={set.reps}
              class="value-input bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
            />
          </td>
          <td>
            <input
              type="number"
              bind:value={set.rest}
              class="value-input bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
            />
          </td>
          <td>
            <input
              type="number"
              bind:value={set.weight}
              class="value-input bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
            />
          </td>
        </tr>
      {/each}
    </table>
  {/each}
  <div class="flex flex-row justify-center items-center mt-1 mb-3">
    <ButtonComponent on:click={finishExercise}>{translate("SUBMIT")}</ButtonComponent>
  </div>
</div>

<style>
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  input {
    padding: 0px;
  }
  .value-input {
    width: 32px;
    border: 0;
    border-bottom: 1px solid silver;
  }
</style>
