<script lang="ts">
  import {createEventDispatcher, onMount} from "svelte";

  import {
    deleteWithJwt,
    dialogTypes,
    parseDate,
    serverlessRoutes,
    translate
  } from "lib";

  import {dialogData, user} from "stores";
  import {Avatar, More} from "ui";
  import type {MenuItem} from "interfaces";

  export let comment: any;
  export let isReadonly = false;
  export let entity: string;
  export let entityId: number;

  const dispatch = createEventDispatcher();
  let commentElement: HTMLDivElement;

  const editCommentDialog = (): void => {
    $dialogData.data = {
      comment,
      executeFunction (response: any): void {
        comment.text = response.data.text;
      },
    };

    $dialogData.type = dialogTypes.EDIT_COMMENT;
  };

  const deleteComment = async (): Promise<void> => {
    try {
      await deleteWithJwt(
        `${serverlessRoutes.COMMENT}/${comment.id}`,
        {entity: entity.toLowerCase(), entityId}
      );

      $dialogData.type = "";
      $dialogData.data = {};

      dispatch("delete", { id: comment.id });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCommentDialog = (): void => {
    $dialogData.data = {
      title: translate("COMMENT"),
      executeFunction: deleteComment,
    };
    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  let menuItems: MenuItem[] = [{
    title: "EDIT",
    icon: "edit",
    executeFunction: editCommentDialog,
  }, {
    title: "DELETE",
    icon: "delete",
    executeFunction: deleteCommentDialog,
  }];

  onMount((): void => {
    if (comment.userId !== $user.id) { menuItems = []; }
  });
</script>

<div class="flex gap-2" bind:this={commentElement}>

  <Avatar avatarUrl={comment.avatarUrl} size={48} alt="Avatar" />

  <div
    class="
      p-2
      flex
      flex-col
      gap-2
      grow
      border
      border-slate-200
      rounded-md
      leading-none
      dark:border-zinc-600
    "
  >
    <div class="flex items-center justify-between">
      <div class="font-bold">{comment.name}</div>
      {#if !isReadonly}
        <More {menuItems} />
      {/if}
    </div>
    <div class="text-xs text-justify leading-normal">{comment.text}</div>
    <div class="text-xxs self-end">{parseDate(comment.createdAt)}</div>
  </div>

</div>
