<script lang="ts">
  import { postWithJwt, serverlessRoutes, storage, translate } from "lib";
  import { dialogData } from "stores";
  import { ButtonComponent, CheckboxComponent, InputField } from "ui";

  let disabled = true;

  const form = {
    dateOfBirth: { value: "", error: "" },
    dontShowSetDateOfBirth: { value: false, error: "" },
  };

  const onInput = (key: keyof typeof form): void => {
    if (key === "dateOfBirth") {
      form.dateOfBirth.error = "";
    }

    disabled = Object.values(form).some(({ error }): boolean => error !== "");
  };

  const onClose = async (): Promise<void> => {
    if (form.dontShowSetDateOfBirth.value) {
      await storage.set("dontShowSetDateOfBirth", form.dontShowSetDateOfBirth.value);
    }

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    try {
      const response = await postWithJwt(serverlessRoutes.USER_DATA, {
        dateOfBirth: form.dateOfBirth.value,
      });
    } catch (error) {
      console.error(error);
    } finally {
      $dialogData.type = "";
      $dialogData.data = {};
    }
  };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="font-bold leading-none text-center mt-4 mb-2">
    {translate("ADD")}
    {translate("DATE_OF_BIRTH").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <InputField
      type="date"
      label={translate("DATE_OF_BIRTH")}
      error={form.dateOfBirth.error}
      disabled={form.dontShowSetDateOfBirth.value}
      bind:value={form.dateOfBirth.value}
      on:input={() => onInput("dateOfBirth")}
    />

    <CheckboxComponent
      label={translate("DONT_SHOW_ANYMORE")}
      bind:value={form.dontShowSetDateOfBirth.value}
    />

    <div class="flex justify-center gap-4">
      <ButtonComponent isOutline on:click={onClose}>
        {translate("CANCEL")}
      </ButtonComponent>
      <ButtonComponent type="submit" disabled={disabled || form.dontShowSetDateOfBirth.value}>
        {translate("CONFIRM")}
      </ButtonComponent>
    </div>
  </form>
</div>
