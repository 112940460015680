<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import { push } from "svelte-spa-router";
  import { TrainersClientNotification } from "enums";
  import { getWithJwt, serverlessRoutes, translate } from "lib";
  import { dialogData } from "stores";
  import { currentNotificationDialogClient } from "stores/trainerStores";
  import { ButtonComponent } from "ui";
  import MealExpansionPanel from "../Diet/MealExpansionPanel.svelte";
  import WorkoutExpansionPanel from "../UI/WorkoutExpansionPanel.svelte";

  export let data: any;

  let workout: any;
  let meal: any;
  let client: any;

  const { type, clientId, workoutId, exerciseId, mealId } = data;

  const onClientProfile = async (): Promise<void> => {
    $dialogData.type = "";
    $dialogData.data = {};

    await push(`/profile/${clientId}?view=training&type=active`);
  };

  const fetchClient = async () => {
    if (!clientId) {
      return;
    }
    const response = await getWithJwt(`${serverlessRoutes.TRAINER_CLIENT}/${clientId}/profile`);
    client = response.data;
    $currentNotificationDialogClient = client;
  };

  const fetchMeal = async () => {
    const response = await getWithJwt(`${serverlessRoutes.MEAL}/${mealId}`);
    meal = response.data.meal;
    meal.recipes = response.data.recipes;
    for (const [index, recipe] of meal.recipes.entries()) {
      const response = await getWithJwt(`${serverlessRoutes.RECIPE}/${recipe.id}/ingredients`);

      meal.recipes[index].ingredients = response.data.ingredients;
    }
    meal.ingredients = response.data.ingredients;
  };

  const fetchWorkout = async () => {
    workout = await getWithJwt(`${serverlessRoutes.WORKOUT}/v2/${workoutId}`);
  };

  onMount(async (): Promise<void> => {
    const promises: Array<Promise<void>> = [];

    promises.push(fetchClient());

    if (
      type === TrainersClientNotification.COMMENTED_WORKOUT ||
      type === TrainersClientNotification.COMMENTED_EXERCISE
    ) {
      promises.push(fetchWorkout());
    } else if (type === TrainersClientNotification.COMMENTED_MEAL) {
      promises.push(fetchMeal());
    }

    await Promise.all(promises);
  });

  onDestroy((): void => {
    $currentNotificationDialogClient = {};
  });
</script>

<div class="p-4 flex flex-col gap-4">
  {#if client}
    <div class="p-4 flex items-center gap-4 border rounded-md">
      <img
        class="rounded-full h-[64px]"
        src={client.imageUrl || "/default-avatar.png"}
        height="64"
        width="64"
        alt="Avatar"
      />
      <div class="grow">
        {client.name}<br />
        <span class="text-xxs">{client.email}</span>
      </div>
      <ButtonComponent on:click={onClientProfile}>{translate("PROFILE")}</ButtonComponent>
    </div>
  {/if}

  {#if type === TrainersClientNotification.COMMENTED_WORKOUT}
    {#if workout}
      <WorkoutExpansionPanel {workout} isReadonly showComments scrollToWorkoutComments />
    {/if}
  {:else if type === TrainersClientNotification.COMMENTED_EXERCISE}
    {#if workout}
      <WorkoutExpansionPanel
        {workout}
        isReadonly
        showComments
        scrollToExerciseComments={exerciseId}
      />
    {/if}
  {:else if type === TrainersClientNotification.COMMENTED_MEAL}
    {#if meal}
      <MealExpansionPanel {meal} isReadonly isExpanded isCommentable scrollToMealComments />
    {/if}
  {/if}
</div>
