import { language } from "../stores/languageStore";
import { dialogData, user } from "stores";
import { get } from "svelte/store";
import { storage } from "./storage";
import * as Sentry from "@sentry/browser";
import { Capacitor } from "@capacitor/core";
import initNativePushNotifications from "./initPushNotifications";
import { setupAvatars } from "./setupAvatars";
import { fetchNotifications } from "./fetchNotifications";
import { fetchChatSessions } from "./fetchSessions";
import { dialogTypes, serverlessRoutes, version } from "./constants";
import { putWithJwt } from "./requests";
import { fetchTags } from "./fetchTags";
import { isClient } from "./roles";
import { socketConnectionV2 } from "./connectSocket";
import axios from "axios";

const updateVersionInfo = async () => {
  const url = serverlessRoutes.PATCH_VERSION;
  try {
    await putWithJwt(url, { version });
  } catch (err) {}
};

const askForPhonenumber = async (user) => {
  let showPhonenumberDialog = await storage.get("showPhonenumberDialog");

  if (
    user &&
    isClient(user) &&
    (user.trainer.id === 13 || user.trainer.id === 87649) &&
    !user.phoneNumber &&
    showPhonenumberDialog !== false
  )
    dialogData.set({ type: dialogTypes.ASK_FOR_PHONENUMBER });
};

export const initiateUser = async () => {
  const jwt = await storage.get("authJwt");
  const token = `Bearer ${jwt}`;
  axios.defaults.headers.common["Authorization"] = token;
  const userRef = get(user);
  if (!userRef || !userRef.id) return;
  Sentry.setUser({ email: userRef.email });
  language.set((await storage.get("language")) || "sr");
  if (Capacitor.isNativePlatform()) {
    initNativePushNotifications();
  }
  await askForPhonenumber(userRef);
  setupAvatars();
  fetchNotifications().then(() =>
    fetchChatSessions().then(async () => {
      await socketConnectionV2();
    })
  );
  updateVersionInfo();
  fetchTags();
};
