<script lang="ts">
  import { onMount } from "svelte";
  import { postWithJwt, serverlessRoutes, translate } from "lib";
  import { currentClient, dialogData, showAlert } from "stores";
  import { clientTagsMap, tags } from "../../../stores/trainerStores";
  import Tag from "../../../components/Tag.svelte";
  import ColorPicker from "../../../components/UI/ColorPicker.svelte";
  import Input from "../../../components/UI/Input.svelte";
  import { ButtonComponent, CheckboxComponent } from "ui";

  let clientTagIds: number[] = [];
  let disabled = true;
  let isLoading = false;

  const form = {
    newTagName: { value: "", error: "" },
    newTagColor: { value: "", error: "" },
  };

  const toggle = (tagId: number) => {
    if (clientTagIds.includes(tagId)) {
      clientTagIds = clientTagIds.filter((id) => id !== tagId);
    } else {
      clientTagIds.push(tagId);
    }
  };

  const onInput = (key: keyof typeof form): void => {
    if (key === "newTagName") {
      if (form.newTagName.value.length < 2) {
        form.newTagName.error = "FIELD_MINIMUM_2";
      } else if (form.newTagName.value.length > 40) {
        form.newTagName.error = "FIELD_MAXIMUM_40";
      } else {
        form.newTagName.error = "";
      }
    } else if (key === "newTagColor") {
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onAddNewTag = async (): Promise<void> => {
    isLoading = true;

    try {
      const response = await postWithJwt(serverlessRoutes.TAGS, {
        note: form.newTagName.value,
        color: form.newTagColor.value,
      });

      $tags = [...$tags, response.data];

      form.newTagName.value = "";
      form.newTagName.error = "";
      form.newTagColor.value = "";
      form.newTagColor.error = "";
    } catch (error) {
      console.error(error);
    }

    isLoading = false;
  };

  const onSubmit = async () => {
    try {
      const result = await postWithJwt(`${serverlessRoutes.TAGS}/assign`, {
        clientId: $currentClient.id,
        tagIds: clientTagIds,
      });
      if (result.data.length === clientTagIds.length) {
        $dialogData = { type: "", data: {} };
        $clientTagsMap[$currentClient.id] = result.data;
        $showAlert.message = translate("SUCCESSFULLY_UPDATED_TAGS");
      }
    } catch (error) {
      console.error(error);
    }
  };

  onMount((): void => {
    if (!$clientTagsMap[$currentClient.id]) {
      $clientTagsMap[$currentClient.id] = [];
    }
    clientTagIds = $clientTagsMap[$currentClient.id].map((tag) => tag.id);
  });
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-center text-xl">Izmeni oznake klijenta</h3>
  <div class="flex flex-col gap-4 mt-3">
    {#each $tags as tag}
      <div class="flex flex-row w-full justify-between">
        <span class="flex flex-row ml-4">
          <Tag color={tag.color} />
          <span class="ml-1">{tag.note}</span>
        </span>
        <span
          role="checkbox"
          aria-checked="false"
          tabindex="0"
          class="mr-4"
          on:click={() => toggle(tag.id)}
          on:keypress={() => toggle(tag.id)}
        >
          <CheckboxComponent value={clientTagIds.includes(tag.id)} />
        </span>
      </div>
    {/each}

    <div class="flex flex-col gap-4">
      <div
        class="text-center pt-2 border-t-[1px] border-solid border-slate-200 dark:border-zinc-600"
      >
        {translate("NEW_TAG")}?
      </div>

      <div>
        <p class="mb-1">{translate("NEW_TAG_NAME")}:</p>
        <Input
          name="amount"
          placeholder={translate("NEW_TAG_NAME")}
          bind:value={form.newTagName.value}
          onInput={() => onInput("newTagName")}
        />
        {#if form.newTagName.value !== "" && form.newTagName.error}
          <span class="text-red-500 text-xs"
            >{translate(form.newTagName.error)}</span
          >
        {/if}
      </div>

      <div>
        <ColorPicker
          id="color"
          label={translate("NEW_TAG_COLOR")}
          bind:value={form.newTagColor.value}
          on:input={() => onInput("newTagColor")}
        />
        <span class="text-slate-500 text-xs dark:text-slate-300"
          >{translate("NEW_TAG_COLOR_HINT")}</span
        >
      </div>
    </div>

    <div class="flex justify-center mb-2">
      {#if form.newTagName.value.length}
        <ButtonComponent on:click={onAddNewTag} {disabled} {isLoading}
          >{translate("ADD")}</ButtonComponent
        >
      {:else}
        <ButtonComponent on:click={onSubmit}
          >{translate("UPDATE")}</ButtonComponent
        >
      {/if}
    </div>
  </div>
</div>
