<script lang="ts">
  import { onMount } from "svelte";
  import { patchWithJwt, serverlessRoutes, translate } from "lib";
  import { currentClient, dialogData, showAlert, trainerClients } from "stores";
  import { ButtonComponent, InputField } from "ui";
  import type { User } from "../../../interfaces/User";

  let data: any;

  let form = {
    date: { value: "", error: "" },
  };

  let disabled = false;
  let isLoading = false;

  const onInput = (field: keyof typeof form): void => {
    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;
    const url = serverlessRoutes.USER;

    try {
      const partialUser: Partial<User> = {
        id: $currentClient.id,
        expireAt: form.date.value,
      };

      await patchWithJwt(url, { user: partialUser });
      const newExpireDate = new Date(form.date.value);
      $currentClient.expireAt = newExpireDate;
      const client = $trainerClients.find(
        (client): boolean => client.id === $currentClient.id
      );
      if (client) {
        client.expireAt = newExpireDate;
      }
      $trainerClients = $trainerClients;
      $dialogData.data = {};
      $dialogData.type = "";
      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_UPDATED_EXPIRE_DATE");
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error creating header.`;
    }
  };

  onMount((): void => {
    const defaultDate = new Date().toISOString().slice(0, 10);

    if (data.client && data.client.expireAt !== null) {
      form.date.value = data.client.expireAt?.split("T")[0] || defaultDate;
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">{translate("EDIT_EXPIRY_DATE")}</div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <InputField
      type="date"
      label={translate("EXPIRATION_DATE")}
      error={form.date.error}
      bind:value={form.date.value}
      on:input={() => onInput("date")}
    />

    <div class="flex justify-center">
      <ButtonComponent type="submit" {disabled} {isLoading}>
        {translate("EDIT")}
      </ButtonComponent>
    </div>
  </form>
</div>
