<script lang="ts">
  import { showAlert } from "stores";
  import { pop } from "svelte-spa-router";
  import Svg from "../../components/UI/Svg.svelte";
  import { serverlessRoutes } from "../../lib/constants";
  import { postWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { ButtonComponent } from "ui";
  import Textarea from "../../components/UI/Textarea.svelte";

  let description: string = "";

  const submit = async () => {
    const url = serverlessRoutes.BUG_SUGGESTION;
    const bugSuggestion = {
      data: description,
      info: "[]",
      reportType: 0,
      title: "V2",
    };
    try {
      const res = await postWithJwt(url, { bugSuggestion });
      $showAlert.message = `${translate("SUCCESSFULLY_CREATED")} ${translate(
        "BUG_SUGGESTION"
      ).toLowerCase()}`;
    } catch (err) {
      console.error(err);
    }
    pop();
  };
</script>

<svelte:head>
  <style>
    body {
      overflow: hidden;
    }
  </style>
</svelte:head>
<div class="bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100">
  <div class="mt-20" />
  <div class="flex-col items-center">
    <div class="flex flex-row w-full">
      <div class="ml-8" on:click={() => pop()} on:keypress={() => pop()}>
        <Svg
          name="left-arrow-button"
          size={24}
          customColor="bg-black dark:bg-white"
        />
      </div>
    </div>
    <h1
      style="font-size: 1.25rem; font-weight: 400;"
      class="align-self-center text-center justify-self-center mt-16"
    >
      {translate("REPORT_BUG_SUGGESTION")}
    </h1>
    <br />
    <div class="w-5/6">
      <Textarea
        placeholder={translate("BUG_SUGGESTION_EXAMPLE")}
        bind:value={description}
      />
    </div>
    <ButtonComponent myClasses="mt-4" on:click={submit}
      >{translate("SUBMIT")}</ButtonComponent
    >
  </div>
</div>
