<script lang="ts">
  import { onMount } from "svelte";
  import { Fileupload, Heading, Helper, Input, Label, Spinner } from "flowbite-svelte";
  import { translate } from "lib";
  import {
    dialogData,
    showAlert,
    trainerClientPrograms,
    trainerPrograms,
    workoutsStore,
  } from "stores";
  import { ButtonComponent } from "ui";

  let data: any;

  const form = {
    name: { value: "", error: "" },
    pdf: { value: "", error: "" },
  };

  let disabled = true;
  let isLoading = false;
  let files: FileList;
  const dialogType = data.workout ? translate("EDIT") : translate("CREATE");

  const onInput = (field: keyof typeof form): void => {
    // nzm sto svelte jebe, loguje predhodni value, moguce da je flowbite file
    // input component bug, ovo je kao "fix"
    setTimeout(() => {
      const { value } = form[field];

      if (field === "name") {
        if (value.length === 0) {
          form[field].error = translate("FIELD_REQUIRED");
        } else if (value.length < 2) {
          form[field].error = translate("FIELD_MINIMUM_2");
        } else if (value.length > 320) {
          form[field].error = translate("FIELD_MAXIMUM_320");
        } else {
          form[field].error = "";
        }
      }

      if (field === "pdf") {
        if (files.length === 0) {
          form[field].error = translate("FIELD_REQUIRED");
        } else {
          form[field].error = "";
        }
      }

      disabled = Object.values(form).some((field) => field.error !== "");
    }, 100);
  };

  const onSubmit = async (): Promise<void> => {
    const { workout, programId } = data;

    isLoading = true;

    try {
      return;
      let response: any;
      const formData = new FormData();

      if (workout) {
        // Izmeni pdf workout
        const url = ``;

        formData.append("pdf", files[0]);
        formData.append(
          "workout",
          JSON.stringify({
            id: workout.id,
            name: form.name.value,
          })
        );

        response = {};

        if (workout.default) {
          // Workout u workouts
          workoutsStore.replace([response]);
          // const _workout = $trainerWorkouts.find(
          //   (_workout): boolean => _workout.id === workout.id
          // );

          // const index = $trainerWorkouts.indexOf(_workout);

          // $trainerWorkouts.splice(index, 1, response);
          // $trainerWorkouts = $trainerWorkouts;
        } else {
          // Workout u program
          const _program = $trainerPrograms.find(
            (_program): boolean => _program.id === workout.programId
          );
          const _program2 = $trainerClientPrograms.find(
            (_program): boolean => _program.id === workout.programId
          );

          if (_program) {
            const _workout = _program.workouts.find(
              (_workout: any): boolean => _workout.id === workout.id
            );

            const index = _program.workouts.indexOf(_workout);

            _program.workouts.splice(index, 1, response);
            $trainerPrograms = $trainerPrograms;
          }

          if (_program2) {
            const _workout = _program2.workouts.find(
              (_workout: any): boolean => _workout.id === workout.id
            );

            const index = _program2.workouts.indexOf(_workout);

            _program2.workouts.splice(index, 1, response);
            $trainerClientPrograms = $trainerClientPrograms;
          }

          data.executeFunction(response);
        }
      } else {
        // Kreiraj pdf workout
        formData.append("pdf", files[0]);
        formData.append(
          "workout",
          JSON.stringify({
            name: form.name.value,
            entityType: 3,
          })
        );
        formData.append("programId", programId);

        response = {};

        const _program = $trainerPrograms.find(
          (_program: any): boolean => _program.id === programId
        );
        const _program2 = $trainerClientPrograms.find(
          (_program: any): boolean => _program.id === programId
        );

        if (_program) {
          _program.workouts.push(response);
          $trainerPrograms = $trainerPrograms;
        }
        if (_program2) {
          _program2.workouts.push(response);
          $trainerClientPrograms = $trainerClientPrograms;
        }
      }

      $showAlert.color = "black";

      if (workout) {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_EDITED")} ${translate("WORKOUT")}
        `;
      } else {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_CREATED")} ${translate("WORKOUT")}
        `;
      }

      $dialogData.data = {};
      $dialogData.type = "";
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";

      if (workout) {
        $showAlert.message = translate("ERROR_EDITING_WORKOUT");
      } else {
        $showAlert.message = translate("ERROR_CREATING_WORKOUT");
      }
    }
  };

  onMount((): void => {
    if (data.workout) {
      const { name } = data.workout;

      if (name) {
        form.name.value = name;
        onInput("name");
      }
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {dialogType} PDF {translate("WORKOUT").toLowerCase()}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <div>
      <Label for="name">{translate("NAME")}:</Label>
      <Input
        id="name"
        placeholder={translate("NAME")}
        bind:value={form.name.value}
        on:input={() => onInput("name")}
      />
      <Helper color="red">
        {#if form.name.error}
          {form.name.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div>
      <Label for="pdf">PDF:</Label>
      <Fileupload
        id="pdf"
        placeholder="PDF"
        bind:value={form.pdf.value}
        bind:files
        on:change={() => onInput("pdf")}
      />
      <Helper color="red">
        {#if form.pdf.error}
          {form.pdf.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent type="submit" {disabled}>{dialogType}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
