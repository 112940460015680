export const apiV2BaseUrl = "https://sea-lion-app-veosb.ondigitalocean.app";
// export const apiV2BaseUrl = "http://localhost:3001";
const apiV2 = `${apiV2BaseUrl}/api`;

const version = "1.0";

const wsApi = "wss://train-me-api.online/";

const serverlessRoutes = {
  MEASUREMENT: `${apiV2}/measurement`,
  MEASUREMENT_DATA: `${apiV2}/measurement-data`,
  NOTIFICATIONS: `${apiV2}/notification`,
  AUTH: `${apiV2}/auth`,
  PROGRAM: `${apiV2}/program`,
  FETCH_CLIENT_SESSION: `${apiV2}/session/client`,
  USER: `${apiV2}/user`,
  EMAIL_LOOKUP: `${apiV2}/email-lookup`,
  COMMENT: `${apiV2}/comment`,
  FETCH_MESSAGES: `${apiV2}/message/list`,
  MESSAGE: `${apiV2}/message`,
  TRAINER_CLIENT: `${apiV2}/trainer-client`,
  PATCH_VERSION: `${apiV2}/version`,
  SESSION: `${apiV2}/session`,
  EXERCISE: `${apiV2}/exercise`,
  WORKOUT: `${apiV2}/workout`,
  INGREDIENT: `${apiV2}/ingredient`,
  RECIPE: `${apiV2}/recipe`,
  MEAL: `${apiV2}/meal`,
  MEAL_PLANS: `${apiV2}/meal-plan`,
  HABITS: `${apiV2}/habit`,
  HABIT_DATA: `${apiV2}/habit-data`,
  REMINDERS: `${apiV2}/reminder`,
  BOOKMARK: `${apiV2}/bookmark`,
  GOAL: `${apiV2}/goal`,
  CRON_EXCEPTIONS: `${apiV2}/cron-exception`,
  AI_LOG: `${apiV2}/ai-log`,
  FRONTEND_LOG: `${apiV2}/frontend-log`,
  TAGS: `${apiV2}/tag`,
  USER_DATA: `${apiV2}/user-data`,
  FILE: `${apiV2}/file`,
  MACRO: `${apiV2}/macro`,
  PAYMENT: `${apiV2}/payment`,
  WEB_PUSH: `${apiV2}/web-push`,
  FASTING: `${apiV2}/fasting`,
  BUG_SUGGESTION: `${apiV2}/bug-suggestion`,
  NOTE: `${apiV2}/note`,
  TEMPLATE_MESSAGE: `${apiV2}/template`,
  GALLERY: `${apiV2}/gallery`,
  OVERVIEW: `${apiV2}/overview`,
  TRAINER_GROUP: `${apiV2}/trainer-group`,
};

const entities = {
  DIET: "DIET",
  FASTING: "FASTING",
  TRAINING: "TRAINING",
  PROGRESS: "PROGRESS",
  MEASUREMENTS: "MEASUREMENTS",
  CLIENTS: "CLIENTS",
  WORKOUT: "WORKOUT",
  EXERCISE: "EXERCISE",
  NOTIFICATIONS: "NOTIFICATIONS",
};

enum Roles {
  Client = 1,
  Trainer = 2,
}

const dialogTypes = {
  // ?
  CREATE_EDIT_PROGRAM: "CREATE_EDIT_PROGRAM",
  CANT_DO_EXERCISES: "CANT_DO_EXERCISES",
  // Client Dialogs
  ASSIGN_MEAL_PLAN: "ASSIGN_MEAL_PLAN",
  ASSIGN_WORKOUT_PLAN: "ASSIGN_WORKOUT_PLAN",
  CREATE_AUTO_MESSAGE: "CREATE_AUTO_MESSAGE",
  CREATE_EDIT_CLIENT: "CREATE_EDIT_CLIENT",
  CREATE_EDIT_GROUP: "CREATE_EDIT_GROUP",
  CREATE_EDIT_MEASUREMENT: "CREATE_EDIT_MEASUREMENT",
  CREATE_EDIT_HABIT: "CREATE_EDIT_HABIT",
  CREATE_EDIT_TAG: "CREATE_EDIT_TAG",
  CREATE_EDIT_PAYMENT: "CREATE_EDIT_PAYMENT",
  CREATE_EDIT_TEMPLATE_MESSAGE: "CREATE_EDIT_TEMPLATE_MESSAGE",
  CREATE_NEW_REMINDER: "CREATE_NEW_REMINDER",
  ASK_FOR_PHONENUMBER: "ASK_FOR_PHONENUMBER",
  BOOKMARKED_MESSAGES: "BOOKMARKED_MESSAGES",
  EDIT_CLIENT_MACROS: "EDIT_CLIENT_MACROS",
  EDIT_EXPIRATION_DATE: "EDIT_EXPIRATION_DATE",
  SEND_TO_ARCHIVE: "SEND_TO_ARCHIVE",
  SET_GOAL: "SET_GOAL",
  SET_TAG: "SET_TAG",
  TRANSFER_TO_ANOTHER_TRAINER: "TRANSFER_TO_ANOTHER_TRAINER",
  TEMPLATE_MESSAGES: "TEMPLATE_MESSAGES",
  GENERATE_WORKOUT: "GENERATE_WORKOUT",
  // Diet Dialogs
  ASSIGN_TO_CLIENTS: "ASSIGN_TO_CLIENTS",
  CREATE_EDIT_HEADER_MEAL: "CREATE_EDIT_HEADER_MEAL",
  CREATE_EDIT_INGREDIENT: "CREATE_EDIT_INGREDIENT",
  CREATE_EDIT_MEAL: "CREATE_EDIT_MEAL",
  CREATE_EDIT_MEAL_PLAN: "CREATE_EDIT_MEAL_PLAN",
  CREATE_EDIT_PDF_MEAL: "CREATE_EDIT_PDF_MEAL",
  CREATE_EDIT_RECIPE: "CREATE_EDIT_RECIPE",
  CREATE_EDIT_RECIPE_MEAL: "CREATE_EDIT_RECIPE_MEAL",
  CREATE_EDIT_TEXT_MEAL: "CREATE_EDIT_TEXT_MEAL",
  FASTING: "FASTING",
  IMPORT_INGREDIENTS: "IMPORT_INGREDIENTS",
  IMPORT_RECIPES: "IMPORT_RECIPES",
  INGREDIENT_INFO: "INGREDIENT_INFO",
  TEMPLATE: "TEMPLATE",
  // Workout Dialogs
  CREATE_EDIT_EXERCISE: "CREATE_EDIT_EXERCISE",
  CREATE_EDIT_FINISHED_WORKOUT: "CREATE_EDIT_FINISHED_WORKOUT",
  CREATE_EDIT_HEADER_WORKOUT: "CREATE_EDIT_HEADER_WORKOUT",
  CREATE_EDIT_PDF_WORKOUT: "CREATE_EDIT_PDF_WORKOUT",
  CREATE_EDIT_REST_DAY: "CREATE_EDIT_REST_DAY",
  CREATE_EDIT_TEXT_WOKROUT: "CREATE_EDIT_TEXT_WOKROUT",
  CREATE_EDIT_WORKOUT: "CREATE_EDIT_WORKOUT",
  EXERCISE_HISTORY_V2: "EXERCISE_HISTORY_V2",
  EXERCISE_PREVIEW: "EXERCISE_PREVIEW",
  FINISH_EXERCISE: "FINISH_EXERCISE",
  FINISH_EXERCISE_V2: "FINISH_EXERCISE_V2",
  FINISH_WORKOUT: "FINISH_WORKOUT",
  FINISH_WORKOUT_V2: "FINISH_WORKOUT_V2",
  IMPORT_EXERCISE: "IMPORT_EXERCISE",
  IMPORT_WORKOUTS: "IMPORT_WORKOUTS",
  SELECT_MUSCLE_GROUP: "SELECT_MUSCLE_GROUP",
  // Other
  CONFIRM_DELETE: "CONFIRM_DELETE",
  CONNECT_WITH_PARENT_EXERCISE: "CONNECT_WITH_PARENT_EXERCISE",
  CREATE_EDIT_GALLERY_ENTRY: "CREATE_EDIT_GALLERY_ENTRY",
  COMPARE_PHOTOS: "COMPARE_PHOTOS",
  CREATE_EDIT_GOAL: "CREATE_EDIT_GOAL",
  EDIT_COMMENT: "EDIT_COMMENT",
  NOTIFICATION: "NOTIFICATION",
  IMAGE_ZOOM: "IMAGE_ZOOM",
  SET_DATE_OF_BIRTH: "SET_DATE_OF_BIRTH",
  VIDEO_ZOOM: "VIDEO_ZOOM",
  VIDEO_ZOOM_V2: "VIDEO_ZOOM_V2",
  CONFIRM_DELETE_ACCOUNT: "CONFIRM_DELETE_ACCOUNT",
  FORCE_UPDATE: "FORCE_UPDATE",
};

// tipovi sa servera se fecuju na srpski, a kad se salje request za exercises
// i workouts salje se u ALL_CAPS.
// i ovi tipovi za exercises i workouts ne bi bilo lose se ubace na server u
// bazu, zbog konsistencije, da ne budu ovako hardkodirani.
// i tipovi na server mogu svi da se stave u ALL_CAPS format da bi radio i
// translate, i da se u svaki request uvek salju u ALL_CAPS.
const muscleGroupRequestMap = new Map<string, string>([
  ["Trbuh", "ABS"],
  ["Biceps", "BICEPS"],
  ["Listovi", "CALVES"],
  ["Grudi", "CHEST"],
  ["Podlaktica", "FOREARM"],
  ["Zadnjica", "GLUTE"],
  ["Zadnja loža", "HAMSTRINGS"],
  ["Donja leđa", "LOWER_BACK"],
  ["Prednja loža", "QUADS"],
  ["Ramena", "SHOULDERS"],
  ["Triceps", "TRICEPS"],
  ["Gornja leđa", "UPPER_BACK"],
]);
const reverseMuscleGroupRequest = new Map<string, string>([
  ["ABS", "Trbuh"],
  ["BICEPS", "Biceps"],
  ["CALVES", "Listovi"],
  ["CHEST", "Grudi"],
  ["FOREARM", "Podlaktica"],
  ["GLUTE", "Zadnjica"],
  ["HAMSTRINGS", "Zadnja loža"],
  ["LOWER_BACK", "Donja leđa"],
  ["QUADS", "Prednja loža"],
  ["SHOULDERS", "Ramena"],
  ["TRICEPS", "Triceps"],
  ["UPPER_BACK", "Gornja leđa"],
]);

export {
  wsApi,
  serverlessRoutes,
  entities,
  Roles,
  dialogTypes,
  muscleGroupRequestMap,
  reverseMuscleGroupRequest,
  version,
};
