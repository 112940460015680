<script lang="ts">
  import Svg from "./UI/Svg.svelte";
  import { theme } from "../stores/themeStore";
  import { translate } from "../lib/translate";
  import { language } from "../stores/languageStore";
  import { StatusBar, Style } from "@capacitor/status-bar";
  import { Capacitor } from "@capacitor/core";
  import { storage } from "lib";

  export let iconName: string;
  export let title: string;
  export let custom: string = "";
  export let execFunction: () => void;

  const setTheme = async (theme: string) => {
    await storage.set("theme", theme);
    $theme = theme;

    const root = document.documentElement;
    if ($theme === "light") {
      root.setAttribute("data-theme", "light");
      root.style.setProperty("--date-picker-background", "#FFFFFF");
      root.style.setProperty("--date-picker-foreground", "#27272A");
    } else {
      root.setAttribute("data-theme", "dark");
      root.style.setProperty("--date-picker-background", "#27272A");
      root.style.setProperty("--date-picker-foreground", "#FFFFFF");
    }
  };

  const setLanguage = async (language: string) => {
    await storage.set("language", language);
    $language = language;
  };
</script>

<div
  class="settings-item flex-row center-space-between"
  on:click={() => execFunction()}
  on:keydown={() => execFunction()}
>
  <div class="flex-row center-center">
    <Svg name={iconName} size={22} />
    <span class="mgl-12">{translate(title)}</span>
  </div>
  {#if custom === ""}
    <Svg name={"right-arrow"} size={16} />
  {/if}
  {#if custom === "theme"}
    <div class="flex-row">
      <div
        on:click={async () => {
          await setTheme("dark");
          if (Capacitor.isNativePlatform())
            await StatusBar.setStyle({ style: Style.Dark });
          document.documentElement.classList.add("dark");
        }}
        on:keypress={async () => {
          await setTheme("dark");
          if (Capacitor.isNativePlatform())
            await StatusBar.setStyle({ style: Style.Dark });
          document.documentElement.classList.add("dark");
        }}
        class="theme-box flex-col center-center"
        style={`background-color: #444; color: #fff`}
      >
        {translate("DARK")}
      </div>
      <div
        on:click={async () => {
          await setTheme("light");
          if (Capacitor.isNativePlatform())
            await StatusBar.setStyle({ style: Style.Light });
          document.documentElement.classList.remove("dark");
        }}
        on:keypress={async () => {
          await setTheme("light");
          if (Capacitor.isNativePlatform())
            await StatusBar.setStyle({ style: Style.Light });
          document.documentElement.classList.remove("dark");
        }}
        class="theme-box flex-col center-center"
        style={`background-color: #eee; color: #000`}
      >
        {translate("LIGHT")}
      </div>
    </div>
  {/if}
  {#if custom === "language"}
    <div class="flex-row">
      <img
        on:click={() => setLanguage("sr")}
        on:keypress={() => setLanguage("sr")}
        src={`/svg/serbia.svg`}
        style={`height: ${24}px; margin-right: 12px`}
        alt={"Serbian flag"}
      />
      <img
        on:click={() => setLanguage("en")}
        on:keypress={() => setLanguage("en")}
        src={`/svg/us.svg`}
        style={`height: ${24}px;`}
        alt={"US flag"}
      />
    </div>
  {/if}
</div>

<style>
  .settings-item {
    width: 84%;
    height: 48px;
  }
  .theme-box {
    font-size: 12px;
    height: 28px;
    width: 56px;
    margin: 4px;
    border-radius: 4px;
  }
</style>
