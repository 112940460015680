<script lang="ts">
  import { onMount } from "svelte";
  import { translate } from "lib";
  import { InputField } from "ui";
  import InsertGalleryEntry from "../Progress/InsertGalleryEntry.svelte";

  export let data: any;

  let selectedDate: string = "";
  let maxDate: string = new Date().toISOString().slice(0, 10);

  onMount(() => {
    selectedDate = data.date ? data.date : "";
  });
</script>

<div class="flex-col" style="align-items: center">
  <h3 class="mt-5 mg-4">
    {translate("ADD_PROGRESS_PHOTOS")}
  </h3>
  <br/>
  <div class="w-[80%]">
    <InputField
      type="date"
      label={translate("PICK_DATE")}
      bind:value={selectedDate}
      min="2010-01-01"
      max={maxDate}
    />
  </div>
  <br />
  {#if selectedDate !== ""}
    <InsertGalleryEntry date={selectedDate} />
  {/if}
</div>

<style>
</style>