<script lang="ts">
  import { More } from "ui";
  import { deleteWithJwt, dialogTypes, serverlessRoutes, translate } from "lib";
  import { alertStore, dialogData, habitsStore } from "stores";
  import type { MenuItem } from "interfaces";

  export let defaultHabit: any;

  const editHabitDialog = (): void => {
    $dialogData.type = dialogTypes.CREATE_EDIT_HABIT;
    $dialogData.data = {
      isDefaultHabit: true,
      defaultHabit,
    };
  };

  const deleteHabit = async (): Promise<void> => {
    try {
      await deleteWithJwt(`${serverlessRoutes.HABITS}?habitId=${defaultHabit.id}`);

      habitsStore.remove([defaultHabit.id]);
      alertStore.show(translate("SUCCESSFULLY_DELETED_HABIT"));
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHabitDialog = (): void => {
    $dialogData.data = {
      title: translate("HABIT_U"),
      executeFunction: deleteHabit
    };
    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const menuItems: Array<MenuItem> = [{
    title: "EDIT",
    icon: "edit",
    executeFunction: editHabitDialog
  }, {
    title: "DELETE",
    icon: "delete",
    executeFunction: deleteHabitDialog
  }];
</script>

<div class="flex items-center justify-between">
  <div class="text-xs">{defaultHabit.name}</div>
  <More {menuItems}/>
</div>
