<script lang="ts">
  import { dialogTypes, patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import { currentClient, dialogData, showAlert, trainerClientsStore } from "stores";
  import Avatar from "./UI/Avatar.svelte";
  import Svg from "./UI/Svg.svelte";
  import More from "./UI/More.svelte";
  import NotesExpansionPanel from "./UI/NotesExpansionPanel.svelte";
  import type { MenuItem } from "interfaces";
  import { parseDate } from "../lib/parseDate";
  import Badge from "./UI/Badge.svelte";
  import { pop } from "svelte-spa-router";
  import { onlineUsers } from "stores/onlineUsersStore";

  const clientMenuItems: Array<MenuItem> = [
    {
      title: "CREATE_NEW_PAYMENT",
      icon: "money",
      executeFunction(): void {
        $dialogData.data = {
          client: $currentClient,
          executeFunction(res: any): void {
            $currentClient.hash = res.hash;
            $currentClient.expireAt = res.expireAt;
          },
        };
        $dialogData.type = dialogTypes.CREATE_EDIT_PAYMENT;
      },
    },
    {
      title: "CREATE_NEW_REMINDER",
      icon: "calendar",
      executeFunction(): void {
        $dialogData.data = { clientId: $currentClient.id };
        $dialogData.type = dialogTypes.CREATE_NEW_REMINDER;
      },
    },
    {
      title: "TRANSFER_TO_ANOTHER_TRAINIER",
      icon: "switch",
      executeFunction(): void {
        $dialogData.data = { client: $currentClient };
        $dialogData.type = dialogTypes.TRANSFER_TO_ANOTHER_TRAINER;
      },
    },
    {
      title: "SET_TAG",
      icon: "tag",
      executeFunction(): void {
        $dialogData.data = {};
        $dialogData.type = dialogTypes.SET_TAG;
      },
    },
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        $dialogData.data = {};
        $dialogData.data = { client: $currentClient };
        $dialogData.type = dialogTypes.CREATE_EDIT_CLIENT;
      },
    },
    {
      title: "ARCHIVE",
      icon: "archive",
      executeFunction(): void {
        $dialogData.data = {};
        $dialogData.data = {
          client: $currentClient,
          executeFunction: archiveClient,
        };
        $dialogData.type = dialogTypes.SEND_TO_ARCHIVE;
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        // $dialogData.data = { title: "" };
        $dialogData.data = {
          executeFunction: deleteClient,
          title: translate("CLIENT_A"),
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
    {
      title: "CANT_DO_EXERCISES",
      icon: "bullet-list",
      executeFunction(): void {
        $dialogData.data = {};
        $dialogData.type = dialogTypes.CANT_DO_EXERCISES;
      },
    },
  ];

  const updateClient = async (user) => {
    const url = serverlessRoutes.USER;
    try {
      const res = await patchWithJwt(url, { user });
      return res;
    } catch (error) {
      console.error(error);
      return { data: { success: false } };
    }
  };

  const unarchive = async () => {
    const res = await updateClient({
      id: $currentClient.id,
      archivedAt: null,
    });
    if (res.data.success) {
      $showAlert.message = translate("CLIENT_UNARCHIVED_SUCCESSFULLY");
      trainerClientsStore.remove([$currentClient.id]);
    } else {
      $showAlert.color = "red";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };

  const deleteClient = async () => {
    const res = await updateClient({
      id: $currentClient.id,
      deletedAt: new Date().toISOString().slice(0, 10),
    });
    if (res.data.success) {
      $showAlert.message = translate("CLIENT_DELETED_SUCCESSFULLY");
      trainerClientsStore.remove([$currentClient.id]);
    } else {
      $showAlert.color = "red";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };

  let archivedClientMenuItems: MenuItem[] = [
    {
      title: "RETURN_FROM_ARCHIVE",
      icon: "unarchive",
      executeFunction(): void {
        unarchive();
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          executeFunction: deleteClient,
          title: translate("CLIENT_A"),
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  const menuItems = $currentClient.archivedAt ? archivedClientMenuItems : clientMenuItems;

  const onCopyEmail = (): void => {
    navigator.clipboard.writeText($currentClient.email);
    $showAlert.message = translate("EMAIL_COPIED_TO_CLIPBOARD");
  };

  let preparedLink: string | null = null;

  const onPrepareLink = async (): Promise<void> => {
    const AUTO_LOGIN_LINK = `https://v2.train-me.online/#/autologin/${$currentClient.hash}`;
    const url = `${serverlessRoutes.TRAINER_CLIENT}/generate-auto-login-link`;
    try {
      const result = await postWithJwt(url, { url: AUTO_LOGIN_LINK });
      preparedLink = result.data.url;
      $showAlert.message = translate("LINK_PREPARED");
    } catch (error) {
      console.error("Error generating link:", error);
      $showAlert.message = translate("ERROR_PREPARING_LINK");
    }
  };

  const onCopyLink = async (): Promise<void> => {
    if (!preparedLink) {
      $showAlert.message = translate("LINK_NOT_READY");
      return;
    }
    try {
      // Directly triggered by the button click
      await navigator.clipboard.writeText(preparedLink);
      $showAlert.message = translate("LOGIN_COPIED_TO_CLIPBOARD");
    } catch (error) {
      console.error("Clipboard error:", error);
      $showAlert.message = translate("COPY_FAILED");
    }
  };

  const archiveClient = async () => {
    const now = new Date().toISOString().slice(0, 10);
    const res = await updateClient({
      id: $currentClient.id,
      archivedAt: now,
    });
    if (res.data.success) {
      $currentClient.archivedAt = now;
      trainerClientsStore.remove([$currentClient.id]);
    } else {
      $showAlert.color = "red";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };

  const onEditExpirationDate = (): void => {
    $dialogData.data = { client: $currentClient };
    $dialogData.type = dialogTypes.EDIT_EXPIRATION_DATE;
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="p-4 mt-2 flex flex-col gap-4 border-b border-slate-200 dark:border-zinc-600">
  <div class="flex justify-between">
    <div class="flex flex-col mx-2">
      <div class="mb-4 mr-14" on:click={() => pop()} on:keypress={() => pop()}>
        <Svg name="left-arrow-button" size={20} customColor="bg-black dark:bg-white" />
      </div>
      <Avatar
        avatarUrl={$currentClient.imageUrl
          ? $currentClient.imageUrl.replace(
              "https://train-me-api.online/files/images/defaults/avatar.png",
              "/default-avatar.png"
            )
          : "/default-avatar.png"}
        size={80}
        alt="Users Avatar"
      />
      {#if $currentClient.deletedAt}
        <Badge customClass="mt-2">{translate("ACCOUNT_DELETED")}</Badge>
      {:else if $currentClient.archivedAt}
        <Badge customClass="mt-2">{translate("ACCOUNT_ARCHIVED")}</Badge>
      {:else if $currentClient.expireAt && new Date($currentClient.expireAt) < new Date()}
        <Badge customClass="mt-2 mr-0">{translate("ACCOUNT_EXPIRED")}</Badge>
      {/if}
    </div>

    <div class="flex flex-col gap-3 text-xs">
      <div class="flex items-center justify-end gap-4">
        <div class="flex flex-row items-center">
          {$currentClient.name}
          {#if $onlineUsers.includes($currentClient.id)}
            <Svg
              customColor="bg-green-700 dark:bg-green-300"
              myClass="ml-1"
              name="online"
              size={12}
            />
          {/if}
        </div>
        <More size={24} {menuItems} />
      </div>

      <div class="flex items-center justify-end gap-4" on:click={onCopyEmail}>
        <div>{$currentClient.email}</div>
        <Svg name="clipboard" size={22} />
      </div>

      <div class="flex items-center justify-end gap-4">
        <div>Login link</div>
        <div on:click={onPrepareLink}>
          <Svg name="download" size={20} />
        </div>
        <div on:click={onCopyLink}>
          <Svg name="clipboard" size={22} />
        </div>
      </div>

      <div class="flex items-center justify-end gap-4" on:click={onEditExpirationDate}>
        <div>
          Nalog ističe: {$currentClient.expireAt
            ? parseDate($currentClient.expireAt)
            : "Nije postavljeno"}
        </div>
        <Svg name="edit" size={22} />
      </div>
    </div>
  </div>

  <NotesExpansionPanel />
</div>
