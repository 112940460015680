<script lang="ts">
  import { dialogTypes, getWithJwt, serverlessRoutes, translate } from "lib";
  import Dropdown from "../../components/UI/Dropdown.svelte";
  import { dialogData, selectedIds, trainerClients } from "stores";
  import { showMenu } from "../../stores/menuStore";
  import { ButtonComponent, CheckboxComponent } from "ui";

  export let isSelectMode;
  export let areCheckboxesVisible = true;

  let menuItems = [
    {
      title: "ARCHIVE",
    },
    {
      title: "EDIT_EXPIRY_DATE",
    },
    {
      title: "DELETE",
    },
    {
      title: "SEND_MESSAGE_U",
    },
  ];

  const menuActions = {
    SEND_MESSAGE_U: () => {
      $dialogData.data = { ids: $selectedIds };
      $dialogData.type = dialogTypes.CREATE_AUTO_MESSAGE;
    },
  };

  let title = "SELECT_ACTION";
  let checkboxValueAll = false;
  let checkboxValueLoaded = false;

  const onSelectMenuItem = (event: CustomEvent<any>): void => {
    title = event.detail.title;
  };

  const onSelect = () => {
    menuActions[title]();
  };

  const onSelectAll = async () => {
    if (checkboxValueLoaded) {
      checkboxValueLoaded = false;
    }

    if (!checkboxValueAll) {
      const res = await getWithJwt(`${serverlessRoutes.TRAINER_CLIENT}/all-ids`);
      $selectedIds = res.data;
    } else {
      $selectedIds = [];
    }
    checkboxValueAll = !checkboxValueAll;
  };

  const onSelectLoaded = async () => {
    if (checkboxValueAll) {
      checkboxValueAll = false;
    }

    if (!checkboxValueLoaded) {
      $selectedIds = $trainerClients.map(({ id }) => id);
    } else {
      $selectedIds = [];
    }
    checkboxValueLoaded = !checkboxValueLoaded;
  };
</script>

<div
  class="sticky p-4 inset-x-0 bottom-0 w-full flex flex-col gap-4 bg-white dark:bg-zinc-800 border-t dark:border-zinc-600"
>
  <div class="flex items-center justify-between">
    <div>{translate("TOTAL_SELECTED_CLEIENTS")}: {$selectedIds.length}</div>
    <div style="flex flex-col">
      {#if areCheckboxesVisible}
        <span class="flex justify-end gap-2">
          <span>{translate("SELECT_ALL")}</span>
          <CheckboxComponent value={checkboxValueAll} on:change={onSelectAll} />
        </span>
        <span class="flex justify-end gap-2">
          <span>{translate("SELECT_LOADED")}</span>
          <CheckboxComponent value={checkboxValueLoaded} on:change={onSelectLoaded} />
        </span>
      {/if}
    </div>
  </div>

  <div class="flex w-full justify-between">
    <Dropdown {menuItems} {title} on:selectMenuItem={onSelectMenuItem} />
    <div class="flex gap-2">
      <ButtonComponent
        on:click={() => {
          $selectedIds = [];
          isSelectMode = !isSelectMode;
          $showMenu = true;
        }}
        customColor="bg-red-500"
      >
        {translate("CANCEL")}
      </ButtonComponent>
      <ButtonComponent
        on:click={() => onSelect()}
        disabled={$selectedIds.length < 1 || title === "SELECT_ACTION"}
      >
        {translate("CONFIRM")}
      </ButtonComponent>
    </div>
  </div>
</div>
