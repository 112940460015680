<script lang="ts">
  import Svg from "../../UI/Svg.svelte";
  import ExerciseDetails from "../../Training/ExerciseDetails.svelte";
  import { dialogData } from "stores";
    import { translate } from "lib";

  let data: any;

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <!-- <div class="flex flex-row"> -->
    <div class="text-center font-semibold">
      {translate("EXERCISE")}
    </div>
    <div class="text-center font-semibold">
      {data.name}
      {#if data.type}- {data.type}{/if}
    </div>
    <!-- <span on:click={() => ($dialogData = { type: "", data: {} })}>
      <Svg name="x" size={20} myClass="m-2" />
    </span> -->
  <!-- </div> -->

  <div class="relative h-0 w-full pt-[56.25%]">
    <iframe
      id="player"
      class="absolute top-0 left-0 h-full w-full"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen={true}
      frameborder="0"
      src="https://www.youtube.com/embed/{data.youtubeId}?playlist={data.youtubeId}&loop=1;rel=0&autoplay=1&controls=1&showinfo=0"
      title={data.name}
    />
  </div>

  <ExerciseDetails
    id={data.id}
    type={data.type}
    sets={data.details?.sets}
    reps={data.details?.reps}
    rest={data.details?.rest}
    time={data.details?.time}
    distance={data.details?.distance}
    isSupersetExercise={data.isSupersetExercise}
  />

  <div class="text-xs text-justify" data-cy="description">{data.description}</div>
</div>
