import { storage } from "./storage";
import ioV4 from "socket.io-client"; // The latest version
import { socketV2 } from "../stores/socketStore";
import { apiV2BaseUrl } from "./constants";
import { onlineUsers } from "../stores/onlineUsersStore";

export const socketConnectionV2 = async () => {
  const token = await storage.get<string>("authJwt");

  const sock = ioV4(apiV2BaseUrl, {
    query: { authToken: token },
  });

  sock.on("connect", () => {
    console.log("Connected to server:", sock.id);
    socketV2.set(sock);
  });

  sock.on("disconnect", () => {
    console.log("Disconnected from server");
    socketV2.set({});
  });

  sock.on("onlineUsers", (users) => {
    onlineUsers.set(users);
  });
};
