import { Capacitor } from "@capacitor/core";
import { storage } from "./storage";
import { version } from "./constants";

async function post(url = "", data = {}, headers?: HeadersInit, method = "POST") {
  const defaultHeaders = {
    "Content-Type": "application/json",
  };

  let finalHeaders = headers ? { ...headers } : defaultHeaders;

  if (Capacitor.isNativePlatform()) {
    const appInfo = {
      version,
      platform: Capacitor.getPlatform(),
    };
    finalHeaders["X-App-Version"] = JSON.stringify(appInfo);
  }

  const response = await fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: finalHeaders,
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  if (response.status === 401) {
    throw new Error(`Unauthorized ${location.href}`);
  }
  return response.json();
}

async function get(url = "", headers?: HeadersInit, retries = 3, delay = 1000) {
  const defaultHeaders = {
    "Content-Type": "application/json",
  };

  let finalHeaders = headers ? { ...headers } : defaultHeaders;

  if (Capacitor.isNativePlatform()) {
    const appInfo = {
      version,
      platform: Capacitor.getPlatform(),
    };
    finalHeaders["X-App-Version"] = JSON.stringify(appInfo);
  }

  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: finalHeaders,
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });

      if (response.status === 401) {
        // localStorage.removeItem("authJwt");
        // location.href = "";
        throw new Error(`Unauthorized ${location.href}`);
      }

      if (!response.ok) throw new Error(`Server error: ${response.status}`);

      return await response.json();
    } catch (error) {
      if (attempt < retries - 1) {
        console.warn(`GET request attempt ${attempt + 1} failed. Retrying in ${delay} ms...`);
        await new Promise((res) => setTimeout(res, delay)); // Wait before retrying
      } else {
        throw error; // Throw error after final attempt
      }
    }
  }
}

async function postWithJwt(url = "", data = {}) {
  const jwt = await storage.get("authJwt");

  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerJwt,
    };
    return await post(url, data, headers);
  }
}

async function deleteWithJwt(url = "", data = {}) {
  const jwt = await storage.get("authJwt");

  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerJwt,
    };
    return await post(url, data, headers, "DELETE");
  }
}

async function putWithJwt(url = "", data = {}) {
  const jwt = await storage.get("authJwt");

  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerJwt,
    };
    return await post(url, data, headers, "PUT");
  }
}

async function patchWithJwt(url = "", data = {}) {
  const jwt = await storage.get("authJwt");

  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerJwt,
    };
    return await post(url, data, headers, "PATCH");
  }
}

async function getWithJwt(url = "") {
  const jwt = await storage.get("authJwt");

  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const headers = {
      "Content-Type": "application/json",
      authorization: bearerJwt,
    };
    return await get(url, headers, 3, 500);
  }
  throw new Error("No JWT token found");
}

async function getPdfWithJwt(url = "") {
  const jwt = await storage.get("authJwt");

  if (jwt) {
    const bearerJwt = `Bearer ${jwt}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        authorization: bearerJwt,
        "Content-Type": "application/pdf",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.blob();
  }
}

export {
  get,
  post,
  postWithJwt,
  putWithJwt,
  getWithJwt,
  getPdfWithJwt,
  deleteWithJwt,
  patchWithJwt,
};
