// NOTIFIKACIJE TRENER
//   Klijent {name} je zavrsio trening {name}
//   Klijent {name} je zavrsio sve treninge
//   {name} nalog istice danas & sutra
//   {name} plan ishrane istice danas & sutra
//   {name} plan treninga istice danas & sutra
//   {name} je zavrsio/la obrok {name}
//   {name} za danas ima podsetnik {name}
//   {name} je dodao/la novi unos u galeriju
//   {name} je komentarisao/la vezbu {name} #journal & #active
//   {name} je komentarisao/la obrok {name} #journal & #active
//   {name} je komentarisao/la trening {name} #active & #journal

// NOTIFIKACIJE CLIENT
//   {name} je komentarisao vezbu {name} #journal & #active
//   {name} je komentarisao trening {name} #journal & #active
//   {name} je komentarisao obrok {name} #journal & #active
//   Nalog ti istice dana {day}
//   {name} ti je dodelio novi trening program
//   {name} ti je dodelio novi plan ishrane

//   ??? {name} ti je dodelio/la plan treninga
//   ??? {name} ti je dodelio/la plan ishrane



enum ClientNotification { // ovo se ne koristi...
  COMMENTED_EXERCISE,
  COMMENTED_WORKOUT,
  COMMENTED_MEAL,
  ACCOUNT_EXPIRES,
  ASSIGNED_MEAL_PLAN,
  ASSIGNED_PROGRAM,
}

enum TrainersClientNotification {
  // TRAINER
  ACCOUNT_EXPIRES_TOMORROW,
  ACCOUNT_EXPIRES_TODAY,
  MEAL_PLAN_EXPIRES_TOMORROW,
  MEAL_PLAN_EXPIRES_TODAY,
  PROGRAM_EXPIRES_TOMORROW,
  PROGRAM_EXPIRES_TODAY,
  FINISHED_MEAL,
  FINISHED_WORKOUT,
  FINISHED_ALL_WORKOUTS,
  NEW_GALLERY_ENTRY,
  NEW_MEASUREMENT,
  NEW_HABIT,
  COMMENTED_EXERCISE,
  COMMENTED_WORKOUT,
  COMMENTED_MEAL,
  REMINDER,
  // CLIENT
  ACCOUNT_EXPIRES,
  ASSIGNED_MEAL_PLAN,
  ASSIGNED_PROGRAM,
}

// const newNotifications = [{
//   type: TrainersClientNotification.COMMENTED_EXERCISE,
//   data: "{clientId: 12345, workoutId: 12345, exerciseId: 12345}"
// }, {
//   type: TrainersClientNotification.COMMENTED_WORKOUT,
//   data: "{clientId: 12345, workoutId: 12345}"
// }, {
//   type: TrainersClientNotification.COMMENTED_MEAL,
//   data: "{clientId: 12345, mealId: 12345}"
// }, {
//   type: TrainersClientNotification.FINISHED_WORKOUT,
//   data: "{clientId: 12345}"
// }, {
//   type: TrainersClientNotification.FINISHED_MEAL,
//   data: "{clientId: 12345}"
// }, {
//   type: TrainersClientNotification.FINISHED_ALL_WORKOUTS,
//   data: "{clientId: 12345, programId: 12345}"
// }, {
//   type: TrainersClientNotification.PROGRAM_EXPIRES_TODAY,
//   data: "{clientId: 12345, programId: 12345}"
// }, {
//   type: TrainersClientNotification.PROGRAM_EXPIRES_TOMORROW,
//   data: "{clientId: 12345, programId: 12345}"
// }, {
//   type: TrainersClientNotification.MEAL_PLAN_EXPIRES_TODAY,
//   data: "{clientId: 12345, mealPlanId: 12345}"
// }, {
//   type: TrainersClientNotification.MEAL_PLAN_EXPIRES_TOMORROW,
//   data: "{clientId: 12345, mealPlanId: 12345}"
// }, {
//   type: TrainersClientNotification.ACCOUNT_EXPIRES_TODAY,
//   data: "{clientId: 12345}"
// }, {
//   type: TrainersClientNotification.ACCOUNT_EXPIRES_TOMORROW,
//   data: "{clientId: 12345}"
// }, {
//   type: TrainersClientNotification.NEW_GALLERY_ENTRY,
//   data: "{clientId: 12345}"
// }, {
//   type: TrainersClientNotification.NEW_MEASUREMENT,
//   data: "{clientId: 12345}"
// }, {
//   type: TrainersClientNotification.NEW_HABIT,
//   data: "{clientId: 12345}"
// }, {
//   type: TrainersClientNotification.REMINDER,
//   data: "{clientId: 12345}"
// }, {
//   type: TrainersClientNotification.ASSIGNED_MEAL_PLAN,
//   data: ""
// }, {
//   type: TrainersClientNotification.ASSIGNED_PROGRAM,
//   data: ""
// }, {
//   type: TrainersClientNotification.ACCOUNT_EXPIRES,
//   data: ""
// }];

export {ClientNotification, TrainersClientNotification};
