import { user } from "../stores/userStore";
import { serverlessRoutes } from "./constants";
import { getWithJwt, postWithJwt } from "./requests";
import { storage } from "./storage";

export const fetchUser = async () => {
  const url = serverlessRoutes.USER;
  try {
    const fetchedUser = await getWithJwt(url);
    user.set(fetchedUser);
    await storage.set("role", fetchedUser.roleId);

    if (
      !fetchedUser.avatarUrl?.includes("imagedelivery") &&
      fetchedUser.hash &&
      fetchedUser.fileableId
    ) { // ZA AVATAR MIGRACIJU
      postWithJwt(`${serverlessRoutes.FILE}/migrate-s3-avatar`)
        .then((response) => {
          if (response.error) { return; }

          user.update((store) => {
            store.avatarUrl = response.data.avatarUrl;
            store.hash = null;
            store.fileableId = null;

            return store;
          });

          fetchedUser.avatarUrl = response.data.avatarUrl;
          fetchedUser.hash = null;
          fetchedUser.fileableId = null;
        });
    }


    return fetchedUser;
  } catch (err) {}
};

export const refreshToken = async () => {
  const url = `${serverlessRoutes.USER}/refresh-token`;
  try {
    const result = await getWithJwt(url);

    if (result?.accessToken) {
      await storage.set("authJwt", result.accessToken);
    }
  } catch (err) {}
};
