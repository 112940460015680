<script lang="ts">
  import { onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { Capacitor } from "@capacitor/core";
  import { animations, entities } from "lib";
  import { mealPlansStore } from "stores";
  import { MealPlanComponent, MealPlansToolbarComponent } from "diet";
  import { InfiniteScroll } from "ui";
  import TopMenu from "../../components/TopMenu.svelte";

  onMount(() => {
    mealPlansStore.loadCache();
    mealPlansStore.fetchData();
  });
</script>

<TopMenu entity={entities.DIET} />

<div class="p-4 flex flex-col gap-4">
  <MealPlansToolbarComponent isAddVisible />

  {#each $mealPlansStore.items as mealPlan (mealPlan.id)}
    <div animate:flip={animations.flip}>
      <MealPlanComponent {mealPlan} />
    </div>
  {/each}

  <InfiniteScroll
    hasMore={$mealPlansStore.hasMore}
    isFetchingMore={$mealPlansStore.isFetchingMore}
    on:loadMore={mealPlansStore.fetchMoreData}
  />

  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
