<script lang="ts">
  import { onMount } from "svelte";
  import { patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import {
    dialogData,
    showAlert,
    trainerClientPrograms,
    trainerPrograms,
    trainerWorkouts,
    workoutsStore,
  } from "stores";
  import { updateTrainerPrograms } from "../../../lib/updateTrainerPrograms";
  import { ButtonComponent, InputField } from "ui";

  let data: any;

  const form = {
    name: { value: "", error: "" },
    description: { value: "", error: "" },
  };

  let disabled = true;
  let isLoading = false;
  const dialogType = data.workout ? translate("EDIT") : translate("CREATE");

  const onInput = (field: keyof typeof form): void => {
    const { value } = form[field];

    if (field === "name") {
      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onSubmit = async (): Promise<void> => {
    const { workout, programId } = data;
    const { name, description } = form;

    isLoading = true;

    try {
      let response: any;

      if (workout) {
        response = await patchWithJwt(serverlessRoutes.WORKOUT, {
          partialWorkoutMap: {
            [workout.id]: {
              name: name.value,
              description: description.value
            }
          }
        });
        response.data.workouts[0].exercises = []; // fix za neki bag ne mogu se setim koji

        if (workout.default) {
          workoutsStore.replace([response.data.workouts[0]]);
        } else {
          $trainerPrograms.forEach((program) => {
            const w = program?.workouts?.find(({ id }) => id === workout.id);
            if (w) {
              const index = program.workouts.indexOf(w);
              program.workouts.splice(index, 1, response.data.workouts[0]);
            }
          });
          $trainerPrograms = $trainerPrograms;

          $trainerClientPrograms.forEach((program) => {
            const w = program?.workouts?.find(({ id }) => id === workout.id);
            if (w) {
              const index = program.workouts.indexOf(w);
              program.workouts.splice(index, 1, response.data.workouts[0]);
            }
          });
          $trainerClientPrograms = $trainerClientPrograms;
        }
      } else {
        // Kreiraj dan odmora
        response = await postWithJwt(serverlessRoutes.WORKOUT, {
          partialWorkout: {
            name: name.value,
            description: description.value,
            entityType: 4,
            programId,
            isTemplate: 0
          }
        });

        updateTrainerPrograms(programId, (p) => p.workouts.push(response.data.workout));
      }

      $showAlert.color = "black";

      if (workout) {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_EDITED")} ${translate("REST_DAY")}
        `;
      } else {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_CREATED")} ${translate("REST_DAY")}
        `;
      }

      $dialogData.data = {};
      $dialogData.type = "";
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";

      if (workout) {
        $showAlert.message = translate("ERROR_EDITING_REST_DAY");
      } else {
        $showAlert.message = translate("ERROR_CREATING_REST_DAY");
      }
    }
  };

  onMount((): void => {
    if (data.workout) {
      const { name, description } = data.workout;

      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {dialogType}
    {translate("REST_DAY").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <InputField
      label={translate("NAME")}
      error={form.name.error}
      bind:value={form.name.value}
      on:input={() => onInput("name")}
    />
    <!-- <div>
      <Label for="name">{translate("NAME")}:</Label>
      <Input
        id="name"
        placeholder={translate("NAME")}
        bind:value={form.name.value}
        on:input={() => onInput("name")}
      />
      <Helper color="red">
        {#if form.name.error}
          {form.name.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div> -->

    <!-- <div>
      <Label for="description">
        {translate("DESCRIPTION")}:
      </Label>
      <Textarea
        id="description"
        placeholder={translate("DESCRIPTION")}
        rows="4"
        bind:value={form.description.value}
        on:input={() => onInput("description")}
      />
      <Helper color="red">
        {#if form.description.error}
          {form.description.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div> -->

    <InputField
      label={translate("DESCRIPTION")}
      error={form.description.error}
      bind:value={form.description.value}
      on:input={() => onInput("description")}
    />

    <div class="flex justify-center">
      <ButtonComponent type="submit" {disabled} {isLoading}>
        {dialogType}
      </ButtonComponent>
    </div>
  </form>
</div>
