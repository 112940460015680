<script lang="ts">
  import { onMount } from "svelte";
  import { dialogTypes } from "../../lib/constants";
  import { dialogData } from "../../stores/dialogDataStore";
  import { signCloudflareImage } from "../../lib/cloudflare/uploadImageCloudflare";
  import type { IMessage } from "src/interfaces/Chat";
  import { parseDateWithTime, translate } from "lib";
  import { user } from "stores";

  export let message: IMessage;
  export let isMine: boolean;
  export let scrollToBottom = () => {};
  export let isBottomImage = false;

  export let touchstart: (
    messageId: number,
    messagePayload: string,
    timestamp: any,
    isMine: boolean,
    deletedAt: string | null
  ) => void;
  export let touchend: () => void;

  let imgUrl = "";
  let showTimestamp = false;

  const align = isMine ? "flex-end" : "flex-start";

  onMount(async () => {
    if (message?.payload?.includes(":cloudflareimg:")) {
      imgUrl = await signCloudflareImage(message?.payload);
      return;
    }
  });
</script>

<!-- svelte-ignore a11y-media-has-caption -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
{#if message?.deletedAt}
  <div
    unselectable="on"
    id={`${message?.id}`}
    on:click={() => (showTimestamp = !showTimestamp)}
    on:keypress={() => (showTimestamp = !showTimestamp)}
    class={`deleted-message max-w-80 font-sans whitespace-pre-wrap mgl-8 mgr-8 mt-1 text-red-900 dark:text-red-200 bg-slate-100 dark:bg-zinc-800 dark:border dark:border-zinc-600`}
    style={`
  font-size: ${$user.id === 13 ? 13 : 14}px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  align-self: ${align};`}
  >
    <p class="italic">{translate("DELETED_MESSAGE")}</p>
  </div>
  {#if showTimestamp}
    <span
      class={`ml-3 mr-3 mt-1 text-xs font-medium text-slate-900 dark:text-slate-100`}
      style={`align-self: ${align}`}>{parseDateWithTime(message.createdAt)}</span
    >
  {/if}
{:else}
  <div
    class="message mgl-8 mgr-8 mt-2"
    style={`align-self: ${align};`}
    class:video-height={message?.payload?.includes("cloudflarestream")}
    class:image-height={message?.payload?.includes(":cloudflareimg:")}
    on:mousedown={() =>
      touchstart(message?.id, "FILE_MESSAGE", message?.createdAt, isMine, message?.deletedAt)}
    on:mouseup={() => touchend()}
    on:touchend={() => touchend()}
    on:touchstart={() =>
      touchstart(message?.id, "FILE_MESSAGE", message?.createdAt, isMine, message?.deletedAt)}
  >
    {#if message?.payload && message?.payload.includes("cloudflarestream")}
      <div>
        <!-- loading="lazy" -->
        <iframe
          title="Video player"
          src={message?.payload}
          style="width: 100%; height: auto;"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen={true}
          on:load={() => (isBottomImage === true ? scrollToBottom() : {})}
        ></iframe>
      </div>
    {:else if imgUrl !== ""}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="gallery-entry-image"
        role="button"
        tabindex="0"
        on:click={() => {
          $dialogData.data = { src: imgUrl };
          $dialogData.type = dialogTypes.IMAGE_ZOOM;
        }}
      >
        <img
          src={imgUrl}
          alt={"img"}
          class="image border rounded-md border-slate-200 dark:border-zinc-600"
          on:load={() => (isBottomImage === true ? scrollToBottom() : {})}
        />
      </div>
    {/if}
  </div>
{/if}

<style>
  .deleted-message {
    align-self: flex-end;
    padding: 6px 10px 6px 10px;
    border-radius: 16px;
    width: fit-content;
  }
  .message {
    align-self: flex-end;
    width: fit-content;
    max-width: 70%;
  }
  .video-height {
    height: 150px;
  }
  .image-height {
    height: 384px;
  }
  .image {
    /*max-width: 256px;
    max-height: 384px;*/
    object-fit: contain;
    height: 384px;
    width: auto;
  }
  /* .video {
    width: 256px;
    height: fit-content;
  } */
</style>
